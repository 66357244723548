import React from "react";
import { TouchableOpacity } from 'react-native';
import View from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { StyledText, ThemeUtil } from '@space/common';
import { useState } from "react";
import {
    colors,
    palette,
} from '@features/preferences/style/themes';
import theme from 'styled-theming';

type FileInputProps = {
    title: string;
    onSelectFile: (file: File) => void;
    onPreviewLoaded: (buffer: string | ArrayBuffer | null) => void;
    onFileSizeError: () => void;
};

const fontOptions = theme('mode', {
    light: colors.black,
    dark: colors.white,
});

const StyledFileInput: React.FC<FileInputProps> = ({
    title,
    onSelectFile,
    onPreviewLoaded,
    onFileSizeError
}) => {

    const styleName = useTheme().mode;
    const fontColor = ThemeUtil.getThemeValue(fontOptions)(styleName);
    const maxAllowedSize = 2097152;

    const [currentFile, setFile] = useState<File | undefined>();

    const resetFile = () => {
        setFile(undefined);
    };

    const onlocalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files[0].size > maxAllowedSize) {
            onFileSizeError();
            resetFile();
            return;
        };
        
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            setFile(file);
            let reader = new FileReader();

            reader.onloadend = () => {
                onPreviewLoaded(reader.result);
            };

            reader.readAsDataURL(file);
            onSelectFile(file);
        }
    }

    return (
        <StyledTouchableOpacity>
            <input type="file" name="file" id="file" style={{
                width: '0.1px',
                height: '0.1px',
                opacity: 0,
                overflow: 'hidden',
                position: 'absolute'
            }} onChange={onlocalChange} />
            <label htmlFor="file" style={{
                fontFamily: 'Barlow_400Regular',
                color: fontColor,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                cursor: 'pointer',
                textDecorationLine: 'underline',
                textDecorationStyle: 'solid',
            }}>{title}</label>
        </StyledTouchableOpacity>
    );
}

export default StyledFileInput;

const StyledTouchableOpacity = styled.View`
  border-radius: 4px;
  margin: 0px 0px 4px 0px;
`;


