import { Space, SpaceGroup, SpaceTag, SpaceType } from 'SpaceFeature';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import {
  createSpaceAsync,
  createSpaceGroupAsync,
  createSpaceTagAsync,
  createSpaceTypeAsync,
  deleteSpaceAsync,
  deleteSpaceGroupAsync,
  deleteSpaceTagAsync,
  deleteSpaceTypeAsync,
  fetchAllSpacesAsync,
  fetchSpaceGroupsAsync,
  fetchSpaceTagsAsync,
  fetchSpaceTypesAsync,
  fetchSpacesAsync,
  updateSpaceAsync,
  updateSpaceGroupAsync,
  updateSpaceTagAsync,
  updateSpaceTypeAsync
} from './actions';
import { from, of } from 'rxjs';

import { RootEpic } from 'StoreTypes';
import { defer } from 'rxjs';
import { isActionOf } from 'typesafe-actions';

export const fetchAllSpacesEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchAllSpacesAsync.request)),
    switchMap((action) =>
      defer(() => from(api.admin.fetchAllSpaces())).pipe(
        map((data) => data as Space[]),
        map(fetchAllSpacesAsync.success),
        catchError((error) => of(fetchAllSpacesAsync.failure(error))),
      ),
    ),
  );

export const loadSpacesEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchSpacesAsync.request)),
    switchMap((action) =>
      defer(() => from(api.common.fetchSpaces(action.payload))).pipe(
        map((data) => data as Space[]),
        map(fetchSpacesAsync.success),
        catchError((error) => of(fetchSpacesAsync.failure(error))),
      ),
    ),
  );

export const createSpaceEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createSpaceAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.createSpace(action.payload)
      ).pipe(
        map((updateResponse) => {
          return createSpaceAsync.success(updateResponse);
        }),
        catchError((err) => of(createSpaceAsync.failure(err))),
      );
    }),
  );

export const updateSpaceEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateSpaceAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.updateSpace(action.payload.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateSpaceAsync.success(updateResponse);
        }),
        catchError((err) => of(updateSpaceAsync.failure(err))),
      );
    }),
  );

export const deleteSpaceEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteSpaceAsync.request)),
    switchMap((action) =>
      defer(() => from(api.admin.deleteSpace(state$.value.space.selectedSpace))).pipe(
        map(deleteSpaceAsync.success),
        catchError((error) => of(deleteSpaceAsync.failure(error.message))),
      ),
    ),
  );

export const fetchSpaceGroupsEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf([fetchSpaceGroupsAsync.request])),
    switchMap((action) =>
      defer(() => from(api.admin.fetchSpaceGroups())).pipe(
        map((data) => data as SpaceGroup[]),
        map(fetchSpaceGroupsAsync.success),
        catchError((error) => of(fetchSpaceGroupsAsync.failure(error))),
      ),
    ),
  );

export const createSpaceGroupEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createSpaceGroupAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.createSpaceGroup(action.payload)
      ).pipe(
        map((updateResponse) => {
          return createSpaceGroupAsync.success(updateResponse);
        }),
        catchError((err) => of(createSpaceGroupAsync.failure(err))),
      );
    }),
  );

export const updateSpaceGroupEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateSpaceGroupAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.updateSpaceGroup(state$.value.space.selectedSpaceGroup.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateSpaceGroupAsync.success(updateResponse);
        }),
        catchError((err) => of(updateSpaceAsync.failure(err))),
      );
    }),
  );

export const deleteSpaceGroupEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteSpaceGroupAsync.request)),
    switchMap((action) =>
      defer(() => from(api.admin.deleteSpaceGroup(state$.value.space.selectedSpaceGroup))).pipe(
        map(deleteSpaceGroupAsync.success),
        catchError((error) => of(deleteSpaceAsync.failure(error.message))),
      ),
    ),
  );

export const fetchSpaceTagsEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf([fetchSpaceTagsAsync.request])),
    switchMap((action) =>
      defer(() => from(api.common.fetchSpaceTags())).pipe(
        map((data) => data as SpaceTag[]),
        map(fetchSpaceTagsAsync.success),
        catchError((error) => of(fetchSpaceTagsAsync.failure(error))),
      ),
    ),
  );

export const fetchSpaceTypesEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf([fetchSpaceTypesAsync.request])),
    switchMap((action) =>
      defer(() => from(api.admin.fetchSpaceTypes())).pipe(
        map((data) => data as SpaceType[]),
        map(fetchSpaceTypesAsync.success),
        catchError((error) => of(fetchSpaceTypesAsync.failure(error))),
      ),
    ),
  );

export const createSpaceTagEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createSpaceTagAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.createSpaceTag(action.payload)
      ).pipe(
        map((updateResponse) => {
          return createSpaceTagAsync.success(updateResponse);
        }),
        catchError((err) => of(createSpaceTagAsync.failure(err))),
      );
    }),
  );

export const updateSpaceTagEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateSpaceTagAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.updateSpaceTag(state$.value.space.selectedSpaceTag.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateSpaceTagAsync.success(updateResponse);
        }),
        catchError((err) => of(updateSpaceTagAsync.failure(err))),
      );
    }),
  );

export const deleteSpaceTagEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteSpaceTagAsync.request)),
    switchMap((action) =>
      defer(() => from(api.admin.deleteSpaceTag(state$.value.space.selectedSpaceTag))).pipe(
        map(deleteSpaceTagAsync.success),
        catchError((error) => of(deleteSpaceTagAsync.failure(error.message))),
      ),
    ),
  );

export const createSpaceTypeEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createSpaceTypeAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.createSpaceType(action.payload)
      ).pipe(
        map((updateResponse) => {
          return createSpaceTypeAsync.success(updateResponse);
        }),
        catchError((err) => of(createSpaceTypeAsync.failure(err))),
      );
    }),
  );

export const updateSpaceTypeEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateSpaceTypeAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.updateSpaceType(state$.value.space.selectedSpaceType.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateSpaceTypeAsync.success(updateResponse);
        }),
        catchError((err) => of(updateSpaceTypeAsync.failure(err))),
      );
    }),
  );

export const deleteSpaceTypeEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteSpaceTypeAsync.request)),
    switchMap((action) =>
      defer(() => from(api.admin.deleteSpaceType(state$.value.space.selectedSpaceType))).pipe(
        map(deleteSpaceTypeAsync.success),
        catchError((error) => of(deleteSpaceTypeAsync.failure(error.message))),
      ),
    ),
  );
