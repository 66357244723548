import React, { useContext } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { useWindowWidth } from '@react-hook/window-size';
import AvatarBooking from './AvatarBooking';
import { connect } from 'react-redux';
import { RootState } from 'StoreTypes';
import { loadBookingAsync } from '@features/booking/actions';
import { StyledText } from '@space/common';
import { palette } from '@features/preferences/style/themes';
import styled from 'styled-components/native';
import { User } from 'UserFeature';
import { SpaceGroup, Space } from 'SpaceFeature';
import { SpaceUserMapping } from 'BookingFeature';
import { localize, localizedName } from '../localization';
import { SpaceModel } from '@features/space';

const mapStateToProps = (state: RootState) => ({
  selectedDate: state.booking.selectedDate,
  bookings: state.booking.bookings,
  error: state.booking.error,
  spaces: state.space.allSpaces,
  allspaces: state.space.allSpaces,
  spaceGroups: state.space.spaceGroups,
  activeLanguages: state.organization.languages,
});

const dispatchProps = {
  loadBookings: loadBookingAsync.request,
};

type AvatarBookingCarouselProps = {
  users: User[];
  onPress: (user: User[]) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  AvatarBookingCarouselProps;

const AvatarBookingCarousel: React.FC<Props> = ({
  selectedDate,
  bookings,
  onPress,
  error,
  users,
  spaces,
  spaceGroups,
  activeLanguages,
}) => {
  const capacity = (item: User) => {
    const todayBooking = item.todayBooking;
    if (_.first(todayBooking.spaces)?.type?.model === SpaceModel.ROOM) {
      const space = _.first(item.todayBooking.spaces);
      return localize('CARROUSEL_ROOM_CAPACITY', { count: space?.capacity ?? 0 })
    } else if (_.first(todayBooking.spaces)?.type?.model === SpaceModel.POD) {
      const spaceIds = item.todayBooking.spaceUserMapping.map((key: SpaceUserMapping) => key.spaceId);
      const groupIds = spaces.filter((space: Space) => {
        return spaceIds.includes(space.id);
      }).map((key) => key.groupId);
      const groups = spaceGroups.filter((group: SpaceGroup) => groupIds.includes(group.id));
      const total = groups.reduce((total, current) => total + current.spaces.length, 0);
      return localize('CARROUSEL_POD_CAPACITY', { occupation: todayBooking.spaceUserMapping.length, total: total });
    } else if (_.first(todayBooking.spaces)?.type?.model === SpaceModel.SEAT) {
      return localizedName(_.first(item.todayBooking.spaces)?.name, activeLanguages) ?? '';
    }
    return '';
  };

  const avatars: any[] = users.map((item, index) => {
    const space = spaces.filter((spaceItem: Space) => spaceItem.id === item.todayBooking.sp)
    return (
      <AvatarBooking
        key={index}
        name={item.firstName}
        lastName={item.lastName}
        spaceName={localizedName(_.first(item.todayBooking.spaces)?.type.name, activeLanguages)}
        capacity={capacity(item)}
        src={item.avatar}
        onPress={() => onPress(item)}
      />
    );
  });

  return (
    <Container>
      <StyledText variant={'h3'} style={{ letterSpacing: 3.2 }}>
        {localize('CARROUSEL_TITLE').toUpperCase()}
      </StyledText>
      <ItemContainer>
        {avatars.length === 0 || error ? (
          <StyledText variant={'body2'} color={'hint'}>
            {error
              ? localize('ERROR_DEFAULT')
              : localize('EMPTY_CARROUSEL_TITLE')}
          </StyledText>
        ) : (
          <ScrollView
            showsHorizontalScrollIndicator={false}
            horizontal>
            {avatars}
          </ScrollView>
        )}
      </ItemContainer>
    </Container >
  );
};

const Container = styled.View`
  padding-top:24px;
  padding-left: 48px;
  width: 100%;
`;

const ItemContainer = styled.View`
  margin-top: 9px;
  flex-direction: row;
`;

export default connect(mapStateToProps, dispatchProps)(AvatarBookingCarousel);

