import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { User, UserRole, UserGroup } from 'UserFeature';
import {
  loadUserRolesAsync,
  loadUsersAsync,
  loadUserGroupsAsync,
  setSpaceIdSelectedForMapping,
  setUserSearchTextForMapping,
  createUserAsync,
  updateUserAsync,
  updateUserNoImageAsync,
  deleteUserAsync,
  openUserEditor,
  closeUserEditor,
  openUserRolesEditor,
  closeUserRolesEditor,
  openUserGroupsEditor,
  closeUserGroupsEditor,
  showConfirmUserDelete,
  showConfirmUserDisabled,
  selectUser,
  clearError,
  selectUserRole,
  selectUserGroup,
  deleteUserRoleAsync,
  deleteUserGroupAsync,
  createUserRoleAsync,
  createUserGroupAsync,
  updateUserRoleAsync,
  updateUserGroupAsync,
  showConfirmUserRoleDelete,
  showConfirmUserGroupDelete
} from './actions';

const reducer = combineReducers({
  isLoadingUsers: createReducer<boolean>(false)
    .handleAction([loadUsersAsync.request], (_state, _action) => true)
    .handleAction(
      [loadUsersAsync.success, loadUsersAsync.failure],
      (_state, _action) => false,
    ),

  usersError: createReducer('')
    .handleAction([loadUsersAsync.failure], (_state, action) => action.payload)
    .handleAction([loadUsersAsync.request], (_state, _action) => ''),

  users: createReducer([] as User[])
    .handleAction(loadUsersAsync.success, (_state, action) => {
      return action.payload;
    })
    .handleAction(createUserAsync.success, (state, action) => {
      return [...state, action.payload];
    })
    .handleAction(updateUserAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    })
    .handleAction(updateUserNoImageAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    })
    .handleAction(deleteUserAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  userDeleted: createReducer<User | null>(null).handleAction(
    deleteUserAsync.success,
    (state, action) => action.payload,
  ),

  userRoleDeleted: createReducer<UserRole | null>(null).handleAction(
    deleteUserRoleAsync.success,
    (state, action) => action.payload,
  ),

  userGroupDeleted: createReducer<UserGroup | null>(null).handleAction(
    deleteUserGroupAsync.success,
    (state, action) => action.payload,
  ),

  isConfirmUserDeleteViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmUserDelete],
    (state, action) => action.payload,
  ),

  isConfirmUserDisabledViewDisplayed: createReducer<boolean>(false)
    .handleAction(
      [showConfirmUserDisabled],
      (state, action) => action.payload,
    ).handleAction(
      [updateUserNoImageAsync.success, updateUserNoImageAsync.failure],
      (_state, _action) => false,
    ),

  isConfirmUserRoleDeleteViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmUserRoleDelete],
    (state, action) => action.payload,
  ),

  isConfirmUserGroupDeleteViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmUserGroupDelete],
    (state, action) => action.payload,
  ),

  userSearchTextForMapping: createReducer<string | null>(null).handleAction(
    setUserSearchTextForMapping,
    (state, action) => action.payload,
  ),

  spaceIdSelectedForMapping: createReducer<string | null>(null).handleAction(
    setSpaceIdSelectedForMapping,
    (state, action) => action.payload,
  ),

  isLoadingUserRoles: createReducer<boolean>(false)
    .handleAction([loadUserRolesAsync.request], (_state, _action) => true)
    .handleAction(
      [loadUserRolesAsync.success, loadUserRolesAsync.failure],
      (_state, _action) => false,
    ),

  userRolesError: createReducer('')
    .handleAction([loadUserRolesAsync.failure], (_state, action) => action.payload)
    .handleAction([loadUserRolesAsync.request], (_state, _action) => ''),

  userRoles: createReducer([] as UserRole[])
    .handleAction(loadUserRolesAsync.success, (_state, action) => {
      return action.payload;
    })
    .handleAction(createUserRoleAsync.success, (state, action) => {
      return [...state, action.payload];
    })
    .handleAction(updateUserRoleAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    })
    .handleAction(deleteUserRoleAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  isLoadingUserGroups: createReducer<boolean>(false)
    .handleAction([loadUserGroupsAsync.request], (_state, _action) => true)
    .handleAction(
      [loadUserGroupsAsync.success, loadUserGroupsAsync.failure],
      (_state, _action) => false,
    ),

  userGroupsError: createReducer('')
    .handleAction([loadUserGroupsAsync.failure], (_state, action) => action.payload)
    .handleAction([loadUserGroupsAsync.request], (_state, _action) => ''),

  userGroups: createReducer([] as UserGroup[])
    .handleAction(loadUserGroupsAsync.success, (_state, action) => {
      return action.payload;
    })
    .handleAction(createUserGroupAsync.success, (state, action) => {
      return [...state, action.payload];
    })
    .handleAction(updateUserGroupAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    })
    .handleAction(deleteUserGroupAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  setUserError: createReducer<string | null>(null) 
    .handleAction([createUserAsync.failure],(state, action) => action.payload)
    .handleAction([updateUserAsync.failure],(state, action) => action.payload)
    .handleAction([createUserAsync.request],(state, action) => '')
    .handleAction([updateUserAsync.request],(state, action) => '')
    .handleAction(clearError, (state, action) => null),

  setUserNoImageError: createReducer<string | null>(null)
    .handleAction(
      [
        updateUserNoImageAsync.failure
      ],
      (state, action) => action.payload,
    )
    .handleAction(clearError, (state, action) => null),

  setUserRoleError: createReducer<string | null>(null)
    .handleAction(
      [
        createUserRoleAsync.failure,
        updateUserRoleAsync.failure
      ],
      (state, action) => action.payload,
    )
    .handleAction(clearError, (state, action) => null),

  setUserGroupError: createReducer<string | null>(null)
    .handleAction(
      [
        createUserGroupAsync.failure,
        updateUserGroupAsync.failure
      ],
      (state, action) => action.payload,
    )
    .handleAction(clearError, (state, action) => null),

  editUserIsDisplayed: createReducer<boolean>(false)
    .handleAction(
      [openUserEditor, closeUserEditor],
      (state, action) => action.payload
    ).handleAction(createUserAsync.success, (state, action) => {
      return false;
    }).handleAction(updateUserAsync.success, (state, action) => {
      return false;
    }),

  editUserRoleIsDisplayed: createReducer<boolean>(false).handleAction(
    [openUserRolesEditor, closeUserRolesEditor],
    (state, action) => action.payload
  ),

  editUserGroupIsDisplayed: createReducer<boolean>(false).handleAction(
    [openUserGroupsEditor, closeUserGroupsEditor],
    (state, action) => action.payload
  ),

  selectedUser: createReducer<User | null>(null)
    .handleAction(
      selectUser,
      (state, action) => action.payload,
    ).handleAction(createUserAsync.success, (state, action) => {
      return null;
    }).handleAction(updateUserAsync.success, (state, action) => {
      return null;
    }).handleAction(updateUserNoImageAsync.success, (state, action) => {
      return null;
    }),

  selectedUserRole: createReducer<UserRole | null>(null).handleAction(
    selectUserRole,
    (state, action) => action.payload,
  ).handleAction(createUserRoleAsync.success, (state, action) => {
    return null;
  }).handleAction(updateUserRoleAsync.success, (state, action) => {
    return null;
  }),

  selectedUserGroup: createReducer<UserGroup | null>(null).handleAction(
    selectUserGroup,
    (state, action) => action.payload,
  ).handleAction(createUserGroupAsync.success, (state, action) => {
    return null;
  }).handleAction(updateUserGroupAsync.success, (state, action) => {
    return null;
  }),

});

export default reducer;
