import theme from 'styled-theming';

export type Colors =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success'
  | 'background'
  | 'stroke';

export type TextColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'disabled'
  | 'hint'
  | 'title'
  | 'inactive';

export const palette = {
  primary: theme('mode', {
    light: '#44C3AC',
    dark: '#8DC89D',
  }),

  secondary: theme('mode', {
    light: '#34A28E',
    dark: '#8DC89D',
  }),

  tertiary: theme('mode', {
    light: '#7D7D7D',
    dark: '#999999',
  }),

  destructive: theme('mode', {
    light: '#FF6A6A',
    dark: '#FF6A6A',
  }),

  error: theme('mode', {
    light: '#FF6A6A',
    dark: '#FF6A6A',
  }),

  warning: theme('mode', {
    light: '#EEBC09',
    dark: '#EEBC09',
  }),

  info: theme('mode', {
    light: '#000',
    dark: '#FFF',
  }),

  success: theme('mode', {
    light: '#3A6445',
    dark: '#3A6445',
  }),

  common: theme('mode', {
    light: '#FFFFFF',
    dark: '#111111',
  }),

  commonInverted: theme('mode', {
    light: '#111111',
    dark: '#FFFFFF',
  }),

  border: theme('mode', {
    light: '#333333',
    dark: '#7D7D7D',
  }),

  borderLight: theme('mode', {
    light: '#333333',
    dark: '#F2F2F2',
  }),

  background: theme('mode', {
    light: '#FCFCFC',
    dark: '#1C1C1C',
  }),

  actionItem: theme('mode', {
    light: '#F2F2F2',
    dark: '#111111',
  }),

  disable: theme('mode', {
    light: '#999',
    dark: '#999',
  }),

  textInput: theme('mode', {
    light: '#F2F2F2',
    dark: 'rgba(0, 0, 0, 0.45)',
  }),
  text: theme.variants('mode', 'color', {
    primary: { light: '#202020', dark: '#FFFFFF' },
    secondary: { light: '#7D7D7D', dark: '#7D7D7D' },
    tertiary: { light: '#7D7D7D', dark: '#999999' },
    quaternary: { light: '#34A28E', dark: '#8DC89D' },
    disabled: { light: 'rgba(0, 0, 0, 0.38)', dark: 'rgba(255, 255, 255, 0.5)' },
    hint: { light: '#7d7d7d', dark: '#999999' },
    title: { light: '#595959', dark: '#C5C5C5' },
    inactive: { light: '#C5C5C5', dark: '#595959' },
  }),

  action: theme.variants('mode', 'state', {
    active: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    hover: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    hoverOpacity: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    selected: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    selectedOpacity: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    disabled: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    disabledBackground: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    disabledOpacity: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    focus: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    focusOpacity: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
    activatedOpacity: { light: 'rgba(0, 0, 0, 0.87)', dark: '#fff' },
  }),
};

export const colors = {
  grey1: '#F2F2F2',
  grey2: '#C5C5C5',
  grey3: '#999999',
  grey4: '#202020',
  grey5: '#333333',
  grey6: '#222222',
  grey7: '#595959',
  white: '#FFFFFF',
  black: '#202020',
  primaryLight: '#44C3AC',
  primaryDark: '#8DC89D',
  destructive: '#FF6A6A',
  quaternary: '#34A28E',
}

export const shadow = theme('mode', {
  light: '0px 4px 10px rgba(0, 0, 0, 0.15)',
  dark: '0px 5px 10px rgba(0, 0, 0, 0.6)',
});
