import { AccountInfo, Configuration, RedirectRequest, EndSessionRequest, SilentRequest } from '@azure/msal-browser';

export const msalConfig = (redirectUrl: string, clientId: string): Configuration => {
    return {
        auth: {
            clientId: clientId || '',
            redirectUri: redirectUrl,
            authority: 'https://login.microsoftonline.com/organizations/'
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false
        }
    }
}

export const loginRequest: RedirectRequest = {
    scopes: ['openid', 'profile', 'User.Read']
}

export const logoutRequest = (accountInfo: AccountInfo | null) => {
    const request: EndSessionRequest = {
        account: accountInfo || undefined
    }
    return request;
}

export const tokenRequest = (accountInfo: AccountInfo) => {
    const request: SilentRequest = {
        scopes: ['User.Read'],
        account: accountInfo
    }

    return request;
}