import React, { useContext } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { useWindowWidth } from '@react-hook/window-size';
import Avatar from './Avatar';
import { connect } from 'react-redux';
import { RootState } from 'StoreTypes';
import { loadBookingAsync } from '@features/booking/actions';
import { StyledText } from '@space/common';
import { palette } from '@features/preferences/style/themes';
import styled from 'styled-components/native';
import { User } from 'UserFeature';
import { localize } from '../localization';

const mapStateToProps = (state: RootState) => ({
  selectedDate: state.booking.selectedDate,
  bookings: state.booking.bookings,
  error: state.booking.error,
});

const dispatchProps = {
  loadBookings: loadBookingAsync.request,
};

type AvatarListProps = {
  users: User[];
  onPress: (user: User[]) => void;
  reloadData: boolean;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  AvatarListProps;

const AvatarCarousel: React.FC<Props> = ({
  selectedDate,
  bookings,
  onPress,
  reloadData,
  error,
  users
}) => {
  const width = useWindowWidth();
  const elementWidth = 37;

  const numberOfProfiles = users.length;

  const numberOfProfilesCanDisplay = () => {
    return (width - (width % elementWidth)) / elementWidth;
  };

  const avatars: any[] = users.map((item, index) => (
    <Avatar
      key={index}
      name={item?.firstName}
      lastName={item?.lastName}
      src={item?.avatar}
      onPress={() => onPress(users)}
    />
  ));

  return (
    <Container>
      <StyledText variant={'h3'} style={{ letterSpacing: 3.2 }}>
        {localize('CARROUSEL_TITLE').toUpperCase()}
      </StyledText>
      <ItemContainer>
        {avatars.length === 0 || error ? (
          <StyledText variant={'body2'} color={'hint'}>
            {error
              ? localize('ERROR_DEFAULT')
              : localize('EMPTY_CARROUSEL_TITLE')}
          </StyledText>
        ) : (
            <ScrollView
              scrollEnabled={false}
              style={styles({ size: numberOfProfiles }).indicators}
              horizontal
              contentContainerStyle={{ width: numberOfProfiles * elementWidth }}>
              {avatars}
            </ScrollView>
          )}
        <StyledContainer
          selected={numberOfProfilesCanDisplay() <= numberOfProfiles}
          marginRight={numberOfProfilesCanDisplay() > numberOfProfiles ? undefined : width % elementWidth}
          borderColor={palette.text["primary"]}>
          <StyledText
            variant={'body2'}
            color={'hint'}>
            {numberOfProfilesCanDisplay() > numberOfProfiles ? '' : '+' + (numberOfProfiles - numberOfProfilesCanDisplay() + 1)}
          </StyledText>
        </StyledContainer>
      </ItemContainer>
    </Container>
  );
};

const Container = styled.View`
  padding-top:24px;
  padding-left: 24px;
  width: 100%;
`;

const ItemContainer = styled.View`
  margin-top: 9px;
  flex-direction: row;
`;

type PlusIconProps = {
  selected: boolean;
  marginRight?: number;
  borderColor: string;
};

const StyledContainer = styled.View<PlusIconProps>`
  width: ${(props) => (props.selected ? '32px' : 0)};
  height: 32px;
  border-radius: 16px;
  justify-content: center;
  opacity: ${(props) => (props.selected ? 100 : 0)};
  border-width: 1px;
  border-color: ${(props) => props.borderColor};
  margin: 0px 5px 0px 5px;
  margin-right: ${(props) => props.marginRight ?? ''};
  align-self: flex-end;
`;

const styles = (props) =>
  StyleSheet.create({
    indicators: {
      maxWidth: (props.size + 1) * 37,
    },
  });

export default connect(mapStateToProps, dispatchProps)(AvatarCarousel);

