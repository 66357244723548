import { Name } from 'Common';
import * as Localization from 'expo-localization';
import i18n, {TranslateOptions} from 'i18n-js';
import * as _ from 'lodash';
import moment from 'moment';
import { Language } from 'OrganizationFeature';

import en from './assets/i18n/en.json';
import fr from './assets/i18n/fr.json';

import { LocaleConfig } from "react-native-calendars";

const initializeCalendarStrings = (language: string) => {
  LocaleConfig.locales.en = LocaleConfig.locales[''];
  LocaleConfig.locales.fr = {
    monthNames: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    monthNamesShort: [
      'Janv.',
      'Févr.',
      'Mars',
      'Avr.',
      'Mai',
      'Juin',
      'Juill.',
      'Août',
      'Sept.',
      'Oct.',
      'Nov.',
      'Déc.',
    ],
    dayNames: [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ],
    dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
  };
  LocaleConfig.defaultLocale = language;
};

export const configureLocalization = () => {
  i18n.translations = {en, fr};
  i18n.locale = Localization.locale.split('-')[0];
  i18n.fallbacks = true;
  moment.locale(i18n.locale);
  initializeCalendarStrings(i18n.locale);
}

export const localize = (key: string, options?: TranslateOptions) => i18n.t(key, options)

export const localizedName = (names: string | Name[], activeLanguages: Language[]): string => {
  // TODO: Remove once types containing name is converted to Name[].
  if (typeof names === 'string' || names instanceof String) {
    return names as string;
  }

  const language = activeLanguages.find(((lang: Language) =>  lang.name.split('-')[0] === i18n.locale)) ?? activeLanguages[0];

  return names?.find((item) => language && item.languageId === language.id)?.name ?? _.first(names)?.name ?? 'null';
}

