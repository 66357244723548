import _ from 'lodash';
import moment from 'moment';
import { DateObject } from 'react-native-calendars';

export const formatPhoneNumber = (rawPhone: string): string => {
    if (!_.isEmpty(rawPhone)) {
        const regional = rawPhone.substring(0, 3);
        const mid = rawPhone.substring(3, 6);
        const end = rawPhone.substring(6);
        return '(' + regional + ') ' + mid + '-' + end;
    }
    return rawPhone;
}