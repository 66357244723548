const languageMap = new Map([
  ['fr-fr', 'FRENCH_FR'],
  ['fr-ca', 'FRENCH_CA'],
  ['en-ca', 'ENGLISH_CA'],
  ['en-us', 'ENGLISH_US'],
  ['es-es', 'SPANISH_ES'],
  ['es-mx', 'SPANISH_MX'],
  ['pt-pt', 'PORTUGUESE_PT'],
  ['pt-br', 'PORTUGUESE_BR'],
]);

export default languageMap;
