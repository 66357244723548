import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import theme from 'styled-theming';
import { colors } from '../features/preferences/style/themes';
import { StyledText, Divider, ThemeUtil} from '@space/common';

type SearchViewProps = {
  placeholder: string;
  caption: string;
  onChangeText?: (text: string) => void;
};

type ThemeProps = {
  styleName: string;
};

const textColor = theme('mode', {
  light: colors.grey5,
  dark: colors.white,
});

const placeHolderColor = theme('mode', {
  light: colors.grey2,
  dark: colors.grey7,
});

const captionColor = theme('mode', {
  light: colors.grey7,
  dark: colors.grey2,
});

const SearchView: React.FC<SearchViewProps> = ({
  placeholder,
  caption,
  onChangeText,
}) => {
  const styleName = useTheme().mode;

  return (
    <StyledContainer>
      <StyledTextInput
        styleName={styleName}
        onChangeText={onChangeText}
        placeholder={placeholder}
        placeholderTextColor={ThemeUtil.getThemeValue(placeHolderColor)(styleName)}
      />
      <Divider />
      <StyledText
        variant={'caption'}
        fontSize={11}
        fontColor={ThemeUtil.getThemeValue(captionColor)(styleName)}>
        {caption}
      </StyledText>
    </StyledContainer>
  );
};

const StyledContainer = styled.View`
  height: 36px;
  width: 250px;
  justify-content: center;
  align-items: flex-start;
`;

const StyledTextInput = styled.TextInput<ThemeProps>`
  color: ${(props) => ThemeUtil.getThemeValue(textColor)(props.styleName)};
  font-familly: Barlow;
`;

export default SearchView;
