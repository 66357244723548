export const constraints = {
  alphanumeric: {
    format: {
      pattern: "[a-z0-9\-\s]+",
      flags: "i",
      message: "can only contain a-z and 0-9"
    }
  },
  phone: {
    format: {
      pattern: "[0-9\-]+",
      flags: "i",
      message: "can only contain 0-9 and -"
    }
  },
  emailAddress: {
    presence: {
      allowEmpty: false,
      message: "^Please enter an email address"
    },
    email: {
      message: "^Please enter a valid email address"
    }
  },
};

export default constraints;