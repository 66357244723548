import { RootEpic } from 'StoreTypes';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { defer } from 'rxjs';
import {
  loadUsersAsync,
  loadUserRolesAsync,
  loadUserGroupsAsync,
  createUserAsync,
  updateUserAsync,
  updateUserNoImageAsync,
  deleteUserAsync,
  createUserRoleAsync,
  updateUserRoleAsync,
  deleteUserRoleAsync,
  createUserGroupAsync,
  updateUserGroupAsync,
  deleteUserGroupAsync
} from './actions';
import { User, UserRole, UserGroup } from 'UserFeature';
import { localize } from '../../localization';

export const loadUsersEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf([loadUsersAsync.request])),
    switchMap((_action) =>
      defer(() => from(api.common.fetchUsers())).pipe(
        map((data) => data as User[]),
        map(loadUsersAsync.success),
        catchError((error) => of(loadUsersAsync.failure(error))),
      ),
    ),
  );

export const loadUserRolesEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf([loadUserRolesAsync.request])),
    switchMap((_action) =>
      defer(() => from(api.admin.fetchUserRoles())).pipe(
        map((data) => data as UserRole[]),
        map(loadUserRolesAsync.success),
        catchError((error) => of(loadUserRolesAsync.failure(error))),
      ),
    ),
  );

export const loadUserGroupsEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf([loadUserGroupsAsync.request])),
    switchMap((_action) =>
      defer(() => from(api.admin.fetchUserGroups())).pipe(
        map((data) => data as UserGroup[]),
        map(loadUserGroupsAsync.success),
        catchError((error) => of(loadUserGroupsAsync.failure(error))),
      ),
    ),
  );

export const createUserEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createUserAsync.request)),
    switchMap((action) => {
      return from(
        action.payload.avatar ? api.admin.createUser(action.payload) : api.admin.createUserNoImage(action.payload)
      ).pipe(
        map((updateResponse) => {
          return createUserAsync.success(updateResponse);
        }),
        catchError((err) => { 
          let errorMessage;
          switch (err.statusCode) {
            case 413:
              errorMessage = err.message;
              break;
            default:
              errorMessage = localize('ERROR_DEFAULT');
          }
          return of(createUserAsync.failure(errorMessage)) 
        }),
      );
    }),
  );

export const updateUserEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateUserAsync.request)),
    switchMap((action) => {
      return from(
        api.common.updateUser(state$.value.user.selectedUser.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateUserAsync.success(updateResponse);
        }),
        catchError((err) => {
          let errorMessage;
          switch (err.statusCode) {
            case 413:
              errorMessage = err.message;
              break;
            default:
              errorMessage = localize('ERROR_DEFAULT');
          }
          return of(updateUserAsync.failure(errorMessage))
        }),
      );
    }),
  );

export const updateUserNoImageEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateUserNoImageAsync.request)),
    switchMap((action) => {
      return from(
        api.common.updateUserNoImage(state$.value.user.selectedUser.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateUserNoImageAsync.success(updateResponse);
        }),
        catchError((err) => of(updateUserNoImageAsync.failure(err))),
      );
    }),
  );

export const deleteUserEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteUserAsync.request)),
    switchMap((action) =>
      defer(() => from(api.admin.deleteUser(state$.value.user.selectedUser))).pipe(
        map(deleteUserAsync.success),
        catchError((error) => of(deleteUserAsync.failure(error.message))),
      ),
    ),
  );

export const createUserRoleEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createUserRoleAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.createUserRole(action.payload)
      ).pipe(
        map((updateResponse) => {
          return createUserRoleAsync.success(updateResponse);
        }),
        catchError((err) => of(createUserRoleAsync.failure(err))),
      );
    }),
  );

export const updateUserRoleEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateUserRoleAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.updateUserRole(state$.value.user.selectedUserRole.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateUserRoleAsync.success(updateResponse);
        }),
        catchError((err) => of(updateUserRoleAsync.failure(err))),
      );
    }),
  );

export const deleteUserRoleEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteUserRoleAsync.request)),
    switchMap((action) =>
      defer(() => from(api.admin.deleteUserRole(state$.value.user.selectedUserRole))).pipe(
        map(deleteUserRoleAsync.success),
        catchError((error) => of(deleteUserRoleAsync.failure(error.message))),
      ),
    ),
  );


export const createUserGroupEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(createUserGroupAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.createUserGroup(action.payload)
      ).pipe(
        map((updateResponse) => {
          return createUserGroupAsync.success(updateResponse);
        }),
        catchError((err) => of(createUserGroupAsync.failure(err))),
      );
    }),
  );

export const updateUserGroupEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateUserGroupAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.updateUserGroup(state$.value.user.selectedUserGroup.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateUserGroupAsync.success(updateResponse);
        }),
        catchError((err) => of(updateUserAsync.failure(err))),
      );
    }),
  );

export const deleteUserGroupEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteUserGroupAsync.request)),
    switchMap((action) =>
      defer(() => from(api.admin.deleteUserGroup(state$.value.user.selectedUserGroup))).pipe(
        map(deleteUserGroupAsync.success),
        catchError((error) => of(deleteUserGroupAsync.failure(error.message))),
      ),
    ),
  );
