import React from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { StyledText } from '@space/common';
import { colors, palette } from '@features/preferences/style/themes';
import { useTheme } from 'styled-components/native';
import { DateObject } from 'react-native-calendars';
import moment from "moment";
import config from '../../config';

type DayProps = {
  state: string;
  marking: any;
  date: DateObject;
  onPress: (date: DateObject) => any;
  exDates: string[];
};

const ScheduleDayView: React.FC<DayProps> = ({
  state,
  marking,
  date,
  onPress,
  exDates
}) => {
  const onDayPress = () => onPress(date);

  const styleName = useTheme().mode;
  const { selected } = marking;
  const highlighted = state === 'today';

  return (
    <ItemContainer selected={selected} highlighted={highlighted}>
      <ItemTouchableOpacity
        selected={selected}
        highlighted={highlighted}
        onPress={onDayPress}
        disabled={!selected && marking.disabled}
        >
        <StyledText
          variant={'caption'}
          fontSize={18}
          fontColor={
            highlighted
              ? colors.white
              : selected
                ? styleName === 'dark'
                  ? colors.black
                  : colors.white
                : undefined
          }
          fontWeight={'normal'}
          color={selected ? 'hint' : 'primary'}>
          {date.day}
        </StyledText>
      </ItemTouchableOpacity>
    </ItemContainer>
  );
};

export default React.memo(ScheduleDayView);

type DayViewProps = {
  selected: boolean;
  highlighted: boolean;
};

const ItemContainer = styled.View<DayViewProps>`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-bottom: 12px;
`;

const ItemTouchableOpacity = styled.TouchableOpacity<DayViewProps>`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${(props) =>
    props.highlighted ? palette.secondary : props.selected ? palette.info : ''};
`;

type IndicatorViewProps = {
  selected: boolean;
  occupation: number | null;
};

