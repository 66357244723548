import React, {useContext} from 'react';
import {Platform} from 'react-native';
import LoginView, {LoginViewName} from './routes/LoginView';
import HomeView, {HomeViewName} from './routes/HomeView';
import CalendarView, {CalendarViewName} from './routes/CalendarView';
import BookingsView, {BookingsViewName} from './routes/BookingsView';
import SpacesView, {SpacesViewName} from './routes/SpacesView';
import SettingsView, {SettingsViewName} from './routes/SettingsView';
import UsersView, {UsersViewName} from './routes/UsersView';
import OfficeMapView, {OfficeMapViewName} from './routes/OfficeMapView'
import {connect} from 'react-redux';
import {RootState} from 'StoreTypes';
import {
  NavigationContainer,
  DefaultTheme,
  NavigationContainerRef,
} from '@react-navigation/native';
import {createStackNavigator, HeaderStyleInterpolators} from '@react-navigation/stack';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

import {palette} from './features/preferences/style/themes';
import {ThemeContext} from 'styled-components/native';
import appConfig from './config';
import { StyledText, ThemeUtil } from '@space/common';
import { localize, localizedName } from './localization';
import { UserRole } from 'UserFeature';

const Stack = createStackNavigator();

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.login.isAuthenticated,
  isAdmin: state.login.user?.isAdmin,
  user: state.login.user,
  activeLanguages: state.organization.languages,
});

type Props = ReturnType<typeof mapStateToProps>;

const MainNavigationStack: React.FC<Props> = (props) => {
  const navigationRef = React.useRef<NavigationContainerRef>(null);
  const themeContext = useContext(ThemeContext);

  const configurePreferredLanguage = () => {
    if (props.isAuthenticated === true && props.user.preferredLanguage.name != Localization.locale.toLocaleLowerCase()) {
      i18n.locale = props.user.preferredLanguage.name.split('-')[0];
    }
  };

  const NavigationTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: ThemeUtil.getThemeValue(palette.background)(themeContext.mode),
    },
  };

  const config = {
    screens: {
      LoginView: 'login',
      HomeView: 'home',
      CalendarView: 'calendar',
      BookingsView: 'bookings',
      SpacesView: 'spaces',
      SettingsView: 'settings',
      UsersView: 'users',
      OfficeMapView: 'plan'
    },
  };

  const linking = {
    prefixes: [appConfig.url.base, 'assistantadmin://'],
    config,
  };

  const isAuthorized = 
    props.isAuthenticated === true &&
    props.user.rolesList?.map((role : UserRole) => 
      localizedName(role.name, props.activeLanguages))
      .includes('Admin');
    

  configurePreferredLanguage();

  return (
    <NavigationContainer
      linking={linking}
      theme={NavigationTheme}
      fallback={<StyledText>Loading...</StyledText>}
      ref={navigationRef}>
        <Stack.Navigator
          screenOptions={{
            headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
            headerShown: Platform.OS !== 'web',
          }}
        >
        {!isAuthorized ? (
          <Stack.Screen
            name={LoginViewName}
            component={LoginView}
            options={{title: localize('APP_LOGIN')}}
          />
        ) : (
          <>
            <Stack.Screen
              name={HomeViewName}
              component={HomeView}
              options={{title: localize('HomeView')}}
            />
            <Stack.Screen
              name={CalendarViewName}
              component={CalendarView}
              options={{title: localize('CalendarView')}}
            />
            <Stack.Screen
              name={BookingsViewName}
              component={BookingsView}
              options={{title: localize('BookingsView')}}
            />
            <Stack.Screen
              name={SpacesViewName}
              component={SpacesView}
              options={{title: localize('SpacesView')}}
            />
            {(props.isAdmin || process.env.NODE_ENV !== "development") && <Stack.Screen
              name={SettingsViewName}
              component={SettingsView}
              options={{title: localize('SettingsView')}}
            />}
            <Stack.Screen
              name={UsersViewName}
              component={UsersView}
              options={{title: localize('UsersView')}}
            />
            <Stack.Screen
              name={OfficeMapViewName}
              component={OfficeMapView}
              options={{title: localize('OfficeMapView')}}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default connect(mapStateToProps)(MainNavigationStack);
