import React from "react";
import { connect } from "react-redux";
import { StyleSheet, FlatList, Dimensions, Platform } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { RootState } from 'StoreTypes';
import { colors, palette } from '../../preferences/style/themes';
import { localize, localizedName } from '../../../localization';
import { StyledText, Divider, Button, ThemeUtil } from '@space/common';
import VerticalDivider from '@components/VerticalDivider';
import SpacingView from '@components/SpacingView';
import Avatar from '@components/Avatar';
import _ from 'lodash';
import moment from "moment";
import { Booking, SpaceUserMapping } from 'BookingFeature';
import { User } from 'UserFeature';
import SpaceHelper from "../../../utils/SpaceHelper";
import { SpaceModel } from '@features/space';
import { setSpaceIdSelectedForMapping } from '../..//user/actions';
import { 
    showConfirmBookingDelete, 
    selectBooking, 
    showBookingEditor, 
    setSelectedUserMappingForBooking, 
    setSelectedSpaceType,
    showUserSelectionView 
} from '../actions';
import SvgIcon from '@components/SvgIcon';
import NewUser from '../../../img/new_user.svg';


type BookingConfirmationProps = {
    onButtonConfirmPressed: () => void;
    showConfirmBooking: (active: boolean) => void;
};

const mapStateToProps = (state: RootState) => ({
    users: state.user.users,
    selectedBooking: state.booking.selectedBooking,
    spaceList: state.space.space,
    spaceTags: state.space.spaceTags,
    selectedSpaceType: state.booking.selectedSpaceType,
    selectedUserMappingForBooking: state.booking.selectedUserMappingForBooking,
    activeLanguages: state.organization.languages,
});

const dispatchProps = {
    selectEditBooking: selectBooking, 
    showConfirmBookingDeleteModal: showConfirmBookingDelete,
    showBookingEditorView: showBookingEditor,
    selectUserMapping: setSelectedUserMappingForBooking,
    selectSpaceType: setSelectedSpaceType,
    showUserSelection: showUserSelectionView,
    selectSpaceIdForMapping: setSpaceIdSelectedForMapping,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & BookingConfirmationProps;

const SpaceTypeEditorView: React.FC<Props> = ({
    users,
    spaceList,
    spaceTags,
    selectedBooking,
    selectedSpaceType,
    selectedUserMappingForBooking,
    activeLanguages,
    selectSpaceIdForMapping,
    showUserSelection,
    selectEditBooking,
    showConfirmBookingDeleteModal,
    showBookingEditorView,
    selectSpaceType,
    selectUserMapping,
    onButtonConfirmPressed,
    showConfirmBooking
}) => {
    const styleName = useTheme().mode;
    const onButtonConfirmPress = () => {
        showConfirmBooking(false);
        onButtonConfirmPressed();
    };

    const onButtonCancelPress = () => {
        showConfirmBooking(false);
    };

    const renderSingleBookingInfo = () => {
        const space = spaceList.filter(space => selectedBooking.spaces[0].id === space.id);
        const details = !_.isEmpty(_.first(selectedBooking.spaces).tagsList) ? _.first(selectedBooking.spaces).tagsList.map((tag) => localizedName(tag.name, activeLanguages)).join(', ') : '';
        const user: User = selectedBooking.organizer;

        return (
            <>
                <RowContainer>
                    <LargeCellContainer>
                        <AvatarContainer>
                            <Avatar name={user.firstName} lastName={user.lastName} src={user.avatar} />
                        </AvatarContainer>
                        <AvatarName style={Styles.text}>
                            <StyledText variant={'body4'}>
                                {`${user.firstName} ${user.lastName}`}
                            </StyledText>
                        </AvatarName>
                    </LargeCellContainer>
                    <VerticalDivider />
                    <CellContainer>
                        <StyledText variant={'body4'}>{localizedName(_.first(space).name, activeLanguages)}</StyledText>
                    </CellContainer>
                    <VerticalDivider />
                    <LargeCellContainer>
                        <StyledText variant={'body4'}>{details}</StyledText>
                    </LargeCellContainer>
                    <VerticalDivider />
                    <CellContainer>
                        <StyledText variant={'body4'}>
                            {moment(selectedBooking?.schedule.startDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_DAY_FORMAT'))}   
                        </StyledText>
                        <SpacingView height={'2px'} />
                            <StyledText variant={'body4'}>{localize('WHOLE_DAY')}</StyledText>
                        <SpacingView height={'8px'} />
                    </CellContainer>
                </RowContainer>
            </>
        );
    }

    const renderSpaceGroupBookingInfo = () => {
        return (
            <>
                <RowContainer>
                    <LargeCellContainer>
                        <AvatarContainer>
                            <Avatar src={selectedBooking.organizer.avatar} />
                        </AvatarContainer>
                        <AvatarName style={Styles.text}>
                            <StyledText variant={'body4'}>
                                {selectedBooking.organizerName}
                            </StyledText>
                        </AvatarName>
                    </LargeCellContainer>
                    <VerticalDivider />
                    <CellContainer>
                        <StyledText variant={'body4'}>{selectedBooking.title}</StyledText>
                    </CellContainer>
                    <VerticalDivider />
                    <LargeCellContainer>
                    </LargeCellContainer>
                    <VerticalDivider />
                    <CellContainer>
                        <StyledText variant={'body4'}>
                            {moment(selectedBooking?.schedule.startDate).format(localize('DATE_DAY_FORMAT'))}   
                        </StyledText>
                        <SpacingView height={'2px'} />
                            <StyledText variant={'body4'}>{localize('WHOLE_DAY')}</StyledText>
                        <SpacingView height={'8px'} />
                    </CellContainer>
                </RowContainer>
                <FlatList
                    keyExtractor={(item) => item.spaceId}
                    data={selectedBooking?.users}
                    renderItem={renderSpaceGroupItemBookingInfo}
                    extraData={selectedUserMappingForBooking}/>
            </>
        );
    }

    const renderSpaceGroupItemBookingInfo = (data) => {
        const currentSpace = spaceList.filter(space => space.id === data.item.spaceId)[0];
        const details = SpaceHelper.availableSpaceDetails(currentSpace, spaceTags);

        const mapping = _.first(
        selectedUserMappingForBooking.filter(
            (userSpace) => userSpace.spaceId === data.item.spaceId,
        ),
        ) as SpaceUserMapping;

        return (
            <RowContainer>
                <LargeCellContainer>
                    <UserSelection onPress={() => {
                        selectSpaceIdForMapping(data.item.spaceId);
                        showUserSelection(true);
                        }}>
                        { mapping ? renderUserMappedSpace(mapping) : renderEmptySpace() }
                    </UserSelection>
                </LargeCellContainer>
                <VerticalDivider />
                <CellContainer>
                    <StyledText variant={'body4'}>{currentSpace.name}</StyledText>
                </CellContainer>
                <VerticalDivider />
                <LargeCellContainer>
                    <StyledText variant={'body4'}>{details}</StyledText>
                </LargeCellContainer>
                <VerticalDivider />
                <CellContainer>
                    <StyledText variant={'body4'}>
                        {moment(selectedBooking?.schedule.startDate).format(localize('DATE_DAY_FORMAT'))}   
                    </StyledText>
                    <SpacingView height={'2px'} />
                        <StyledText variant={'body4'}>{localize('WHOLE_DAY')}</StyledText>
                    <SpacingView height={'8px'} />
                </CellContainer>
            </RowContainer>
        );
    }
    

    const renderEmptySpace = () => {
        return (
            <>
                <AvatarEmptyContainer>
                    <SvgIcon svg={NewUser}/>
                </AvatarEmptyContainer>
                <AvatarName style={Styles.text}>
                    <StyledText variant={'body4'}>{localize('USER_NOT_ATTRIBUTED')}</StyledText>
                </AvatarName> 
            </>
        );
    };

    const renderUserMappedSpace = (
        mapping: SpaceUserMapping,
      ) => {
        const selectedUser = users
          ? (_.first(
            users.filter((user: User) => user.id === mapping.userId),
          ) as User)
          : null;
    
        const name = selectedUser
          ? selectedUser.firstName + ' ' + selectedUser.lastName
          : 'Undefined';
    
        const editorBooking = selectedBooking;

        _.first(editorBooking.users.filter(
            (userSpace) => mapping && userSpace.spaceId === mapping.spaceId,
        )).userId = mapping.userId;
        selectEditBooking(editorBooking);
        return (
            <> 
                <Avatar
                    name={selectedUser?.firstName ?? ''}
                    lastName={selectedUser?.lastName ?? ''}/>
                <AvatarName style={Styles.text}>
                    <StyledText variant={'body4'}>{name}</StyledText>
                </AvatarName> 
            </>
        );
    };

    return selectedBooking && (
        <>
            <Container>
                <HeaderContainer>
                    <Title>
                        <StyledText variant={'h2'}>{localize('CONFIRM_BOOKING_TITLE')}</StyledText>
                    </Title>
                </HeaderContainer>
                <HorizontalSeparator />

                <MainContainer>
                    <HeaderRowContainer>
                        <LargeCellContainer>
                            <StyledText variant={'h3'}>{localize('FILTER_BY_USER')}</StyledText>
                        </LargeCellContainer>
                        <CellContainer>
                            <StyledText variant={'h3'}>{localize('FILTER_BY_SPACE')}</StyledText>
                        </CellContainer>
                        <LargeCellContainer>
                            <StyledText variant={'h3'}>{localize('FILTER_BY_DETAILS')}</StyledText>
                        </LargeCellContainer>
                        <CellContainer>
                            <StyledText variant={'h3'}>{localize('FILTER_BY_DATE')}</StyledText>
                        </CellContainer>
                    </HeaderRowContainer>
                    <HorizontalSeparator />
                    {selectedSpaceType.model === SpaceModel.POD ? renderSpaceGroupBookingInfo() : renderSingleBookingInfo()}
                </MainContainer>
            </Container >

            <FooterContainer>
                <Divider />
                <ButtonContainer>
                    <Button style={Styles.Button} title={localize('CONFIRM')} fontColor={colors.black} onPress={onButtonConfirmPress}/>
                    <Button 
                        style={Styles.Button}
                        onPress={onButtonCancelPress} 
                        title={localize('CANCEL')} 
                        variant={'light'} 
                        disableElevation={true} 
                        border={ThemeUtil.getThemeValue(palette.borderLight)(styleName)} 
                        fontColor={colors.black} />
                </ButtonContainer>
            </FooterContainer>
        </>
    );
};

export default connect(mapStateToProps, dispatchProps)(SpaceTypeEditorView);

const Container = styled.View`
width: 100vw;
max-width: 800px;
min-height: 470px;
padding: 32px 24px 0;
background-color: ${palette.background};
`;

const HeaderContainer = styled.TouchableOpacity`
flex-direction: row;
padding: 0 0 24px 0;
`;

const Title = styled.View`
  padding-top: 24px;
  flex-grow: 1;
`;

const MainContainer = styled.View`
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
`;

const CellContainer = styled.View`
  flex-direction: row;
  justify-items: center;
  width: 19%;
  padding: 2px 12px;
  display: inline; 
`;

const HeaderRowContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: 2px 12px;
`;

const RowContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: 20px 12px 2px;
`;

const LargeCellContainer = styled.View`
  display: flex;  
  flex-direction: row;
  justify-items: center;
  width: 31%;
  padding: 2px 12px;
`;

const HorizontalSeparator = styled.View`
  background-color: ${palette.border};
  opacity: 0.25;
  height: 1px;
  flex-shrink: 0;
`;

const ButtonContainer = styled.View`
    flex-direction: row;
    justify-content: space-around;
    padding: 0 44px;
`;

const FooterContainer = styled.View`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  min-height: 94px;
  background-color: ${palette.background};
`;

const AvatarContainer = styled.View`
    width: 32px;
    height: 32px;
    margin: 8px 8px 8px 0px;
`;

const AvatarName = styled.View`
  align-items: center;
  justify-content: center;
`;

const UserSelection = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 14px 24px 24px 0;
`;

const AvatarEmptyContainer = styled.View`
    width: 32px;
    height: 32px;
    border-radius: 44px;
    background-color: ${colors.grey4};
    align-items: center;
    justify-content: center;
    padding: 0 5px 0 5px;
    margin-right: 8px;
`;

const Styles = StyleSheet.create({
    Button: {
        flex: 1,
        marginTop: '25px',
        marginRight: '12px',
        marginBottom: '20px',
        marginLeft: '12px',
    },
    text: {
        flexShrink: 1,
    },
});