import React, { useState, useMemo, useRef, useEffect } from "react";
import {
    FlatList,
} from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { StyledText, ThemeUtil } from '@space/common';
import Down from '../img/Down.svg';
import Up from '../img/Up.svg';
import SvgIcon from '@components/SvgIcon';
import { xorBy } from "lodash";
import _ from 'lodash';
import {
    palette,
} from '@features/preferences/style/themes';
import SpacingView from '../SpacingView';

export type SingleChoiceItem = {
    id: string;
    label: string;
};

type ViewProps = {
    width: number;
};

type ChoiceProps = {
    items: SingleChoiceItem[];
    selectedValue: string;
    onItemSelected: (selectedId: string) => void;
    onTouchOutside: () => void;
    width: number;
};

const ListSingleChoice: React.FC<ChoiceProps> = ({
    items,
    selectedValue,
    onItemSelected,
    onTouchOutside,
    width,
}) => {
    const mainContainer = useRef();
    const styleName = useTheme().mode;

    const [expanded, setExpanded] = useState(false);
    const [selectedId, setSelectedId] = useState(selectedValue);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = (event) => {
        if (mainContainer.current.contains(event.target)) {
            return;
        }
        onTouchOutside();
    };

    const onExpandPress = () => {
        setExpanded(!expanded);
    };

    const onCheckChange = (item: SingleChoiceItem) => {
        setSelectedId(item.id);
        onItemSelected(item.id);
        onExpandPress();
    }

    const renderTick = (item: SingleChoiceItem) => (
        <CheckedOnContainer />
    );

    return (
        <Container width={width} ref={mainContainer}>
            <FlatList
                data={items}
                renderItem={({ item }) => (
                    <ItemContainer>
                        <TextContainer>
                            <StyledText variant={'body3'} color={'primary'}>
                                {item.label}
                            </StyledText>
                        </TextContainer>
                        <CheckedContainer onPress={() => onCheckChange(item)}>
                            {selectedId === item.id ? renderTick(item) : null}
                        </CheckedContainer>
                    </ItemContainer>
                )}
            />

        </Container>
    );
}

export default ListSingleChoice;

const Container = styled.View<ViewProps>`
  width: ${(props) => `${props.width}px` ?? '150px'};
  height: 200px;
  padding-top: 12px;
`;

const ItemContainer = styled.View`
  justify-content: center;
  align-items: center;
  margin: 9px;
  flex-direction: row;
`;

const ButtonView = styled.TouchableOpacity<ViewProps>`
  align-items: center;
  justify-content: center;
  width: ${(props) => `${props.width}px` ?? '150px'};
  flex-direction: row;
  border-radius: 4px;
  height: 14px;
  padding: 0px 12px;
`;

const DropDownContainer = styled.View<ViewProps>`
  background-color: ${palette.background};
  border: 1px solid ${palette.color};
  border-radius: 4px;
  width: ${(props) => `${props.width}px` ?? '150px'};
  max-width: 200px;
`;

const TextContainer = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
`;

const CheckedContainer = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  background-color: ${palette.actionItem};
  border-radius: 12px;
  width: 24px;
  height: 24px;
`;

const CheckedOnContainer = styled.TouchableOpacity`
  background-color: ${palette.border};
  border-radius: 6px;
  width: 12px;
  height: 12px;
`;

