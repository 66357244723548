import axios from 'axios';
import config from '../config';
import _ from 'lodash';
import { refreshTokenInterceptor } from './axios-refresh-token';
import {
  User,
  UserRole,
  UserGroup,
  CreateUserQuery,
  CreateUserRoleQuery,
  UpdateUserRoleQuery,
  CreateUserGroupQuery,
  UpdateUserGroupQuery,
} from 'UserFeature';

import {
  Space,
  SpaceGroup,
  SpaceQuery,
  SpaceTag,
  SpaceType,
  CreateSpaceTagQuery,
  UpdateSpaceTagQuery,
  CreateSpaceTypeQuery,
  UpdateSpaceTypeQuery,
  CreateSpaceQuery,
  UpdateSpaceQuery,
} from 'SpaceFeature';
import { Message, CreateMessageQuery } from 'MessageFeature';
import { Organization, UpdateOrganizationQuery } from 'OrganizationFeature';

const axiosClient = axios.create({
  baseURL: config.url.server,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'X-Office-Identifier': config.url.base
  },
});

const axiosMultipartClient = axios.create({
  baseURL: config.url.server,
  headers: { 
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'multipart/form-data',
    'X-Office-Identifier': config.url.base
  },
});

refreshTokenInterceptor(axiosClient);
refreshTokenInterceptor(axiosMultipartClient);

export const updateAuthToken = (token: string) => {
  axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  axiosMultipartClient.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const fetchAllSpaces = async (): Promise<Space[]> => {
  return axiosClient
    .get('/space')
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const createSpace = async (space: CreateSpaceQuery): Promise<Space> => {
  return axiosClient
    .post('/space', space)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};


export const updateSpace = async (id: string, space: UpdateSpaceQuery): Promise<Space> => {
  return axiosClient
    .put('/space/' + id, space)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const deleteSpace = async (space: Space): Promise<Space> => {
  return axiosClient
    .delete('/space/' + space.id)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return space;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchSpaceGroups = async (): Promise<SpaceGroup[]> => {
  return axiosClient
    .get('/space_group')
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};


export const createSpaceGroup = async (spaceGroup: CreateSpaceGroupQuery): Promise<SpaceGroup> => {
  return axiosClient
    .post('/space_group', spaceGroup)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};


export const updateSpaceGroup = async (id: string, spaceGroup: UpdateSpaceGroupQuery): Promise<SpaceGroup> => {
  return axiosClient
    .put('/space_group/' + id, spaceGroup)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const deleteSpaceGroup = async (spaceGroup: SpaceGroup): Promise<SpaceGroup> => {
  return axiosClient
    .delete('/space_group/' + spaceGroup.id)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return spaceGroup;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchSpaceTypes = async (): Promise<SpaceType[]> => {
  return axiosClient
    .get('/space_type')
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const createMessage = async (message: CreateMessageQuery): Promise<Message> => {
  return axiosClient
    .post('/message', message)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const updateMessage = async (id: string, message: UpdateMessageQuery): Promise<Message> => {
  return axiosClient
    .put('/message/' + id, message)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const fetchOrganizations = async (): Promise<Organization[]> => {
  return axiosClient
    .get('/organization')
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const updateOrganization = async (id: string, organization: UpdateOrganizationQuery): Promise<Organization> => {
  return axiosClient
    .put('/organization/' + id, organization)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const createUser = async (user: CreateUserQuery): Promise<User> => {
  const form = new FormData();
  form.append('firstName', user.firstName);
  form.append('lastName', user.lastName);
  form.append('jobTitle', user.jobTitle);
  form.append('email', user.email);

  if (!_.isEmpty(user.phone)) {
    form.append('phone', user.phone);
  }

  form.append('organization', user.organization);
  !_.isEmpty(user.rolesList) ? form.append('rolesList', user.rolesList.toString()) : form.append('rolesList', '');
  !_.isEmpty(user.groupsList) ? form.append('groupsList', user.groupsList.toString()) : form.append('groupsList', '');

  form.append('avatar', user.avatar);

  if (!_.isEmpty(user.preferredLanguageId)) {
    form.append('preferredLanguageId', user.preferredLanguageId);
  }

  return axiosClient
    .post('/user', form, { headers: { 
      'Content-Type': 'multipart/form-data',
      'X-Office-Identifier': config.url.base
      } })
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const createUserNoImage = async (user: CreateUserQuery): Promise<User> => {
  return axiosClient
    .post('/user/', user)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const deleteUser = async (user: User): Promise<User> => {
  return axiosClient
    .delete('/user/' + user.id)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return user;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchUserRoles = async (): Promise<UserRole[]> => {
  return axiosClient
    .get('/user_role')
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const createUserRole = async (userRole: CreateUserRoleQuery): Promise<UserRole> => {
  return axiosClient
    .post('/user_role', userRole)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const updateUserRole = async (id: string, userRole: UpdateUserRoleQuery): Promise<UserRole> => {
  return axiosClient
    .put('/user_role/' + id, userRole)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const deleteUserRole = async (userRole: UserRole): Promise<UserRole> => {
  return axiosClient
    .delete('/user_role/' + userRole.id)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return userRole;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchUserGroups = async (): Promise<UserGroup[]> => {
  return axiosClient
    .get('/user_group')
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const createUserGroup = async (userGroup: CreateUserGroupQuery): Promise<UserGroup> => {
  return axiosClient
    .post('/user_group', userGroup)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const updateUserGroup = async (id: string, userGroup: UpdateUserGroupQuery): Promise<UserGroup> => {
  return axiosClient
    .put('/user_group/' + id, userGroup)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const deleteUserGroup = async (userGroup: UserGroup): Promise<UserGroup> => {
  return axiosClient
    .delete('/user_group/' + userGroup.id)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return userGroup;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const createSpaceTag = async (spaceTag: CreateSpaceTagQuery): Promise<SpaceTag> => {
  return axiosClient
    .post('/space_tag', spaceTag)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const updateSpaceTag = async (id: string, spaceTag: UpdateSpaceTagQuery): Promise<SpaceTag> => {
  return axiosClient
    .put('/space_tag/' + id, spaceTag)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const deleteSpaceTag = async (spaceTag: SpaceTag): Promise<SpaceTag> => {
  return axiosClient
    .delete('/space_tag/' + spaceTag.id)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return spaceTag;
      }
    })
    .catch((error) => {
      throw error;
    });
};


export const createSpaceType = async (spaceType: CreateSpaceTypeQuery): Promise<SpaceType> => {
  return axiosClient
    .post('/space_type', spaceType)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const updateSpaceType = async (id: string, spaceType: UpdateSpaceTypeQuery): Promise<SpaceType> => {
  return axiosClient
    .put('/space_type/' + id, spaceType)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return response.result;
      }
    })
    .catch((error) => {
      throw error.message;
    });
};

export const deleteSpaceType = async (spaceType: SpaceType): Promise<SpaceType> => {
  return axiosClient
    .delete('/space_type/' + spaceType.id)
    .then((response) => response.data)
    .then((response) => {
      if (!_.isEmpty(response.error)) {
        throw response.error;
      } else {
        return spaceType;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export default axiosClient;
