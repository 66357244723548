import React from 'react';
import styled from 'styled-components/native';
import { SpaceModel } from '@features/space';
import SpaceHelper from '../../utils/SpaceHelper';
import { StyledText } from '@space/common';
import { localize, localizedName } from '../../localization';
import { ScrollView } from 'react-native-gesture-handler';
import SpaceStatsDayListItem from './SpaceStatsDayListItem';
import { connect } from 'react-redux';
import { TouchableOpacity } from 'react-native';
import moment from 'moment';
import config from '../../config';
import { RootState } from 'StoreTypes';

import { SpaceType } from 'SpaceFeature';

const mapStateToProps = (state: RootState) => ({
    spaces: state.space.space,
    availableSpacesCache: state.space.availableSpacesCache,
    spaceGroups: state.space.spaceGroups,
    spaceTypes: state.space.spaceTypes,
    activeLanguages: state.organization.languages,
});

const dispatchProps = {};

type SpaceStatsDayProps = {
    date: string;
    onPress: () => void;
    onModelPress: (model: SpaceModel) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    SpaceStatsDayProps;


const SpaceStatsDayList: React.FC<Props> = ({
    date,
    spaceTypes,
    spaces,
    availableSpacesCache,
    spaceGroups,
    onPress,
    onModelPress,
    activeLanguages
}) => {
    const stats = spaceTypes.map((type: SpaceType) => {
        return type.model === SpaceModel.POD ?
            { type: type, stats: SpaceHelper.groupOccupationForDate(spaces, spaceGroups, date, type.model, type) } :
            { type: type, stats: SpaceHelper.occupationForTypeByDate(spaces, availableSpacesCache, date, type) }
    });

    const data: any[] = stats.map((item, index) => (
        <SpaceStatsDayListItem
            onPress={onModelPress}
            key={index}
            name={localizedName(item.type.name, activeLanguages)}
            model={item.type.model}
            booked={item.stats?.booked}
        />
    ));

    const daygap = moment(date).diff(moment(), "days") + 1;
    const displayDate = (date: string, dayGap: number) => {
        return daygap > 2 ?
            moment(date).format(config.defaultDateFormat) :
            daygap > 1 ? localize('HOME_BOOKED_DAY_AFTER_TOMORROW') : localize('HOME_BOOKED_TOMORROW');
    }

    return (
        <RootContainer>
            <HeaderContainer>
                <StyledText variant={'h3'} color={'primary'}>
                    {displayDate(date, daygap)}
                </StyledText>
            </HeaderContainer>
            <Container>
                <ScrollView
                    showsHorizontalScrollIndicator={true}
                    horizontal >
                    {data}
                </ScrollView>
            </Container>
            <FooterContainer>
                <TouchableOpacity onPress={onPress}>
                    <StyledText variant={'body4'} color={'primary'} underline={true}>
                        {localize('HOME_SEE_BOOKINGS')}
                    </StyledText>
                </TouchableOpacity>
            </FooterContainer>
        </RootContainer>
    );
};

const HeaderContainer = styled.View`
  padding-left: 5px;
  padding-top: 5px;
`;

const RootContainer = styled.View`
  width: 100%;
  padding: 20px;
`;

const Container = styled.View`
  flex-direction: row;
  margin-top: 22px;
`;

const FooterContainer = styled.View`
  margin-top: 15px;
  padding-left: 5px;
  padding-top: 5px;
`;

export default connect(mapStateToProps, dispatchProps)(SpaceStatsDayList);