import {createReducer} from 'typesafe-actions';
import {loadHomeMessageAsync, openMessageEditor, closeMessageEditor, clearError, createHomeMessageAsync, updateHomeMessageAsync} from './actions';
import {combineReducers} from 'redux';
import i18n from 'i18n-js';
import {LocalizedMessage, Message} from 'MessageFeature';

const reducer = combineReducers({
  homeMessage: createReducer<Message | null>(null).handleAction(
    loadHomeMessageAsync.success,
    (state, action) => action.payload ?? null,
  ),
  homeMessageValue: createReducer<string | null>(null).handleAction(
    loadHomeMessageAsync.success,
    (state, action) => action.payload?.content.find((item) => item.language.split('-')[0] === i18n.locale)?.message ?? action.payload?.content[0]?.message ?? null,
  ),
  editHomeMessageIsDisplayed: createReducer<boolean>(false).handleAction(
    [openMessageEditor, closeMessageEditor],
    (state, action) => action.payload
  ),
  setHomeMessageError: createReducer<string | null>(null)
    .handleAction(
      [
        createHomeMessageAsync.failure,
        updateHomeMessageAsync.failure
      ],
      (state, action) => action.payload,
    )
    .handleAction(clearError, (state, action) => null),
  loadHomeMessageError: createReducer<string | null>(null)
    .handleAction(
      [loadHomeMessageAsync.failure],
      (state, action) => action.payload,
    )
    .handleAction([
      clearError,
      loadHomeMessageAsync.request,
    ], (state, action) => null),
});

export default reducer;
