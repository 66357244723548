import moment from 'moment';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import _ from 'lodash';

import { Booking } from 'BookingFeature';
import { Organization } from 'OrganizationFeature';
import {
  MixedSpace,
  Occupancy,
  Space,
  SpaceGroup,
  SpaceTag,
  SpaceType,
  SpaceTypeWithAvailability,
  SpaceCache
} from 'SpaceFeature';
import { SpaceModel } from '@features/space';
import { User } from 'UserFeature';
import { localize } from '../localization';
import config from '../config';
import { isDateInsideRrule } from '@space/common';
import _head from 'lodash/head';
import { Office } from 'OrganizationFeature';
import { SpaceHelper as CommonSpaceHelper } from "@space/common"

export class SpaceHelper {

  static isOfficeOpen = (
    office: Office,
    date: moment.Moment,
  ) => {
    return CommonSpaceHelper.isOfficeOpen(office, date, config.defaultDateFormat);
  };

  static isOfficeOpened = (date: String, officeOpenCache: Map<string, boolean>, spacesList: Space[], availableSpacesCache: Map<string, SpaceCache>) => {
    return CommonSpaceHelper.isOfficeOpened(date, officeOpenCache, spacesList, availableSpacesCache, config.defaultDateFormat);
  };

  static getRruleCurrentOffice = (organization: Organization) => {
    return CommonSpaceHelper.getRruleCurrentOffice(organization);
  }

  static occupationForDate = (
    spacesList: Space[],
    spacesOpen: Map<string, boolean>,
    date: string,
    type?: SpaceModel,
  ): Occupancy | null => {
    return CommonSpaceHelper.occupationForDate(spacesList, spacesOpen, date, type);
  };

  static cacheForOccupancy = (
    date: string, 
    availableSpacesCache: Map<string, SpaceCache>, 
    type?: SpaceModel,
  ): Occupancy | null => {
    return CommonSpaceHelper.cacheForOccupancy(date, availableSpacesCache, type);
  };

  static isOpenSpace = (space: Space, date: moment.Moment): boolean => {
    return CommonSpaceHelper.isOpenSpace(space, date, config.defaultDateFormat);
  }

  static isActiveSpace = (value: Date | Boolean): boolean => {
    return CommonSpaceHelper.isActiveSpace(value, config.defaultDateFormat);
  };

  static spaceGroupFromSpaceList = (spaceList: Space[]): any => {
    return CommonSpaceHelper.spaceGroupFromSpaceList(spaceList);
  };

  static availableSpacesPerType = (
    spacesList: Space[],
    availableSpacesCache: Map<string, SpaceCache>,
    spaceGroups: SpaceGroup[],
    date: moment.Moment
  ): SpaceTypeWithAvailability[] => {
    return CommonSpaceHelper.availableSpacesPerType(spacesList, availableSpacesCache, spaceGroups, date, config.defaultDateFormat);
  }

  static availableSpaceGroupsForType = (
    spacesList: Space[],
    spaceGroupsList: SpaceGroup[],
    date: moment.Moment,
    type: SpaceType
  ): SpaceGroup[] => {
    return CommonSpaceHelper.availableSpaceGroupsForType(spacesList, spaceGroupsList, date, type, config.defaultDateFormat);
  }; 

  static groupOccupationForDate = (
    spacesList: Space[],
    spaceGroups: SpaceGroup[],
    date: string,
    model?: SpaceModel,
    type?: SpaceType
  ) => {
    return CommonSpaceHelper.groupOccupationForDate(spacesList, spaceGroups, date, model, type);
  }
  
  static bookingNumberForDate = (bookings: Booking[], date: moment.Moment) => {
    const dateBookings = bookings.filter((booking) =>
      date.isBetween(
        moment(booking.schedule.startDate).startOf('day'),
        moment(booking.schedule.endDate).endOf('day'),
        undefined,
        '[]'
      )
    );
    return dateBookings?.length ?? 0;
  };

  static occupationForTypeByDate = (
    spacesList: Space[],
    availableSpacesCache: Map<string, SpaceCache>,
    date: string,
    type?: SpaceType
  ) => {
    let m = moment(date);
    const spacesOpenForDate = availableSpacesCache.get(date)?.openSpaces ?? new Map();
    const spacesOpen = spacesList.filter(
      (item) =>
      (spacesOpenForDate && spacesOpenForDate.get(item.id)) &&
        (item.type?.id === type?.id || type === undefined)
    );

    const spacesBooked = spacesOpen.filter((space) =>
      space.bookings?.find((booking) =>
        m.isBetween(
          moment(booking.schedule.startDate).startOf('day'),
          moment(booking.schedule.endDate).endOf('day'),
          undefined,
          '[]'
        )
      )
    );

    return spacesOpen.length
      ? {
          occupation: (spacesBooked.length / spacesOpen.length) * 100,
          availability: spacesOpen.length - spacesBooked.length,
          booked: spacesBooked.length,
        }
      : null;
  };

  static availableSpacesForType = (
    spaces: Space[],
    availableSpacesCache: Map<string, SpaceCache>,
    date: moment.Moment,
    type: SpaceType
  ): Space[] => {
    const formatedDate = date.format(config.defaultDateFormat);

    const spacesOpen = availableSpacesCache.get(formatedDate)?.openSpaces ?? new Map();

    const availableSpaces = spaces.filter(
      (item) =>
        item &&
        (item.type?.id ? item.type.id : item.type?.id) === type.id &&
        (spacesOpen && spacesOpen.get(item.id)) &&
        item.bookings.filter((booking) =>
          date.isSame(moment(booking.schedule.startDate), 'day')
        ).length === 0
    )
    
    return availableSpaces;
  };

  static spaceHasGroups = (spaceGroups: string[], userGroup: string) : Boolean => {
    const spaceGroupsIds: number[] = spaceGroups.map((group) => group?.id );
    return spaceGroupsIds.includes(userGroup?.id);
  };

  static tagsFromBooking = (
    booking: Booking,
    spaceTags: SpaceTag[],
    spaceGroups: SpaceGroup[]
  ) => {
    const isPodBooking = booking?.type?.model === SpaceModel.POD;
    const groupids = booking?.spaces?.map((space) => space?.groupId);
    const groups = spaceGroups.filter((group) => groupids?.includes(group.id));
    const groupsTags = groups.map((group, index) => {
      const groupTags = group.tags.map((tag) => tag.name);
      return index === 0 ? groupTags : ', ' + groupTags;
    });
    return isPodBooking
      ? groupsTags
      : spaceTags
          .filter((tag) => {
            booking?.spaces && booking?.spaces[0]?.tagIdsList.includes(tag.id);
          })
          .map((tag) => tag.name);
  };

  static capitalizeFirstLetter = (
    word: string | undefined
  ): string | undefined => {
    if (word != undefined) {
      return word!.charAt(0).toUpperCase() + word!.slice(1);
    }
    return undefined;
  };

  static scheduleToLiteralDescription = (space: MixedSpace): string => {
    if (_.isEmpty(space.openSchedule)) {
      return space.isGroup ? '' : localize('SPACE_ALWAYS_OPEN');
    }

    const startDate =
      !_.isEmpty(space.openSchedule) && !_.isEmpty(space.openSchedule.startDate)
        ? moment(space.openSchedule.startDate).format(
            localize('SPACE_PERIOD_DATE_FORMAT')
          )
        : null;
    const endDate =
      !_.isEmpty(space.openSchedule) && !_.isEmpty(space.openSchedule.endDate)
        ? moment(space.openSchedule.endDate).format(
            localize('SPACE_PERIOD_DATE_FORMAT')
          )
        : null;
    const period =
      !_.isEmpty(startDate) && !_.isEmpty(endDate)
        ? startDate + ' - ' + endDate
        : null;
    const rrule =
      !_.isEmpty(space.openSchedule) &&
      !_.isEmpty(space.openSchedule.rrule) &&
      space.openSchedule.rrule
        ? RRule.fromString(space.openSchedule.rrule)
        : null;

    if (!_.isEmpty(rrule)) {
      const byDay = rrule?.options.byweekday;
      if (!_.isEmpty(byDay)) {
        const byDayLiteral = byDay
          ?.map((dayNumber) =>
            SpaceHelper.capitalizeFirstLetter(
              moment().weekday(dayNumber).format('dddd')
            )
          )
          .join(', ');
        return byDayLiteral ?? '';
      }

      // add start/end date ?
    } else if (!_.isEmpty(period)) {
      return period ?? '';
    } else {
      return space.openDate ? localize('SPACE_ALWAYS_OPEN') : '';
    }
    return '';
  };

  static availableSpaceDetails = (
    space: Space,
    spaceTags: string[]
  ): String => {
    const details =
      !_.isEmpty(spaceTags) && !_.isEmpty(space?.tagsList)
        ? spaceTags
            .filter((tag) => space.tagsList.includes(tag.id))
            .map((tag) => tag[0].name)
            .join(', ')
        : '';
    return details;
  };
}

export default SpaceHelper;
