import {
  CircularIcon,
  Dialog,
  ModalView,
  StyledText,
  ThemeUtil,
} from '@space/common';
import React, { useMemo, useState, useEffect } from 'react';
import { SectionList, StyleSheet, View } from 'react-native';
import { Space, SpaceGroup, SpaceType } from 'SpaceFeature';
import {
  clearError,
  selectBooking,
  setSelectedDate,
  setSelectedSpaceType,
  setSelectedSpacesForBooking,
  setSelectedUserMappingForBooking,
  showBookingEditor,
  createBookingAsync,
  updateBookingAsync
} from '../actions';
import { fetchAllSpacesAsync, fetchSpacesAsync } from '../../space/actions';
import { colors, palette, shadow } from '../../preferences/style/themes';
import styled, { useTheme } from 'styled-components/native';

import Avatar from '@components/Avatar';
import AvatarCarousel from '@components/AvatarCarousel';
import { Booking } from 'BookingFeature';
import BookingConfirmationView from './BookingConfirmationView';
import CalendarsScreen from '@components/Calendar/Calendar';
import CheckBox from '@components/CheckBox';
import Close from '../../../img/close.svg';
import { FlatList } from 'react-native-gesture-handler';
import Forward from '../../../img/forward.svg';
import NewUser from '../../../img/new_user.svg';
import Pod from '../../../img/pod.svg';
import Room from '../../../img/room.svg';
import { RootState } from 'StoreTypes';
import Seat from '../../../img/seat.svg';
import SpaceHelper from '../../../utils/SpaceHelper';
import { SpaceModel } from '@features/space';
import SvgIcon from '@components/SvgIcon';
import { User } from 'UserFeature';
import UserSelectionView from '@components/user/UserSelectionView';
import VerticalDivider from '@components/VerticalDivider';
import _ from 'lodash';
import { updateDateWithOfficeHours } from '../../../utils';
import config from '../../../config';
import { connect } from 'react-redux';
import { localize, localizedName } from '../../../localization';
import moment from 'moment';
import 'moment-timezone';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { exDatesFromRruleSet } from '../../../utils/dateutils';

export enum BookingEditorType {
  NEW = 'new',
  EDIT = 'edit',
}

type BookingRowProps = {
  bookings: Booking[];
  users: User[];
  closeButtonPressed: () => void;
};

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  spaceTypes: state.space.spaceTypes,
  spaceList: state.space.allSpaces,
  availableSpacesCache: state.space.availableSpacesCache,
  spaceGroups: state.space.spaceGroups,
  spaceTags: state.space.spaceTags,
  selectedDate: state.booking.selectedDate,
  selectedSpaces: state.booking.selectedSpacesForBooking,
  selectedBooking: state.booking.selectedBooking,
  selectedSpaceType: state.booking.selectedSpaceType,
  selectedUserMappingForBooking: state.booking.selectedUserMappingForBooking,
  createBookingError: state.booking.bookingError,
  activeLanguages: state.organization.languages,
  organization: state.organization.organization,
  office: state.organization.office,
  officeOpenCache: state.organization.officeOpenCache,
  selectedEditBookingType: state.booking.selectedEditBookingType,
});

const dispatchProps = {
  fetchAllSpaces: fetchAllSpacesAsync.request,
  fetchSpaces: fetchSpacesAsync.request,
  selectDate: setSelectedDate,
  handleCloseErrorDialog: clearError,
  selectEditBooking: selectBooking,
  showBookingEditorView: showBookingEditor,
  selectUserMapping: setSelectedUserMappingForBooking,
  selectSpaceType: setSelectedSpaceType,
  selectSpaces: setSelectedSpacesForBooking,
  createBooking: createBookingAsync.request,
  updateBooking: updateBookingAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  BookingRowProps;

const BookingEditorView: React.FC<Props> = ({
  bookings,
  users,
  spaceTypes,
  spaceList,
  availableSpacesCache,
  spaceTags,
  spaceGroups,
  selectedDate,
  selectDate,
  selectedBooking,
  selectedSpaces,
  selectedSpaceType,
  selectedUserMappingForBooking,
  createBookingError,
  organization,
  selectedEditBookingType,
  fetchAllSpaces,
  fetchSpaces,
  handleCloseErrorDialog,
  selectSpaces,
  selectSpaceType,
  selectUserMapping,
  selectEditBooking,
  closeButtonPressed,
  createBooking,
  updateBooking,
  activeLanguages,
  office,
  officeOpenCache
}) => {

  useEffect(() => {
    fetchAllSpaces();

    const startDate = moment().utc().format(config.defaultDateFormat);
    const endDate = moment().utc().add(3, 'w').format(config.defaultDateFormat);
    fetchSpaces({ startDate, endDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings]);
  const [displayConfirmBooking, showConfirmBooking] = useState(false);
  const [isUserSelectionViewDisplayed, showUserSelection] = useState(false);
  const styleName = useTheme().mode;
  const navigation = useNavigation();

  const newBooking = {
    id: null,
    title: '',
    description: '',
    deletedAt: null,
    office: {},
    organizer: {},
    spaceUserMapping: [{}],
    schedule: {
      startDate: '',
      endDate: '',
      rrule: ''
    },
    spaces: [{}],
    users: [{}]
  };
  
  if (selectedBooking && (selectedBooking.users.length > 0 || selectedBooking.spaces.length > 0)) {
    const mapping = selectedBooking.users.map((user, i)=> {
      const spaceId = (i < selectedBooking.spaces.length) ? selectedBooking.spaces[i].id : null
      return {userId: user.id, spaceId: spaceId }
    }).filter((item)=>item.spaceId != null);
    selectedBooking.spaceUserMapping = mapping;
  }
  const [currentBooking, setCurrentBooking] = useState(selectedBooking ? selectedBooking : newBooking);

  const defaultType = spaceTypes.filter(
    (spaceType) =>
      SpaceHelper.availableSpacesForType(
        spaceList,
        availableSpacesCache,
        moment(selectedDate),
        spaceType
      ).length > 0
  )[0] ?? null;

  const selectedBookingSpaceType = selectedBooking
    ? spaceTypes.filter(
        (spaceType) => spaceType?.id == _.first(currentBooking.spaces)?.type?.id
      )[0]
    : null; 

  if (!selectedSpaceType) {
    selectSpaceType(selectedBooking ? selectedBookingSpaceType : defaultType);
  } 

  if (!_.isEmpty(selectedUserMappingForBooking)) {
    const updatedUser = users.filter(
      (user) =>
        selectedUserMappingForBooking[0] &&
        user.id == selectedUserMappingForBooking[0].userId
    );

    currentBooking.users = updatedUser;
    const { id, firstName, lastName, avatar } = _.first(updatedUser);
    currentBooking.organizer = { id, firstName, lastName, avatar };
    currentBooking.spaceUserMapping = selectedUserMappingForBooking;
  }

  const excludedDates = useMemo(() => {
    if (!_.isEmpty(organization.rrule)) {
      return exDatesFromRruleSet(organization.rrule);
    }
    return [];
  }, [organization]);

  const literalSelectedDate = useMemo(() => {
    const officeTimezone = SpaceHelper.getRruleCurrentOffice(organization).options.tzid;
    return moment(currentBooking.schedule.startDate).tz(officeTimezone).format(config.exceptionDateFormat);
  }, [currentBooking.schedule.startDate]);

  const onButtonDeletePress = () => {
    selectEditBooking(null);
    selectSpaceType(null);
    selectUserMapping([]);
    closeButtonPressed();
  };

  const showUserSelectionView = () => {
    showUserSelection(true);
  };

  const date = updateDateWithOfficeHours(
    office,
    selectedDate
  );

  const onButtonConfirmPressed = () => {
    if (selectedEditBookingType === BookingEditorType.NEW) {
      createNewBooking();
    } else if (selectedEditBookingType === BookingEditorType.EDIT) {
      updateCurrentBooking();
    }
  };

  const createNewBooking = () => {
    showConfirmBooking(false);
    createBooking({
        ...selectedBooking,
    });
    showConfirmBooking(false);
    selectEditBooking(null);
    selectSpaceType(null);
    selectUserMapping([]);
    showConfirmBooking(false);
    closeButtonPressed();
  }

  const updateCurrentBooking = () => {
    updateBooking({
        ...selectedBooking,
        spaceUserMapping: currentBooking.spaceUserMapping,
    });
    showConfirmBooking(false);
    selectEditBooking(null);
    selectSpaceType(null);
    selectUserMapping([]);
    showConfirmBooking(false);
    closeButtonPressed();
  }

  const showConfirmSingleBookingView = (item: Space) => {
    currentBooking.schedule.startDate = date.startDate;
    currentBooking.schedule.endDate = date.endDate;
    currentBooking.spaces = [item];
    const currentSelection = currentBooking.spaceUserMapping[0];
    currentSelection.spaceId = item.id
    currentBooking.spaceUserMapping = [currentSelection];

    selectEditBooking(currentBooking);
    showConfirmBooking(true);
  };

  const showConfirmSpaceGroupBookingView = (section: Space) => {   
    selectEditBooking(currentBooking);
    showConfirmBooking(true);
  };

  const renderTick = () => <CheckedOnContainer />;

  const onModelCheckChange = (type: SpaceType) => {
    selectSpaceType(type);
  };

  const svgType = (model: string) => {
    switch (model) {
      case SpaceModel.SEAT:
        return Seat;
      case SpaceModel.POD:
        return Pod;
      case SpaceModel.ROOM:
        return Room;
    }
  };

  const carouselUsers: User[] = useMemo(
    () =>
      _.uniqBy(
        bookings.reduce((result: User[], item: Booking) => {
          if (
            selectedDate ===
            moment(item.schedule.startDate).format(config.defaultDateFormat)
          ) {
            return result.concat(item.users);
          }
          return result;
        }, []),
        (user) => user?.email
      ),
    [bookings, selectedDate]
  );

  const renderSingleAvailableSpace = ({ item }) => {
    const details = !_.isEmpty(item.tagsList) ? item.tagsList.map((tag) => localizedName(tag.name, activeLanguages)).join(', ') : '';
    const disabled = _.isEmpty(selectedUserMappingForBooking) && _.isEmpty(currentBooking.organizer);
    return (
      <>
        <RootContainer>
          <CellContainer>
            <StyledText variant={'body4'}>{localizedName(item.name, activeLanguages)}</StyledText>
          </CellContainer>

          <VerticalDivider />

          {selectedSpaceType?.model == SpaceModel.ROOM && (
            <CellContainer>
              <StyledText variant={'body4'}>
                {localize('SPACE_OCCUPATION_MAX', { count: item.capacity })}
              </StyledText>
            </CellContainer>
          )}

          {selectedSpaceType?.model == SpaceModel.ROOM && <VerticalDivider />}

          <DetailsCellContainer>
            <StyledText variant={'body4'}>{details}</StyledText>
          </DetailsCellContainer>

          <VerticalDivider />

          <ContainerCircularIcon
            disabled={disabled}
            style={disabled && Styles.disabled}
            onPress={() => showConfirmSingleBookingView(item)}
          >
            <CircularIcon
              svg={Forward}
              tintColor={colors.black}
              size={'medium'}
              accessibilityLabel={localize(
                'BOOKING_ASSIGN_SPACE_SELECT_USER_ACCESSIBILITY'
              )}
              variant={'fill-stroke'}
              fillColor={colors.primaryLight}
            />
          </ContainerCircularIcon>
        </RootContainer>
        <HorizontalSeparator />
      </>
    );
  };

  const renderItem = ({ item }) => {
    const availability =
      selectedSpaceType &&
      SpaceHelper.availableSpacesForType(
        spaceList,
        availableSpacesCache,
        moment(selectedDate),
        item
      ).length;
    if (availability === 0) return null;

    return (
      <Checkbox
        key={item.id}
        disabled={availability === 0}
        onPress={() => onModelCheckChange(item)}
      >
        <TextContainer>
          <CircularIcon
            svg={svgType(item?.model)}
            variant={'stroke'}
            inverted={true}
            accessibilityLabel={localize('NEW_SPACE')}
            size={'large'}
          />
          <StyledText
            variant={'body3'}
          >{`${availability} ${localizedName(item.name, activeLanguages)}`}</StyledText>
        </TextContainer>
        <CheckedContainer>
          {item?.id == selectedSpaceType?.id ? renderTick() : null}
        </CheckedContainer>
      </Checkbox>
    );
  };

  const renderSpaceGroupItem = ({ item }) => {
    const tags = spaceTags
      .filter((tag) => item.tagIdsList.includes(tag.id))
      .map((tag) => tag.name)
      .join(', ');

    const onPress = () => {
      if (selectedSpaces.includes(item)) {
        const leftSpaces = selectedSpaces.filter(
          (space) => space.id !== item.id
        );
        selectSpaces(leftSpaces);
      } else {
        const newSpaces = [item].concat(selectedSpaces);
        selectSpaces(newSpaces);
      }
    };

    return (
      <>
        <SpaceGroupRootContainer>
          <CellContainer>
            <StyledText variant={'body4'}>{item.name}</StyledText>
          </CellContainer>
          <VerticalDivider />
          <DetailsCellContainer>
            <StyledText variant={'body4'}>{tags}</StyledText>
          </DetailsCellContainer>
          <VerticalDivider />
          <StyledTouchableOpacity style={Styles.CheckBox}>
            <CheckBox
              ticked={selectedSpaces.includes(item)}
              onPress={onPress}
            />
          </StyledTouchableOpacity>
        </SpaceGroupRootContainer>
        <HorizontalSeparator />
      </>
    );
  };

  const renderSpaceGroupSectionHeader = (section: any) => {
    const groupDetail = _.first(
      spaceGroups.filter(
        (item: SpaceGroup) => item.id === section.section.title
      )
    ) as SpaceGroup;
    const disabled =
      groupDetail.spaces.some((space: any) => selectedSpaces.includes(space)) &&
      !_.isEmpty(selectedUserMappingForBooking);

    return (
      <>
        <RootContainer>
          <DetailsCellContainer>
            <StyledText variant={'body4'}>{groupDetail.name}</StyledText>
          </DetailsCellContainer>
          <VerticalDivider />
          <ContainerCircularIcon
            disabled={!disabled}
            style={!disabled && Styles.disabled}
            onPress={() => showConfirmSpaceGroupBookingView(groupDetail)}
          >
            <CircularIcon
              svg={Forward}
              tintColor={colors.black}
              size={'medium'}
              accessibilityLabel={localize(
                'BOOKING_ASSIGN_SPACE_SELECT_USER_ACCESSIBILITY'
              )}
              variant={'fill-stroke'}
              fillColor={colors.primaryLight}
            />
          </ContainerCircularIcon>
          {createBookingError && (
            <Dialog
              isOpen={createBookingError !== null}
              title={localize('APP_ERROR_TITLE')}
              body={createBookingError}
              onClose={handleCloseErrorDialog}
              cancelTitle={localize('CANCEL')}
            />
          )}
        </RootContainer>
        <HorizontalSeparator />
      </>
    );
  };

  const renderSpaceGroupsSectionList = () => {
    const groups = SpaceHelper.availableSpaceGroupsForType(
      spaceList,
      spaceGroups,
      moment(selectedDate),
      selectedSpaceType
    ).map((group) => {
      return { title: group.id, data: group.spaces };
    });

    return (
      <StyledSectionList>
        <SectionList
          sections={groups}
          extraData={selectedDate}
          renderItem={renderSpaceGroupItem}
          renderSectionHeader={renderSpaceGroupSectionHeader}
          keyExtractor={(item, index) => (item.id + index).toString()}
        />
      </StyledSectionList>
    );
  };

  return (
    <Container>
      <TopContainer>
        <TopHeaderContainer>
          <StyledText fontSize={24} variant={'h2'}>
            {selectedEditBookingType === BookingEditorType.EDIT
              ? localize('EDIT_BOOKING')
              : localize('NEW_BOOKING')}
          </StyledText>
          <StyledTouchableOpacity onPress={onButtonDeletePress}>
            <SvgIcon
              svg={Close}
              width={24}
              height={24}
              fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)}
            />
          </StyledTouchableOpacity>
        </TopHeaderContainer>
        <View>
          <StyledText variant={'h3'}>{localize('FILTER_BY_USER')}</StyledText>
          <UserSelection onPress={showUserSelectionView}>
            {!_.isEmpty(selectedUserMappingForBooking) || !_.isEmpty(currentBooking.organizer) ? (
              <Avatar
                name={currentBooking.organizer.firstName}
                lastName={currentBooking.organizer.lastName}
                src={currentBooking.organizer.avatar}
              />
            ) : (
              <AvatarEmptyContainer>
                <SvgIcon svg={NewUser} />
              </AvatarEmptyContainer>
            )}
            <StyledText variant={'body2'}>
              {!_.isEmpty(currentBooking.organizer)
                ? `${currentBooking.organizer.firstName} ${currentBooking.organizer.lastName}`
                : localize('CHOOSE_USER')}
            </StyledText>
          </UserSelection>
        </View>
        <HorizontalSeparator />
        <TopContentHeaderContainer>
          <CalendarContainer>
            <CalendarsScreen
              displayWeekView={false}
              onDateSelect={selectDate}
            />
          </CalendarContainer>

          <AvatarCarouselContainer>
            <AvatarCarousel users={carouselUsers} reloadData={false} />
          </AvatarCarouselContainer>
        </TopContentHeaderContainer>
      </TopContainer>
      <HorizontalSeparator />
      <TextContainer>
        <StyledText variant={'h3'} color={'hint'}>
          {localize('SPACES_TYPES_AVAILABLE')}
        </StyledText>
        {Platform.OS === 'web'?
          <LinkContainer onPress={() => window.open(config.url.base + '/plan', '_blank')}>
            <StyledText 
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
              fontWeight={'400'} 
              fontSize={14} 
              fontColor={palette.text['primary']} 
              letterSpacing={'1.2px'} 
              underline={true} >
              {localize('OFFICE_MAP')} 
              <LinkIcon
                source={{uri: "https://icon-library.com/images/open-in-new-tab-icon/open-in-new-tab-icon-13.jpg"}}
            />
            </StyledText>
        </LinkContainer>
        :
        <LinkContainer onPress={() => navigation.navigate('OfficeMapView')}>
            <StyledText 
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
              fontWeight={'400'} 
              fontSize={14} 
              fontColor={palette.text['primary']} 
              letterSpacing={'1.2px'} 
              underline={true} >
              {localize('OFFICE_MAP')}  
              <LinkIcon 
                source={{uri: "https://icon-library.com/images/open-in-new-tab-icon/open-in-new-tab-icon-13.jpg"}}
            />
            </StyledText>
          </LinkContainer>
        }
      </TextContainer>
      {!_.isEmpty(SpaceHelper.isOfficeOpened(selectedDate, officeOpenCache, spaceList, availableSpacesCache)) && !excludedDates.includes(literalSelectedDate) ? 
        <CheckboxContainer>
          <FlatList
            data={spaceTypes}
            horizontal={true}
            renderItem={renderItem}
            keyExtractor={(item) => item.id.toString()}
          />
        </CheckboxContainer> :
        <StyledText
          style={Styles.closedDay}
          variant={'h3'} 
          fontColor={colors.destructive}
          >
          {localize('CLOSED_DAY')}
        </StyledText>
      }
      <HorizontalSeparator />
      <HeaderContainer>
        <CellContainer>
          <StyledText variant={'h3'}>{localize('FILTER_BY_SPACE')}</StyledText>
        </CellContainer>
        {selectedSpaceType?.model == SpaceModel.ROOM && (
          <CellContainer>
            <StyledText variant={'h3'}>{localize('SPACE_CAPACITY')}</StyledText>
          </CellContainer>
        )}
        <CellContainer>
          <StyledText variant={'h3'}>
            {localize('FILTER_BY_DETAILS')}
          </StyledText>
        </CellContainer>
      </HeaderContainer>

      <HorizontalSeparator />
      {!_.isEmpty(SpaceHelper.isOfficeOpened(selectedDate, officeOpenCache, spaceList, availableSpacesCache)) && !excludedDates.includes(literalSelectedDate) &&
        <SpaceListContainer>
          {selectedSpaceType?.model != SpaceModel.POD ? (
            <FlatList
              data={
                selectedSpaceType &&
                SpaceHelper.availableSpacesForType(
                  spaceList,
                  availableSpacesCache,
                  moment(selectedDate),
                  selectedSpaceType
                )
                  .sort((a, b) => localizedName(a.name, activeLanguages)
                  .localeCompare(localizedName(b.name, activeLanguages), undefined, {
                  numeric: true,
                  sensitivity: 'base'
                }))}
              extraData={selectedDate}
              renderItem={renderSingleAvailableSpace}
              keyExtractor={(item) => item.id.toString()}
            />
          ) : (
            renderSpaceGroupsSectionList()
          )}
        </SpaceListContainer> 
      }
      <ModalView isVisible={displayConfirmBooking}>
        <BookingConfirmationView onButtonConfirmPressed={onButtonConfirmPressed} showConfirmBooking={showConfirmBooking}/>
      </ModalView>
      <ModalView isVisible={isUserSelectionViewDisplayed}>
        <UserSelectionView showUserSelection={showUserSelection}/>
      </ModalView>
    </Container>
  );
};

export default connect(mapStateToProps, dispatchProps)(BookingEditorView);

const Container = styled.View`
  background-color: ${palette.background};
  margin: 40px 48px 0 48px;
`;

const TopContainer = styled.View``;

const TopHeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
`;

const HorizontalSeparator = styled.View`
  background-color: ${palette.border};
  opacity: 0.25;
  height: 1px;
  flex-shrink: 0;
`;

const TopContentHeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin: 0px 0px 24px 0px;
`;

const CalendarContainer = styled.View``;

const AvatarCarouselContainer = styled.View`
  flex: 1;
  padding: 0 0 0 80px;
`;

const StyledTouchableOpacity = styled.TouchableOpacity``;

const ContainerCircularIcon = styled.TouchableOpacity`
  width: 48px;
  height: 48px;
  margin-left: 16px;
`;

const UserSelection = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 14px 24px 24px 0;
`;

const AvatarEmptyContainer = styled.View`
  width: 32px;
  height: 32px;
  border-radius: 44px;
  background-color: ${colors.grey4};
  align-items: center;
  justify-content: center;
  padding: 0 5px 0 5px;
  margin-right: 24px;
`;

const CheckboxContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 20px;
`;

const TextContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Checkbox = styled.TouchableOpacity`
  min-width: 280px;
  height: 64px;
  flex-direction: row;
  align-items: center;
  margin: 14px 24px 0 0;
  padding: 16px 16px 16px 8px;
  border-color: ${palette.border};
  border-width: 1px;
  box-shadow: ${shadow};
  border-radius: 4px;
  justify-content: space-between;
`;

const CheckedContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${palette.actionItem};
  border-radius: 12px;
  width: 24px;
  height: 24px;
`;

const CheckedOnContainer = styled.View`
  background-color: ${palette.border};
  border-radius: 6px;
  width: 12px;
  height: 12px;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  align-self: flex-start;
  width: 100%;
`;

const SpaceListContainer = styled.View`
  flex-direction: row;
  align-self: flex-start;
  width: 100%;
  margin-bottom: 68px;
`;

const CellContainer = styled.View`
  flex-direction: row;
  justify-items: center;
  width: 17%;
  padding: 2px 12px;
`;

const DetailsCellContainer = styled.View`
  display: inline;
  flex-direction: row;
  justify-items: center;
  height: 29px;
  flex: 1;
  padding: 2px 12px;
`;

const RootContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
  min-height: 68px;
  padding: 20px 0px 5px;
`;

const SpaceGroupRootContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
  min-height: 68px;
  padding: 20px 0px 5px 18px;
`;

const StyledSectionList = styled.View`
  width: 100%;
  margin-bottom: 16px;
`;

const LinkContainer = styled.TouchableOpacity`
  font-family: 'Barlow';
`;

const LinkIcon = styled.Image`
  width: 14px;
  height: 14px;
  resize: contain;
  margin-left: 5px;
  font-weight: 400;
  color: ${colors.grey5};
`;

const Styles = StyleSheet.create({
  CheckBox: {
    width: '46px',
    marginLeft: '16px',
    paddingLeft: '10px',
  },
  disabled: {
    opacity: 0.4,
  },
  closedDay: {
    padding: '30px',
  }
});
