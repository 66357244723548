import { RootAction, RootState, Services } from 'StoreTypes';
import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import logger from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';

//import { composeEnhancers } from "./utils";
import rootReducer from './root-reducer';
import rootEpic from './root-epic';
import services from '../services';
import persistAuthToken from './persistAuthToken';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

export const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  Services
>({
  dependencies: services,
});

const authTokenFilter = createFilter('login', [
  'authtoken',
  'isAuthenticated',
  'user',
]);

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  transforms: [authTokenFilter],
  whitelist: ['preferences', 'login', 'organization'],
};

export const history =
  Platform.OS === 'web' ? createBrowserHistory() : createMemoryHistory();
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const configureStore = (preloadedState: Object = {}) => {
  const middlewares = [
    routerMiddleware(history),
    epicMiddleware,
    persistAuthToken
  ];

  if(process.env.REACT_NATIVE_STAGE !== 'prod') {
    middlewares.push(logger);
  }

  const store = createStore(
    persistedReducer,
    preloadedState,
    applyMiddleware(...middlewares)
  );
  const persistor = persistStore(store, {});

  epicMiddleware.run(rootEpic);
  return { store, persistor };
};

export default configureStore;
