import React, {useState} from 'react';
import { connect } from "react-redux";
import { palette } from '@features/preferences/style/themes';
import styled, { css, useTheme } from 'styled-components/native';
import theme from 'styled-theming';
import { User, UserRole, UserGroup } from 'UserFeature';
import { CircularIcon, Divider, StyledText, Dialog, ThemeUtil } from '@space/common';
import VerticalDivider from '../VerticalDivider';
import { Switch } from "react-native";
import { colors } from '../../features/preferences/style/themes';
import { localize, localizedName } from '../../localization';
import Avatar from '../Avatar';
import SpacingView from '../SpacingView';
import AddBooking from '../../img/add_booking.svg';
import Booking from '../../img/booking.svg';
import Edit from '../../img/edit.svg';
import Bin from '../../img/bin.svg';
import SvgIcon from '@components/SvgIcon';
import { selectUser, updateUserNoImageAsync } from "../../features/user/actions";
import { useNavigation } from '@react-navigation/native';
import { BookingsViewName } from '../../routes/BookingsView';
import _ from 'lodash';
import moment from "moment";
import config from '../../config'
import { StackNavigationProp } from '@react-navigation/stack';
import { RootState } from 'StoreTypes';

type UserRowProps = {
    user: User;
    onEditPress: (user: User) => void;
    onDeletePress: (user: User) => void;
};

const mapStateToProps = (state: RootState) => ({
    loggedUser: state.login.user,
    selectedUser: state.user.selectedUser,
    userRoles: state.user.userRoles,
    userGroups: state.user.userGroups,
    activeLanguages: state.organization.languages
});

const dispatchProps = {
    updateUser: updateUserNoImageAsync.request,
    selectEditUser: selectUser,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    UserRowProps;

const trackColorOn = theme('mode', {
    light: '#44C3AC',
    dark: '#8DC89D',
});

const trackColorOff = theme('mode', {
    light: '#C5C5C5',
    dark: '#111111',
});

const iconBackgroundColor = theme('mode', {
    light: '#333333',
    dark: '#F2F2F2',
});

const UserDetailsRow: React.FC<Props> = ({
    loggedUser,
    user,
    selectedUser,
    activeLanguages,
    onEditPress,
    onDeletePress,
    updateUser,
    selectEditUser
}) => {
    const navigation = useNavigation<StackNavigationProp<any>>();
    const [isConfirmUserDisabledViewDisplayed, showConfirmUserDisabledModal] = useState(false);
    const onButtonEditPress = () => {
        onEditPress(user);
    }

    const onButtonDeletePress = () => {
        onDeletePress(user);
    }

    const onNewBookingPress = () => {
        navigation.push(BookingsViewName, { user: user, createNew: true });
    };

    const onShowwBookingsPress = () => {
        navigation.push(BookingsViewName, { user: user });
    };

    const toggleSwitch = () => {
        selectEditUser(user);
        if (_.isEmpty(user.disabledAt) && !isConfirmUserDisabledViewDisplayed) {
            showConfirmUserDisabledModal(true);
            return
        }
        updateActivateStatus(user);
    }

    const updateActivateStatus = (user: User) => {
        const rolesList = !_.isEmpty(user.rolesList) && _.first(user.rolesList).id 
            ? user.rolesList?.map((role: UserRole) => { return role.id }) : null;

        const disabledAt = _.isEmpty(user.disabledAt) ? moment().format(config.defaultDateFormat) : '';

        const updatedUser = {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone ?? "5145144444",
            jobTitle: user.jobTitle,
            organizationId: user.organizationId,
            rolesList: rolesList ? rolesList.join(',') : user.rolesList ? user.rolesList.join(',') : '',
            groupIdsList: user.groupIdsList ? user.groupIdsList.join(',') : '',
            disabledAt: disabledAt,
            preferredLanguageId: user.preferredLanguage.id.toString()
        };
        updateUser(updatedUser);
    }

    const teams = user.groupsList?.map((group: UserGroup) => localizedName(group.name, activeLanguages)).join(', ')
    const roles = user.rolesList?.map((role: UserRole) => localizedName(role.name, activeLanguages)).join(', ')

    const styleName = useTheme().mode;
    const colorTrackColorOn = ThemeUtil.getThemeValue(trackColorOn)(styleName);
    const colorTrackColorOff = ThemeUtil.getThemeValue(trackColorOff)(styleName);

    return (
        <>
            <Dialog
                isOpen={isConfirmUserDisabledViewDisplayed}
                title={localize('DESACTIVATE_USER_ALERT_TITLE')}
                onClose={() => {
                    showConfirmUserDisabledModal(false);
                }}
                actionTitle={localize('DESACTIVATE')}
                cancelTitle={localize('CANCEL')}
                onbuttonAction={() => {
                    updateActivateStatus(selectedUser);
                    showConfirmUserDisabledModal(false);
                }} />
            <RootContainer>
                <CellContainer>
                    <AvatarContainer>
                        <Avatar
                            name={user.firstName}
                            lastName={user.lastName}
                            src={user.avatar}
                        />
                    </AvatarContainer>
                    <AvatarName>
                        <StyledText variant={'body4'}>
                            {user.firstName + ' ' + user.lastName}
                        </StyledText>
                    </AvatarName>

                </CellContainer>
                <VerticalDivider />
                <CellContainer>
                    <StyledText variant={'body4'}>
                        {teams}
                    </StyledText>
                    <SpacingView height={'8px'} />

                </CellContainer>
                <VerticalDivider />
                <CellContainer>
                    <StyledText variant={'body4'}>
                        {roles}
                    </StyledText>
                    <SpacingView height={'8px'} />

                </CellContainer>
                <VerticalDivider />
                <BookingCellContainer>
                    <StyledTouchableOpacity onPress={onNewBookingPress}>
                        <CircularIcon
                            svg={AddBooking}
                            tintColor={ThemeUtil.getThemeValue(palette.common)(styleName)}
                            size={'medium'}
                            inverted={true}
                            accessibilityLabel={
                                localize('NEW_USER')
                            }
                            variant={'fill-stroke'}
                            fillColor={ThemeUtil.getThemeValue(iconBackgroundColor)(styleName)} />
                    </StyledTouchableOpacity>

                    <StyledTouchableOpacity onPress={onShowwBookingsPress}>
                        <CircularIcon
                            svg={Booking}
                            tintColor={ThemeUtil.getThemeValue(palette.common)(styleName)}
                            size={'medium'}
                            inverted={true}
                            accessibilityLabel={
                                localize('BOOKINGS')
                            }
                            variant={'fill-stroke'}
                            fillColor={ThemeUtil.getThemeValue(iconBackgroundColor)(styleName)} />
                    </StyledTouchableOpacity>
                </BookingCellContainer>
                <VerticalDivider />
                <ActiveCellContainer>
                    <Switch
                        disabled={loggedUser.id == user.id}
                        trackColor={{ false: colorTrackColorOff, true: colorTrackColorOn }}
                        thumbColor={colors.white}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={toggleSwitch}
                        value={_.isEmpty(user.disabledAt)}
                    />
                </ActiveCellContainer>
                <VerticalDivider />
                <ActionCellContainer>
                    <StyledTouchableOpacity onPress={onButtonEditPress}>
                        <SvgIcon svg={Edit} width={24} height={24} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
                    </StyledTouchableOpacity>

                    <StyledTouchableOpacity onPress={onButtonDeletePress}>
                        <SvgIcon svg={Bin} width={24} height={24} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
                    </StyledTouchableOpacity>
                </ActionCellContainer>
            </RootContainer>
            <HorizontalSeparator />
        </>
    );
};

const RootContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
  height: 68px;
  padding: 20px 0px;
`;

const AvatarContainer = styled.View`
    width: 32px;
    height: 32px;
    margin: 8px 8px 8px 0px;
`;

const AvatarName = styled.View`
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex: 1;
`;

const CellContainer = styled.View`
    flex-direction: row;
    width: 23%;
    padding: 12px;
`;

const BookingCellContainer = styled.View`
    flex-direction: row;
    width: 14%;
    padding: 12px;
    min-width: 120px;
`;

const ActiveCellContainer = styled.View`
    justify-content: center;
    flex-direction: row;
    width: 6%;
    min-width: 80px;
    padding: 12px;
`;

const ActionCellContainer = styled.View`
    justify-content: center;
    flex-direction: row;
    width: 10%;
    min-width: 100px;
    padding: 12px;
`;

const StyledTouchableOpacity = styled.TouchableOpacity`
  padding: 8px;
`;

const HorizontalSeparator = styled.View`
  background-color: ${palette.border};
  opacity: 0.25;
  height: 1px;
  width: 100%;
  flex-shrink: 0;
`;

export default connect(mapStateToProps, dispatchProps)(UserDetailsRow);

