import React, { useState, useMemo, useEffect, useRef } from "react";
import {
    FlatList,
    StyleSheet
} from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { StyledText, ThemeUtil } from '@space/common';
import Checked from '../img/checked.svg';
import Expand from '../img/expand.svg';
import Expanded from '../img/expanded.svg';
import SvgIcon from '@components/SvgIcon';
import { xorBy } from "lodash";
import _ from 'lodash';
import {
    colors,
    palette,
} from '@features/preferences/style/themes';
import theme from 'styled-theming';
import SpacingView from '../SpacingView';
import { SpaceGroup } from 'SpaceFeature';
import { localizedName } from '../../localization';

export type ChoiceItem = {
    id: string;
    label: string;
    disabled?: Boolean;
    group: SpaceGroup;
};

type ChoiceProps = {
    title: string;
    placeholder: string;
    items: ChoiceItem[];
    selectedValues: string[];
    disabled?: boolean;
    onSelectedItemsChange: (selectedIds: string[]) => void;
    disabledItems?: string[];
};

type ViewProps = {
    color: string;
};

const placeholderColorText = theme('mode', {
    light: colors.grey2,
    dark: colors.grey7,
});

const buttonOptions = theme('mode', {
    light: colors.grey1,
    dark: colors.black,
});

const bgColorOptions = theme('mode', {
    light: colors.white,
    dark: colors.grey6,
});

const ExpandableListChoice: React.FC<ChoiceProps> = ({
    title,
    placeholder,
    items,
    onSelectedItemsChange,
    selectedValues,
    disabled,
    spaceGroups,
    activeLanguages,
    disabledItems
}) => {
    const mainContainer = useRef();
    const styleName = useTheme().mode;
    const placeholderColor = ThemeUtil.getThemeValue(placeholderColorText)(styleName);
    const backgroundColor = ThemeUtil.getThemeValue(bgColorOptions)(styleName);
    const buttonColor = ThemeUtil.getThemeValue(buttonOptions)(styleName);
    const [expanded, setExpanded] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>(selectedValues);

    const displayValue = useMemo(() => {
        const selectedValueText = selectedItems ? items.filter((item) => selectedItems.includes(item.id)).map((item) => item.label).join(', ') : null;
        return (_.isEmpty(selectedValueText) ? placeholder : selectedValueText.length <= 44 ? selectedValueText : selectedValueText?.substring(0,44) + "...")
    }, [placeholder, selectedItems]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = (event) => {
        if (mainContainer.current.contains(event.target)) {
            return;
        }
        setExpanded(false);
    };

    const onExpandPress = () => {
        setExpanded(!expanded);
    };

    const onCheckChange = (item: ChoiceItem) => {
        const updated = xorBy(selectedItems, [item.id]);
        setSelectedItems(updated);
        onSelectedItemsChange(updated);
    }

    const renderTick = (item: ChoiceItem) => (
        <StyledChecked>
            <SvgIcon svg={Checked} width={24} height={24} fillColor={ThemeUtil.getThemeValue(palette.border)(styleName)} />
        </StyledChecked>
    );

    const renderValue = () => {
        return (_.isEmpty(selectedItems) ?
            <StyledText variant={'body3'} fontColor={placeholderColor}> {displayValue}</StyledText> :
            <StyledText variant={'body3'} color={'primary'}> {displayValue}</StyledText>
        );
    }

    const findGroupName = (item: ChoiceItem) => {
        const groupName = spaceGroups.find((group: SpaceGroup) => 
            group.id === item.group.id
        )
        return localizedName(groupName.name, activeLanguages)
    }

    const renderList = () => {
        return (
        <DropDownContainer color={backgroundColor}>
            <FlatList
                data={items}
                renderItem={({ item }) => (
                    <ItemContainer style={item.disabled && styles.disabled} key={item.id}>
                        <TextContainer>
                            <StyledText variant={'body3'} color={'primary'}>
                                {item.group? item.label + ' - ' + findGroupName(item) : item.label}
                            </StyledText>
                        </TextContainer>
                        {!disabledItems?.includes(item.id) ?
                            <CheckedContainer onPress={() => onCheckChange(item)} disabled={item.disabled}>
                                {!_.isEmpty(selectedItems) && selectedItems.includes(item.id) ? renderTick(item) : null}
                            </CheckedContainer> :
                            <CheckedContainer style={styles.disabled}>
                                {renderTick(item)}
                            </CheckedContainer> 
                        }
                    </ItemContainer>
                )}
            />
        </DropDownContainer>
        )
    };

    return (
        <Container ref={mainContainer} style={disabled && styles.disabled}>
            <StyledText variant={'caption'} color={'title'}>{title}</StyledText>
            <SpacingView height={'8px'} />
            <ButtonView disabled={disabled} onPress={onExpandPress} color={buttonColor}>
                <TextContainer>
                    {renderValue()}
                </TextContainer>
                <SpacingView width={'12px'} height={'5px'} />
                <SvgIcon svg={expanded ? Expanded : Expand} fillColor={ThemeUtil.getThemeValue(palette.border)(styleName)} width={14} height={6} />
            </ButtonView>
            {expanded ? renderList() : null}

        </Container >
    );
}

export default ExpandableListChoice;

const Container = styled.View`
  height: 80px;
  padding: 12px 8px 4px 8px;
  margin: 0px 0px 4px 0px;  
  z-index: 10; 
`;

const ItemContainer = styled.View`
  justify-content: center;
  align-items: center;
  margin: 9px;
  flex-direction: row;
  min-height: 30px;
`;

const ButtonView = styled.TouchableOpacity<ViewProps>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 40px;
  padding: 0px 12px;
`;

const StyledChecked = styled.View`
  width: 24px;
  height: 24px;
`;

const DropDownContainer = styled.View<ViewProps>`
  background-color: ${(props) => props.color};
  border: 1px solid ${colors.grey1};
  border-radius: 4px;
  min-height: 30px;
  max-height: 290px;
  z-index: 10;
`;

const TextContainer = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
`;

const CheckedContainer = styled.TouchableOpacity`
  background-color: ${palette.actionItem};
  border-radius: 4px;
  width: 24px;
  height: 24px;
`;

const styles = StyleSheet.create({
    disabled: {
      opacity: '0.4'
    } 
  });