import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { connect } from 'react-redux';
import { AuthManager } from '../auth/AuthManager';
import { AuthProviderName } from '../auth/AuthProviderName';
import { RootState } from 'StoreTypes';
import { authenticateAsync } from '@features/login/actions';
import { AuthenticationResult } from '@azure/msal-browser';
import Logo from '../img/flexable_logo.svg';
import { Divider, Button, StandardAlert, ThemeUtil } from '@space/common';
import styled, { useTheme } from 'styled-components/native';
import { StyledText } from '@space/common';
import { localize } from '../localization';
import * as _ from 'lodash';

const mapStateToProps = (state: RootState) => ({
  isAuthenticating: state.login.isLoading,
  isAuthenticated: state.login.isAuthenticated,
  error: state.login.error,
  theme: state.preferences.theme,
});

const dispatchProps = {
  authenticate: authenticateAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const LoginView: React.FC<Props> = ({
  navigation,
  authenticate,
  isAuthenticated,
  isAuthenticating,
  error,
}) => {
  const authManager = AuthManager.getInstance();
  const theme = useTheme();
  const styleName = theme.mode;

  useEffect(() => {
    if (Platform.OS === 'web' && !isAuthenticating && _.isEmpty(error)) {
      authManager.client
        .handleRedirectPromise()
        .then((authResult: AuthenticationResult | null) => {
          if (authResult) {
            authenticate({
              token: authResult?.accessToken,
              provider: AuthProviderName.PUBLICIS,
            });
          }
        })
        .catch((error: Error) => {
          // TODO: Show feedback to user that the thrid party login failed.
          console.log('🔴', error);
          showLoginFailed();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showLoginFailed = () =>
    StandardAlert(localize('LOGIN_FAILED'), localize('ERROR_DEFAULT'));

  const onSignInClicked = async () => {
    if (Platform.OS === 'web') {
      await authManager.signIn().catch((error: Error) => {
        console.log('❌', error);
        showLoginFailed();
      });
    } else {
      await authManager
        .signIn()
        .then(async () => {
          await authManager.getToken().then((token: any) => {
            authenticate({
              token: token,
              provider: AuthProviderName.PUBLICIS,
            });
          });
        })
        .catch((error: Error) => {
          console.log('🟥', error);
          showLoginFailed();
        });
    }
  };

  if (isAuthenticated) {
    navigation.navigate('HomeView');
  } else if (error) {
    console.log('🔺', error);
    showLoginFailed();
  }

  const logoProps = {
    fill: ThemeUtil.getInvertedThemeValue(theme.palette.common)(styleName),
    width: 250,
    height: 50,
  };

  return (
    <RootContainer>
      <LoginContainer>
        <Header>
          <Logo {...logoProps} />
          <TextBody>
            <StyledText variant={'body1'} fontSize={16} color={'primary'}>
              {localize('LOGIN_DESCRIPTION')}
            </StyledText>
          </TextBody>
        </Header>
        <Footer>
          <Divider />
          <LoginButtonContainer>
            <Button
              fontColor={theme.color.grey5}
              title={localize('APP_LOGIN')}
              onPress={onSignInClicked}
            />
          </LoginButtonContainer>
          {/* <ThemeSwitch /> */}
        </Footer>
      </LoginContainer>
    </RootContainer>
  );
};

const LoginButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 128px;
`;

const Header = styled.View`
  padding: 50px 40px 0 40px;
  justify-content: space-evenly;
  display: flex;
  height: 370px;
`;

const Footer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const RootContainer = styled.View`
  flex: 1;
  justify-content: center;
  width: ${Platform.OS === 'web' ? '60vw' : '60%'};
  min-height: ${Platform.OS === 'web' ? '100vh' : '100%'};
`;

const TextBody = styled.View`
  justify-content: center;
  align-items: center;
`;

const LoginContainer = styled.View`
  justify-content: space-between;
  align-items: center;
  margin-left: 25%;
  width: 336px;
  height: 497px;
  border-radius: 4px;
  border-color: ${(props) => props.theme.palette.border};
  border-width: 1px;
`;

export const LoginViewName = 'LoginView';

export default connect(mapStateToProps, dispatchProps)(LoginView);
