import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import styled, {useTheme} from 'styled-components/native';
import { palette } from '@features/preferences/style/themes';
import Back from '../img/back.svg'
import { ThemeUtil } from '@space/common';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import config from '../config';

const OfficeMapView: React.FC = ({
 
}) => {

  const styleName = useTheme().mode;
  const navigation = useNavigation();

  return (
    <Container>
      {Platform.OS !== 'web' ?
        <HeaderContainer>
          <CloseContainer onPress={() => navigation.goBack()}>
            <Back fill={ThemeUtil.getThemeValue(palette.tertiary)(styleName)}/>
          </CloseContainer> 
        </HeaderContainer>
        : ""
      }
      <ImageContainer>
        <Image source={`https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/qa/map/${config.officeId}/bureau-${config.officeId}-global.jpg`} style={styles.planImage}/>
      </ImageContainer>
    </Container>
  )
}

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;  
  width: 100vw;
  height: 100%;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  padding: 24px 24px 0 24px;
  background: ${palette.background};
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
`;

const CloseContainer = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

const ImageContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;  
  background-color: ${palette.background};
  width: 100%;
  height: 100%;
`;

const styles = StyleSheet.create({
  planImage: {
    width: '90vw',
    height: '90vh',
    resizeMode: 'contain'
  }
})

export const OfficeMapViewName = 'OfficeMapView';
export default OfficeMapView;