import React from 'react';
import { StyleSheet, Image, TouchableOpacity } from 'react-native';
import { colors } from '@features/preferences/style/themes';
import Avatar from './Avatar';
import SpacingView from './SpacingView';
import { StyledText } from '@space/common';
import styled from 'styled-components/native';
import theme from "styled-theming";

type AvatarBookingProps = {
  name: string;
  lastName?: string;
  spaceName?: string;
  capacity?: string;
  src?: string;
  backgroundColor?: string;
  onPress?: () => void;
};

const color = theme('mode', {
  light: colors.grey5,
  dark: colors.grey4,
});

const AvatarBooking: React.FC<AvatarBookingProps> = ({
  name,
  lastName,
  spaceName,
  capacity,
  src,
  onPress,
}) => {

  return (
    <StyledTouchableOpacity
      onPress={onPress}>
      <StyledLefttContainer>
        <Avatar name={name} lastName={lastName} src={src} size={'medium'} />
      </StyledLefttContainer>
      <StyledRightContainer>
        <StyledText variant={'body1'}>{name} {lastName}</StyledText>
        <SpacingView height={'5px'} />
        <StyledText variant={'body2'}>{spaceName}</StyledText>
        <SpacingView height={'5px'} />
        <StyledText variant={'body3'}>{capacity}</StyledText>
      </StyledRightContainer>
    </StyledTouchableOpacity>
  );
};

const StyledTouchableOpacity = styled.TouchableOpacity`
  align-items: flex-start;
  justify-content: flex-start;
  height: 98px;
  border-radius: 4px;
  border: 1px solid ${color};
  margin: 0px 8px;
  flex-direction: row;
`;

const StyledLefttContainer = styled.View`
  width: 35px;
  height: 35px;
  margin: 16px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledRightContainer = styled.View`
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-right: 16px; 
`;

export default AvatarBooking;
