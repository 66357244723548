import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  Booking,
  BookingQuery,
  BookingRequestBody,
  SpaceUserMapping,
} from 'BookingFeature';
import { Space, SpaceType } from 'SpaceFeature';
import { BookingEditorType} from './components/BookingEditorView';

export const loadTodayBookingAsync = createAsyncAction(
  'LOAD_TODAY_BOOKING_REQUEST',
  'LOAD_TODAY_BOOKING_SUCCESS',
  'LOAD_TODAY_BOOKING_FAILURE',
)<void, Booking[], string>();

export const loadMyBookingAsync = createAsyncAction(
  'LOAD_MY_BOOKING_REQUEST',
  'LOAD_MY_BOOKING_SUCCESS',
  'LOAD_MY_BOOKING_FAILURE',
)<string, Booking[], string>();

export const loadBookingAsync = createAsyncAction(
  'LOAD_BOOKING_REQUEST',
  'LOAD_BOOKING_SUCCESS',
  'LOAD_BOOKING_FAILURE',
)<BookingQuery, Booking[], string>();

export const deleteBookingAsync = createAsyncAction(
  'DELETE_BOOKING_REQUEST',
  'DELETE_BOOKING_SUCCESS',
  'DELETE_BOOKING_FAILURE',
)<Booking, Booking, string>();

export const createBookingAsync = createAsyncAction(
  'CREATE_BOOKING_REQUEST',
  'CREATE_BOOKING_SUCCESS',
  'CREATE_BOOKING_FAILURE',
)<BookingRequestBody, Booking, string>();

export const updateBookingAsync = createAsyncAction(
  'UPDATE_BOOKING_REQUEST',
  'UPDATE_BOOKING_SUCCESS',
  'UPDATE_BOOKING_FAILURE',
)<BookingRequestBody, Booking, string>();

export const clearError = createAction('CLEAR_ERROR', () => { })();

export const selectBooking = createAction(
  'SELECT_BOOKING',
  (value: Booking | null) => value,
)();

export const showConfirmBooking = createAction(
  'SHOW_CONFIRM_BOOKING',
  (value: boolean) => value,
  )();

export const setSelectedDate = createAction(
  'SET_SELECTED_DATE',
  (value: string) => value,
)();

export const setSelectedSpaceType = createAction(
  'SET_SELECTED_SPACE_TYPE',
  (value: SpaceType | null) => value,
)();

export const setSelectedSpacesForBooking = createAction(
  'SET_SELECTED_SPACES_FOR_BOOKING',
  (value: Space[]) => value,
)();

export const setSelectedUserMappingForBooking = createAction(
  'SET_SELECTED_USER_MAPPING_FOR_BOOKING',
  (value: SpaceUserMapping[]) => value,
)();

export const showBookingView = createAction(
  'SHOW_BOOKING_VIEW',
  (value: boolean) => value,
)();

export const showUserSelectionView = createAction(
  'SHOW_USER_SELECTION_VIEW',
  (value: boolean) => value,
)();

export const showBookingEditor = createAction(
  'SHOW_BOOKING_DETAILS_EDITOR_VIEW',
  (value: boolean) => value,
)();

export const showConfirmBookingDelete = createAction(
  'SHOW_CONFIRM_BOOKING_DELETE_MODAL',
  (value: boolean) => value,
)();

export const setEditBookingType = createAction(
  'SET_EDIT_BOOKING_TYPE',
  (value: BookingEditorType | null) => value,
)();
