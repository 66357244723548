import React from 'react';
import { StyleSheet, Image, TouchableOpacity } from 'react-native';
import { colors } from '@features/preferences/style/themes';
import { StyledText } from '@space/common';

type AvatarProps = {
  name: string;
  lastName?: string;
  src?: string;
  size?: string;
  backgroundColor?: string;
  onPress?: () => void;
};

const initial = (name: string, lastName: string = '') =>
  name?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase();

const renderInitialAvatar = (
  name: string,
  itemSize: string,
  lastName?: string,
) => (
  <StyledText variant={'caption'} fontColor={colors.white}>
    {initial(name, lastName)}
  </StyledText>
);

const renderImageAvatar = (src: string, name: string, size: number) => (
  <Image
    style={[Styles.image, { width: size, height: size, borderRadius: size / 2 }]}
    source={{
      uri: src ?? 'https://reactnative.dev/img/tiny_logo.png',
    }}
    accessibilityLabel={name}
  />
);

const avatarSize = (size: string) => {
  switch (size) {
    case 'xsmall':
      return 24;
    case 'small':
      return 32;
    case 'medium':
      return 35;
    case 'large':
      return 45;
    case 'xlarge':
      return 60;
    case 'xxlarge':
      return 88;
    default:
      return 30;
  }
};

const Avatar: React.FC<AvatarProps> = ({
  name,
  lastName,
  src,
  backgroundColor = colors.grey4,
  size = 'small',
  onPress,
}) => {
  const viewSize = avatarSize(size);
  const avatar = src
    ? renderImageAvatar(src, name, viewSize)
    : renderInitialAvatar(name, size, lastName);

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        Styles.container,
        {
          backgroundColor,
          width: viewSize,
          height: viewSize,
          borderRadius: viewSize / 2,
        },
      ]}>
      {avatar}
    </TouchableOpacity>
  );
};

const Styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#bdc3c7',
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 5,
  },
  image: {
    width: 30,
    height: 30,
    borderRadius: 15,
    objectFit: 'cover'
  },
});

export default Avatar;
