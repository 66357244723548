import React, { useState, useEffect, useRef } from "react";
import {
    FlatList, TouchableOpacity,
} from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { StyledText, ThemeUtil } from '@space/common';
import Expand from '../img/expand.svg';
import Expanded from '../img/expanded.svg';
import SvgIcon from '@components/SvgIcon';
import _ from 'lodash';
import {
    colors,
    palette,
} from '@features/preferences/style/themes';
import theme from 'styled-theming';
import SpacingView from '../SpacingView';

export type SingleChoiceItem = {
    id: string;
    label: string;
};

type ChoiceProps = {
    title: string;
    placeholder: string;
    items: SingleChoiceItem[];
    selectedValue: string;
    onItemSelected: (selectedId: string) => void;
};

type ViewProps = {
    color: string;
};

const buttonOptions = theme('mode', {
    light: colors.grey1,
    dark: colors.black,
});

const bgColorOptions = theme('mode', {
    light: colors.white,
    dark: colors.grey6,
});

const ExpandableSingleChoice: React.FC<ChoiceProps> = ({
    title,
    placeholder,
    items,
    selectedValue,
    onItemSelected,
}) => {
    const mainContainer = useRef();
    const styleName = useTheme().mode;
    const backgroundColor = ThemeUtil.getThemeValue(bgColorOptions)(styleName);
    const buttonColor = ThemeUtil.getThemeValue(buttonOptions)(styleName);

    const [expanded, setExpanded] = useState(false);
    const [selectedId, setSelectedId] = useState(selectedValue);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = (event) => {
        if (mainContainer.current.contains(event.target)) {
            return;
        }
        setExpanded(false);
    };

    const onExpandPress = () => {
        setExpanded(!expanded);
    };

    const onCheckChange = (item: SingleChoiceItem) => {
        setSelectedId(item.id);
        onItemSelected(item.id);
        onExpandPress();
    }

    const renderTick = (item: SingleChoiceItem) => (
        <CheckedOnContainer />
    );

    const renderList = () => (
        <DropDownContainer color={backgroundColor}>
            <FlatList
                data={items}
                renderItem={({ item }) => (
                    <ItemContainer onPress={() => onCheckChange(item)}> 
                        <TextContainer>
                            <StyledText variant={'body3'} color={'primary'}>
                                {item.label}
                            </StyledText>
                        </TextContainer>
                        <CheckedContainer>
                            {selectedId === item.id ? renderTick(item) : null}
                        </CheckedContainer>
                    </ItemContainer>
                )}
            />
        </DropDownContainer>
    );

    return (
        <Container ref={mainContainer}>
            <StyledText variant={'caption'} color={'title'}>{title}</StyledText>
            <SpacingView height={'8px'} />
            <ButtonView onPress={onExpandPress} color={buttonColor}>
                <ValueContainer>
                    <StyledText variant={'body3'}>{items.filter((item) => item.id === selectedId)[0]?.label ?? placeholder}</StyledText>
                </ValueContainer>
                <SpacingView width={'12px'} height={'5px'} />
                <SvgIcon svg={expanded ? Expanded : Expand} fillColor={ThemeUtil.getThemeValue(palette.border)(styleName)} width={14} height={6} />
            </ButtonView>
            {expanded ? renderList() : null}

        </Container>
    );
}

export default ExpandableSingleChoice;

const Container = styled.View`
  height: 80px;
  padding: 8px 8px 4px 8px;
  margin: 0px 0px 4px 0px;
  z-index: 10;
`;

const ItemContainer = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  margin: 9px;
  flex-direction: row;
  min-height: 30px;
`;

const ButtonView = styled.TouchableOpacity<ViewProps>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 40px;
  padding: 0px 12px;
`;

const ValueContainer = styled.View`
  flex-grow: 1;
`;

const StyledChecked = styled.View`
  width: 24px;
  height: 24px;
`;

const DropDownContainer = styled.View<ViewProps>`
  background-color: ${(props) => props.color};
  border: 1px solid ${colors.grey1};
  border-radius: 4px;
  min-height: 30px;
`;

const TextContainer = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
`;

const CheckedContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${palette.actionItem};
  border-radius: 12px;
  width: 24px;
  height: 24px;
`;

const CheckedOnContainer = styled.View`
  background-color: ${palette.border};
  border-radius: 6px;
  width: 12px;
  height: 12px;
`;

