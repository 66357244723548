import React, { useState, useMemo } from "react";
import { ScrollView, Dimensions, StyleSheet, View, Text } from "react-native";
import {
  Calendar as CalendarReact,
  CalendarList,
  DateObject,
  LocaleConfig
} from "react-native-calendars";
import moment from "moment";
import config from "../../config";
import styled from "styled-components/native";
import { colors } from "../../features/preferences/style/themes";
import { StyledText } from "@space/common";
import _ from "lodash";
import i18n from 'i18n-js';
import ScheduleDayView from "./ScheduleDayView";
import { Space } from 'SpaceFeature';
import { localize } from '../../localization';

type CalendarProps = {
  onStartDateSelect: (date: string) => void;
  onEndDateSelect: (date: string) => void;
  space: Space;
  currentStartDate: moment.Moment;
  currentEndDate: moment.Moment;
  setCurrentStartDate: (date: moment.Moment) => void;
  setCurrentEndDate: (date: moment.Moment) => void;
};

const PeriodCalendar: React.FC<CalendarProps> = ({
  onStartDateSelect,
  onEndDateSelect,
  space,
  currentStartDate,
  currentEndDate,
  setCurrentStartDate,
  setCurrentEndDate,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [currentStartCalendar, setCurrentStartCalendar] = useState(moment().startOf('day').utc().subtract(1, "M"))
  const [currentEndCalendar, setCurrentEndCalendar] = useState(moment().startOf('day').utc().add(1, "M"));
  const [selectedDate, setSelectedDate] = useState<DateObject | null>(null);
  const [editingStart, setEditingStart] = useState(true);

  const didSelectDate = (day: DateObject) => {
    const date = moment(day.dateString).utc();
    setSelectedDate(day);

    if (editingStart) {
      setCurrentStartDate(date);
      onStartDateSelect(date.toISOString());
    } else {
      if (date.unix() < currentStartDate.unix()) {
        setCurrentStartDate(date);
        onStartDateSelect(date.toISOString());
        return;
      }
      setCurrentEndDate(date);
      onEndDateSelect(date.toISOString());
    }
    setEditingStart(!editingStart);
  };

  const [currentMarked, setMarked] = useState({});

  const markedDatesWithOptions = useMemo(() => {
    if (_.isEmpty(currentStartDate)) {
      setMarked({});
      return;
    }

    var selectedDates: { [k: string]: any } = {};
    var date = currentStartDate.clone();

    if (Object.keys(selectedDates).length <= 0 && _.isEmpty(currentEndDate)) {
      const keyDate = moment(selectedDate).format(config.defaultDateFormat);
      selectedDates[keyDate] = { selected: true};
      setMarked({selectedDates})
    }

    if(date > currentEndDate) {
      const keyDate = moment(date).format(config.defaultDateFormat);
      selectedDates[keyDate] = { selected: true };
    }

    while (date <= currentEndDate) {
      const keyDate = moment(date).format(config.defaultDateFormat);
      selectedDates[keyDate] = { selected: true };
      date = date.add(1, 'day');
    }
    setMarked(selectedDates);

  }, [space, currentStartDate, currentEndDate, currentStartCalendar, currentEndCalendar]);

  const date = new Date();

  const renderCalendarWithSelectableDate = () => {
    LocaleConfig.defaultLocale = localize('LOCALE_LANGUAGE')
    return (
      <CalendarList
        disableAllTouchEventsForDisabledDays={true}
        monthFormat={"MMMM"}
        calendarWidth={_.min([Dimensions.get("window").width, 375])}
        calendarHeight={300}
        pastScrollRange={0}
        futureScrollRange={2}
        style={styles.calendar}
        onDayPress={didSelectDate}
        markedDates={currentMarked}
        dayComponent={(props) => (
          <ScheduleDayView
            {...props}
          />
        )}
        hideDayNames={true}
        theme={{
          calendarBackground: "",
          "stylesheet.day.basic": {
            base: {
              width: 32,
              height: 32,
            },
          },
          monthTextColor: colors.grey3,
          "stylesheet.calendar.main": {
            week: {
              marginTop: 0,
              marginBottom: 0,
              flexDirection: "row",
              justifyContent: "space-around",
            },
          },
          "stylesheet.calendar-list.main": {
            calendar: {
              paddingLeft: 24,
              paddingRight: 24,
              height: 'auto'
            },
          },
          "stylesheet.calendar.header": {
            header: {
              alignItems: "flex-start",
              paddingLeft: 11,
            },
            monthText: {
              fontSize: 13,
              fontFamily: 'Barlow_400Regular',
              color: colors.grey3,
              margin: 10,
            },
          },
        }}
        onVisibleMonthsChange={(date) => {
          const start = moment(date[0]?.dateString).subtract(1, "M");
          const end = moment(date[0]?.dateString).add(1, "M");

          setCurrentStartCalendar(start);
          setCurrentEndCalendar(end);
        }}
      />
    );
  };

  const startDateValue = () => {
    if (moment().isoWeekday() === 7) {
      return moment().locale(localize('LOCALE_LANGUAGE'));
    } else {
      return moment().locale(localize('LOCALE_LANGUAGE')).subtract(1, "week").endOf("week");
    }
  };

  var daysView: any[] = _.range(7).map((day) => {
    const date = startDateValue().add(day, "day");
    return (
      <StyledText
        key={day}
        variant={"body4"}
        fontSize={11}
        fontWeight={"600"}
        color={_.includes([6, 7], date.isoWeekday()) ? "hint" : "primary"} //TODO : Fix color
        style={
          textStyles({ disabled: _.includes([6, 7], date.isoWeekday()) })
            .dayText
        }
      >
        {date.locale(localize('LOCALE_LANGUAGE')).format("dd").charAt(0).toUpperCase()}
      </StyledText>
    );
  });

  return (
    <>
      <DaysContainer>{daysView}</DaysContainer>
      <ScrollView showsVerticalScrollIndicator={false}>
        {renderCalendarWithSelectableDate()}
      </ScrollView>
    </>
  );
};

export default PeriodCalendar;

type DayTextProps = {
  selected: boolean;
};

type HeaderProps = {
  displayWeekView: boolean;
};

const DaysContainer = styled.View`
  flex-direction: row;
  width: 100%;
  max-width: 375px;
  justify-content: space-around;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 2px;
  align-items: stretch;
`;

const styles = StyleSheet.create({
  calendar: {
    maxWidth: 375,
    height: 305,
    paddingBottom: 4,
  },
});

const textStyles = (props) =>
  StyleSheet.create({
    todayText: {
      textAlign: "center",
      paddingVertical: 2,
      paddingHorizontal: props.selected ? 8 : 0,
      borderRadius: 4,
      height: 16,
      width: props.selected ? "auto" : 0,
      color: colors.white,
    },
    dateText: {
      marginLeft: 8,
      letterSpacing: 3.2,
    },
    dayText: {
      paddingBottom: 1,
      paddingTop: 2,
      textAlign: "center",
      width: 32,
      height: 18,
    },
  });
