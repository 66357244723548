import React, { useEffect } from 'react';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import Menu from '@features/menu/components/Menu'
import { localize } from '../localization';
import { palette } from '@features/preferences/style/themes';
import BigCalendar from '../components/Calendar/BigCalendar';
import { StyledText } from '@space/common';
import { StackNavigationProp } from '@react-navigation/stack';
import { loadUserAsync } from '@features/login/actions';
import { fetchOrganizationAsync } from '../features/organization/actions';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {
  fecthUser: loadUserAsync.request,
  fetchOrganization: fetchOrganizationAsync.request
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Calendar: React.FC<Props> = ({ fecthUser, fetchOrganization }) => {
  const navigation = useNavigation<StackNavigationProp<any>>();

  useEffect(() => {
    fecthUser();
    fetchOrganization();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Menu />
      <ContentContainer>
        <TopContainer>
          <StyledText variant={'h1'}>{localize('CalendarView')}</StyledText>
        </TopContainer>
        <MainContainer>

          <CalendarContainer>
            <BigCalendar
              displayWeekView={false}
              onDateSelect={(date) => console.log(date)} />
          </CalendarContainer>

        </MainContainer>
      </ContentContainer>

    </Container>
  );
};
export const CalendarViewName = 'CalendarView';
export default connect(mapStateToProps, dispatchProps)(Calendar);

const Container = styled.View`
  flex-direction: row;
  background-color: ${palette.background};
  width: 100vw;
  height: 100%;
`;

const ContentContainer = styled.ScrollView`
  height: 100vh;
  background-color: ${palette.background};
`;

const TopContainer = styled.View`
  align-items: flex-start;
  flex-grow: 1;
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${palette.background};
  padding: 40px 48px;
  margin: 0;
  border-bottom-color: ${palette.border};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

const CalendarContainer = styled.View`
  width: 100%;
`;

const MainContainer = styled.View`
  padding: 48px;
`;
