import React, { useState } from 'react';
import { colors } from "../../../../features/preferences/style/themes";
import { localize } from "../../../../localization";
import { Language } from "OrganizationFeature";
import styled from "styled-components/native";
import LanguageListItem from "./LanguageListItem";
import { Button, StyledText } from '@space/common';
import _ from 'lodash';

type LanguageEditorModalProps = {
    languages: Language[];
    onSave: (languages: Language[]) => void;
};

const LanguageEditorModal: React.FC<LanguageEditorModalProps> = ({
    languages,
    onSave
}) => {
    const [languagesState, setLanguagesState] = useState<Language[]>(languages);
    const [error, setError] = useState<String | null>(null);

    const toggleLanguage = (language: Language) => {
        const newLanguagesList = languagesState.map((item) => {
            return item.id === language.id ? language : item;
        });

        if (newLanguagesList.some((item: Language) => item.active)) {
            setLanguagesState(newLanguagesList);
            setError(null);
        } else {
            setError(localize('ERROR_ACTIVE_LANGUAGE'));
        }
    };

    const onSavePressed = () => onSave(languagesState);

    return (
        <>
            <TextContainer>
                <StyledText variant={'body2'} color={'primary'}>
                    {localize('ADD_OR_REMOVE_LANGUAGES')}
                </StyledText>

                {error &&
                    <StyledText variant={'caption'} color={'error'}>
                        {error}
                    </StyledText>
                }
            </TextContainer>

            {languagesState.map((language: Language) =>
                LanguageListItem({
                    key: language.id.toString(),
                    language: language,
                    onPress: toggleLanguage,
                })
            )}

            <ButtonContainer>
                <Button
                    onPress={onSavePressed}
                    title={localize('APPLY')}
                    fontColor={colors.black}
                />
            </ButtonContainer>
        </>
    );
};

const TextContainer = styled.View`
  margin: 0px 0px 20px 0px;
`;

const ButtonContainer = styled.TouchableOpacity`
  margin: 34px 0px 0px 0px;
  width: 100%;
  align-self: center;
`;

export default LanguageEditorModal;
