import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Organization, Language, Office } from 'OrganizationFeature';
import {
  fetchOrganizationAsync,
  fetchOrganizationsAsync,
  setOrganizationEditType,
  openOrganizationEditorModal,
  closeOrganizationEditorModal,
  updateOrganizationAsync,
  clearError,
  showConfirmOrganizationBookingsDelete,
} from './actions';
import { OrganizationEditType } from './components/OrganizationEditorModal';
import config from '../../config';
import { weeksForOfficeInAdvanceBookingLimit, openDates } from "@space/common";

const reducer = combineReducers({
  isLoadingOrganization: createReducer(false as boolean)
    .handleAction([fetchOrganizationAsync.request], (state, action) => true)
    .handleAction(
      [fetchOrganizationAsync.success, fetchOrganizationAsync.failure],
      (state, action) => false
    ),
  
  organization: createReducer({} as Organization)
    .handleAction([fetchOrganizationAsync.success, updateOrganizationAsync.success], (state, action) => {
      const office = action.payload.offices.find((office: Office)=> office.hostId === config.officeId);
      return {...action.payload, rrule: office?.rrule, inAdvanceBookingLimit: office?.inAdvanceBookingLimit, currentOffice: office};
    }),

  office: createReducer({} as Office)
  .handleAction([fetchOrganizationAsync.success, updateOrganizationAsync.success],
  (_state, action) => {
    return action.payload.offices.find((office: Office)=> office.hostId === config.officeId);
  }),

  officeOpenCache: createReducer<Map<string, boolean>>(new Map)
  .handleAction([fetchOrganizationAsync.success, updateOrganizationAsync.success],
  (_state, action) => {
    const office = action.payload.offices.find((office: Office)=> office.hostId === config.officeId);

    const officeBookingWeeks = weeksForOfficeInAdvanceBookingLimit(office.inAdvanceBookingLimit);
    return openDates(office.rrule, officeBookingWeeks);
  }),

  languages: createReducer<Language[]>([])
  .handleAction([fetchOrganizationAsync.success, updateOrganizationAsync.success],
  (_state, action) => {
    return action.payload.languages.filter((language: Language) => language.active);
  }),

  organizationError: createReducer<string | null>(null)
    .handleAction(
      [fetchOrganizationAsync.failure, updateOrganizationAsync.failure],
      (state, action) => action.payload
    )
    .handleAction([fetchOrganizationAsync.request], (state, action) => null),

  isLoadingOrganizations: createReducer(false as boolean)
    .handleAction([fetchOrganizationsAsync.request], (state, action) => true)
    .handleAction([fetchOrganizationsAsync.success], (state, action) => false),

  organizations: createReducer([] as Organization[])
    .handleAction(fetchOrganizationsAsync.success, (state, action) => {
      return action.payload;
    })
    .handleAction(updateOrganizationAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    }),

  organizationsError: createReducer('')
    .handleAction(
      [fetchOrganizationsAsync.failure],
      (state, action) => action.payload
    )
    .handleAction([fetchOrganizationsAsync.request], (state, action) => ''),

  selectedOrganizationEditType: createReducer<OrganizationEditType | null>(
    null
  ).handleAction(setOrganizationEditType, (state, action) => action.payload),

  organizationEditorModalIsDisplayed: createReducer<boolean>(
    false
  ).handleAction(
    [openOrganizationEditorModal, closeOrganizationEditorModal],
    (state, action) => action.payload
  ),

  setOrganizationError: createReducer<string | null>(null)
    .handleAction(
      [updateOrganizationAsync.failure],
      (state, action) => action.payload
    )
    .handleAction(clearError, (state, action) => null),

  isDeleteBookingConfirmationDisplayed: createReducer<boolean>(false)
    .handleAction(
      [showConfirmOrganizationBookingsDelete],
      (state, action) => action.payload
    )
    .handleAction(
      [updateOrganizationAsync.success, updateOrganizationAsync.failure],
      (_state, _action) => false
    ),
});

export default reducer;
