import Api from '../services/common-api-client';
import {updateAuthToken} from '../services/admin-api-client';

const persistAuthToken = (store: any) => (next: any) => (action: any) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      const token = action.payload?.login.authtoken.token;
      if (token) {
        Api.updateAuthToken(token);
        updateAuthToken(token);
      }
      break;
  }

  return next(action);
};

export default persistAuthToken;
