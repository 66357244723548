import React, { useEffect, useRef, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreTypes';
import { FlatList, StyleSheet, Switch, View } from 'react-native';
import DateField from 'react-native-datefield';
import styled, { useTheme } from 'styled-components/native';
import theme from 'styled-theming';
import { colors, palette, shadow } from '../../preferences/style/themes';
import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';
import { Formik } from 'formik';
import config from '../../../config';
import { localize, localizedName } from '../../../localization';
import { Language } from 'OrganizationFeature';

import {
  showEditSpace,
  createSpaceAsync,
  createSpaceGroupAsync,
  updateSpaceGroupAsync,
  selectSpace,
  selectSpaceGroup,
} from '../actions';
import { loadUserGroupsAsync } from '../../user/actions';
import Close from '../../../img/close.svg';
import Checked from '../../../img/checked.svg';
import { Button, StyledText, ThemeUtil } from '@space/common';
import SvgIcon from '@components/SvgIcon';
import SpacingView from '@components/SpacingView';
import ListChoice from '@components/form/ListChoice';
import ListScheduleChoice from '@components/form/ListScheduleChoice';
import ListSingleChoice from '@components/form/ListSingleChoice';
import ExpandableListChoice from '@components/form/ExpandableListChoice';
import StyledTextInput from '@components/form/StyledTextInput';
import { Schedule } from 'LoginFeature';
import { Space, SpaceTag, SpaceType } from 'SpaceFeature';
import { SpaceModel } from '@features/space';

export enum SpaceEditorType {
  NEW = 'new',
  EDIT = 'edit',
  TYPE = 'type',
  NAME = 'name',
  CAPACITY = 'capacity',
  TAGS = 'tags',
  SCHEDULE = 'schedule',
  ALWAYS = 'always',
  SOME_WEEK_DAY = 'week',
  BETWEEN_DATES = 'dates',
}

const mapStateToProps = (state: RootState) => ({
  allSpaces: state.space.allSpaces,
  selectedSpace: state.space.selectedSpace,
  selectedSpaceGroup: state.space.selectedSpaceGroup,
  spaceGroups: state.space.spaceGroups,
  spaceTypes: state.space.spaceTypes,
  spaceTags: state.space.spaceTags,
  selectedEditSpaceType: state.space.selectedEditSpaceType,
  userGroups: state.user.userGroups,
  activeLanguages: state.organization.languages,
});

const dispatchProps = {
  showEditSpace: showEditSpace,
  createSpace: createSpaceAsync.request,
  createSpaceGroup: createSpaceGroupAsync.request,
  updateSpaceGroup: updateSpaceGroupAsync.request,
  fetchUserGroups: loadUserGroupsAsync.request,
  selectEditSpace: selectSpace,
  selectEditSpaceGroup: selectSpaceGroup,
};

type SpaceProps = {
  onEditorClosed: () => void;
  onSaveEditedSpace: () => void;
  onTypeChanged: (selectedType: string | SpaceType) => void;
  onTagsChanged: (selectedTags: string[]) => void;
  onGroupsChanged: (selectedTags: string[]) => void;
  onSpacesChanged: (selectedSpaces: string[]) => void;
  onScheduleChanged: (openSchedule: Schedule) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  SpaceProps;

const trackColorOn = theme('mode', {
  light: '#ACD2B6',
  dark: '#8DC89D',
});

const trackColorOff = theme('mode', {
  light: '#C5C5C5',
  dark: '#111111',
});

const SpaceEditorView: React.FC<Props> = ({
  activeLanguages,
  allSpaces,
  selectedEditSpaceType,
  selectedSpace,
  selectedSpaceGroup,
  spaceGroups,
  spaceTypes,
  spaceTags,
  userGroups,
  fetchUserGroups,
  createSpace,
  createSpaceGroup,
  selectEditSpace,
  selectEditSpaceGroup,
  onEditorClosed,
  onSaveEditedSpace,
  onTagsChanged,
  onGroupsChanged,
  onTypeChanged,
  onSpacesChanged,
  onScheduleChanged,
}) => {
  const mainContainer = useRef();
  const styleName = useTheme().mode;
  const today = moment().startOf('day').format(config.defaultDateFormat);
  const tomorrow = moment().add(1, 'days').format(config.defaultDateFormat);

  const space = selectedSpace
    ? selectedSpace
    : selectedSpaceGroup
    ? selectedSpaceGroup
    : null;

  const newSpace = {
    id: '',
    type: null,
    name: [],
    capacity: 1,
    tagsList: [],
    groupsList: [],
    groupSpaces: [],
    openSchedule: null,
    openDate: today,
    deletedAt: null,
  };

  const [currentSpace, setSpace] = useState(space ? space : newSpace);

  const groupSpaces = useMemo(() => {
    const inGroup = allSpaces
      .filter((space) => space.group?.id === currentSpace.id)
      .map((space) => space.id);
    return inGroup;
  }, [allSpaces, spaceGroups]);

  const allAvailableSpaces = useMemo(() => {
    return allSpaces.sort((spaceA, spaceB) =>
      spaceA.name < spaceB.name ? -1 : 1
    );
  }, [allSpaces]);


  if (userGroups[0]?.id !== null) {
    userGroups.unshift({ id: null, name: localize('USER_GROUPS_ALL') });
  }

  // Editable fields
  const [currentType, setType] = useState(currentSpace.type);
  const [currentTags, setTags] = useState(currentSpace.tagsList);
  const [currentGroupSpaces, setGroupSpaces] = useState(groupSpaces);
  const colorTrackColorOn = ThemeUtil.getThemeValue(trackColorOn)(styleName);
  const colorTrackColorOff = ThemeUtil.getThemeValue(trackColorOff)(styleName);

  useEffect(() => {
    fetchUserGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeSpaceEditor = () => {
    onEditorClosed();
  };

  const saveNewSpace = () => {
    const type = currentSpace.type;
    if (type.model === SpaceModel.POD) {
      const createdSpaceGroup = {
        type: currentSpace.type,
        name: currentSpace.name,
        capacity: currentSpace.groupSpaces.length,
        tagsList: currentSpace.tagsList,
        groupsList: currentSpace.groupsList,
        openSchedule: currentSpace.openSchedule,
        openDate: currentSpace.openDate,
        groupSpaces: currentSpace.groupSpaces,
        disabledAt: null,
      };
      createSpaceGroup(createdSpaceGroup);
    } else {
      const createdSpace = {
        type: currentSpace.type,
        name: currentSpace.name,
        capacity: currentSpace.capacity,
        tagsList: currentSpace.tagsList,
        groupsList: currentSpace.groupsList,
        openSchedule: currentSpace.openSchedule,
        openDate: currentSpace.openDate,
        disabledAt: null,
        groupId: null,
      };
      createSpace(createdSpace);
    }
    closeSpaceEditor();
  };

  const saveEditedSpace = () => {
    if (selectedSpace) {
      selectEditSpace(selectedSpace);
      onSaveEditedSpace();
    } else if (selectedSpaceGroup) {
      selectEditSpaceGroup(selectedSpaceGroup);
      onSaveEditedSpace();
    }
  };

  const SpaceSchema = Yup.object().shape({
    name: Yup.array().of(
      Yup.object().shape({
        languageId: Yup.number().required(localize('REQUIRED')),
        name: Yup.string().required(localize('REQUIRED')),
        title: Yup.string().required(localize('REQUIRED'))
      })
    ).required(localize('REQUIRED')),
    capacity: Yup.number().max(100, localize('MAX_CAPACITY')),
  });

  const renderSpaceForm = () => {
    switch (selectedEditSpaceType) {
      case SpaceEditorType.TYPE:
        return renderTypeForm();
      case SpaceEditorType.CAPACITY:
        return renderCapacityForm();
      case SpaceEditorType.TAGS:
        return renderTagsForm();
      default:
        return renderNewSpaceForm();
    }
  };

  const getTitle = () => {
    switch (selectedEditSpaceType) {
      case SpaceEditorType.NEW:
        return localize('NEW_SPACE');
      case SpaceEditorType.EDIT:
        return localize('EDIT_SPACE');
      case SpaceEditorType.TYPE:
        return localize('SPACE_UPDATE_TYPE');
      case SpaceEditorType.CAPACITY:
        return localize('SPACE_GROUP_UPDATE_SPACES');
      case SpaceEditorType.TAGS:
        return localize('SPACE_UPDATE_TAGS');
      case SpaceEditorType.SCHEDULE:
        return localize('SPACE_UPDATE_SCHEDULE');
      default:
        return '';
    }
  };

  const getSubmitButtonTitle = () => {
    switch (selectedEditSpaceType) {
      case SpaceEditorType.NEW:
        return localize('CREATE');
      case SpaceEditorType.EDIT:
        return localize('UPDATE');
      default:
        return '';
    }
  };

  const isSpaceInGroup = (space: Space) => {
    return !_.isEmpty(space.group) || space.type.model !== SpaceModel.SEAT;
  };
  

  const renderTick = () => {
    return(
      <StyledChecked>
        <SvgIcon
          svg={Checked}
          width={24}
          height={24}
          fillColor={ThemeUtil.getThemeValue(palette.border)(styleName)}
        />
      </StyledChecked>
    )
  };

  const disabledRenderTick = () => {
    return(
      <DisabledStyledChecked disabled={true}>
        <SvgIcon
          svg={Checked}
          width={24}  
          height={24}
          fillColor={ThemeUtil.getThemeValue(palette.border)(styleName)}
        />
      </DisabledStyledChecked>
    )
  };

  const renderNewSpaceForm = () => {
    const defaultName = activeLanguages.map((language: Language) => ({
      languageId: language.id,
      name: currentSpace.name?.find(i=>i.languageId === language.id)?.name ?? '',
      title: localize(language.name)
    }));
  
    return (
    <>
      <Formik
        validationSchema={SpaceSchema}
        initialValues={{
          type: currentSpace.type,
          name: defaultName,
          capacity: currentSpace.capacity,
          tagsList: currentSpace.tagsList,
          openSchedule: currentSpace.openSchedule,
          openDate: currentSpace.openDate,
          groupsList: currentSpace.groupsList,
          groupSpaces: currentSpace.groupSpaces,
        }}
        onSubmit={(values) => {
          currentSpace.type = spaceTypes.filter((type) => {
            return type.id === values.type.id;
          })[0];
          currentSpace.name = values.name.map((newName) => ({ name: newName.name, languageId: newName.languageId }));
          currentSpace.capacity = parseInt(values.capacity, 10);
          currentSpace.tagsList = values.tagsList;
          currentSpace.openSchedule = values.openSchedule;
          currentSpace.openDate = values.openDate;
          currentSpace.groupsList = values.groupsList;
          currentSpace.groupSpaces = values.groupSpaces;
          setSpace(currentSpace);

          if (selectedEditSpaceType === SpaceEditorType.NEW) {
            saveNewSpace();

          } else if (selectedEditSpaceType === SpaceEditorType.EDIT) {
            saveEditedSpace();
          }

          selectEditSpace(null);
          selectEditSpaceGroup(null);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => {
          const submitDisabled =
            !_.isEmpty(localizedName(values.name, activeLanguages)) && !_.isEmpty(values.openSchedule) && !_.isEmpty(values.type); 
          const allSpaceTypes = spaceTypes
            .filter((key) => key.model != SpaceModel.UNDEFINED)
            .sort((a, b) =>
              a.model < b.model
                ? 1
                : a.model === b.model
                ? a.name > b.name
                  ? 1
                  : -1
                : -1
            );

          const onModelCheckChange = (model: SpaceType) => {
            currentSpace.type = model;
            setFieldValue('type', model);
          };

          const onDetailsChange = (item: SpaceType) => {
            currentSpace.tagsList.some((det) => {
              return det.id === item.id;
            })
              ? currentSpace.tagsList.map((tag, index) =>
                  tag.id === item.id
                    ? currentSpace.tagsList.splice(index, 1)
                    : null
                )
              : currentSpace.tagsList.push(item);

            setFieldValue('tagsList', currentSpace.tagsList);
            onTagsSelected(currentSpace.tagsList);
          };

          const onGroupsChange = (item: SpaceType) => {
            if (item.id === null) {
              currentSpace.groupsList = [];
            } else {
              currentSpace.groupsList.some((group) => {
                return group.id === item.id;
              })
                ? currentSpace.groupsList.map((group, index) =>
                    group.id === item.id
                      ? currentSpace.groupsList.splice(index, 1)
                      : null
                  )
                : currentSpace.groupsList.push(item);
            }
            setFieldValue('groupIdsList', currentSpace.groupIdsList);
            onGroupsSelected(currentSpace.groupIdsList);
          };

          const onScheduleSelected = (schedule: Schedule) => {
            currentSpace.openSchedule = schedule;
            setFieldValue('openSchedule', schedule);
            onScheduleChanged(schedule);
          };

          const onSpacesSelected = (selectedItemIds: string[]) => {
            const chosenSpaces = allSpaces.filter((space) => selectedItemIds.includes(space.id))
            currentSpace.groupSpaces = chosenSpaces;
            setFieldValue('groupSpaces', currentSpace.groupSpaces);
            onSpacesChanged(currentSpace.groupSpaces);
            setGroupSpaces(currentSpace.groupSpaces);
          };

          const onSwitchSelected = () => {
            currentSpace.openDate = currentSpace.openDate ? null : today;
            setFieldValue('openDate', currentSpace.openDate);
          };

          const fromTodaySelected = () => {
            currentSpace.openDate = today;
            setFieldValue('openDate', currentSpace.openDate);
          };

          const fromDateSelected = (date: Date) => {
            currentSpace.openDate = date
              ? moment(date).format(config.defaultDateFormat)
              : tomorrow;
            setFieldValue('openDate', currentSpace.openDate);
          };

          const renderAdditionalInput = (type: SpaceType) => (
            <AdditionalInfoContainer>
              {type.model === SpaceModel.POD && (
                <ExpandableListChoice
                  items={allAvailableSpaces
                    .filter((space: Space) => {
                      return space.type.model === SpaceModel.SEAT;
                    })
                    .map((space: Space) => {
                      return {
                        label: localizedName(space.name, activeLanguages),
                        id: space.id,
                        disabled: isSpaceInGroup(space),
                        group: space.group
                      };
                  }).sort((a, b) => localizedName(a.label, activeLanguages)
                      .localeCompare(localizedName(b.label, activeLanguages), undefined, {
                      numeric: true,
                      sensitivity: 'base'
                    }))}
                  disabled={
                    _.first(
                      spaceTypes.filter((key) => key?.id === values.type?.id)
                    )?.model !== SpaceModel.POD || type.id !== values.type?.id
                  }
                  selectedValues={currentGroupSpaces}
                  title={localize('SPACE_TYPE_AVAILABLE_SEATS')}
                  onSelectedItemsChange={onSpacesSelected}
                  spaceGroups={spaceGroups}
                  activeLanguages={activeLanguages}
                />
              )}
              {type?.model === SpaceModel.ROOM && (
                <StyledTextInput
                  disabled={
                    _.first(
                      spaceTypes.filter((key) => key?.id === values.type?.id)
                    )?.model !== SpaceModel.ROOM || type?.id !== values.type?.id
                  }
                  width={124}
                  title={localize('HOME_CAPACITY')}
                  multiline={false}
                  keyboardType={'numeric'}
                  maxLength={124}
                  onChangeText={handleChange('capacity')}
                  onBlur={handleBlur('capacity')}
                  error={errors.capacity}
                  touched={touched.capacity}
                  value={values.capacity.toString()}
                />
              )}
            </AdditionalInfoContainer>
          );

          return (
            <FormContainer>
              <FieldContainer>
                <View>
                  {values.name.map((value, index) => {
                    const title = `${values.name[index].title}`;
                    return (
                      <StyledTextInput
                        key={index}
                        value={values.name[index].name}
                        placeholder={localize('PLACEHOLDER_NAME', {title: title})}
                        onBlur={handleBlur(`name.${index}.name`)}
                        title={title}
                        multiline={false}
                        maxLength={356}
                        onChangeText={handleChange(`name.${index}.name`)}
                        error={errors.name && errors?.name[index]?.name}
                        touched={touched.name}
                      />
                    );
                  })}
                </View>
              </FieldContainer>

              <SectionContainer>
                <ActivationSwitchContainer>
                  <StyledText variant={'caption'}>
                    {localize('ACTIVATE_SPACE')}
                  </StyledText>
                  <Switch
                    trackColor={{
                      false: colorTrackColorOff,
                      true: colorTrackColorOn,
                    }}
                    thumbColor={colors.white}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={() => onSwitchSelected()}
                    value={values.openDate >= today}
                  />
                </ActivationSwitchContainer>

                <Checkbox
                  onPress={() => fromTodaySelected()}
                  disabled={values.openDate === null || !values.openDate}
                  style={
                    (values.openDate === null || !values.openDate) &&
                    styles.disabled
                  }
                >
                  <TextContainer>
                    <StyledText variant={'body3'}>{localize('NOW')}</StyledText>
                  </TextContainer>
                  <RoundedCheckedContainer>
                    {values.openDate == today && <CheckedOnContainer />}
                  </RoundedCheckedContainer>
                </Checkbox>

                <CheckboxDateContainer>
                  <Checkbox
                    onPress={() => fromDateSelected()}
                    disabled={values.openDate === null || !values.openDate}
                    style={
                      (values.openDate === null || !values.openDate) &&
                      styles.disabled
                    }
                  >
                    <TextContainer>
                      <StyledText variant={'body3'}>
                        {localize('FROM_DATE')}
                      </StyledText>
                    </TextContainer>
                    <RoundedCheckedContainer>
                      {values.openDate > today && <CheckedOnContainer />}
                    </RoundedCheckedContainer>
                  </Checkbox>

                  <DateFieldContainer
                    pointerEvents={values.openDate > today ? 'auto' : 'none'}
                    style={values.openDate > today ? null : styles.disabled}
                  >
                    <StyledText variant={'caption'} color={'title'}>
                      {localize('FILTER_BY_DATE')}
                    </StyledText>
                    <SpacingView height={'8px'} />
                    <DateField
                      labelDate="JJ"
                      labelMonth="MM"
                      labelYear="AAAA"
                      defaultValue={
                        space && space.openDate > today
                          ? new Date(space.openDate)
                          : null
                      }
                      containerStyle={styles.containerStyle}
                      styleInput={styles.inputStyle}
                      onSubmit={(value) => fromDateSelected(value)}
                    />
                  </DateFieldContainer>
                </CheckboxDateContainer>
              </SectionContainer>
              <SectionTypeContainer>
                <HorizontalSeparator />
                <StyledText variant={'h3'} color={'hint'}>
                  {localize('SPACE_TYPES')}
                </StyledText>
                <TypesSelection>
                  <View>
                    {allSpaceTypes.map((key) => {
                      return (
                        <CheckboxContainer
                          key={key.id}
                          style={
                            key?.id === values.type?.id &&
                            styles.checkboxActiveContainer
                          }
                        >
                          <Checkbox
                            key={key.id}
                            onPress={() => onModelCheckChange(key)}
                          >
                            <TextContainer>
                              <StyledText variant={'body3'}>
                                {localizedName(key.name, activeLanguages)}
                              </StyledText>
                              <StyledText variant={'body4'}>
                                {localize(
                                  'SPACE_TYPE_DESCRIPTION_' + key.model
                                )}
                              </StyledText>
                            </TextContainer>
                            <RoundedCheckedContainer>
                              {key?.id === values.type?.id ? (
                                <CheckedOnContainer />
                              ) : null}
                            </RoundedCheckedContainer>
                          </Checkbox>
                          {renderAdditionalInput(key)}
                        </CheckboxContainer>
                      );
                    })}
                  </View>
                </TypesSelection>
              </SectionTypeContainer>
              <SectionContainer>
                <HorizontalSeparator />
                <StyledText variant={'h3'} color={'hint'}>
                  {localize('SPACE_DETAILS')}
                </StyledText>
                <FlatList
                  data={
                    spaceTags.map((type: SpaceTag) => {
                      return { name: localizedName(type.name, activeLanguages), id: type.id };
                    })
                  }
                  numColumns={2}
                  renderItem={({ item }) => {
                    return (
                      <ItemContainer>
                        <TextContainer>
                          <StyledText variant={'body3'} color={'primary'}>
                            {item.name}
                          </StyledText>
                        </TextContainer>
                        <CheckedContainer onPress={() => onDetailsChange(item)}>
                            {values.tagsList?.find((det) => det.id === item.id ) ? renderTick() :
                              currentGroupSpaces.find((groupSpace) => {
                                return (
                                  groupSpace.tagsList?.find(
                                    (tag) => tag.id === item.id
                                  ) !== undefined 
                                );
                              }) !== undefined 
                            ? disabledRenderTick() : null}
                        </CheckedContainer>
                      </ItemContainer>
                    );
                  }}
                />
              </SectionContainer>
              <SectionContainer>
                <HorizontalSeparator />
                <StyledText variant={'h3'} color={'hint'}>
                  {localize('SPACE_PERIOD')}
                </StyledText>

                <ListScheduleChoice
                  onTouchOutside={() => console.log('out')}
                  selectedValues={currentSpace.openSchedule}
                  onScheduleSelected={onScheduleSelected}
                  space={space}
                />
              </SectionContainer>
              <SectionContainer>
                <HorizontalSeparator />
                <StyledText variant={'h3'} color={'hint'}>
                  {localize('AVAILABLE_FOR')}
                </StyledText>
                <FlatList
                  data={userGroups.map((group: SpaceTag) => {
                    return { id: group.id, name: group.name };
                  })}
                  numColumns={2}
                  renderItem={({ item }) => (
                    <ItemContainer>
                      <TextContainer>
                        <StyledText variant={'body3'} color={'primary'}>
                          {localizedName(item.name, activeLanguages)}
                        </StyledText>
                      </TextContainer>
                      <CheckedContainer onPress={() => onGroupsChange(item)}>
                        {currentSpace.groupsList.some((group) => {
                          return group.id === item.id;
                        })
                          ? renderTick()
                          : item.id === null &&
                            _.isEmpty(currentSpace.groupsList)
                          ? renderTick()
                          : null}
                      </CheckedContainer>
                    </ItemContainer>
                  )}
                />
              </SectionContainer>
              {submitDisabled && (
                <ButtonContainer>
                  <Button
                    style={styles.button}
                    onPress={ () => handleSubmit()}
                    title={getSubmitButtonTitle()}
                    fontColor={colors.black}
                  />
                  <SpacingView width={'16px'} />
                  <Button
                    style={styles.button}
                    variant={'cancel'}
                    onPress={closeButtonPressed}
                    title={localize('CANCEL')}
                    // TODO: fix cancel button's font color
                    fontColor={colors.black}
                  />
                </ButtonContainer>
              )}
            </FormContainer>
          );
        }}
      </Formik>
    </>
  );
};

  const onEditTypeChanged = (type: string) => {
    onTypeChanged(type);
    setType(type);
  };

  const renderTypeForm = () => (
    <ListSingleChoice
      width={250}
      onItemSelected={onEditTypeChanged}
      onTouchOutside={() => console.log('OUTSIDE')}
      items={spaceTypes
        .filter((type) =>
          selectedSpace
            ? type.model === SpaceModel.SEAT || type.model === SpaceModel.ROOM
            : type.model === SpaceModel.POD
        )
        .map((type: SpaceType) => {
          return { label: type.name, id: type.id };
        })}
      selectedValue={currentType}
    />
  );

  const onSpacesSelected = (selectedItems: string[]) => {
    onSpacesChanged(selectedItems);
    setGroupSpaces(currentGroupSpaces);
  };

  const renderCapacityForm = () => (
    <ListChoice
      width={320}
      onTouchOutside={() => console.log('OUTSIDE')}
      items={allAvailableSpaces.map((space: Space) => {
        return { label: space.name, id: space.id };
      })}
      onSelectedItemsChange={onSpacesSelected}
      selectedValues={currentGroupSpaces}
    />
  );

  const onTagsSelected = (selectedTags: string[]) => {
    onTagsChanged(selectedTags);
    setTags(selectedTags);
  };

  const onGroupsSelected = (selectedGroups: string[]) => {
    onGroupsChanged(selectedGroups);
  };

  const renderTagsForm = () => (
    <ListChoice
      width={300}
      onTouchOutside={() => console.log('OUTSIDE')}
      items={spaceTags.map((type: SpaceTag) => {
        return { label: localizedName(type.name, activeLanguages), id: type.id };
      })}
      onSelectedItemsChange={onTagsSelected}
      selectedValues={currentTags}
    />
  );

  const closeButtonPressed = () => {
    onEditorClosed();
    selectEditSpace(null);
    selectEditSpaceGroup(null);
  };

  return (
    <Container ref={mainContainer}>
      <HeaderContainer>
        <TitleContainer>
          <StyledText fontSize={24} variant={'h2'}>
            {getTitle()}
          </StyledText>
        </TitleContainer>
        <CloseContainer onPress={closeButtonPressed}>
          <SvgIcon svg={Close} inverted={true} />
        </CloseContainer>
      </HeaderContainer>
      {renderSpaceForm()}
    </Container>
  );
};

export default connect(mapStateToProps, dispatchProps)(SpaceEditorView);

const Container = styled.View`
  padding: 40px 0 0;
  background-color: ${palette.background};
  min-height: 150px;
`;

const HeaderContainer = styled.View`
  margin: 0 48px;
  flex-direction: row;
`;

const TextContainer = styled.View`
  flex-grow: 0.4;
`;

const TitleContainer = styled.View`
  flex-grow: 1;
`;

const ButtonContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  height: 97px;
  align-items: center;
  position: sticky;
  bottom: 0;
  background-color: ${palette.background};
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${palette.border};
  z-index: 1;
`;

const CloseContainer = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

const FormContainer = styled.View`
  justify-content: flex-start;
  min-height: 250px;
`;

const FieldContainer = styled.View`
  margin: 0 40px;
  flex-direction: row;
`;

const NameContainer = styled.View`
  width: 356px;
  padding: 4px 4px 4px 0;
`;

const Checkbox = styled.TouchableOpacity`
  width: 340px;
  height: 64px;
  flex-direction: row;
  align-items: center;
  margin: 16px 24px 0 0;
  padding: 16px;
  border-color: ${palette.border};
  border-width: 1px;
  box-shadow: ${shadow};
  border-radius: 4px;
  justify-content: space-between;
`;

const CheckedOnContainer = styled.View`
  background-color: ${palette.border};
  border-radius: 6px;
  width: 12px;
  height: 12px;
`;

const HorizontalSeparator = styled.View`
  background-color: ${palette.border};
  opacity: 0.25;
  height: 1px;
  flex-shrink: 0;
`;

const SectionContainer = styled.View`
  margin: 8px 48px 34px;
  z-index: 0;
`;

const SectionTypeContainer = styled.View`
  margin: 8px 48px 34px;
  z-index: 1;
`;

const TypesSelection = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;

const CheckboxContainer = styled.View`
  flex-direction: row;
  z-index: 0;
`;

const CheckboxDateContainer = styled.View`
  flex-direction: row;
`;

const ActivationSwitchContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 13%;
`;

const DateFieldContainer = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ItemContainer = styled.View`
  width: 35%;
  display: flex;
  align-items: center;
  margin: 9px 90px 9px 0;
  padding: 0 9px;
  flex-direction: row;
  justify-content: space-between;
`;

const CheckedContainer = styled.TouchableOpacity`
  background-color: ${palette.actionItem};
  border-radius: 4px;
  width: 24px;
  height: 24px;
`;

const RoundedCheckedContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${palette.actionItem};
  border-radius: 12px;
  width: 24px;
  height: 24px;
`;

const StyledChecked = styled.View`
  width: 24px;
  height: 24px;
`;

const DisabledStyledChecked = styled.TouchableOpacity`
  background-color: ${palette.background};
  width: 24px;
  height: 24px;
`;

const AdditionalInfoContainer = styled.View`
  width: 100%;
  max-width: 362px;
`;

const styles = StyleSheet.create({
  button: {
    minWidth: '327px',
  },
  disabled: {
    opacity: 0.4,
  },
  inputStyle: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    outline: 'none!important', // Overwrite needed
  },
  containerStyle: {
    maxWidth: 150,
    height: 40,
    borderRadius: 4,
    backgroundColor: colors.grey1,
  },
  checkboxActiveContainer: {
    zIndex: 1,
  },
});
