import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components/native';
import { ScrollView } from 'react-native';
import { StyledText } from '@space/common';
import {
    colors,
    palette,
} from '@features/preferences/style/themes';
import i18n from 'i18n-js';
import { localize } from '../../localization';

type WeekDayProps = {
    selectedDays: number[];
    onSelectedDays: (selectedDays: number[]) => void;
};

type CircleProps = {
    selected: boolean;
};

const WeekDayView: React.FC<WeekDayProps> = ({
    selectedDays,
    onSelectedDays,
    space
}) => {
    const nameToNum = {'MO': 0, 'TU': 1, 'WE': 2, 'TH': 3, 'FR': 4, 'SA': 5, 'SU': 6}
    const currentRrule = space?.openSchedule?.rrule;
    const byday = currentRrule?.split('=').pop();
    const daysArray = byday?.split(',');
    const weekdays = [6, 0, 1, 2, 3, 4, 5];

    const convertToNumber = (days: string[]): number[] => {
        return days?.map((day) => {
            return nameToNum[day];
        })
    }

    const allSelectedDays = selectedDays?.concat(convertToNumber(daysArray));
    selectedDays = allSelectedDays?.filter((day,index,days)=> days.indexOf(day) == index && typeof day === 'number');

    const onPress = (item) => {
        const index = selectedDays?.indexOf(item);
        if (index === -1) {
            const days = selectedDays?.concat([item]).sort();
            onSelectedDays(days);
        } else {
            const days = selectedDays?.filter((day) => day != item).sort();
            onSelectedDays(days);
        }
    };

    const days: any[] = weekdays.map((item) => {
        const day = moment().isoWeekday(item+1).locale(localize('LOCALE_LANGUAGE')).format('dd').charAt(0).toUpperCase();

        return (
            <CircleView key={item} selected={selectedDays?.includes(item)} onPress={() => onPress(item)}>
                <StyledText inverted={selectedDays && selectedDays?.includes(item) ? true : false} color={selectedDays && selectedDays.includes(item) ? 'primary' : 'tertiary'}>{day}</StyledText>
            </CircleView>
        );
    });

    return (
        <Container>
            <ScrollView
                scrollEnabled={false}
                horizontal>
                {days}
            </ScrollView>
        </Container>
    );
};
export default WeekDayView;

const Container = styled.View`
  flex: 1;
  flex-basis: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  max-width: 375px;
  padding-bottom: 1px;
`;
const CircleView = styled.TouchableOpacity<CircleProps>`
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 8px;
  background-color: ${(props) =>
        props.selected ? palette.border : palette.background};
  width: 32px;
  height: 32px;
  border-radius: 16px;
`;