import {
  CircularIcon,
  Dialog,
  Divider,
  StyledText,
  ThemeUtil,
} from '@space/common';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { Dimensions, FlatList, StyleSheet, View } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { colors, palette, shadow } from '@features/preferences/style/themes';
import {
  deleteBookingAsync,
  loadBookingAsync,
  selectBooking,
  setSelectedDate,
  showBookingEditor,
  clearError,
  setSelectedUserMappingForBooking,
  setSelectedSpaceType,
  setEditBookingType
} from '../features/booking/actions';
import {
  fetchAllSpacesAsync,
  fetchSpaceTagsAsync,
  fetchSpaceTypesAsync,
  fetchSpacesAsync,
} from '../features/space/actions';
import {
  loadUserGroupsAsync,
  loadUserRolesAsync,
  loadUsersAsync,
} from '../features/user/actions';
import { fetchOrganizationAsync } from '../features/organization/actions';
import { loadUserAsync } from '@features/login/actions';
import styled, { useTheme } from 'styled-components/native';

import AddBooking from '../img/add_booking.svg';
import { Booking } from 'BookingFeature';
import { BookingEditorType } from '../features/booking/components/BookingEditorView';
import BookingDetailsRow from '@components/bookings/BookingDetailsRow';
import BookingEditorView from '../features/booking/components/BookingEditorView';
import CalendarsScreen from '@components/Calendar/Calendar';
import ExpandableFilterListChoice from '@components/form/ExpandableFilterListChoice';
import ExpandableFilterSingleChoice from '@components/form/ExpandableFilterSingleChoice';
import Menu from '@features/menu/components/Menu';
import ProgressBar from '@ramonak/react-progress-bar';
import { RootState } from 'StoreTypes';
import Sort from '../img/sort.svg';
import SpacingView from '@components/SpacingView';
import SvgIcon from '@components/SvgIcon';
import _ from 'lodash';
import config from '../config';
import { connect } from 'react-redux';
import { localize, localizedName } from '../localization';
import moment from 'moment';
import theme from 'styled-theming';
import { useRoute } from '@react-navigation/native';
import { Space } from 'SpaceFeature';

enum Period {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  SELECTED = 'selected'
}

enum SortBooking {
  USER = 'user',
  SPACE = 'space',
  DATE = 'date',
}

export type BookingFilter = {
  period: string;
  types: string[];
  details: string[];
};

const mapStateToProps = (state: RootState) => ({
  users: state.user.users,
  bookings: state.booking.bookings,
  spaces: state.space.space,
  availableSpacesCache: state.space.availableSpacesCache,
  spaceTypes: state.space.spaceTypes,
  spaceTags: state.space.spaceTags,
  selectedDate: state.booking.selectedDate,
  allSpaces: state.space.allSpaces,
  displayEditBooking: state.booking.isBookingEditorViewDisplayed,
  error: state.booking.bookingError,
  activeLanguages: state.organization.languages,
});

const dispatchProps = {
  selectEditBooking: selectBooking,
  setSelectedDate: setSelectedDate,
  deleteBooking: deleteBookingAsync.request,
  showBookingEditorView: showBookingEditor,
  fecthUser: loadUserAsync.request,
  fecthUsers: loadUsersAsync.request,
  fetchUserRoles: loadUserRolesAsync.request,
  fetchUserGroups: loadUserGroupsAsync.request,
  fetchBookings: loadBookingAsync.request,
  fetchSpaceTags: fetchSpaceTagsAsync.request,
  fetchSpaceTypes: fetchSpaceTypesAsync.request,
  fetchSpaces: fetchSpacesAsync.request,
  fetchAllSpaces: fetchAllSpacesAsync.request,
  clearError: clearError,
  selectSpaceType: setSelectedSpaceType,
  selectUserMapping: setSelectedUserMappingForBooking,
  setEditBookingType: setEditBookingType,
  fetchOrganization: fetchOrganizationAsync.request
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Bookings: React.FC<Props> = ({
  users,
  bookings,
  spaceTypes,
  spaceTags,
  selectedDate,
  spaces,
  availableSpacesCache,
  allSpaces,
  displayEditBooking,
  error,
  setSelectedDate,
  selectEditBooking,
  deleteBooking,
  showBookingEditorView,
  fecthUser,
  fecthUsers,
  fetchSpaceTags,
  fetchSpaceTypes,
  fetchBookings,
  fetchSpaces,
  fetchAllSpaces,
  fetchOrganization,
  clearError,
  selectUserMapping,
  selectSpaceType,
  activeLanguages,
  setEditBookingType
}) => {
  const today = moment().startOf('day').format(config.defaultDateFormat);
  const styleName = useTheme().mode;
  const route = useRoute();
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(
    selectedDate
  );
  const [isConfirmBookingDeleteViewDisplayed, showConfirmBookingDeleteModal] = useState(false);

  const defaultSort = { user: 1, space: 1, date: 1, order: SortBooking.DATE };
  const [bookingsSort, setBookingsSort] = useState(defaultSort);
  const emptyFilters = { period: Period.DAY, types: [], details: [] };
  const [bookingsFilters, setBookingsFilters] = useState<BookingFilter>(
    emptyFilters
  );
  const [animation, setAnimation] = useState(false);

  const periodItems = [
    { id: Period.DAY, label: localize('FILTER_BY_DAY') },
    { id: Period.WEEK, label: localize('FILTER_BY_WEEK') },
    { id: Period.MONTH, label: localize('FILTER_BY_MONTH') },
    { id: Period.SELECTED, label: localize('SELECTED_DATE') }
  ];
  const pathName = window.location.href;

  useEffect(() => {
    selectBooking(null);
    selectSpaceType(null);
    selectUserMapping([]);
  showBookingEditorView(false);
  },[pathName])

  const onPressSortUser = () => {
    setBookingsSort({
      user: -bookingsSort.user,
      space: bookingsSort.space,
      date: bookingsSort.date,
      order: SortBooking.USER,
    });
  };

  const onPressSortSpace = () => {
    setBookingsSort({
      user: bookingsSort.user,
      space: -bookingsSort.space,
      date: bookingsSort.date,
      order: SortBooking.SPACE,
    });
  };

  const onPressSortDate = () => {
    setBookingsSort({
      user: bookingsSort.user,
      space: bookingsSort.space,
      date: -bookingsSort.date,
      order: SortBooking.DATE,
    });
  };

  const sortBookingCondition = (
    bookingA: Booking,
    bookingB: Booking,
    sortParam: SortBooking
  ) => {
    switch (sortParam) {
      case SortBooking.USER:
        const userA = _.first(bookingA.users).firstName.toLowerCase() ?? '';
        const userB = _.first(bookingB.users).firstName.toLowerCase() ?? '';
        return userA < userB
          ? bookingsSort.user
          : userA > userB
          ? -bookingsSort.user
          : 0;
      case SortBooking.SPACE:
        const spaceA = localizedName(_.first(bookingA.spaces).name, activeLanguages).toLowerCase() ?? '';
        const spaceB = localizedName(_.first(bookingB.spaces).name, activeLanguages).toLowerCase() ?? '';
          return spaceA.localeCompare(spaceB, undefined, {numeric: true, ignorePunctuation: true}) === 1
          ? bookingsSort.space
          : spaceA.localeCompare(spaceB, undefined, {numeric: true, ignorePunctuation: true}) === -1
          ? -bookingsSort.space
          : 0;
      case SortBooking.DATE:
        const dateA = !_.isEmpty(bookingA.schedule)
          ? moment(bookingA.schedule.startDate)
          : moment();
        const dateB = !_.isEmpty(bookingB.schedule)
          ? moment(bookingB.schedule.startDate)
          : moment();
        return dateA > dateB
          ? bookingsSort.date
          : dateA < dateB
          ? -bookingsSort.date
          : 0;
    }
  };

  const sortBooking = (bookingA: Booking, bookingB: Booking) => {
    return sortBookingCondition(bookingA, bookingB, bookingsSort.order);
  };

  const background = theme('mode', {
    light: colors.grey1,
    dark: colors.black,
  });

  useEffect(() => {
    const startDate = moment().format(config.defaultDateFormat);
    const endDate = moment().add(1, 'M').format(config.defaultDateFormat);

    fetchOrganization();
    fecthUser();
    fecthUsers();
    fetchSpaceTags();
    fetchSpaceTypes();
    fetchSpaces({ startDate, endDate });
    fetchBookings({ startDate, endDate });
    fetchAllSpaces();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const startDate = moment().format(config.defaultDateFormat);
    const endDate = moment().add(1, 'M').format(config.defaultDateFormat);
    fetchBookings({ startDate, endDate });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayEditBooking]);

  const onFilterPeriod = (selectedId: string) => {
    setBookingsFilters({
      period: selectedId,
      types: bookingsFilters.types,
      details: bookingsFilters.details,
    });
  };

  const onFilterType = (selectedItems: string[]) => {
    setBookingsFilters({
      period: bookingsFilters.period,
      types: selectedItems,
      details: bookingsFilters.details,
    });
  };

  const onFilterDetails = (selectedItems: string[]) => {
    setBookingsFilters({
      period: bookingsFilters.period,
      types: bookingsFilters.types,
      details: selectedItems,
    });
  };

  const resetFilter = () => {
    setBookingsFilters(emptyFilters);
  };

  const newBookingButtonPressed = () => {
    setEditBookingType(BookingEditorType.NEW);
    showBookingEditorView(true);
    setTimeout(() => setAnimation(true), 100);
  };

  const closeButtonPressed = () => {
    setAnimation(false);
    setTimeout(() => showBookingEditorView(false), 100);
  };

  const editBookingButtonPressed = (booking: Booking) => {
    setEditBookingType(BookingEditorType.EDIT);
    selectEditBooking(booking);
    setSelectedDate(
      moment(booking.schedule.startDate).format(config.defaultDateFormat)
    );
    showBookingEditorView(true);
    setTimeout(() => setAnimation(true), 100);
  };

  const deleteBookingButtonPressed = (booking: Booking) => {
    selectEditBooking(booking);
    showConfirmBookingDeleteModal(true);
  };

  const filterPeriod = (booking: Booking) => {
    const startDate = moment(booking.schedule.startDate).format(
      config.defaultDateFormat
    );
    const endDate = moment(booking.schedule.endDate).format(
      config.defaultDateFormat
    );
    let selectedDateStartRange;
    let selectedDateEndRange;

    switch (bookingsFilters.period) {
      case 'day':
        selectedDateStartRange = moment().format(config.defaultDateFormat);
        selectedDateEndRange = moment().format(config.defaultDateFormat);
        break;
      case 'week':
        selectedDateStartRange = moment()
          .startOf('week')
          .format(config.defaultDateFormat);
        selectedDateEndRange = moment()
          .endOf('week')
          .format(config.defaultDateFormat);
        break;
      case 'month':
        selectedDateStartRange = moment()
          .startOf('month')
          .format(config.defaultDateFormat);
        selectedDateEndRange = moment()
          .endOf('month')
          .format(config.defaultDateFormat);
        break;
      case 'selected':
        selectedDateStartRange = moment(selectedDate).format(config.defaultDateFormat);
        selectedDateEndRange = moment(selectedDate).format(config.defaultDateFormat);
        break;
    }
    return startDate >= selectedDateStartRange && endDate <= selectedDateEndRange;
  };

  useEffect(() => {
    if (selectedDate !== moment().format(config.defaultDateFormat)){
      setBookingsFilters({
        period: 'selected',
        types: bookingsFilters.types,
        details: bookingsFilters.details,
      });
    } 
  }, [selectedDate])

  useEffect(() => {
    if (bookingsFilters.period !== 'selected'){
      setSelectedDate(moment().format(config.defaultDateFormat))
    } 
  }, [bookingsFilters.period])

  const filterType = (booking: Booking) => {
    return (
      bookingsFilters.types.length === 0 ||     
      (_.isEmpty(booking.type) && bookingsFilters.types.includes(_.first(booking.spaces).type.id))
    );
  };

  const filterDetails = (booking: Booking) => {
    const bookingSpace = allSpaces.filter(
      (space) => !_.isEmpty(booking.spaces) && space.id === booking.spaces[0].id
    )[0];

    return (
      bookingsFilters.details.length === 0 ||
      (!_.isEmpty(bookingSpace) &&
        bookingsFilters.details.filter((detailId: string) =>
          bookingSpace.tagsList.map((tag) => tag.id).includes(detailId)
        ).length > 0)
    );
  };

  const allBookings = useMemo(() => {
    return bookings.filter((booking) => _.first(booking.spaces).openDate !== null)
      .filter(
        (booking) =>
          filterPeriod(booking) && filterType(booking) && filterDetails(booking)
      )
      .sort((bookingA: Booking, bookingB: Booking) =>
        sortBooking(bookingA, bookingB)
      );
  }, [bookings, selectedDate, bookingsFilters, bookingsSort]);

  const renderBookingItem = ({ item }) => {
    const bookingSpace = allSpaces.filter(
      (space) => !_.isEmpty(item.spaces) && space.id === item.spaces[0].id
    );
    const spaceType = spaceTypes.filter(
      (spaceType) => spaceType.id === item.typeId
    );

    return (
      <BookingDetailsRow
        booking={item}
        allSpaces={allSpaces}
        bookingSpace={bookingSpace}
        bookingSpaceType={spaceType}
        spaceTags={spaceTags}
        onEditPress={editBookingButtonPressed}
        onDeletePress={deleteBookingButtonPressed}
      />
    );
  };

  const allTypes = useMemo(() => {
    const types = spaceTypes.map((type) => ({ id: type.id, label: localizedName(type.name, activeLanguages) }));
    return types;
  }, [spaceTypes]);

  const bookedSpaces: string[] = useMemo(
    () =>
      _.uniqBy(
        bookings.reduce((result: string[], item: Booking) => {
          if (
            selectedDate === moment(item.schedule.startDate).format(config.defaultDateFormat) &&
            ((moment(selectedDate).isBetween(moment(_.first(item?.spaces).openSchedule.startDate), 
              moment(_.first(item?.spaces).openSchedule.endDate)
            )) || (_.first(item?.spaces).openSchedule.startDate === null && 
              _.first(item?.spaces).openSchedule.endDate === null && 
              _.first(item?.spaces).openSchedule.rrule === null)) &&
            _.first(item.spaces)?.openDate !== null
          ) {
            return result.concat(
              item.spaces?.map((map) => {
                return map.id;
              })
            );
          }
          return result;
        }, []),
        (item) => item
      ),
    [bookings, selectedDate]
  );

  const activeSpaces = useMemo(() => spaces.filter((space: Space) => {
    return availableSpacesCache.get(selectedDate)
  }).length, [spaces, selectedDate]);

  const percentOccupation =
    activeSpaces > 0 ? (bookedSpaces.length / activeSpaces) * 100 : 0;

  return (
    <Container>
      <Menu />
      <ContentContainer>
        <TopContainer>
          <StyledText variant={'h1'}>{localize('BookingsView')}</StyledText>
          <SpacingView height={'35px'} />
          <RowContent>
            <LeftSpaceContainer>
              <ButtonContainer
                style={displayEditBooking && styles.disabled}
                disabled={displayEditBooking}
                onPress={newBookingButtonPressed}
              >
                <LeftButtonContent>
                  <StyledText>{localize('NEW_BOOKING')}</StyledText>
                </LeftButtonContent>
                <CircularIcon
                  svg={AddBooking}
                  tintColor={colors.black}
                  accessibilityLabel={localize('NEW_SPACE')}
                  variant={'fill'}
                  size={'large'}
                />
              </ButtonContainer>
            </LeftSpaceContainer>
          </RowContent>
        </TopContainer>

        {!displayEditBooking ? (
          <MainContainer>
            <View style={styles.container}>
              <CalendarContainer>
                <CalendarsScreen
                  displayWeekView={true}
                  onDateSelect={setCalendarSelectedDate}
                />
              </CalendarContainer>
              <CapacityContainer>
                <StyledText fontSize={12} variant={'h3'}>
                  {localize('DAY_CAPACITY')}
                </StyledText>
                <ProgressbarContainer>
                  <CircularProgressbarContainer>
                    <CircularProgressbarWithChildren
                      value={percentOccupation}
                      background
                      circleRatio={0}
                      styles={buildStyles({
                        strokeLinecap: 'round',
                        backgroundColor: ThemeUtil.getThemeValue(background)(
                          styleName
                        ),
                        pathColor: ThemeUtil.getThemeValue(palette.primary)(
                          styleName
                        ),
                        trailColor: ThemeUtil.getThemeValue(background)(
                          styleName
                        ),
                      })}
                    >
                      <StyledContent>
                        <StyledText
                          fontSize={24}
                          variant={'h1'}
                          color={'primary'}
                          letterSpacing={'0.01em'}
                        >
                          {Math.round(percentOccupation) + '%'}
                        </StyledText>
                      </StyledContent>
                    </CircularProgressbarWithChildren>
                  </CircularProgressbarContainer>
                  <ProgressBarWrapper>
                    <ProgressBar
                      completed={percentOccupation}
                      bgColor={ThemeUtil.getThemeValue(palette.primary)(
                        styleName
                      )}
                      isLabelVisible={false}
                      width={'287px'}
                      height={'10px'}
                    />
                    <StyledText
                      style={styles.capacityText}
                      fontSize={12}
                      variant={'h3'}
                      color={'primary'}
                      letterSpacing={'1.2px'}
                    >
                      <StyledText fontWeight={600} variant={'h3'}>
                        {bookedSpaces.length ?? 0}
                      </StyledText>
                      {`/${activeSpaces}`}
                    </StyledText>
                  </ProgressBarWrapper>
                </ProgressbarContainer>
              </CapacityContainer>
            </View>

            <HorizontalSeparator />

            <ActionContainer>
              <FilterContainer>
                <StyledText variant={'h3'}>{localize('FILTER_BY')}</StyledText>
                <FilterActionContainer>
                  <ExpandableFilterSingleChoice
                    width={115}
                    items={periodItems}
                    title={localize('FILTER_BY_PERIOD')}
                    onItemSelected={onFilterPeriod}
                    selectedValue={bookingsFilters.period}
                  />
                  <SpacingView width={'8px'} height={'5px'} />
                  <ExpandableFilterListChoice
                    width={92}
                    items={allTypes.map((type) => ({
                      id: type.id,
                      label: type.label,
                    }))}
                    title={localize('FILTER_BY_TYPE')}
                    selectedValues={bookingsFilters.types}
                    onSelectedItemsChange={onFilterType}
                  />
                  <SpacingView width={'8px'} height={'5px'} />
                  <ExpandableFilterListChoice
                    width={120}
                    items={spaceTags.map((tag) => ({
                      id: tag.id,
                      label: localizedName(tag.name, activeLanguages),
                    }))}
                    title={localize('FILTER_BY_DETAILS')}
                    selectedValues={bookingsFilters.details}
                    onSelectedItemsChange={onFilterDetails}
                  />
                  <SpacingView width={'8px'} height={'5px'} />
                  <ResetButton onPress={resetFilter}>
                    <StyledText variant={'body4'}>
                      {localize('RESET_FILTER')}
                    </StyledText>
                  </ResetButton>
                </FilterActionContainer>
              </FilterContainer>
            </ActionContainer>

            <HorizontalSeparator />

            <HeaderContainer>
              <CellContainer>
                <StyledText variant={'body1'}>
                  {localize('FILTER_BY_USER')}
                </StyledText>
                <CellSvgContainer onPress={onPressSortUser}>
                  <SvgIcon
                    svg={Sort}
                    width={18}
                    height={18}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}
                  />
                </CellSvgContainer>
              </CellContainer>
              <CellContainer>
                <StyledText variant={'body1'}>
                  {localize('FILTER_BY_SPACE')}
                </StyledText>
                <CellSvgContainer onPress={onPressSortSpace}>
                  <SvgIcon
                    svg={Sort}
                    width={18}
                    height={18}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}
                  />
                </CellSvgContainer>
              </CellContainer>
              <CellContainer>
                <StyledText variant={'body1'}>
                  {localize('FILTER_BY_DATE')}
                </StyledText>
                <CellSvgContainer onPress={onPressSortDate}>
                  <SvgIcon
                    svg={Sort}
                    width={18}
                    height={18}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}
                  />
                </CellSvgContainer>
              </CellContainer>
              <CellContainer>
                <StyledText variant={'body1'}>
                  {localize('FILTER_BY_DETAILS')}
                </StyledText>
              </CellContainer>
            </HeaderContainer>

            <HorizontalSeparator />

            <SpacingView height={'2px'} />
            <FlatList
              data={allBookings}
              renderItem={renderBookingItem}
              keyExtractor={(item: Booking) => item.id}
            />
          </MainContainer>
        ) : (
          <BookingEditorViewContainer
            style={[!animation ? styles.editorOpen : styles.editorClosed]}
          >
            <BookingEditorView
              bookings={bookings}
              users={users}
              closeButtonPressed={closeButtonPressed}
            />
          </BookingEditorViewContainer>
        )}
        <Dialog
          isOpen={!_.isEmpty(error)}
          title={localize('ERROR_DEFAULT')}
          onClose={() => clearError()}
          onbuttonAction={() => clearError()}
        />
        <Dialog
          isOpen={isConfirmBookingDeleteViewDisplayed}
          title={localize('DELETE_BOOKING_ALERT_TITLE')}
          onClose={() => {
            selectEditBooking(null);
            showConfirmBookingDeleteModal(false);
          }}
          actionTitle={localize('DELETE')}
          cancelTitle={localize('CANCEL')}
          cancelVariant={'light'}
          cancelInverted={false}
          cancelBorder={ThemeUtil.getThemeValue(palette.borderLight)(styleName)}
          cancelFontColor={colors.black}
          onbuttonAction={() => {
            showConfirmBookingDeleteModal(false);
            deleteBooking(selectBooking);
            selectEditBooking(null);
          }}
        />
      </ContentContainer>
    </Container>
  );
};
export const BookingsViewName = 'BookingsView';
export default connect(mapStateToProps, dispatchProps)(Bookings);

const Container = styled.View`
  flex-direction: row;
  background-color: ${palette.background};
  width: 100vw;
  height: 100%;
`;

const ContentContainer = styled.View`
  height: 100vh;
  background-color: ${palette.background};
  flex: 1;
`;

const TopContainer = styled.View`
  align-items: flex-start;
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${palette.background};
  padding: 40px 48px;
  margin: 0;
  border-bottom-color: ${palette.border};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

const RowContent = styled.SafeAreaView`
  flex-direction: row;
  width: 100%;
`;

const CalendarContainer = styled.View`
  width: 50%;
`;

const CapacityContainer = styled.View`
  width: 50%;
  align-self: center;
  padding-top: 12px;
`;

const CellContainer = styled.View`
  flex-direction: row;
  justify-items: center;
  height: 29px;
  width: 17%;
  padding: 2px 12px;
`;

const CellSvgContainer = styled.TouchableOpacity`
  padding: 2px;
  align-self: center;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  align-self: flex-start;
  width: 100%;
`;

const LeftSpaceContainer = styled.View`
  flex-grow: 1;
`;

const ButtonContainer = styled.TouchableOpacity`
  width: 327px;
  height: 64px;
  flex-direction: row;
  align-items: center;
  margin: 16px 0px 0px 0px;
  padding: 0px 16px 0px 32px;
  border-color: ${palette.border};
  border-width: 1px;
  box-shadow: ${shadow};
  border-radius: 8px;
  justify-content: center;
`;

const LeftButtonContent = styled.View`
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
`;

const MainContainer = styled.ScrollView`
  padding: 48px;
`;

const ProgressbarContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
`;

const CircularProgressbarContainer = styled.View`
  width: 65px;
  height: 65px;
`;

const ProgressBarWrapper = styled.View`
  padding: 0 0 0 16px;
`;

const StyledContent = styled.View`
  margin-top: -10px;
`;

const ActionContainer = styled.View`
  padding: 40px 0 16px;
  flex-direction: row;
  width: 100%;
  z-index: 10;
`;

const ActionCellContainer = styled.View`
  flex-direction: row;
  width: 136px;
  padding: 2px 12px; ;
`;

const FilterContainer = styled.View`
  width: 70%;
  z-index: 10;
`;

const FilterActionContainer = styled.View`
  margin: 12px 0px;
  flex-direction: row;
`;

const HorizontalSeparator = styled.View`
  background-color: ${palette.border};
  opacity: 0.25;
  height: 1px;
  width: 100%;
  flex-shrink: 0;
`;

const ResetButton = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 24px;
  height: 40px;
  text-decoration: underline;
`;

const BookingEditorViewContainer = styled.ScrollView`
  transition: 0.4s;
`;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '49px',
  },
  capacityText: {
    alignSelf: 'flex-end',
    marginBottom: '-12px',
    paddingTop: '10px',
  },
  disabled: {
    opacity: 0.4,
  },
  editorOpen: {
    transform: `translateX(${Dimensions.get('window').width}px)`,
  },
  editorClosed: {
    transform: 'initial',
  },
});