import Constants from 'expo-constants';

const officeId = window.location.origin?.replace(/(^\w+:|^)\/\//, '').split('.')[0].split('-')[0];

const local = {
    url: {
        server: 'http://localhost:8081',
        redirect: 'http://localhost:19007/login',
        base: 'http://localhost:19007',
        client: 'http://localhost:19006'
    },
    officeId: 'mtl'
};

const dev = {
    url: {
        server: 'https://api.dev.assistant.nurunmtl.com',
        redirect: `${window.location.origin}/login`,
        base: window.location.origin,
        client: `https://${officeId}.dev.assistant.nurunmtl.com`
    },
};

const qa = {
    url: {
        server: 'https://api.qa.assistant.nurunmtl.com',
        redirect: `${window.location.origin}/login`,
        base: window.location.origin,
        client: `https://${officeId}.qa.assistant.nurunmtl.com`
    },
};

const prod = {
    url: {
        server: 'https://api.flexableapp.com',
        redirect: `${window.location.origin}/login`,
        base: window.location.origin,
        client: `https://${officeId}.flexableapp.com`,
    }
};

const config = () => {
    switch (Constants.manifest.extra.environment) {
        case 'local':
            return local;
        case 'dev':
            return dev;
        case 'qa':
            return qa;
        default:
            return prod;
    }
};

export default {
    defaultDateFormat: 'YYYY-MM-DD',
    dayFormat: 'dddd DD MMM YYYY',
    fulldayFormat: 'dddd DD MMMM YYYY',
    monthdayFormat: 'DD MMM',
    monthyearFormat: "MMMM YYYY",
    weekdayFormat: 'dddd DD MMM',
    homeMessageFormat: 'DD MMMM YYYY',
    exceptionDateFormat: 'YYYYMMDDTHHmmss',
    appRedirect: 'msauth://com.nurun.assistant/callback',
    authClientId: '15d17be2-a6a6-4c68-9226-1e16be1d6ef3',
    officeId: officeId,
    weeksToDisplay: 3,
    ...config()
};