import React, { useContext } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import {
  loadUsersAsync,
  setSpaceIdSelectedForMapping,
  setUserSearchTextForMapping,
} from '../../features/user/actions';
import Close from '../../img/close.svg';
import AvatarList from '../AvatarList';
import {
  setSelectedUserMappingForBooking,
} from '../../features/booking/actions';
import styled, { useTheme } from 'styled-components/native';
import SearchView from '../SearchView';
import { User } from 'UserFeature';
import { SpaceUserMapping } from 'BookingFeature';
import _ from 'lodash';
import { localize } from '../../localization';
import { ThemeUtil, StyledText } from '@space/common';

const mapStateToProps = (state: RootStateOrAny) => ({
  users: state.user.users,
  selectedUserMapping: state.booking.selectedUserMappingForBooking,
  userSearchText: state.user.userSearchTextForMapping,
  selectedSpaceIdForMapping: state.user.spaceIdSelectedForMapping,
  selectedSpaceForMapping: state.booking.selectedUserMappingForBooking,
});

const dispatchProps = {
  fetchUsers: loadUsersAsync.request,
  selectUserMapping: setSelectedUserMappingForBooking,
  setUserSearchText: setUserSearchTextForMapping,
  selectSpaceIdForMapping: setSpaceIdSelectedForMapping,
};

type UserSelectionProps = {
    showUserSelection: (active: boolean) => void;
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & UserSelectionProps & any;

const UserSelectionView: React.FC<Props> = ({
  users,
  fetchUsers,
  showUserSelection,
  selectUserMapping,
  setUserSearchText,
  userSearchText,
  selectSpaceIdForMapping,
  selectedSpaceForMapping,
  selectedSpaceIdForMapping,
}) => {
  const styleName = useTheme().mode;

  const updateUserPlaceMappingListState = (user?: User) => {
    const current = _.first(
      selectedSpaceForMapping.filter(
        (mapping: SpaceUserMapping) =>
          mapping.spaceId === selectedSpaceIdForMapping,
      ),
    ) as SpaceUserMapping;

    const newUser = current === null || current?.userId !== user?.id;

    const emptyMapping = _.filter(
      selectedSpaceForMapping,
      (mapping: SpaceUserMapping) =>
        mapping.spaceId !== selectedSpaceIdForMapping,
    );

    if (newUser) {
      const newMapping = { userId: user?.id, spaceId: selectedSpaceIdForMapping };
      selectUserMapping([newMapping].concat(emptyMapping));
    } else {
      selectUserMapping(emptyMapping);
    }
  };

  const selectedMapping = selectedSpaceForMapping
    ? (_.first(
      selectedSpaceForMapping.filter(
        (mapping) => mapping.spaceId === selectedSpaceIdForMapping,
      ),
    ) as SpaceUserMapping)
    : null;

  const closeUserSelection = () => {
    selectSpaceIdForMapping(null);
    setUserSearchText(null);
    showUserSelection(false);
  };

  const listOfNotSelectedAndSearchedUsers = () => { 
    const usersList = users
      ?.filter((item: User) => {
        return (
          ((userSearchText === null ||
            userSearchText.length < 3 ||
            item.firstName
              .toLowerCase()
              .includes(userSearchText.toLowerCase()) ||
            item.lastName
              .toLowerCase()
              .includes(userSearchText.toLowerCase())) &&
              item.disabledAt === null &&
            selectedSpaceForMapping.filter(
              (mapping) => mapping.userId === item.id,
            ).length === 0) ||
          (selectedMapping != null && item.id === selectedMapping?.userId)
        );
      })
      .sort((a, b) => 0 - (a.firstName < b.firstName ? 1 : -1));

    return usersList;
  }

  const theme = useTheme();
  return (
    <StyledContainer>
      <StyledHeader>
        <ActionButton onPress={closeUserSelection}>
          <Close
            width={24}
            height={24}
            fill={ThemeUtil.getInvertedThemeValue(theme.palette.common)(styleName)}
          />
        </ActionButton>
      </StyledHeader>

      <SearchView
        placeholder={localize('BOOKING_ASSIGN_SPACE_SEARCH_PLACEHOLDER')}
        caption={localize('BOOKING_ASSIGN_SPACE_SEARCH_CAPTION')}
        onChangeText={(text) => {
          setUserSearchText(text);
        }}
      />

      <AvatarList
        list={listOfNotSelectedAndSearchedUsers()}
        selectedUserId={selectedMapping?.userId}
        onItemSelected={(user) => {
          updateUserPlaceMappingListState(user);
          closeUserSelection();
        }}
      />
      {listOfNotSelectedAndSearchedUsers().length === 0 ? <StyledText variant={'caption'}>
        {localize('NO_RESULT')}
      </StyledText> : null}

    </StyledContainer>
  );
};

const ActionButton = styled.TouchableOpacity`
  position: absolute;
  right: 0px;
  top: 0px;
`;

const StyledContainer = styled.View`
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin: 0px;
  padding: 32px 25px;
  background-color: ${(props) => props.theme.palette.background};
`;

const StyledHeader = styled.View`
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  min-height: 25px;
`;

export default connect(mapStateToProps, dispatchProps)(UserSelectionView);
