import * as auth from "./auth-client";
import * as admin from "./admin-api-client";
import common from './common-api-client';

export default {
  api: {
    auth,
    admin,
    common
  },
};
