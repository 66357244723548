export enum SpaceModel {
  SEAT = 'SEAT',
  POD = 'POD',
  ROOM = 'ROOM',
  UNDEFINED = 'UNDEFINED',
}

export enum SpaceModelIcon {
  SEAT = 'img/seat.svg',
  POD = 'img/pod.svg',
  ROOM = 'img/room.svg',
}
