import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { StyledText, CircularIcon } from '@space/common';
import { Space, SpaceGroup, SpaceType } from 'SpaceFeature';
import { SpaceModel } from '@features/space';
import SpaceHelper from '../../utils/SpaceHelper';
import SpaceStats from './SpaceStats';
import { localize, localizedName } from '../../localization';
import { colors } from '@features/preferences/style/themes';
import Forward from '../img/forward.svg';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';

type SpaceStatsForTypeProps = {
  date: string;
  spaces: Space[];
  spaceGroups: SpaceGroup[];
  type: SpaceType;
  onPress: (spaceType: SpaceType) => void;
};

const mapStateToProps = (state: RootState) => ({
  activeLanguages: state.organization.languages,
});

type Props = ReturnType<typeof mapStateToProps> & SpaceStatsForTypeProps & any;

const SpaceStatsForTypeView: React.FC<Props> = ({
  date,
  spaces,
  availableSpacesCache,
  spaceGroups,
  type,
  onPress,
  activeLanguages
}) => {
  // TODO: modify with current language

  const onTypePress = () => {
    onPress(type);
  };
  
  const stats =
    type.model === SpaceModel.POD
      ? SpaceHelper.groupOccupationForDate(
          spaces,
          spaceGroups,
          date,
          type.model,
          type
        )
      : SpaceHelper.occupationForTypeByDate(spaces, availableSpacesCache, date, type);
  return (
    <RootContainer>
      <SpaceStats
        variant={'iconOnly'}
        size={'small'}
        spaceName={localizedName(type.name, activeLanguages)}
        icon={type.icon}
        availability={stats?.availability ?? 0}
        percent={stats?.occupancy ?? 0}
      />
      <SpacingView />
      <TextContainer>
        <StyledText variant={'body2'}>
          {stats?.booked ?? 0} {localizedName(type.name, activeLanguages)}
        </StyledText>
      </TextContainer>
      <StyledTouchableOpacity onPress={onTypePress}>
        <CircularIcon
          svg={Forward}
          tintColor={colors.black}
          size={'medium'}
          accessibilityLabel={localize(
            'BOOKING_ASSIGN_SPACE_SELECT_USER_ACCESSIBILITY'
          )}
          variant={'fill-stroke'}
          fillColor={colors.primaryLight}
        />
      </StyledTouchableOpacity>
    </RootContainer>
  );
};

const RootContainer = styled.View`
  width: 135px;
  height: auto;
  flex: 1;
  justify-content: space-around;
  padding-top: 4px;
  align-items: center;
`;
const SpacingView = styled.View`
  width: 100%;
  height: 8px;
`;

const StyledTouchableOpacity = styled.TouchableOpacity`
  padding: 8px;
  width: 62px;
  height: 62px;
`;

const TextContainer = styled.TouchableOpacity`
  text-align: center;
`;

export default connect(mapStateToProps)(SpaceStatsForTypeView);
