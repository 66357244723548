import React from "react";
import { connect } from "react-redux";
import styled from 'styled-components/native';
import { RootState } from 'StoreTypes';
import { colors, palette } from '../../preferences/style/themes';
import { Button } from '@space/common';
import { localize, localizedName } from '../../../localization';
import { StyledText, Divider, Dialog } from '@space/common';
import { useState } from "react";
import _ from 'lodash';
import SvgIcon from '@components/SvgIcon';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { closeUserEditor, createUserAsync, updateUserAsync, selectUser, updateUserNoImageAsync } from "../actions";
import StyledTextInput from "@components/form/StyledTextInput";
import StyledMaskedInput from "@components/form/StyledMaskedInput";
import StyledFileInput from "@components/form/StyledFileInput";
import Avatar from '@components/Avatar';
import Close from '../../../img/close.svg';
import Delete from '../../../img/delete.svg';
import EmptyAvatar from '@components/img/empty_avatar.svg';
import { Organization } from 'OrganizationFeature';
import { UserRole, UserGroup } from 'UserFeature';
import ExpandableListChoice from "@components/form/ExpandableListChoice";
import ExpandableSingleChoice from "@components/form/ExpandableSingleChoice";
import SpacingView from '@components/SpacingView';
import { formatPhoneNumber } from '../../../utils/stringutils';
import * as Localization from 'expo-localization';


export enum UserEditorType {
    NEW = 'new',
    EDIT = 'edit',
}

type TextInputProps = {
    error?: string;
    touched?: boolean;
    onEditorClosed: () => void;
};

const mapStateToProps = (state: RootState) => ({
    selectedUser: state.user.selectedUser,
    organizations: state.organization.organizations,
    roles: state.user.userRoles,
    groups: state.user.userGroups,
    activeLanguages: state.organization.languages
});

const dispatchProps = {
    closeModal: closeUserEditor,
    updateUser: updateUserAsync.request,
    updateUserNoImage: updateUserNoImageAsync.request,
    createUser: createUserAsync.request,
    selectEditUser: selectUser,
};

type UserProps = {};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    UserProps;

const UserEditorView: React.FC<Props> = ({
    selectedUser,
    organizations,
    groups,
    roles,
    activeLanguages,
    closeModal,
    updateUser,
    updateUserNoImage,
    createUser,
    selectEditUser,
    onEditorClosed,
}) => {
    const defaultRole = roles.filter((role: UserRole) => {
            return localizedName(role.name, activeLanguages) === localize('ROLE_USER');
        });
    const newUser = { id: '', firstName: '', lastName: '', avatar: null, email: '', phone: '', jobTitle: '', organization: _.first(organizations) ?? '', rolesList: defaultRole };
    const [currentUser, setUser] = useState(selectedUser ?? newUser);
    const [currentAvatar, setAvatar] = useState<File | string | null>(currentUser.avatar);
    const [currentPreview, setPreview] = useState<string | ArrayBuffer | null>();
    const [fileSizeLimitExceeded, setFileSizeLimitExceeded] = useState(false);

    const currentIdsRoles : string[] = currentUser.rolesList ? currentUser.rolesList?.map((role: UserRole) => {
        return role.id;
    }) : [_.first(defaultRole).id];

    const currentIdsGroups : string[] = currentUser.groupsList?.map((group: UserGroup) => {
        return group.id;
    });

    const savePressed = () => {
        const jobTitle = !_.isEmpty(currentUser.jobTitle) ? currentUser.jobTitle : '';
        const cleanPhone = !_.isEmpty(currentUser.phone) ? currentUser.phone.replace(/\D/g, '') : null;
        const avatar = !isEmptyAvatar(currentAvatar) ? currentAvatar : null;
        const rolesList = !_.isEmpty(currentUser.rolesList) && _.first(currentUser.rolesList).id 
            ? currentUser.rolesList?.map((role: UserRole) => { return role.id }) : null;

        if (selectedUser) {
            
            const groupsList = !_.isEmpty(currentUser.groupsList) && _.first(currentUser.groupsList).id 
            ? currentUser.groupsList?.map((group: UserGroup) => { return group.id }) : null;

            const updatedUser = {
                id: currentUser.id,
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                email: currentUser.email,
                phone: formatPhoneNumber(cleanPhone),
                jobTitle: jobTitle,
                organization: _.first(organizations),
                groupsList: groupsList ? groupsList.join(',') : currentUser.groupsList ? currentUser.groupsList.join(',') : '',
                rolesList: rolesList ? rolesList.join(',') : currentUser.rolesList ? currentUser.rolesList.join(',') : '',
                avatar: avatar,
                disabledAt: currentUser.disabledAt,
                preferredLanguageId: currentUser.preferredLanguage ? currentUser.preferredLanguage.id.toString() : 
                    _.first(activeLanguages.filter((lang) => lang.name.split("-")[0] == Localization.locale.toLocaleLowerCase()))?.id.toString(),
            };
            avatar ? updateUser(updatedUser) : updateUserNoImage(updatedUser);
            onEditorClosed();
        } else {
            const createdUser = {
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                email: currentUser.email,
                phone: formatPhoneNumber(cleanPhone),
                jobTitle: jobTitle,
                organization: currentUser.organization,
                groupsList: currentUser.groupsList ? currentUser.groupsList.join(',') : '',
                rolesList: rolesList ? rolesList.join(',') : currentUser.rolesList ? currentUser.rolesList.join(',') : '',
                disabledAt: null,
                avatar: avatar,
                preferredLanguageId: _.first(activeLanguages.filter((lang) => lang.name.split("-")[0] == Localization.locale.toLocaleLowerCase()))?.id.toString(),
            };
            createUser(createdUser);   
        }
    };

    const onDeletePicturePress = () => {
        setPreview(null);
        setAvatar(null);
    };

    const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
    const UserSchema = Yup.object().shape({
        firstName: Yup.string().required(localize('REQUIRED')).min(3, localize('INVALID_NAME')),
        lastName: Yup.string().required(localize('REQUIRED')).min(1, localize('INVALID_NAME')),
        email: Yup.string().email(localize('INVALID_EMAIL')).required(localize('REQUIRED')),
        phone: Yup.string().matches(phoneRegExp, localize('INVALID_PHONE')).nullable()
    });

    const isEmptyAvatar = (avatar: File | string | null) => {
        if (avatar instanceof File) {
            return false;
        }
        return _.isEmpty(avatar) || avatar === 'undefined'
    }

    const renderDeleteContainer = () => {
        if (!isEmptyAvatar(currentUser.avatar) || currentPreview) {
            return <DeleteContainer onPress={onDeletePicturePress}>
                <Delete/>
            </DeleteContainer>
        }
    }

    const renderPreview = () => {
        const size = 88;
        const avatarName = (currentAvatar instanceof File) ? currentAvatar.name : currentAvatar;
        return <AvatarImageContainer><img src={currentPreview} style={{ width: size, height: size, borderRadius: size / 2, objectFit: 'cover'}}
            alt={avatarName ? avatarName : localize('PROFILE_PICTURE')}
            height={size}
            width={size} />
            {renderDeleteContainer()}
        </AvatarImageContainer>
    };

    const renderAvatar = () => {
        if (!isEmptyAvatar(currentAvatar)) {
            const avatarName = (currentAvatar instanceof File) ? currentAvatar.name : currentAvatar;
            return <AvatarContainer>
                <Avatar name={currentUser?.firstName ?? ''} lastName={currentUser?.lastName ?? ''} src={avatarName} size={'xxlarge'} />
                {renderDeleteContainer()}
            </AvatarContainer>
        }

        return <AvatarEmptyContainer>
            <SvgIcon svg={EmptyAvatar} witdh={21} height={21} />
        </AvatarEmptyContainer>

    };

    const renderProfileAvatarForm = () => {
        return (
            <>
                <StyledText variant={'body4'}>{localize('PROFILE_PICTURE')}</StyledText>
                <SpacingView height={'24px'} />
                <DownloadContainer>
                    {currentPreview ? renderPreview() : renderAvatar()}
                    <DownloadActionContainer>
                        <StyledFileInput title={localize('MODIFY_IMAGE')} onSelectFile={(file) => {
                            setAvatar(file);
                        }} onPreviewLoaded={(buffer) => {
                            setPreview(buffer);
                        }} onFileSizeError={() => {
                            setFileSizeLimitExceeded(true);
                        }}/>
                    </DownloadActionContainer>
                </DownloadContainer>
            </>
        );
    };

    const renderUserForm = () => {

        return (
            <>
                <Formik
                    validationSchema={UserSchema}
                    initialValues={{
                        firstName: currentUser.firstName,
                        lastName: currentUser.lastName,
                        organization: currentUser.organization,
                    email: currentUser.email,
                        jobTitle: currentUser.jobTitle,
                        phone: currentUser.phone,
                        groups: currentUser.groupsList,
                        roles: currentUser.rolesList ? currentUser.rolesList : defaultRole
                    }}
                    onSubmit={values => {
                        currentUser.firstName = values.firstName
                        currentUser.lastName = values.lastName
                        currentUser.email = values.email
                        currentUser.phone = values.phone
                        currentUser.jobTitle = values.jobTitle
                        currentUser.organization = values.organization
                        currentUser.groupsList = values.groups
                        currentUser.rolesList = values.roles
                        currentUser.avatar = currentAvatar
                        setUser(currentUser);
                        savePressed();
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => {
                        const organizationItems = organizations.map((organization: Organization) => {
                            return { label: organization.name, id: organization.id };
                        });

                        const groupOptions = groups.map((group: UserGroup) => {
                            return { id: group.id, label: localizedName(group.name, activeLanguages) };
                        });

                        const roleOptions = roles.map((role: UserRole) => {
                            return { id: role.id, label: localizedName(role.name, activeLanguages) };
                        });

                        const onRoleValueChange = (roles: string[]) => {
                            setFieldValue("roles", roles);
                        };

                        const onGroupValueChange = (groups: string[]) => {
                            setFieldValue("groups", groups);
                        };

                        const onOrganizationValueChange = (organizationId: string) => {
                            const organization = _.first(organizations.filter((organization) => organization.id = organizationId));
                            setFieldValue("organization", organization);
                        }

                        return (
                            <FormContainer>
                                <FieldContainer>
                                    <LeftColumnContainer>
                                        <StyledTextInput
                                            title={localize('FIRSTNAME')}
                                            placeholder={localize('USER_FIRSTNAME')}
                                            multiline={false}
                                            maxLength={280}
                                            onChangeText={handleChange('firstName')}
                                            onBlur={handleBlur('firstName')}
                                            error={errors.firstName}
                                            touched={touched.firstName}
                                            value={values.firstName} />
                                        <StyledTextInput
                                            title={localize('LASTNAME')}
                                            placeholder={localize('USER_LASTNAME')}
                                            multiline={false}
                                            maxLength={280}
                                            onChangeText={handleChange('lastName')}
                                            onBlur={handleBlur('lastName')}
                                            error={errors.lastName}
                                            touched={touched.lastName}
                                            value={values.lastName} />
                                        <ExpandableSingleChoice
                                            items={organizationItems}
                                            selectedValue={currentUser.organization?.id}
                                            placeholder={localize('ORGANIZATION')}
                                            title={localize('ORGANIZATION')} onItemSelected={onOrganizationValueChange} />
                                        <StyledTextInput
                                            title={localize('EMAIL')}
                                            placeholder={localize('USER_PLACEHOLDER_EMAIL')}
                                            multiline={false}
                                            maxLength={280}
                                            onChangeText={handleChange('email')}
                                            onBlur={handleBlur('email')}
                                            error={errors.email}
                                            touched={touched.email}
                                            value={values.email} />
                                        <ExpandableListChoice
                                            placeholder={localize('USER_ROLES')}
                                            items={roleOptions}
                                            selectedValues={currentIdsRoles}
                                            title={localize('USER_ROLES')} onSelectedItemsChange={onRoleValueChange} 
                                            disabledItems={roleOptions.filter((role) => role.label === localize('ROLE_USER'))
                                                .map((role) => role.id)} />
                                    </LeftColumnContainer>

                                    <RightColumnContainer>
                                        {renderProfileAvatarForm()}
                                        <StyledTextInput
                                            title={localize('JOB_TITLE')}
                                            placeholder={localize('JOB_TITLE')}
                                            multiline={false}
                                            maxLength={280}
                                            onChangeText={handleChange('jobTitle')}
                                            onBlur={handleBlur('jobTitle')}
                                            error={errors.jobTitle}
                                            touched={touched.jobTitle}
                                            value={values.jobTitle} />
                                        <StyledMaskedInput
                                            title={localize('PHONE_NUMBER')}
                                            placeholder={localize('PHONE_NUMBER_PLACEHOLDER')}
                                            maxLength={280}
                                            onChangeText={handleChange('phone')}
                                            onBlur={handleBlur('phone')}
                                            error={errors.phone}
                                            touched={touched.phone}
                                            value={values.phone} />
                                        <ExpandableListChoice
                                            placeholder={localize('USER_GROUPS')}
                                            items={groupOptions}
                                            selectedValues={currentIdsGroups}
                                            title={localize('USER_GROUPS')} onSelectedItemsChange={onGroupValueChange} />
                                    </RightColumnContainer>
                                </FieldContainer>
                                <ButtonContainer>
                                    <Button onPress={handleSubmit} title={selectedUser ? localize('MODIFY_USER') : localize('CREATE_USER')} fontColor={colors.black} />
                                </ButtonContainer>
                            </FormContainer>
                        );
                    }}
                </Formik >
            </>
    )};

    const cancelButtonPressed = () => {
        selectEditUser(null);
        onEditorClosed();
    };
    return (
        <Container>
            <HeaderContainer>
                <Title>
                    <StyledText variant={'h2'}>{selectedUser ? localize('UPDATE_USER') : localize('NEW_USER')}</StyledText>
                </Title>
                <CloseContainer onPress={cancelButtonPressed}>
                    <SvgIcon svg={Close} inverted={true} />
                </CloseContainer>
            </HeaderContainer>
            { renderUserForm()}
            {fileSizeLimitExceeded && (
                <Dialog
                isOpen={fileSizeLimitExceeded}
                title={localize('APP_ERROR_TITLE')}
                body={localize('IMAGE_LIMIT')}
                onClose={() => setFileSizeLimitExceeded(false)}
                closeIcon={true}
            />)}
        </Container >
    );
};

export default connect(mapStateToProps, dispatchProps)(UserEditorView);

const Container = styled.View`
  width: 100%;
  padding: 40px 30px 40px 48px;
  background-color: ${palette.background};
`;

const HeaderContainer = styled.TouchableOpacity`
  flex-direction: row;
  padding: 0 0 6px 0;
`;

const Title = styled.View`
  flex-grow: 1;
`;

const ButtonContainer = styled.TouchableOpacity`
  margin: 34px 0px 0px 0px;
  height: 48px;
  align-self: flex-start;
`;

const CloseContainer = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

const FormContainer = styled.View`
    max-width: 800px;   
    justify-content: flex-start;
    padding: 24px 0;
`;

const FieldContainer = styled.View`
  flex-direction: row;
  z-index:10;
`;

const RightColumnContainer = styled.View`
  margin-bottom: 14px;
  margin-left: 8px;
  width: 50%;
  z-index: 10;
`;

const LeftColumnContainer = styled.View`
  margin-bottom: 14px;
  margin-right: 8px;
  margin-left: -8px;
  width: 50%;
  z-index: 10;
`;

const DownloadContainer = styled.View`
    flex-direction: row;
    margin-bottom: 30px;
`;

const DownloadActionContainer = styled.View`
    padding: 24px 24px 12px 24px;
    justify-content: center;
`;

const AvatarContainer = styled.View`
    width: 88px;
    height: 88px;
`;

const AvatarEmptyContainer = styled.View`
    width: 88px;
    height: 88px;
    border-radius: 44px;
    background-color: ${colors.grey4};
    align-items: center;
    justify-content: center;
    padding-left: 5px;
`;

const DeleteContainer = styled.TouchableOpacity`
    position: absolute;
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
`;

const AvatarImageContainer = styled.View`
    position: relative;
`;
