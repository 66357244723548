import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { StyledText } from '@space/common';
import SvgIcon, { SvgType } from '@components/SvgIcon';
import { SpaceModel } from '@features/space';
import SpacingView from '../SpacingView';

import Seat from '../../img/seat.svg';
import Room from '../../img/room.svg';
import Pod from '../../img/pod.svg';

type Props = {
    model: SpaceModel;
    name: string;
    booked?: number;
    onPress: (model: SpaceModel) => void
};

const SpaceStatsDayListItem: React.FC<Props> = ({
    model,
    name,
    booked,
    onPress
}) => {
    const onRootPress = () => {
        onPress(model);
    };

    const svgType = (model: SpaceModel) => {
        switch (model) {
            case SpaceModel.SEAT:
                return Seat;
            case SpaceModel.POD:
                return Pod;
            case SpaceModel.ROOM:
                return Room;
        }
    };

    return (
        <RootContainer onPress={onRootPress}>
            <SvgIcon svg={svgType(model)} inverted={true} />
            <SpacingView height={'100%'} width={'4px'} />
            <StyledText variant={'body2'}>{booked ?? 0} </StyledText>
            <TextContainer>
                <StyledText variant={'body1'}>{' ' + name}</StyledText>
            </TextContainer>
        </RootContainer>
    );
};

const RootContainer = styled.TouchableOpacity`
  min-width: 120px;
  height: auto;
  padding-right: 50px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
`;

const TextContainer = styled.TouchableOpacity`
  max-width: 120px;
`;

export default SpaceStatsDayListItem;
