import React, { useEffect, useMemo } from 'react';
import { connect } from "react-redux";
import { palette } from '@features/preferences/style/themes';
import styled, { css, useTheme } from 'styled-components/native';
import { StyleSheet } from 'react-native';
import theme from 'styled-theming';
import { StyledText, ThemeUtil } from '@space/common';
import VerticalDivider from '../VerticalDivider';
import Avatar from '../Avatar';
import SpacingView from '../SpacingView';
import Edit from '../../img/edit.svg';
import Bin from '../../img/bin.svg';
import SvgIcon from '@components/SvgIcon';
import { Booking } from 'BookingFeature';
import { Space, SpaceType, SpaceTag, SpaceGroup } from 'SpaceFeature';
import { loadUsersAsync } from "../../features/user/actions";
import { selectBooking } from '../../features/booking/actions';
import { fetchSpaceGroupsAsync } from '../../features/space/actions';
import moment from "moment";
import { localize, localizedName } from '../../localization';
import { SpaceModel } from '@features/space';
import SpaceHelper from '../../utils/SpaceHelper';
import { RootState } from 'StoreTypes';
import { SpaceUserMapping } from 'BookingFeature';

type BookingRowProps = {
    booking: Booking;
    allSpaces: Space[];
    bookingSpace: Space;
    bookingSpaceType: SpaceType;
    onEditPress: (booking: Booking) => void;
    onDeletePress: (booking: Booking) => void;
};

const mapStateToProps = (state: RootState) => ({
    spaceGroups: state.space.spaceGroups,
    activeLanguages: state.organization.languages,
    organization: state.organization.organization
});

const dispatchProps = {
    fecthUsers: loadUsersAsync.request,
    fetchSpaceGroups: fetchSpaceGroupsAsync.request,
    selectEditBooking: selectBooking,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & BookingRowProps;

const BookingDetailsRow: React.FC<Props> = ({
    booking,
    allSpaces,
    bookingSpace,
    bookingSpaceType,
    spaceGroups,
    fetchSpaceGroups,
    onEditPress,
    onDeletePress,
    activeLanguages,
    organization
}) => {
    const styleName = useTheme().mode;
    const user = booking.organizer;
    const userName = `${user?.firstName} ${user?.lastName}`
    const capacityDefault = bookingSpace[0] && localize('SPACE_ROOM_CAPACITY', { count: bookingSpace[0].capacity });
    const details = !_.isEmpty(booking.spaces) ? _.first(booking.spaces).tagsList.map((elem) => { return localizedName(elem.name, activeLanguages)}).join(", ") : '';

    useEffect(() => {
        fetchSpaceGroups();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bookingStartDate = useMemo(() => {
        const officeTimezone = SpaceHelper.getRruleCurrentOffice(organization).options.tzid;
        return moment(booking?.schedule.startDate).tz(officeTimezone).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_DAY_FORMAT'));;
      }, [booking]); 

    const capacity = () => {
        const spaceIds = booking.spaceUserMapping.map((key: SpaceUserMapping) => key.spaceId);
        const groupIds = allSpaces.filter((space: Space) => {
            return spaceIds.includes(space.id);
        }).map((key) => key.groupId);
        const groups = spaceGroups.filter((group: SpaceGroup) => groupIds.includes(group.id));
        const total = groups.reduce((total, current) => total + current.spaces.length, 0);

        if (bookingSpaceType[0].model === SpaceModel.ROOM) {
            return capacityDefault;
        } else if (bookingSpaceType[0].model === SpaceModel.POD) {
            return localize('CARROUSEL_POD_CAPACITY', { occupation: booking.spaceUserMapping.length, total: total });
        } else if (bookingSpaceType[0].model === SpaceModel.SEAT) {
            return ''
        }
    };

    const onButtonEditPress = () => {
        onEditPress(booking);
    }

    const onButtonDeletePress = () => {
        onDeletePress(booking);
    }

    return (
        <>
            <RootContainer>
                <AvatarCellContainer>
                    <AvatarContainer>
                        {user && <Avatar
                            name={user.firstName}
                            lastName={user.lastName}
                            src={user.avatar}
                        />}
                    </AvatarContainer>
                    <AvatarName style={Styles.text}>
                        <StyledText variant={'body4'}>
                            {userName}
                        </StyledText>
                    </AvatarName>
                </AvatarCellContainer>
                <VerticalDivider />
                <CellContainer>
                    <StyledText variant={'body4'}>{!_.isEmpty(booking.spaces) && localizedName(booking.spaces[0].name, activeLanguages)}</StyledText>
                    <SpacingView height={'2px'} />
                    <StyledText variant={'body4'}>{bookingSpaceType[0] && capacity()}</StyledText>
                    <SpacingView height={'8px'} />

                </CellContainer>
                <VerticalDivider />
                <CellContainer>
                    <StyledText variant={'body4'}>
                        {bookingStartDate}
                    </StyledText>
                    <SpacingView height={'2px'} />
                    <StyledText variant={'body4'}>{localize('WHOLE_DAY')}</StyledText>
                    <SpacingView height={'8px'} />
                </CellContainer>
                <VerticalDivider />
                <DetailsCellContainer>
                    <StyledText variant={'body4'}>
                        {details}
                    </StyledText>
                </DetailsCellContainer>
                <VerticalDivider />
                <ActionCellContainer>
                    <StyledTouchableOpacity onPress={onButtonEditPress}>
                        <SvgIcon svg={Edit} width={24} height={24} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
                    </StyledTouchableOpacity>

                    <StyledTouchableOpacity onPress={onButtonDeletePress}>
                        <SvgIcon svg={Bin} width={24} height={24} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
                    </StyledTouchableOpacity>
                </ActionCellContainer>
            </RootContainer>
            <HorizontalSeparator />
        </>
    );
};

const elevation = theme('mode', {
    light: css`
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  `,
    dark: css`
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.6);
  `,
});

const RootContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
  min-height: 68px;
  padding: 20px 0px 5px;
`;

const AvatarContainer = styled.View`
    width: 32px;
    height: 32px;
    margin: 8px 8px 8px 0px;
`;

const AvatarName = styled.View`
  align-items: center;
  justify-content: center;
`;

const AvatarCellContainer = styled.View`
    display: flex;  
    flex-direction: row;
    width: 17%;
    padding: 8px 8px 8px 0;
`;

const CellContainer = styled.View`
    flex-direction: row;
    width: 17%;
    display: inline; 
    padding: 8px 12px;
`;

const DetailsCellContainer = styled.View`
    flex-direction: row;
    width: 40%;
    padding: 8px 12px;
`;

const ActionCellContainer = styled.View`
    flex-direction: row;
    width: 9%;
    padding: 12px;
`;

const TouchableContainer = styled.TouchableOpacity`
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: ${palette.background};
  border: 1px solid ${palette.border};
  border-radius: 4px;
  padding: 16px;
  ${elevation};
`;

const StyledTouchableOpacity = styled.TouchableOpacity`
  padding: 8px;
`;

const HorizontalSeparator = styled.View`
  background-color: ${palette.border};
  opacity: 0.25;
  height: 1px;
  width: 100%;
  flex-shrink: 0;
`;

const Styles = StyleSheet.create({
    text: {
        flexShrink: 1,
    },
});

export default connect(mapStateToProps, dispatchProps)(BookingDetailsRow);

