import { AuthManager } from "../auth/AuthManager";
import { AuthProviderName } from "../auth/AuthProviderName";
import { authenticateAsync } from "../features/login/actions";
import { login } from './auth-client';
import { updateAuthToken } from "./admin-api-client";
import { store as reduxStore } from '../App';
import config from '../config';

const authManager = AuthManager.getInstance();

// Adapted from axio-retry
const getCurrentState = (config) => {
    const state = config['login_retry'] || {};
    state.retryCount = state.retryCount || 0;
    config['login_retry'] = state;
    return state
}


export const refreshTokenInterceptor = (axios) => {
    axios.interceptors.request.use(config => {
        const state = getCurrentState(config);
        return config;
    });

    axios.interceptors.response.use((response) => response, async (error) => {
        const config = error.config;
        const { status } = error.response;
        const state = getCurrentState(config);

        if (status === 401 && state.retryCount < 1 && config.url !== '/auth/login') {
            state.retryCount += 1;
            config.transformRequest = [data => data];

            const token = await authManager.getTokenSilently()
                .catch(() => {
                    reduxStore.store.dispatch(authenticateAsync.failure(""));
                    return null;
                });
            //TODO: Check if the request token is different from the one saved

            if (token) {
                const response = await login(token, AuthProviderName.PUBLICIS);
                reduxStore.store.dispatch(authenticateAsync.success(response));
                updateAuthToken(response.accessToken);
                config.headers.Authorization = `Bearer ${response.accessToken}`;
                return new Promise(resolve => setTimeout(() => resolve(axios(config))));
            }

            return Promise.reject(error);

        }

        return Promise.reject(error);

    })
}
