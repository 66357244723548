import React, {useEffect, useCallback, useMemo} from "react";
import {
    FlatList,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import { connect } from "react-redux";
import styled, { useTheme } from 'styled-components/native';
import { RootState } from 'StoreTypes';
import { colors, palette } from '../../preferences/style/themes';
import { Button } from '@space/common';
import { localize } from '../../../localization';
import { StyledText, Dialog, ThemeUtil } from '@space/common';
import { useState } from "react";
import _ from 'lodash';
import SvgIcon from '@components/SvgIcon';
import { Formik, FieldArray } from 'formik';
import { StyleSheet } from 'react-native';
import * as Yup from 'yup';
import StyledTextInput from "@components/form/StyledTextInput";
import SimpleDeleteRow, { RowItem } from "@components/list/SimpleDeleteRow";
import Close from '../../../img/close.svg';
import VerticalDivider from '@components/VerticalDivider';
import {
    createSpaceTagAsync,
    updateSpaceTagAsync,
    deleteSpaceTagAsync,
    showSpaceTagEditor,
    selectSpaceTag,
    showConfirmSpaceTagDelete
} from "../actions";
import {
    fetchOrganizationAsync,
} from '@features/organization/actions';
import { SpaceTag } from "SpaceFeature";

const mapStateToProps = (state: RootState) => ({
    spaceTags: state.space.spaceTags,
    selectedSpaceTag: state.space.selectedSpaceTag,
    isConfirmSpaceTagDeleteViewDisplayed: state.space.isConfirmSpaceTagDeleteViewDisplayed,
    activeLanguages: state.organization.languages,
});

const dispatchProps = {
    createSpaceTag: createSpaceTagAsync.request,
    updateSpaceTag: updateSpaceTagAsync.request,
    deleteSpaceTag: deleteSpaceTagAsync.request,
    showSpaceTagEditor: showSpaceTagEditor,
    selectSpaceTag: selectSpaceTag,
    showConfirmSpaceTagDeleteModal: showConfirmSpaceTagDelete,
    fetchOrganization: fetchOrganizationAsync.request,
};

type SpaceTagProps = {
    showSpaceTagEditorModal: (active: boolean) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    SpaceTagProps;

const SpaceTagEditorView: React.FC<Props> = ({
    spaceTags,
    selectedSpaceTag,
    isConfirmSpaceTagDeleteViewDisplayed,
    createSpaceTag,
    updateSpaceTag,
    deleteSpaceTag,
    selectSpaceTag,
    showConfirmSpaceTagDeleteModal,
    showSpaceTagEditorModal,
    fetchOrganization,
    activeLanguages,
}) => {
    useEffect(() => {
        fetchOrganization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const styleName = useTheme().mode;
    const newTag = { id: '', name: [] };
    const [currentTag, setSpaceTag] = useState(newTag);
    const savePressed = () => {
        if (selectedSpaceTag) {
            const updatedSpaceTag = {
                id: currentTag.id,
                name: currentTag.name,
            };
            updateSpaceTag(updatedSpaceTag);
        } else {
            const createdSpaceTag = {
                name: currentTag.name,
            };
            createSpaceTag(createdSpaceTag);
        }

        resetField();
    };

    const SpaceTagSchema = Yup.object().shape({
        name: Yup.array().of(
            Yup.object().shape({
                languageId: Yup.number().required(localize('REQUIRED')),
                name: Yup.string().required(localize('REQUIRED')),
                title: Yup.string().required(localize('REQUIRED'))
            })
        ).required(localize('REQUIRED')),
    });

    const onCancelEditPress = () => {
        selectSpaceTag(null);
        resetField();
    };

    const resetField = () => {
        currentTag.id = '';
        currentTag.name = [{}];
        setSpaceTag(currentTag);
    }

    const renderCancelEdit = () => {
        return (
            <StyledButton onPress={onCancelEditPress} variant={'cancel'} title={localize('CANCEL')} fontColor={colors.black} />
        );
    };

    const renderSpaceTagForm = () => {
        const defaultName = activeLanguages.map((language: Language) => ({
            languageId: language.id,
            name: currentTag.name?.find(i=>i.languageId === language.id)?.name ?? '',
            title: localize(language.name)
        }));
        return (<Formik
            enableReinitialize={true}
            validationSchema={SpaceTagSchema}
            initialValues={{
                name: defaultName
            }}
            onSubmit={(values, { resetForm, setFieldValue, setFieldTouched }) => {
                currentTag.name = values.name;
                setSpaceTag(currentTag);
                savePressed();
                setFieldValue('values', {
                    name: values.name.map((newName) => ({ name: newName.name, languageId: newName.languageId })),
                  });
                setFieldTouched('values', false);
                resetForm({ values: { name: defaultName } });
            }}
            
        >
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => {
                const submitDisabled = useMemo(() => {
                    return !values.name.every(value => value.name !== '');
                }, [values]);        

                return (
                    <FormContainer>
                        <FieldContainer style={styles.field}>
                            <FieldArray>
                            {() => (
                                <>
                                {values.name.map((name, index) => {
                                    const title = localize(`${name.title}`);
                                    return (
                                    <div key={name.languageId}>
                                        <StyledTextInput
                                        value={name.name}
                                        placeholder={localize('PLACEHOLDER_NAME', {title: title})}
                                        onChangeText={handleChange(`name.${index}.name`)}
                                        title={title}
                                        multiline={false}
                                        maxLength={185}
                                        error={errors.name && errors?.name[index]?.name}
                                        />
                                    </div>
                                    );
                                })}
                                </>
                            )}
                            </FieldArray>
                        </FieldContainer>
                        <ButtonContainer>
                            <Button onPress={handleSubmit} title={selectedSpaceTag ? localize('UPDATE') : localize('CREATE')} disabled={!_.isEmpty(errors) || submitDisabled} fontColor={colors.black} />
                            {selectedSpaceTag ? renderCancelEdit() : null}
                        </ButtonContainer>
                    </FormContainer>
                );
            }}
        </Formik>)
    };


    const cancelButtonPressed = () => {
        selectSpaceTag(null);
        showSpaceTagEditorModal(false);
    };

    const onRowPress = (item: RowItem) => {
        const tag = spaceTags.filter((tag: SpaceTag) => tag.id === item.id)[0];
        selectSpaceTag(tag);
        currentTag.id = tag.id;
        currentTag.name = tag.name;
        setSpaceTag(currentTag);
    };

    const onDeletePress = (item: RowItem) => {
        const tag = spaceTags.filter((tag: SpaceTag) => tag.id === item.id)[0]
        selectSpaceTag(tag);
        showConfirmSpaceTagDeleteModal(true);
    };

    const renderSpaceTag = ({ item }) => {
        return (
            <SimpleDeleteRow item={{ id: item.id, label: item.name }} onRowPress={onRowPress} onDeletePress={onDeletePress} />
        );
    };

// Exiting modal with escape key
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
        document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, []);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
        cancelButtonPressed();
        }
    },[]);

    return (
        <Container>
            <ContentContainer>
                <HeaderContainer>
                    <Title>
                        <StyledText variant={'h2'}>{localize('MANAGE_SPACE_TAG')}</StyledText>
                    </Title>
                    <CloseContainer onPress={cancelButtonPressed}>
                        <SvgIcon svg={Close} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
                    </CloseContainer>
                </HeaderContainer>
                <ScrollView>
                    <MainContainer>
                        <ColumnContainer>
                            <ColumnTitle>
                                <StyledText variant={'h3'} color={'hint'}>
                                    {selectedSpaceTag ? localize('MODIFY_SPACE_TAG') : localize('CREATE_SPACE_TAG')}
                                </StyledText>
                            </ColumnTitle>
                            {renderSpaceTagForm()}
                        </ColumnContainer>
                        <VerticalDivider variant={'middle'} />
                        <ColumnRightContainer>
                            <ColumnRightTitle>
                                <StyledText variant={'h3'} color={'hint'}>
                                    {localize('EXISTING_SPACE_TAGS')}
                                </StyledText>
                            </ColumnRightTitle>
                            <FlatList
                                data={spaceTags.sort((tagA, tagB) => (tagA.id < tagB.id ? -1 : 1))}
                                renderItem={renderSpaceTag}
                                keyExtractor={(item: SpaceTag) => item.id}
                            />
                        </ColumnRightContainer>
                    </MainContainer>
                </ScrollView>
            </ContentContainer>
            <Dialog
                isOpen={isConfirmSpaceTagDeleteViewDisplayed}
                title={localize('DELETE_SPACE_TAG')}
                onClose={() => {
                    showConfirmSpaceTagDeleteModal(false);
                }}
                actionTitle={localize('DELETE')}
                cancelTitle={localize('CANCEL')}
                onbuttonAction={() => {
                    deleteSpaceTag(selectedSpaceTag);
                    selectSpaceTag(null);
                    showConfirmSpaceTagDeleteModal(false);
                }} />
        </Container >
    );
};

export default connect(mapStateToProps, dispatchProps)(SpaceTagEditorView);

const Container = styled.View`
  width: 100vw;
  max-width: 800px;
  background-color: ${palette.background};
  max-height: 90vh;
  flex-direction: row;
`;

const ButtonContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 34px 0 0 16px;
  align-self: flex-start;
`;

const CloseContainer = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  padding: 32px 24px 24px 24px;
  background: ${palette.background};
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom-width: 1px;
  border-bottom: solid black;
`;

const Title = styled.View`
  padding-top: 24px;
  flex-grow: 1;
`;

const FormContainer = styled.View`
`;

const FieldContainer = styled.View`
  flex-direction: row;
`;

const ColumnContainer = styled.View`
  margin: 0px 0px 14px 0px;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ColumnTitle = styled.View`
    padding-left: 16px;
`;

const ColumnRightContainer = styled.View`
  width: 300px;   
  margin: 0px 20px 14px 0px;
`;

const ColumnRightTitle = styled.View`
    padding: 8px;
`;

const MainContainer = styled.View`
  padding: 0px 8px;
  flex-direction: row;
  justify-content: flex-end;
  margin: 22px 0px;
`;

const LinkContainer = styled.View`
  text-decoration-line: underline;
`;

const ContentContainer = styled.View`
  background-color: ${palette.background};
  flex: 1;
`;

const StyledButton = styled(Button)`
    margin-left: 24px;
    border: 1px solid black;
`;

const styles = StyleSheet.create({
    field: {
        paddingTop: 8,
        paddingRight: 8,
        paddingLeft: 8,
        flexDirection: 'column',
    }
});