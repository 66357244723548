import React from 'react';
import { colors, palette } from '@features/preferences/style/themes';
import { StyledText, ThemeUtil } from '@space/common';
import styled, { useTheme } from 'styled-components/native';
import theme from 'styled-theming';
import StatusFull from '../img/status_full.svg';

type SpaceStatsProps = {
  availability: number;
};

type StyleProps = {
  availability: number;
  styleName: string;
};

const fontColor = theme('mode', {
  light: colors.white,
  dark: colors.grey5,
});

const backgroundColor = theme('mode', {
  light: colors.grey5,
  dark: colors.white,
});

const renderNoAvailability = () => (
  <StyledContent>
    <StatusFull width={16} height={10} />
  </StyledContent>
);

const renderAvailability = (availability: number, styleName: string) => (
  <StyledContent>
    <StyledText
      variant={'caption'}
      fontWeight={'600'}
      fontColor={ThemeUtil.getThemeValue(fontColor)(styleName)}>
      {availability}
    </StyledText>
  </StyledContent>
);

/**
 * A small profile card
 */
const SpaceAvailabilityItem: React.FC<SpaceStatsProps> = ({ availability }) => {
  const styleName = useTheme().mode;
  const display =
    availability > 0
      ? renderAvailability(availability, styleName)
      : renderNoAvailability();
  return (
    <StyledContainer availability={availability} styleName={styleName}>
      {display}
    </StyledContainer>
  );
};

const StyledContainer = styled.View<StyleProps>`
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) =>
    props.availability > 0
      ? ThemeUtil.getThemeValue(backgroundColor)(props.styleName)
      : props.theme.palette.destructive};
`;

const StyledContent = styled.View`
  align-self: center;
`;

export default SpaceAvailabilityItem;
