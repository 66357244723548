import {createReducer} from 'typesafe-actions';
import {applyTheme, setLanguage} from './actions';
import {combineReducers} from 'redux';
import { Appearance} from 'react-native-appearance';

const reducer = combineReducers({
  theme: createReducer(Appearance.getColorScheme()).handleAction(
    applyTheme,
    (state, action) => action.payload,
  ),

  language: createReducer<string | null>(null).handleAction(
    setLanguage,
    (state, action) => action.payload,
  ),
});

export default reducer;
