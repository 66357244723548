import React, { useState } from 'react';
import styled from 'styled-components/native';

import { localize } from '../../../../localization';
import { StyledText } from '@space/common';
import CheckBox from '@components/CheckBox';

import { Language } from 'OrganizationFeature';
import LanguageMap from './LanguageMap';
import LanguageError from './LanguageError';

type LanguageListItemProps = {
  key: string;
  language: Language;
  onPress: (language: Language) => void;
};

const LanguageListItem: React.FC<LanguageListItemProps> = ({
  key,
  language,
  onPress,
}) => {
  let languageName = LanguageMap.get(language.name);

  if (languageName !== undefined) {
    languageName = localize(languageName);
  }

  const toggleLanguage = () => {
    onPress({...language, active: !language.active});
  };

  return (
    <LanguageListItemContainer key={key}>
      <StyledText>
        {languageName}
      </StyledText>

      <CheckBox ticked={language.active} onPress={toggleLanguage}></CheckBox>
    </LanguageListItemContainer>
  );
};

export default LanguageListItem;

const LanguageListItemContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
`;
