import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  Space,
  SpaceQuery,
  SpaceType,
  SpaceTag,
  SpaceGroup,
  CreateSpaceQuery,
  UpdateSpaceQuery,
  CreateSpaceTagQuery,
  UpdateSpaceTagQuery,
  CreateSpaceTypeQuery,
  UpdateSpaceTypeQuery,
  CreateSpaceGroupQuery,
  UpdateSpaceGroupQuery
} from 'SpaceFeature';
import { SpaceEditorType } from './components/SpaceEditorView';

export const fetchAllSpacesAsync = createAsyncAction(
  'LOAD_ALL_SPACES_REQUEST',
  'LOAD_ALL_SPACES_SUCCESS',
  'LOAD_ALL_SPACES_FAILURE',
)<void, Space[], string>();

export const fetchSpacesAsync = createAsyncAction(
  'LOAD_SPACES_REQUEST',
  'LOAD_SPACES_SUCCESS',
  'LOAD_SPACES_FAILURE',
)<SpaceQuery, Space[], string>();

export const createSpaceAsync = createAsyncAction(
  'CREATE_SPACE_REQUEST',
  'CREATE_SPACE_SUCCESS',
  'CREATE_SPACE_FAILURE',
)<CreateSpaceQuery, SpaceType, string>();

export const updateSpaceAsync = createAsyncAction(
  'UPDATE_SPACE_REQUEST',
  'UPDATE_SPACE_SUCCESS',
  'UPDATE_SPACE_FAILURE',
)<UpdateSpaceQuery, SpaceType, string>();

export const deleteSpaceAsync = createAsyncAction(
  'DELETE_SPACE_REQUEST',
  'DELETE_SPACE_SUCCESS',
  'DELETE_SPACE_FAILURE',
)<Space, Space, string>();

export const fetchSpaceGroupsAsync = createAsyncAction(
  'LOAD_SPACE_GROUPS_REQUEST',
  'LOAD_SPACE_GROUPS_SUCCESS',
  'LOAD_SPACE_GROUPS_FAILURE',
)<void, SpaceGroup[], string>();

export const createSpaceGroupAsync = createAsyncAction(
  'CREATE_SPACE_GROUP_REQUEST',
  'CREATE_SPACE_GROUP_SUCCESS',
  'CREATE_SPACE_GROUP_FAILURE',
)<CreateSpaceGroupQuery, SpaceGroup, string>();

export const deleteSpaceGroupAsync = createAsyncAction(
  'DELETE_SPACE_GROUP_REQUEST',
  'DELETE_SPACE_GROUP_SUCCESS',
  'DELETE_SPACE_GROUP_FAILURE',
)<SpaceGroup, SpaceGroup, string>();

export const updateSpaceGroupAsync = createAsyncAction(
  'UPDATE_SPACE_GROUP_REQUEST',
  'UPDATE_SPACE_GROUP_SUCCESS',
  'UPDATE_SPACE_GROUP_FAILURE',
)<UpdateSpaceGroupQuery, SpaceGroup, string>();

export const showSpaceTagEditor = createAction(
  'SHOW_SPACE_DETAILS_EDITOR_MODAL',
  (value: boolean) => value,
)();

export const fetchSpaceTagsAsync = createAsyncAction(
  'LOAD_SPACE_TAGS_REQUEST',
  'LOAD_SPACE_TAGS_SUCCESS',
  'LOAD_SPACE_TAGS_FAILURE',
)<void, SpaceTag[], string>();

export const createSpaceTagAsync = createAsyncAction(
  'CREATE_SPACE_TAG_REQUEST',
  'CREATE_SPACE_TAG_SUCCESS',
  'CREATE_SPACE_TAG_FAILURE',
)<CreateSpaceTagQuery, SpaceTag, string>();

export const deleteSpaceTagAsync = createAsyncAction(
  'DELETE_SPACE_TAG_REQUEST',
  'DELETE_SPACE_TAG_SUCCESS',
  'DELETE_SPACE_TAG_FAILURE',
)<SpaceTag, SpaceTag, string>();

export const updateSpaceTagAsync = createAsyncAction(
  'UPDATE_SPACE_TAG_REQUEST',
  'UPDATE_SPACE_TAG_SUCCESS',
  'UPDATE_SPACE_TAG_FAILURE',
)<UpdateSpaceTagQuery, SpaceTag, string>();

export const selectSpaceTag = createAction(
  'SELECT_SPACE_TAG',
  (value: SpaceTag) => value,
)();

export const showConfirmSpaceTagDelete = createAction(
  'SHOW_CONFIRM_SPACE_TAG_DELETE_MODAL',
  (value: boolean) => value,
)();

export const showSpaceTypeEditor = createAction(
  'SHOW_SPACE_TYPE_DETAILS_EDITOR_MODAL',
  (value: boolean) => value,
)();

export const fetchSpaceTypesAsync = createAsyncAction(
  'LOAD_SPACE_TYPES_REQUEST',
  'LOAD_SPACE_TYPES_SUCCESS',
  'LOAD_SPACE_TYPES_FAILURE',
)<void, SpaceType[], string>();

export const createSpaceTypeAsync = createAsyncAction(
  'CREATE_SPACE_TYPE_REQUEST',
  'CREATE_SPACE_TYPE_SUCCESS',
  'CREATE_SPACE_TYPE_FAILURE',
)<CreateSpaceTypeQuery, SpaceType, string>();

export const deleteSpaceTypeAsync = createAsyncAction(
  'DELETE_SPACE_TYPE_REQUEST',
  'DELETE_SPACE_TYPE_SUCCESS',
  'DELETE_SPACE_TYPE_FAILURE',
)<SpaceType, SpaceType, string>();

export const updateSpaceTypeAsync = createAsyncAction(
  'UPDATE_SPACE_TYPE_REQUEST',
  'UPDATE_SPACE_TYPE_SUCCESS',
  'UPDATE_SPACE_TYPE_FAILURE',
)<UpdateSpaceTypeQuery, SpaceType, string>();

export const selectSpaceType = createAction(
  'SELECT_SPACE_TYPE',
  (value: SpaceType) => value,
)();

export const selectSpace = createAction(
  'SELECT_SPACE',
  (value: Space | null) => value,
)();

export const selectSpaceGroup = createAction(
  'SELECT_SPACE_GROUP',
  (value: SpaceGroup | null) => value,
)();

export const showConfirmSpaceDelete = createAction(
  'SHOW_CONFIRM_SPACE_DELETE_MODAL',
  (value: boolean) => value,
)();

export const showConfirmSpaceDisabled = createAction(
  'SHOW_CONFIRM_SPACE_DISABLED_MODAL',
  (value: boolean) => value,
)();

export const showEditSpace = createAction(
  'SHOW_EDIT_SPACE_MODAL',
  (value: boolean) => value,
)();

export const setEditSpaceType = createAction(
  'SET_EDIT_SPACE_TYPE',
  (value: SpaceEditorType | null) => value,
)();

export const showConfirmSpaceBookingsDelete = createAction(
  'SHOW_CONFIRM_SPACE_BOOKINGS_DELETE_MODAL',
  (value: boolean) => value,
)();

export const showConfirmSpaceGroupDelete = createAction(
  'SHOW_CONFIRM_SPACE_GROUP_DELETE_MODAL',
  (value: boolean) => value,
)();

export const showConfirmSpaceGroupDisabled = createAction(
  'SHOW_CONFIRM_SPACE_GROUP_DISABLED_MODAL',
  (value: boolean) => value,
)();

export const clearSpaceError = createAction('CLEAR_SPACE_ERROR', () => { })();

export const showConfirmSpaceTypeDelete = createAction(
  'SHOW_CONFIRM_SPACE_TYPE_DELETE_MODAL',
  (value: boolean) => value,
)();

export const clearError = createAction('CLEAR_ERROR', () => { })();

export const showWarningPodActivation = createAction(
  'SHOW_WARNING_POD_ACTIVATION',
  (value: boolean) => value,
)();
