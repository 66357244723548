import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreTypes';
import { StyleSheet, View } from 'react-native';
import styled from 'styled-components/native';
import _ from 'lodash';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { validate } from 'validate.js';
import config from '../../../config';
import { Button, Divider, StyledText } from '@space/common';
import { localize } from '../../../localization';
import constraints from '../../../utils/constraints';
import { colors, palette } from '@features/preferences/style/themes';
import StyledMaskedInput from '@components/form/StyledMaskedInput';
import SpacingView from '@components/SpacingView';
import SvgIcon from '@components/SvgIcon';
import Close from '../../../img/close.svg';
import LanguageEditorModal from './Language/LanguageEditorModal';

import { Organization, Language } from 'OrganizationFeature';
import {
  closeOrganizationEditorModal,
  updateOrganizationAsync,
} from '../actions';
import { formatPhoneNumber } from '../../../utils/stringutils';

export enum OrganizationEditType {
  NAME = 'name',
  LOCATION = 'location',
  MAIN_ADMIN = 'main_admin',
  LANGUAGES = 'languages',
}

type TextInputProps = {
  error?: string;
  touched?: boolean;
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  organization: state.organization.organization,
  office: state.organization.office,
  selectedOrganizationEditType: state.organization.selectedOrganizationEditType,
});

const dispatchProps = {
  closeOrganizationEditorModal: closeOrganizationEditorModal,
  updateOrganization: updateOrganizationAsync.request,
};

const OrganizationEditorModal: React.FC<Props> = ({
  user,
  organization,
  selectedOrganizationEditType,
  closeOrganizationEditorModal,
  updateOrganization,
  office
}) => {
  const [currentName, setOrganizationName] = useState(organization.name);
  const [currentLocation, setOrganizationLocation] = useState(office.location);
  const [currentOrganization, setOrganization] = useState<Organization>(
    organization
  );
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      cancelButtonPressed();
    }
  }, []);

  const savePressed = () => {
    const cleanPhone = !_.isEmpty(organization.adminPhone)
      ? organization.adminPhone.replace(/\D/g, '')
      : null;
  
    const updateLocation = (offices, prop) => {
      return offices.map((office) => {
        const obj = Object.assign({}, office);
        office.hostId === config.officeId ? obj[prop] = currentLocation : office.location;
        return obj
      })
    }

    const updatedOrganization = {
      id: user.organization.id,
      name: currentName,
      location: currentLocation,
      description: organization.description,
      rrule: organization.rrule,
      inAdvanceBookingLimit: organization.inAdvanceBookingLimit,
      offices: updateLocation(organization.offices, 'location'),
      languages: organization.languages,
    };
    updateOrganization(updatedOrganization);
    closeOrganizationEditorModal();
  };

  const updateLanguage = (languages: Language[]) => {
    const newOrganization = {...organization};
    newOrganization.languages = languages;
    updateOrganization(newOrganization);
    closeOrganizationEditorModal();
  }

  // commented out in case of need in the future

  // const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  // const AdminSchema = Yup.object().shape({
  //   adminName: Yup.string()
  //     .required(localize('REQUIRED'))
  //     .min(3, localize('INVALID_NAME')),
  //   adminEmail: Yup.string()
  //     .email(localize('INVALID_EMAIL'))
  //     .required(localize('REQUIRED')),
  //   adminPhone: Yup.string().matches(phoneRegExp, localize('INVALID_PHONE')),
  // });

  const renderEditorModal = (
    type: OrganizationEditType,
    organization: Organization
  ) => {
    switch (type) {
      case OrganizationEditType.NAME:
        return renderNameEditorModal(organization);
      case OrganizationEditType.LOCATION:
        return renderLocationEditorModal(organization);
      case OrganizationEditType.MAIN_ADMIN:
        return renderMainAdminEditorModal(organization);
      case OrganizationEditType.LANGUAGES:
        return renderLanguagesEditorModal(organization);

      default:
        return renderNameEditorModal(organization);
    }
  };

  const renderNameEditorModal = (organization: Organization) => (
    <>
      <TextContainer>
        <StyledText variant={'caption'} color={'hint'}>
          {localize('ORGANIZATION_NAME_SUBTITLE')}
        </StyledText>
        <SpacingView height={'8px'} />
        <SingleInputTextContainer
          placeholder={localize('CHOOSE_NAME')}
          value={currentName}
          multiline={false}
          maxLength={280}
          onChangeText={(text) => {
            const validationName = validate(
              { alphanumeric: text },
              constraints
            );
            if (!_.isEmpty(text)) {
              setOrganizationName(text);
            }
          }}
        />
      </TextContainer>
      <ButtonContainer>
        <Button
          onPress={savePressed}
          title={localize('APPLY')}
          fontColor={colors.black}
        />
      </ButtonContainer>
    </>
  );

  const renderLocationEditorModal = (organization: Organization) => (
    <>
      <TextContainer>
        <StyledText variant={'caption'} color={'hint'}>
          {localize('ADDRESS_SUBTITLE')}
        </StyledText>
        <SpacingView height={'8px'} />
        <MultipleTextContainer
          value={currentLocation}
          placeholder={localize('ADDRESS')}
          multiline={true}
          numberOfLine={4}
          maxLength={280}
          onChangeText={(text) => {
            if (!_.isEmpty(text)) {
              setOrganizationLocation(text);
            }
          }}
        />
      </TextContainer>
      <ButtonContainer>
        <Button
          onPress={savePressed}
          title={localize('APPLY')}
          fontColor={colors.black}
        />
      </ButtonContainer>
    </>
  );

  // commented out in case of need in the future
  
  // const renderMainAdminEditorModal = (organization: Organization) => (
  //   <>
  //     <Formik
  //       validationSchema={AdminSchema}
  //       initialValues={{
  //         adminName: organization.adminName,
  //         adminPhone: formatPhoneNumber(organization.adminPhone),
  //         adminEmail: organization.adminEmail,
  //       }}
  //       onSubmit={(values) => {
  //         organization.adminName = values.adminName;
  //         organization.adminPhone = values.adminPhone.replace(/\D/g, '')
  //         organization.adminEmail = values.adminEmail;
  //         setOrganization(organization);
  //         savePressed();
  //       }}
  //     >
  //       {({
  //         handleChange,
  //         handleBlur,
  //         handleSubmit,
  //         values,
  //         errors,
  //         touched,
  //       }) => (
  //         <View>
  //           <TextContainer>
  //             <StyledText variant={'caption'} color={'hint'}>
  //               {localize('MAIN_ADMINISTRATOR_SUBTITLE')}
  //             </StyledText>
  //             <SpacingView height={'8px'} />
  //             <SingleInputTextContainer
  //               placeholder={localize('FIRSTNAME_LASTNAME')}
  //               multiline={false}
  //               maxLength={280}
  //               onChangeText={handleChange('adminName')}
  //               onBlur={handleBlur('adminName')}
  //               error={errors.adminName}
  //               touched={touched.adminName}
  //               value={values.adminName}
  //             />
  //             {errors.adminName && touched.adminName ? (
  //               <StyledText variant={'caption'} color={'error'}>
  //                 {errors.adminName}
  //               </StyledText>
  //             ) : null}
  //             <SpacingView height={'24px'} />
  //             <StyledText variant={'caption'} color={'hint'}>
  //               {localize('PHONE_NUMBER')}
  //             </StyledText>
  //             <SpacingView height={'8px'} />

  //             <StyledMaskedInput
  //               placeholder={localize('PHONE_NUMBER_PLACEHOLDER')}
  //               multiline={false}
  //               maxLength={280}
  //               onChangeText={handleChange('adminPhone')}
  //               onBlur={handleBlur('adminPhone')}
  //               keyboardType={'phone-pad'}
  //               error={errors.adminPhone}
  //               touched={touched.adminPhone}
  //               value={values.adminPhone}
  //             />
  //             {errors.adminPhone && touched.adminPhone ? (
  //               <StyledText variant={'caption'} color={'error'}>
  //                 {errors.adminPhone}
  //               </StyledText>
  //             ) : null}
  //             <SpacingView height={'24px'} />
  //             <StyledText variant={'caption'} color={'hint'}>
  //               {localize('EMAIL')}
  //             </StyledText>
  //             <SpacingView height={'8px'} />
  //             <SingleInputTextContainer
  //               placeholder={localize('USER_PLACEHOLDER_EMAIL')}
  //               multiline={false}
  //               maxLength={280}
  //               onChangeText={handleChange('adminEmail')}
  //               onBlur={handleBlur('adminEmail')}
  //               error={errors.adminEmail}
  //               touched={touched.adminEmail}
  //               value={values.adminEmail}
  //               autoCompleteType="email"
  //               keyboardType={'email-address'}
  //             />
  //             {errors.adminEmail && touched.adminEmail ? (
  //               <StyledText variant={'caption'} color={'error'}>
  //                 {errors.adminEmail}
  //               </StyledText>
  //             ) : null}
  //           </TextContainer>

  //           <ButtonContainer>
  //             <Button
  //               onPress={handleSubmit}
  //               title={localize('APPLY')}
  //               fontColor={colors.black}
  //               disabled={
  //                 _.isEmpty(values.adminName) ||
  //                 _.isEmpty(values.adminPhone) ||
  //                 _.isEmpty(values.adminEmail)
  //               }
  //             />
  //           </ButtonContainer>
  //         </View>
  //       )}
  //     </Formik>
  //   </>
  // );

  const renderLanguagesEditorModal = (organization: Organization) => {
    return (
      <LanguageEditorModal languages={organization.languages} onSave={updateLanguage} />
    );
  };

  const cancelButtonPressed = () => {
    closeOrganizationEditorModal();
  };

  return (
    <Container>
      <CloseContainer onPress={cancelButtonPressed}>
        <SvgIcon svg={Close} inverted={true} />
      </CloseContainer>
      {renderEditorModal(selectedOrganizationEditType, organization)}
    </Container>
  );
};

export default connect(mapStateToProps, dispatchProps)(OrganizationEditorModal);

const Container = styled.View`
  width: 100vw;
  max-width: 402px;
  padding: 32px 32px 32px 32px;
  background-color: ${palette.background};
`;

const TextContainer = styled.View`
  margin: 0px 0px 20px 0px;
`;

const SingleInputTextContainer = styled.TextInput<TextInputProps>`
  height: 40px;
  padding: 16px 16px 16px 16px;
  background-color: ${palette.textInput};
  border-radius: 4px;
  border-color: ${(props) => (props.error ? 'red' : 'transparent')};
  margin: 0px 0px 4px 0px;
  color: ${palette.info};
`;

const MultipleTextContainer = styled.TextInput`
  height: 160px;
  padding: 16px 16px 16px 16px;
  background-color: ${palette.textInput};
  border-radius: 4px;
  margin: 0px 0px 4px 0px;
  color: ${palette.info};
`;

const ButtonContainer = styled.TouchableOpacity`
  margin: 34px 0px 0px 0px;
  width: 100%;
  align-self: center;
`;

const CloseContainer = styled.TouchableOpacity`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const field = StyleSheet.create({
  height: '40px',
  marginBottom: '4px',
  backgroundColor: '#444444',
  color: '#000333',
});
