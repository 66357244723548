import { StyledText, Dialog } from "@space/common";
import _ from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import {
  DateObject,
  LocaleConfig,
  Calendar
} from "react-native-calendars";
import { connect } from "react-redux";
import { RootState } from "StoreTypes";
import styled from "styled-components/native";
import { setSelectedDate } from "../../features/booking/actions";
import { showConfirmOrganizationBookingsDelete } from "../../features/organization/actions";
import { colors, palette } from "../../features/preferences/style/themes";
import XDate from 'xdate';
import { byDayFromRrule, exDatesFromRruleSet, rDatesFromRruleSet } from '../../utils/dateutils';
import ScheduleDayView from "./ScheduleDayView";
import config from '../../config';
import { localize } from '../../localization';
import ForwardArrow from '../img/forward_arrow.svg';
import BackArrow from '../img/back_arrow.svg';
import { DateTime } from 'luxon';

const mapStateToProps = (state: RootState) => ({
  organization: state.organization.organization,
  isDeleteBookingConfirmationDisplayed: state.organization.isDeleteBookingConfirmationDisplayed,
});

const dispatchProps = {
  selectDate: setSelectedDate,
  showConfirmOrganizationBookingsDelete: showConfirmOrganizationBookingsDelete,
};

type CalendarProps = {
  onDateSelected: (date: DateObject) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  CalendarProps;

const OrganizationScheduleCalendar: React.FC<Props> = ({
  organization,
  onDateSelected,
  isDeleteBookingConfirmationDisplayed,
  showConfirmOrganizationBookingsDelete,
}) => {
  const calendarRef = React.useRef();
  const date = new Date();
  const maxDate = new Date(date.setMonth(date.getMonth()+11));
  const [currentStartDate, setStartDate] = useState(moment().startOf('day').utc().subtract(1, "M"));
  const [currentEndDate, setEndDate] = useState(moment().startOf('day').utc().add(1, "M"));

  const exDates = useMemo(() => {
    if (!_.isEmpty(organization.rrule)) {
      return exDatesFromRruleSet(organization.rrule);;
    }
    return [];
  }, [organization]);

  const rDates = useMemo(() => {
    if (!_.isEmpty(organization.rrule)) {
      return rDatesFromRruleSet(organization.rrule);;
    }
    return [];
  }, [organization]);

  const openWeekDays = useMemo(() => {
    if (!_.isEmpty(organization.rrule)) {
      return byDayFromRrule(organization.rrule).map(weekday => weekday+1);
    }
    return [];
  }, [organization]);

  const [currentDate, setDate] = useState();

  const didSelectDate = (date: DateObject) => {
    const isOpen = markedDatesWithOptions[date.dateString]?.selected;

    if (isOpen) {
      setDate(date);
      showConfirmOrganizationBookingsDelete(true);
      return;
    }

    const mdate = moment(date.dateString).utc();
    if (!openWeekDays.includes(mdate.weekday())) {
      return;
    }
    onDateSelected(date);
  };

  const confirmCloseDate = () => {
    onDateSelected(currentDate);
  };

  const [currentMarked, settMarked] = useState({});

  const markedDatesWithOptions = useMemo(() => {
    var selectedDates: { [k: string]: any } = {};
    var date = currentStartDate.clone();
  
    while (date < currentEndDate) {
      const keyDate = moment(date).format(config.defaultDateFormat);
      const dateCopy = date.toDate();
      const literalDate = `${dateCopy.getUTCFullYear()}-${dateCopy.getUTCMonth() + 1}-${dateCopy.getUTCDate()}`;
 
      const selected = (openWeekDays.includes(date.isoWeekday()) || rDates.includes(literalDate)) && !exDates.includes(literalDate);
      const disabled = !openWeekDays.includes(date.isoWeekday());

      selectedDates[keyDate] = { selected: selected, disabled: disabled };
      date = date.add(1, 'day');
    }
    settMarked(selectedDates);

    return selectedDates;
  }, [openWeekDays, exDates, rDates, currentStartDate, currentEndDate, organization]);
  
  const updateMonthCalendar = () => {
    const start = moment().startOf('day').utc().subtract(1, "M");
    const end = moment().startOf('day').utc().add(2, "M");

    setStartDate(start);
    setEndDate(end);

    calendarRef.current.updateMonth(new XDate(moment().year(), moment().month(), moment().date()), true);
  }

  const disableArrowLeft = () => {
    let minDate = new Date(calendarRef?.current?.state?.currentMonth.getTime());
    minDate.setMonth(minDate.getMonth()-1);
    return (minDate < new Date(moment().format(config.defaultDateFormat)));
  }

  const disableArrowRight = () => {
    let date = new Date(calendarRef?.current?.state?.currentMonth.getTime());
    date.setMonth(date.getMonth()+1);
    return (date > maxDate);
  }

  const renderCalendarHeader = (date: DateObject) => {
    const month = moment(date[0]).locale(localize('LOCALE_LANGUAGE')).format(config.monthyearFormat);
    return (
      <>
        <>
          <StyledText variant={'caption'} fontSize={13} style={{ paddingLeft: 7, textTransform: "capitalize"}}>{month}</StyledText>
          <LinkContainer disabled={disableArrowLeft()} onPress={() => updateMonthCalendar()}>
            <StyledText 
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: disableArrowLeft() ? '0.4' : '1' }}
              fontWeight={'400'} 
              fontSize={12} 
              fontColor={palette.text['primary']} 
              letterSpacing={'1.2px'} 
              underline={true} >
              {localize('TODAY')} 
            </StyledText>
          </LinkContainer>
        </>
        <>
          <DaysContainer>{daysView}</DaysContainer>
        </>
      </>
    );
  }

  const renderCalendarWithSelectableDate = () => {
    LocaleConfig.defaultLocale = localize('LOCALE_LANGUAGE');

    return (
      <>
        <Dialog
          isOpen={isDeleteBookingConfirmationDisplayed}
          title={localize('DISABLE_DAY')}
          onClose={() => {
            showConfirmOrganizationBookingsDelete(false);
          }}
          actionTitle={localize('DESACTIVATE')}
          cancelTitle={localize('CANCEL')}
          onbuttonAction={() => {
            showConfirmOrganizationBookingsDelete(false);
            confirmCloseDate();
          }} />
        <Calendar
          ref={calendarRef}
          renderArrow={(direction) => {
            if (direction == 'left') {
              return (<BackArrowContainer style={disableArrowLeft() && styles.disabled}><BackArrow/></BackArrowContainer>)
            } else {
                return (<ForwardArrowContainer style={disableArrowRight() && styles.disabled}><ForwardArrow/></ForwardArrowContainer>)
            }
          }}
          monthFormat={"MMMM"}
          style={styles.calendar}
          hideExtraDays={true}
          onPressArrowLeft={(subtractMonth) => {        
            if (disableArrowLeft()) return;
            subtractMonth();
          }}
          onPressArrowRight={addMonth => { 
            if (disableArrowRight()) return;
            addMonth();
          }}
          disableArrowLeft={disableArrowLeft()}
          disableArrowRight={disableArrowRight()}
          renderHeader={(date) => renderCalendarHeader(date)}
          onDayPress={didSelectDate}
          markedDates={currentMarked}
          dayComponent={(props) => (
            <ScheduleDayView
              {...props}
              exDates={exDates}
            />
          )}
          hideDayNames={true}
          theme={{
            calendarBackground: "",
            "stylesheet.day.basic": {
              base: {
                width: 32,
                height: 32,
              },
            },
            monthTextColor: colors.grey3,
            "stylesheet.calendar.main": {
              week: {
                marginTop: 0,
                marginBottom: 0,
                flexDirection: "row",
                justifyContent: "space-around",
              },
            },
            "stylesheet.calendar-list.main": {
              calendar: {
                paddingLeft: 24,
                paddingRight: 24,
                height: 'auto'
              },
            },
            "stylesheet.calendar.header": {
              header: {
                width: "100%",
                alignSelf: "center",
                alignItems: "flex-start",
                flexDirection: "row",
                paddingLeft: 11,
              },
              headerContainer: {
                width: "100%",
                left: 0,
              },
              monthText: {
                fontSize: 13,
                fontFamily: 'Barlow_400Regular',
                color: colors.grey3,
                margin: 10,
              },
              arrow: {
                position: "absolute",
                right: 0,
                padding: 10,
                paddingTop: 5,
                zIndex: 2,
              }
            },
          }}
          onVisibleMonthsChange={(date) => {
            if (date[0]) {
              const start = moment(date[0].dateString).subtract(1, "M");
              const end = moment(date[0].dateString).add(2, "M");

              setStartDate(start);
              setEndDate(end);
            }
          }}
        />
      </>
    );
  };

  const startDateValue = () => {
    if (moment().isoWeekday() === 7) {
      return moment().locale(localize('LOCALE_LANGUAGE'));
    } else {
      return moment().locale(localize('LOCALE_LANGUAGE')).subtract(1, "week").endOf("week");
    }
  };

  var daysView: any[] = _.range(7).map((day) => {
    const date = startDateValue().day(day);
    return (
      <StyledText
        key={day}
        variant={"body4"}
        fontSize={11}
        fontWeight={"600"}
        color={_.includes([6, 7], date.isoWeekday()) ? "hint" : "primary"} //TODO : Fix color
        style={
          textStyles({ disabled: _.includes([6, 7], date.isoWeekday()) })
            .dayText
        }
      >
        {date.format("dd").charAt(0).toUpperCase()}
      </StyledText>
    );
  });

  return (
    <>
      {renderCalendarWithSelectableDate()}
    </>
  );
};

export default connect(mapStateToProps, dispatchProps)(OrganizationScheduleCalendar);

const DaysContainer = styled.View`
  flex-direction: row;
  width: 100%;
  max-width: 375px;
  height: 40px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2px;
`;

const BackArrowContainer = styled.View`
  padding-right: 20px;
`;

const LinkContainer = styled.TouchableOpacity`
  font-family: 'Barlow';
  position: absolute;
  right: 0;
  padding: 0 50px 0 4px;
`;

const ForwardArrowContainer = styled.View``;

const styles = StyleSheet.create({
  calendar: {
    maxWidth: 375,
    height: 305,
    paddingBottom: 4,
  },
  disabled: {
    opacity: 0.4,
  }
});

const textStyles = (props) =>
  StyleSheet.create({
    todayText: {
      textAlign: "center",
      paddingVertical: 2,
      paddingHorizontal: props.selected ? 8 : 0,
      borderRadius: 4,
      height: 16,
      width: props.selected ? "auto" : 0,
      color: colors.white,
    },
    dateText: {
      marginLeft: 8,
      letterSpacing: 3.2,
    },
    dayText: {
      paddingBottom: 1,
      paddingTop: 2,
      textAlign: "center",
      width: 32,
      height: 18,
    },
  });
