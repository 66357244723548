import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';
import {Booking, BookingRequestBody, SpaceUserMapping} from 'BookingFeature';
import { BookingEditorType} from './components/BookingEditorView';
import moment from 'moment';
import config from '../../config';
import {
  loadTodayBookingAsync,
  loadMyBookingAsync,
  loadBookingAsync,
  deleteBookingAsync,
  createBookingAsync,
  updateBookingAsync,
  clearError,
  setSelectedDate,
  selectBooking,
  showUserSelectionView,
  setSelectedSpaceType,
  setSelectedSpacesForBooking,
  setSelectedUserMappingForBooking,
  showConfirmBookingDelete,
  showBookingEditor,
  showConfirmBooking,
  setEditBookingType
} from './actions';
import {refreshTokenAsync} from '../login/actions';
import {Space, SpaceType} from 'SpaceFeature';

const reducer = combineReducers({
  isLoadingTodayBooking: createReducer<boolean>(false)
    .handleAction([loadTodayBookingAsync.request], (state, action) => true)
    .handleAction(
      [loadTodayBookingAsync.success, loadTodayBookingAsync.failure],
      (state, action) => false,
    ),

  isLoadingMyBooking: createReducer<boolean>(false)
    .handleAction([loadMyBookingAsync.request], (state, action) => true)
    .handleAction(
      [loadMyBookingAsync.success, loadMyBookingAsync.failure],
      (state, action) => false,
    ),

  isLoadingBooking: createReducer<boolean>(false)
    .handleAction([loadBookingAsync.request], (state, action) => true)
    .handleAction(
      [loadBookingAsync.success, loadBookingAsync.failure],
      (state, action) => false,
    ),

  isConfirmBookingDeleteViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmBookingDelete],
    (state, action) => action.payload,
  ),

  error: createReducer<string | null>(null)
    .handleAction(
      [
        loadTodayBookingAsync.failure,
        loadMyBookingAsync.failure,
        loadBookingAsync.failure,
        refreshTokenAsync.failure,
      ],
      (state, action) => action.payload,
    )
    .handleAction(clearError, (state, action) => null),

  todayBooking: createReducer([] as Booking[]).handleAction(
    loadTodayBookingAsync.success,
    (state, action) => {
      return action.payload;
    },
  ),

  bookings: createReducer([] as Booking[])
    .handleAction(createBookingAsync.success, (state, action) => {
      return [...state, action.payload];
    })
    .handleAction(updateBookingAsync.success, (state, action) => {
      return [...state.filter((item) => item.id !== action.payload.id), action.payload];
    })
    .handleAction(loadBookingAsync.success, (state, action) => {
      return action.payload;
    })
    .handleAction(deleteBookingAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  bookingDeleted: createReducer<Booking | null>(null).handleAction(
    deleteBookingAsync.success,
    (state, action) => action.payload,
  ),

  selectedDate: createReducer<string>(
    moment().format(config.defaultDateFormat),
  ).handleAction(setSelectedDate, (state, action) => action.payload),

  selectedBooking: createReducer<Booking | null>(null)
    .handleAction(selectBooking,(state, action) => action.payload,
    ).handleAction(createBookingAsync.success, (state, action) => {
      return null;
    }).handleAction(updateBookingAsync.success, (state, action) => {
      return null;
    }),

  selectedSpaceType: createReducer<SpaceType | null>(null).handleAction(
    setSelectedSpaceType,
    (state, action) => action.payload,
  ),

  selectedSpacesForBooking: createReducer<Space[]>([]).handleAction(
    setSelectedSpacesForBooking,
    (state, action) => action.payload,
  ),

  selectedUserMappingForBooking: createReducer<SpaceUserMapping[]>(
    [],
  ).handleAction(
    setSelectedUserMappingForBooking,
    (state, action) => action.payload,
  ),

  lastBookingRequest: createReducer<BookingRequestBody | null>(null,)
    .handleAction(createBookingAsync.request, (state, action) => action.payload)
    .handleAction(updateBookingAsync.request, (state, action) => action.payload),

  bookingError: createReducer<string | null>(null)
    .handleAction(createBookingAsync.failure, (state, action) => action.payload)
    .handleAction(updateBookingAsync.failure, (state, action) => action.payload)
    .handleAction(clearError, (state, action) => null),

  userLastBooking: createReducer<Booking | null>(null)
    .handleAction(createBookingAsync.success, (state, action) => action.payload)
    .handleAction(createBookingAsync.failure, (state, action) => null),

  currentUserBookings: createReducer([] as Booking[])
    .handleAction(loadMyBookingAsync.success, (state, action) => {
      return action.payload
        .filter((item) =>
          moment().isBefore(moment(item.schedule.endDate).endOf('day')),
        )
        .sort((itemA, itemB) =>
          itemA.schedule.startDate > itemB.schedule.startDate ? 1 : -1,
        );
    })
    .handleAction(deleteBookingAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  isUserSelectionViewDisplayed: createReducer<boolean>(false).handleAction(
    [showUserSelectionView],
    (state, action) => action.payload,
  ),

  isBookingEditorViewDisplayed: createReducer<boolean>(false).handleAction(
    [showBookingEditor],
    (state, action) => action.payload,
  ),

  isConfirmBookingViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmBooking],
    (state, action) => action.payload,
  ),

  selectedEditBookingType: createReducer<BookingEditorType | null>(null).handleAction(
    setEditBookingType,
    (state, action) => action.payload,
  ),
});

export default reducer;
