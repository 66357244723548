import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreTypes';
import { TouchableOpacity } from 'react-native';
import { DateObject } from 'react-native-calendars';
import styled, { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import { scheduleFromRruleSet, WeekDay } from '../utils/dateutils';
import { localize } from '../localization';
import Edit from '../img/edit.svg';
import config from '../config';
import { palette } from '@features/preferences/style/themes';
import _head from 'lodash/head';

import {
  Divider,
  StyledText,
  ModalView,
  Dialog,
  ThemeUtil,
} from '@space/common';
import VerticalDivider from '@components/VerticalDivider';
import SpacingView from '@components/SpacingView';
import SvgIcon from '@components/SvgIcon';
import OrganizationScheduleCalendar from '@components/Calendar/OrganizationScheduleCalendar';
import WeekDayView from '@components/Calendar/WeekDayView';
import StyledTextInput from '@components/form/StyledTextInput';
import StyledMaskedInput from '@components/form/StyledMaskedInput';
import Menu from '@features/menu/components/Menu';
import OrganizationEditorModal, {
  OrganizationEditType,
} from '@features/organization/components/OrganizationEditorModal';
import LanguageMap from '@features/organization/components/Language/LanguageMap';

import { fetchAllSpacesAsync } from '@features/space/actions';
import { loadUserRolesAsync, loadUsersAsync } from '@features/user/actions';
import { loadUserAsync } from '@features/login/actions';
import {
  clearError,
  fetchOrganizationAsync,
  openOrganizationEditorModal,
  setOrganizationEditType,
  updateOrganizationAsync,
} from '@features/organization/actions';
import { Duration, UpdateOrganizationQuery } from 'OrganizationFeature';
import { TimeUnit } from '@features/organization';
import { UserRoleType } from '@features/user';
import { UsersViewName } from './UsersView';
import { StackNavigationProp } from '@react-navigation/stack';
import { UserRole, User } from 'UserFeature'
import { RRule, RRuleSet, rrulestr } from 'rrule';

const mapStateToProps = (state: RootState) => ({
  organization: state.organization.organization,
  users: state.user.users,
  userRoles: state.user.userRoles,
  allSpaces: state.space.allSpaces,
  organizationEditorModalIsDisplayed:
  state.organization.organizationEditorModalIsDisplayed,
  office: state.organization.office
});

const dispatchProps = {
  fetchOrganization: fetchOrganizationAsync.request,
  fetchAllSpaces: fetchAllSpacesAsync.request,
  fetchUsers: loadUsersAsync.request,
  fetchUser: loadUserAsync.request,
  fetchUserRoles: loadUserRolesAsync.request,
  setOrganizationEditType: setOrganizationEditType,
  openOrganizationEditorModal: openOrganizationEditorModal,
  clearError: clearError,
  updateOrganization: updateOrganizationAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

type ScheduleParams = {
  inAdvanceDays: number;
  exDates: string[];
  rDates: string[];
  times: string[];
  byDays: number[];
};

type ScheduleParamsError = {
  inAdvanceDaysError?: string;
  timesError?: string;
};

const Settings: React.FC<Props> = ({
  fetchOrganization,
  organization,
  fetchAllSpaces,
  allSpaces,
  fetchUsers,
  fetchUser,
  users,
  fetchUserRoles,
  userRoles,
  setOrganizationEditType,
  openOrganizationEditorModal,
  organizationEditorModalIsDisplayed,
  clearError,
  modalError,
  updateOrganization,
  office
}) => {
  const styleName = useTheme().mode;
  const navigation = useNavigation<StackNavigationProp<any>>();
  useEffect(() => {
    fetchOrganization();
    fetchAllSpaces();
    fetchUsers();
    fetchUser();
    fetchUserRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateOrganizationPressed = (type: OrganizationEditType) => {
    setOrganizationEditType(type);
    openOrganizationEditorModal(type);
  };

  const weekDays = [
    WeekDay.MO,
    WeekDay.TU,
    WeekDay.WE,
    WeekDay.TH,
    WeekDay.FR,
    WeekDay.SA,
    WeekDay.SU,
  ];
  const adminRoles = userRoles.filter(
    (role: UserRole) => role.type === UserRoleType.ADMIN
  );
  const adminIds = adminRoles.map((role: UserRole) => role.id);
  const admins = users.filter((user: User) => {
    const intersection = user.roleIdsList?.filter((roleId: string) =>
      adminIds.includes(roleId)
    );
    return !_.isEmpty(user.roleIdsList) ? intersection.length > 0 : false;
  });
  const adminsExtract = admins.slice(0, 3);

  const updateOtherAdminPressed = () => {
    navigation.push(UsersViewName, {
      roles: adminRoles.map((role: UserRole) => role.id),
    });
  };

  // Edition
  const defaultOrganization = {
    inAdvanceDays: 3,
    exDates: [],
    times: ['07:00', '19:00'],
    byDays: [],
  };
  const defaultErrors = { inAdvanceDaysError: '', timesError: '' };
  const [currentSchedule, setSchedule] = useState<ScheduleParams>(
    defaultOrganization
  );
  const [currentErrors, setErrors] = useState<ScheduleParamsError>(
    defaultErrors
  );
  const [typingTimeout, setTypingTimeout] = useState(0);

  const initialOrganization = useMemo(() => {
    if (!_.isEmpty(organization.rrule)) {
      const schedule = scheduleFromRruleSet(organization.rrule);
      const values = {
        inAdvanceDays: organization.inAdvanceBookingLimit?.amount ?? 3,
        exDates: schedule.exDates,
        times: schedule.times,
        byDays: schedule.byDays,
      };
      setSchedule(values);
      return values;
    }
    return defaultOrganization;
  }, [organization]);

  const onSelectedDays = (days: number[]) => {
    const updatedSchedule = {
      inAdvanceDays: currentSchedule.inAdvanceDays,
      exDates: currentSchedule.exDates,
      times: currentSchedule.times,
      byDays: days,
    };
    setSchedule(updatedSchedule);
    saveOrganization(updatedSchedule);
  };

  const onInAdvanceChanged = (value: string) => {
    const updatedSchedule = {
      inAdvanceDays: value,
      exDates: currentSchedule.exDates,
      times: currentSchedule.times,
      byDays: currentSchedule.byDays,
    };
    setSchedule(updatedSchedule);

    if (_.isEmpty(value) || isNaN(Number(value))) {
      setErrors({
        inAdvanceDaysError: localize('IN_ADVANCE_BOOKING_INVALID'),
        timesError: currentErrors.timesError,
      });
      return;
    }
    setErrors({ inAdvanceDaysError: '', timesError: currentErrors.timesError });

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(saveOrganization(updatedSchedule), 5000));
  };

  const validHourFormat = (value: string) => {
    const split = value.split(':');
    if (split.length > 1) {
      const hour = Number(split[0]);
      const min = Number(split[1]);
      if (!isNaN(hour) && !isNaN(min)) {
        return true;
      }
    }
    return false;
  };

  const onOpenHourChanged = (e: React.ChangeEvent<any>) => {
    const text = e.target.value;
    onHourChanged([text, currentSchedule.times[1]]);
  };

  const onClosedHourChanged = (e: React.ChangeEvent<any>) => {
    const text = e.target.value;
    onHourChanged([currentSchedule.times[0], text]);
  };

  const validateHoursRange = (openHour: string, closeHour: string) => {
    if (openHour.includes('_') || closeHour.includes('_')) {
      return false;
    }

    if (openHour.length < 5 || closeHour.length < 5) {
      return false;
    }

    if (!validHourFormat(openHour) || !validHourFormat(closeHour)) {
      return false;
    }

    const openHours = openHour.split(':');
    const closeHours = closeHour.split(':');

    if (openHours.length < 2 || closeHours.length < 2) {
      return false;
    }

    const openTime = moment().set({
      hour: Number(openHours[0]),
      minute: Number(openHours[1]),
    });
    const closeTime = moment().set({
      hour: Number(closeHours[0]),
      minute: Number(closeHours[1]),
    });

    return openTime < closeTime;
  };

  const onHourChanged = (times: string[]) => {
    const updatedSchedule = {
      inAdvanceDays: currentSchedule.inAdvanceDays,
      exDates: currentSchedule.exDates,
      times: times,
      byDays: currentSchedule.byDays,
    };
    setSchedule(updatedSchedule);
    if (validateHoursRange(times[0], times[1])) {
      setErrors({
        inAdvanceDaysError: currentErrors.inAdvanceDaysError,
        timesError: '',
      });
      saveOrganization(updatedSchedule);
    } else {
      setErrors({
        inAdvanceDaysError: currentErrors.inAdvanceDaysError,
        timesError: localize('HOUR_INVALID'),
      });
    }
  };

  const onDateSelected = (date: DateObject) => {
    const time = `${date.year}-${date.month}-${date.day}`;
    onChangeExDates(time);
  };

  const onExceptionDateSelected = (date: Moment.moment) => {
  const time = `${date.year()}-${date.month() + 1}-${date.date()}`;
    onChangeExDates(time);
  };

  const onChangeExDates = (date: string) => {
    var exDates: string[];
    const index = currentSchedule.exDates.indexOf(date);
    if (index === -1) {
      exDates = currentSchedule.exDates.concat([date]);
    } else {
      exDates = currentSchedule.exDates.filter(
        (existingDate) => existingDate != date
      );
    }

    const updatedSchedule = {
      inAdvanceDays: currentSchedule.inAdvanceDays,
      exDates: exDates,
      times: currentSchedule.times,
      byDays: currentSchedule.byDays,
    };
    setSchedule(updatedSchedule);
    saveOrganization(updatedSchedule);
  };
  
  const saveOrganization = (schedule: ScheduleParams) => {
    const openHours = schedule.times[0].split(':');
    const closeHours = schedule.times[1].split(':');

    const currentRRuleSet: RRuleSet = rrulestr(office.rrule, {forceset: true});
    const currentRRule: RRule = _head(currentRRuleSet.rrules())
    const rruleSet = new RRuleSet();

    rruleSet.rrule(new RRule({
      freq: currentRRule.options.freq,
      byweekday: schedule.byDays,
      byhour: [parseInt(openHours[0], 10), parseInt(closeHours[0], 10)],
      byminute: [parseInt(openHours[1], 10), parseInt(closeHours[1], 10)],
      bysecond: 0,
      dtstart: currentRRule.options.dtstart,
      tzid: currentRRule.options.tzid
    }));

    schedule.exDates.forEach((dateString: string) => {
      const date = moment(dateString);
      rruleSet.exdate(new Date(Date.UTC(date.year(), date.month(), date.date(), 0, 0, 0)));
    });

    const duration: Duration = { unitOfTime: TimeUnit.DAY, amount: schedule.inAdvanceDays };
    const updatedOrganization: UpdateOrganizationQuery = {
      id: organization.id,
      name: organization.name,
      description: organization.description,
      offices: organization.offices,
      rrule: rruleSet.toString(), //'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR;BYHOUR=9,17;BYMINUTE=0;BYSECOND=0\nEXDATE:20120601T023000Z',
      inAdvanceBookingLimit: duration,
      languages: organization.languages,
    };
    updateOrganization(updatedOrganization);
  };

  const displayActiveLanguagesList = () => {
    let languageNames: string = '';

    if (organization.languages) {
      for (let language of organization.languages) {
        if (language.active) {
          let languageName = LanguageMap.get(language.name);

          if (languageName !== undefined) {
            languageName = localize(languageName);
          }
          languageNames = `${languageNames}${languageName}\n`;
        }
      }
      languageNames = languageNames.slice(0, -1);
    }

    return (
      <>
        <RowContent>
          <StyledText variant={'h3'} color={'secondary'}>
            {localize('AVAILABLE_LANGUAGES')}
          </StyledText>
          <StyledTouchableOpacity
            onPress={() =>
              updateOrganizationPressed(OrganizationEditType.LANGUAGES)
            }
          >
            <SvgIcon
              svg={Edit}
              width={24}
              height={24}
              fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)}
            />
          </StyledTouchableOpacity>
        </RowContent>
        <StyledText variant={'body1'}>{languageNames}</StyledText>
      </>
    );
  };

  return (
    <Container>
      <Menu />
      <ContentContainer>
        <TopContainer>
          <StyledText variant={'h1'}>{localize('SettingsView')}</StyledText>
          <SpacingView height={'35px'} />
          <RowContent>
            <ColumnContent>
              <RowContent>
                <StyledText variant={'h3'} color={'secondary'}>
                  {localize('ORGANIZATION_NAME_TITLE')}
                </StyledText>
                <StyledTouchableOpacity
                  onPress={() =>
                    updateOrganizationPressed(OrganizationEditType.NAME)
                  }
                >
                  <SvgIcon
                    svg={Edit}
                    width={24}
                    height={24}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}
                  />
                </StyledTouchableOpacity>
              </RowContent>
              <StyledText variant={'body1'}>{organization.name}</StyledText>
              <SpacingView height={'28px'} />
              <RowContent>
                <StyledText variant={'h3'} color={'secondary'}>
                  {localize('ADDRESS_TITLE')}
                </StyledText>
                <StyledTouchableOpacity
                  onPress={() =>
                    updateOrganizationPressed(OrganizationEditType.LOCATION)
                  }
                >
                  <SvgIcon
                    svg={Edit}
                    width={24}
                    height={24}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}          
                  />
                </StyledTouchableOpacity>
              </RowContent>
              <StyledText variant={'body1'}>
                {office.location}
              </StyledText>
              <SpacingView height={'28px'} />
            </ColumnContent>
            <ColumnContent>
            {displayActiveLanguagesList()}
              {/* <RowContent>
                <StyledText variant={'h3'} color={'secondary'}>
                  {localize('MAIN_ADMINISTRATOR_TITLE')}
                </StyledText>
                <StyledTouchableOpacity
                  onPress={() =>
                    updateOrganizationPressed(OrganizationEditType.MAIN_ADMIN)
                  }
                >
                  <SvgIcon
                    svg={Edit}
                    width={24}
                    height={24}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}
                  />
                </StyledTouchableOpacity>
              </RowContent>
              <StyledText variant={'body1'}>
                {organization.adminName}
              </StyledText>
              <StyledText variant={'body1'}>
                {formatPhoneNumber(organization.adminPhone)}
              </StyledText>
              <StyledText variant={'body1'}>
                {organization.adminEmail}
              </StyledText>
              <SpacingView height={'28px'} />
              <RowContent>
                <StyledText variant={'h3'} color={'secondary'}>
                  {localize('OTHER_ADMINISTRATORS')}
                </StyledText>
                <StyledTouchableOpacity
                  onPress={() => updateOtherAdminPressed()}
                >
                  <SvgIcon
                    svg={Edit}
                    width={24}
                    height={24}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}
                  />
                </StyledTouchableOpacity>
              </RowContent>
              <StyledText variant={'body1'}>
                {adminsExtract
                  .map((user) => user.firstName + ' ' + user.lastName)
                  .join('\n')}
                {admins.length > adminsExtract.length ? '\n...' : null}
              </StyledText> */}
            </ColumnContent>
            <ColumnContent>
              <StyledText variant={'h3'} color={'secondary'}>
                {localize('SUBSCRIBED_USERS')}
              </StyledText>
              <StyledText variant={'body1'}>{users.length}</StyledText>
              <SpacingView height={'28px'} />
              <StyledText variant={'h3'} color={'secondary'}>
                {localize('EXISTING_SPACE')}
              </StyledText>
              <StyledText variant={'body1'}>{allSpaces.length}</StyledText>
            </ColumnContent>
          </RowContent>
          <ModalView isVisible={organizationEditorModalIsDisplayed}>
            <OrganizationEditorModal />
          </ModalView>

          {modalError && (
            <Dialog
              isOpen={modalError !== null}
              title={localize('ERROR_DEFAULT')}
              onClose={() => clearError()}
              onbuttonAction={() => {
                clearError();
              }}
            />
          )}
        </TopContainer>
        <Divider />
        <PeriodContainer>
          <StyledText variant={'h2'}>{localize('AVAILABLE_PERIOD')}</StyledText>
          <SpacingView height={'33px'} />
          <RowContent>
            <ColumnContent>
              <StyledText variant={'h3'} color={'secondary'}>
                {localize('IN_ADVANCE_BOOKING')}
              </StyledText>
              <StyledTextInputContainer>
                <StyledTextInput
                  title={''}
                  placeholder={'###'}
                  multiline={false}
                  width={100}
                  maxLength={100}
                  onChangeText={onInAdvanceChanged}
                  keyboardType={'numeric'}
                  error={currentErrors.inAdvanceDaysError}
                  touched={true}
                  value={currentSchedule.inAdvanceDays}
                />
              </StyledTextInputContainer>
            </ColumnContent>
            <ColumnContent>
              <StyledText variant={'h3'} color={'secondary'}>
                {localize('OPEN_DAYS')}
              </StyledText>
              <WeekDayViewContainer>
                <WeekDayView
                  selectedDays={currentSchedule.byDays}
                  onSelectedDays={onSelectedDays}
                />
              </WeekDayViewContainer>
            </ColumnContent>
            <ColumnContent>
              <StyledText variant={'h3'} color={'secondary'}>
                {localize('OPEN_HOURS')}
              </StyledText>
              <HourRowContent>
                <HourContent>
                  <StyledMaskedInput
                    variant={'hour'}
                    placeholder={localize('HOUR_PLACEHOLDER')}
                    maxLength={280}
                    onChangeText={onOpenHourChanged}
                    error={''}
                    value={
                      currentSchedule.times ? currentSchedule.times[0] : ''
                    }
                  />
                </HourContent>
                <HourContent>
                  <StyledMaskedInput
                    variant={'hour'}
                    placeholder={localize('HOUR_PLACEHOLDER')}
                    multiline={false}
                    maxLength={280}
                    onChangeText={onClosedHourChanged}
                    error={''}
                    value={
                      currentSchedule.times ? currentSchedule.times[1] : ''
                    }
                  />
                </HourContent>
              </HourRowContent>
              {!_.isEmpty(currentErrors.timesError) ? (
                <StyledText variant={'caption'} color={'error'}>
                  {'* ' + currentErrors.timesError}
                </StyledText>
              ) : null}
            </ColumnContent>
          </RowContent>

          <DayOffContainer>
            <DayOffColumnContent>
              <StyledText variant={'h3'} color={'secondary'}>
                {localize('OPEN_DAYS_CALENDAR')}
              </StyledText>
              <SpacingView height={'24px'} />
              <OrganizationScheduleCalendarContainer>
                <OrganizationScheduleCalendar onDateSelected={onDateSelected} />
              </OrganizationScheduleCalendarContainer>
            </DayOffColumnContent>
            <VerticalDivider />
            <SpacingView height={'10px'} width={'24px'} />
            <DayOffColumnContent>
              <StyledText variant={'h3'} color={'secondary'}>
                {localize('OPEN_DAYS_LIST')}
              </StyledText>
              <StyledFlatList
                data={currentSchedule.exDates}
                renderItem={({ item }) => {
                  const date = moment(item);
                  return (
                    <TouchableOpacity
                      key={item.toString()}
                      onPress={() => {
                        onExceptionDateSelected(date);
                      }}
                    >
                      <StyledText variant={'body4'} style={{textTransform: 'capitalize'}}>
                        {moment(date).locale(localize('LOCALE_LANGUAGE')).format(localize('EXCEPTION_DATE_DAY_FORMAT'))}
                      </StyledText>
                    </TouchableOpacity>
                  );
                }}
              />
            </DayOffColumnContent>
          </DayOffContainer>
        </PeriodContainer>
      </ContentContainer>
    </Container>
  );
};
export const SettingsViewName = 'SettingsView';
export default connect(mapStateToProps, dispatchProps)(Settings);

const Container = styled.View`
  flex-direction: row;
  background-color: ${palette.background};
  width: 100vw;
  height: 100%;
`;

const ContentContainer = styled.ScrollView`
  height: 100vh;
  background-color: ${palette.background};
`;

const TopContainer = styled.View`
  align-items: flex-start;
  width: 100vw;
  margin: 40px 48px 34px 48px;
`;

const PeriodContainer = styled.View`
  align-items: flex-start;
  width: 100vw;
  margin: 40px 48px 34px 48px;
`;

const RowContent = styled.SafeAreaView`
  flex-direction: row;
  width: 100%;
`;

const HourRowContent = styled.SafeAreaView`
  flex-direction: row;
  width: 100%;
  margin-left: -8px;
`;

const ColumnContent = styled.SafeAreaView`
  flex-direction: column;
  height: 100%;
  margin-right: 80px;
`;

const StyledTouchableOpacity = styled.TouchableOpacity`
  margin-left: 20px;
`;

const DayOffContainer = styled.View`
  width: 60%;
  margin-top: 45px;
  flex-direction: row;
  border: 1px solid ${palette.border};
  border-radius: 4px;
  padding: 24px 0px;
`;

const DayOffColumnContent = styled.View`
  width: 50%;
  flex-direction: column;
  height: 100%;
  padding: 0px 24px;
`;

const HourContent = styled.View`
  width: 100px;
`;

const StyledFlatList = styled.FlatList`
  margin-top: 18px;
`;

const StyledTextInputContainer = styled.View`
  margin-left: -8px;
`;

const WeekDayViewContainer = styled.View`
  margin-left: -8px;
`;

const OrganizationScheduleCalendarContainer = styled.View`
  margin-left: -24px;
`;
