import React from "react";
import styled, { useTheme } from 'styled-components/native';
import { StyledText, ThemeUtil } from '@space/common';
import { palette } from '@features/preferences/style/themes';
import SpacingView from '../SpacingView';
import MaskedInput from "react-text-mask";
import { NativeSyntheticEvent, TargetedEvent } from 'react-native';

type MaskedInputProps = {
    title?: string;
    placeholder?: string;
    onChangeText?: (e: React.ChangeEvent<any>) => void;
    onBlur?: (e: NativeSyntheticEvent<TargetedEvent>) => void
    error?: string;
    touched?: boolean
    value?: string;
    maxLength?: number;
    variant?: 'phone' | 'hour'
};

type InputProps = {
    error?: string;
};

const StyledMaskedInput: React.FC<MaskedInputProps> = ({
    title,
    placeholder,
    onChangeText,
    onBlur,
    error,
    touched,
    value,
    maxLength,
    variant
}) => {
    variant = variant ? variant : 'phone';
    const styleName = useTheme().mode;
    const phoneNumberMask = [
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ];
    const hourNumberMask = [
        /[0-9]/,
        /[0-9]/,
        ":",
        /[0-5]/,
        /[0-9]/,
    ]; // /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/


    const timeMask = (value) => {
        const chars = value.split('');
        const hours = [
            /[0-2]/,
            chars[0] == '2' ? /[0-3]/ : /[0-9]/
        ];

        const minutes = [/[0-5]/, /[0-9]/];

        return hours.concat(':').concat(minutes);
    }

    return (
        <RootContainer>
            {title ?
                <>
                    <StyledText variant={'caption'} color={'title'}>{title}</StyledText>
                    <SpacingView height={'8px'} />
                </>
                : null
            }
            <MaskedInput
                style={{
                    color: ThemeUtil.getThemeValue(palette.info)(styleName),
                    backgroundColor: ThemeUtil.getThemeValue(palette.textInput)(styleName),
                    borderRadius: '4px',
                    border: '1px solid ' + (error && touched) ? 'red' : 'transparent',
                    height: '32px',
                    padding: '4px 16px',
                    margin: '0px 0px 4px 0px',
                }}
                placeholder={placeholder}
                mask={variant === 'phone' ? phoneNumberMask : timeMask}
                type="text"
                onChange={onChangeText}
                onBlur={onBlur}
                value={value}
                error={error}
                touched={touched}
            />

            <ErrorContainer>
                {error && touched ? <StyledText variant={'caption'} color={'error'}>{'* ' + error}</StyledText> : <StyledText />}
            </ErrorContainer>
        </RootContainer>
    );
}

export default StyledMaskedInput;

const RootContainer = styled.View`
  width:100%;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  margin: 0px 0px 4px 0px;
`;

const ErrorContainer = styled.View`
  width:100%;
  flex: 1;
`;