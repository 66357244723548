import React, { useMemo, useState, useEffect } from "react";
import { useNavigation } from '@react-navigation/native';
import { Dimensions, StyleSheet } from "react-native";
import {
  Calendar,
  DateObject,
  LocaleConfig
} from "react-native-calendars";
import moment from "moment";
import XDate from 'xdate';

import { updateOrganizationAsync } from "../../features/organization/actions";
import { setSelectedDate } from "../../features/booking/actions";

import { connect } from "react-redux";
import { RootState } from "StoreTypes";
import config from "../../config";
import BigDayView from "./BigDayView";
import SpaceHelper from "../../utils/SpaceHelper";
import styled, { useTheme } from "styled-components/native";
import { colors, palette } from "../../features/preferences/style/themes";
import { StyledText, Dialog, ThemeUtil } from "@space/common";
import _ from "lodash";
import { byDayFromRrule, exDatesFromRruleSet, rDatesFromRruleSet } from '../../utils/dateutils';
import { loadBookingAsync } from '../../features/booking/actions';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { BookingsViewName } from '../../routes/BookingsView';
import { localize } from '../../localization';
import { StackNavigationProp } from '@react-navigation/stack';
import ForwardArrow from '../img/forward_arrow.svg';
import BackArrow from '../img/back_arrow.svg';

const mapStateToProps = (state: RootState) => ({
  isLoading: state.space.isLoading,
  error: state.space.error,
  spacesList: state.space.space,
  availableSpacesCache: state.space.availableSpacesCache,
  selectedDate: state.booking.selectedDate,
  organization: state.organization.organization,
  bookings: state.booking.bookings,
  office: state.organization.office,
  officeOpenCache: state.organization.officeOpenCache
});

const dispatchProps = {
  selectDate: setSelectedDate,
  updateOrganization: updateOrganizationAsync.request,
  fetchBookings: loadBookingAsync.request
};

type CalendarProps = {
  displayWeekView: boolean;
  onDateSelect: (date: string) => void;
};

type ViewProps = {
  width: number
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  CalendarProps;

const BigCalendar: React.FC<Props> = ({
  spacesList,
  availableSpacesCache,
  selectedDate,
  updateOrganization,
  organization,
  fetchBookings,
  bookings,
  office,
  officeOpenCache
}) => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const width = Dimensions.get("window").width * 70 / 100;
  const height = Dimensions.get("window").height * 75 / 100;
  const [currentDate, setCurrentDate] = useState(selectedDate);
  const styleName = useTheme().mode;
  const calendarRef = React.useRef();
  const date = new Date(currentDate);
  const maxDate = new Date(date.setMonth(date.getMonth()+11));

  const [isDeleteBookingConfirmationDisplayed, showConfirmOrganizationBookingsDelete] = useState(false);
  const [currentStartDate, setStartDate] = useState(moment().startOf('day').utc().subtract(1, "M"));
  const [currentEndDate, setEndDate] = useState(moment().startOf('day').utc().add(1, "M"));

  useEffect(() => {
    const startDate = moment(currentStartDate).format(config.defaultDateFormat);
    const endDate = moment(currentEndDate).format(config.defaultDateFormat);

    fetchBookings({ startDate, endDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const [currentRrule, setRrule] = useState(organization.rrule);

  const exDates = useMemo(() => {
    if (!_.isEmpty(organization.rrule)) {
      return exDatesFromRruleSet(organization.rrule);
    }
    return [];
  }, [organization, currentRrule]);

  const rDates = useMemo(() => {
    if (!_.isEmpty(organization.rrule)) {
      return rDatesFromRruleSet(organization.rrule);
    }
    return [];
  }, [organization, currentRrule]);

  const openWeekDays = useMemo(() => {
    if (!_.isEmpty(organization.rrule)) {
      return byDayFromRrule(organization.rrule).map(weekday => weekday+1);
    }
    return [];
  }, [organization, currentRrule]);

  const [currentMarked, setMarked] = useState({});

  const markedDatesWithOptions = useMemo(() => {
    var selectedDates: { [k: string]: any } = {};
    var date = currentStartDate.clone();

    while (date < currentEndDate) {
      const keyDate = moment(date).format(config.defaultDateFormat);
      const dateCopy = date.toDate();
      const literalDate = `${dateCopy.getUTCFullYear()}-${dateCopy.getUTCMonth() + 1}-${dateCopy.getUTCDate()}`;
 
      const selected = (openWeekDays.includes(date.isoWeekday()) || rDates.includes(literalDate)) && !exDates.includes(literalDate);
      const disabled = !openWeekDays.includes(date.isoWeekday());
      const bookingNumber = SpaceHelper.bookingNumberForDate(bookings, date);
      selectedDates[keyDate] = { selected: selected, disabled: disabled, bookings: bookingNumber };
      date = date.add(1, 'day');
    }
    setMarked(selectedDates);

    return selectedDates;
  }, [openWeekDays, exDates, rDates, currentStartDate, currentEndDate, currentRrule, organization, bookings]);

  const onToggleSwitch = (date: DateObject) => {
    const isOpen = markedDatesWithOptions[date.dateString]?.selected;
    const rruleDate = moment(date.dateString).utc().format(config.exceptionDateFormat) + 'Z';

    let exDates = exDatesFromRruleSet(organization.rrule);
    let rDates = rDatesFromRruleSet(organization.rrule);

    if (isOpen) {
      showConfirmOrganizationBookingsDelete(true);
      // on special open days ? remove
      if (rDates.includes(rruleDate)) {
        rDates = rDates.filter((date) => date !== rruleDate);
      } else {
        exDates.push(rruleDate);
      }
    } else {
      const excludedDate = moment(date.dateString).utc();
      const literalDate = `${excludedDate.year()}-${excludedDate.month() + 1}-${excludedDate.date()}`;

      if (exDates.includes(literalDate)) {
        exDates = exDates.filter((date) => date !== literalDate);
      } else {
        rDates.push(rruleDate);
      }
    }

    const currentRruleSet = rrulestr(organization.rrule, {forceset: true}) as RRuleSet;
    const currentRrule: RRule = _.head(currentRruleSet.rrules());
    const rruleSet = new RRuleSet();

    rruleSet.rrule(new RRule({
      freq: currentRrule.options.freq,
      byweekday: currentRrule.options.byweekday,
      byhour: currentRrule.options.byhour,
      byminute: currentRrule.options.byminute,
      bysecond: 0,
      dtstart: currentRrule.options.dtstart,
      tzid: currentRrule.options.tzid
    }));

    exDates.forEach((dateString: string) => {
      const date = moment(dateString);
      rruleSet.exdate(new Date(Date.UTC(date.year(), date.month(), date.date(), 0, 0, 0)));
    });

    rDates.forEach((dateString: string) => {
      const date = moment(dateString);
      rruleSet.rdate(new Date(Date.UTC(date.year(), date.month(), date.date(), 0, 0, 0)));
    });

    const rruleString = rruleSet.toString();
    setRrule(rruleString);
    if (!isOpen) {
      const updatedOrganization = {
        id: organization.id,
        rrule: rruleString,
        name: organization.name,
        languages: organization.languages,
      };
      updateOrganization(updatedOrganization);
    }
  }

  const confirmCloseDate = (confirn: boolean) => {
    if (confirn) {
      const updatedOrganization = {
        id: organization.id,
        rrule: currentRrule,
        name: organization.name,
        languages: organization.languages,
      };
      updateOrganization(updatedOrganization);
    } else {
      setRrule(organization.rrule);
    }
  }

  const onNewBookingPress = (date: DateObject) => {
    navigation.push(BookingsViewName);
  }

  const updateMonthCalendar = () => {
    const start = moment().startOf('day').utc().subtract(1, "M");
    const end = moment().startOf('day').utc().add(1, "M");

    setStartDate(start);
    setEndDate(end);

    calendarRef.current.updateMonth(new XDate(moment().year(), moment().month(), moment().date()), true);
  }

  const disableArrowLeft = () => {
    let date = new Date(calendarRef?.current?.state?.currentMonth.getTime());
    date.setMonth(date.getMonth()-1);
    return (date < new Date(currentDate));
  }

  const disableArrowRight = () => {
    let date = new Date(calendarRef?.current?.state?.currentMonth.getTime());
    date.setMonth(date.getMonth()+1);
    return (date > maxDate);
  }

  const renderCalendarHeader = (date: DateObject) => {
    const month = moment(date[0]).locale(localize('LOCALE_LANGUAGE')).format(config.monthyearFormat);
    return (
      <>
        <>
          <StyledText variant={'body1'} fontSize={21} style={{ textTransform: "capitalize"}}>{month}</StyledText>
          <LinkContainer disabled={disableArrowLeft()} onPress={() => updateMonthCalendar()}>
            <StyledText 
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: disableArrowLeft() ? '0.4' : '1' }}
              fontWeight={'400'} 
              fontSize={14} 
              fontColor={palette.text['primary']} 
              letterSpacing={'1.2px'} 
              underline={true} >
              {localize('TODAY')} 
            </StyledText>
          </LinkContainer>
        </>
        <>
          <DaysContainer width={width}>{daysView}</DaysContainer>
        </>
      </>
    );
  }

  const renderCalendarWithSelectableDate = () => {
    LocaleConfig.defaultLocale = localize('LOCALE_LANGUAGE');
    
    return (
      <>
        <Dialog
          isOpen={isDeleteBookingConfirmationDisplayed}
          title={localize('DISABLE_DAY')}
          onClose={() => {
            showConfirmOrganizationBookingsDelete(false);
            confirmCloseDate(false);
          }}
          actionTitle={localize('DESACTIVATE')}
          cancelTitle={localize('CANCEL')}
          cancelVariant={'light'}
          cancelInverted={false}
          cancelBorder={ThemeUtil.getThemeValue(palette.borderLight)(styleName)}
          cancelFontColor={colors.black}
          onbuttonAction={() => {
            confirmCloseDate(true);
            showConfirmOrganizationBookingsDelete(false);
          }} />
        <Calendar
          ref={calendarRef}
          renderArrow={(direction) => {
            if (direction == 'left') {
                return (<BackArrowContainer style={disableArrowLeft() && CalendarStyles.disabled}><BackArrow/></BackArrowContainer>)
            } else {
                return (<ForwardArrowContainer style={disableArrowRight() && CalendarStyles.disabled}><ForwardArrow/></ForwardArrowContainer>)
            }
          }}
          current={currentDate}
          key={currentDate}
          monthFormat={"MMMM"}
          pastScrollRange={0}
          hideExtraDays={true}
          onPressArrowLeft={(subtractMonth) => {        
            if (disableArrowLeft()) return;
            subtractMonth();
          }}
          onPressArrowRight={addMonth => { 
            if (disableArrowRight()) return;
            addMonth();
          }}
          disableArrowLeft={disableArrowLeft()}
          disableArrowRight={disableArrowRight()}
          style={styles({ height: height }).calendar}
          onDayPress={onToggleSwitch}
          renderHeader={(date) => renderCalendarHeader(date)}
          markedDates={currentMarked}
          dayComponent={(props) => (
            <BigDayView
              {...props}
              occupation={
                SpaceHelper.isOfficeOpened(props.date.dateString, officeOpenCache, spacesList, availableSpacesCache)?.occupancy ?? null
              }
              onNewBookingPress={onNewBookingPress}
            />
          )}
          hideDayNames={true}
          theme={{
            calendarBackground: "",
            "stylesheet.day.basic": {
              base: {
                width: 123,
                height: 123,
              },
            },
            monthTextColor: colors.grey3,
            "stylesheet.calendar.main": {
              week: {
                marginTop: 0,
                marginBottom: 0,
                flexDirection: "row",
                justifyContent: "space-around",
              },
            },
            "stylesheet.calendar-list.main": {
              calendar: {
                paddingLeft: 24,
                paddingRight: 24,
                height: 'auto',
              },
            },
            "stylesheet.calendar.header": {
              header: {
                width: "100%",
                alignSelf: "center",
                alignItems: "flex-start",
                justifyContent: "center",
                paddingTop: 16,
                flexDirection: "row",
              },
              headerContainer: {
                width: "100%",
                left: 0,
              },
              arrow: {
                position: "absolute",
                right: 0,
                padding: 10,
                zIndex: 2,
              }
            },
          }}
          onVisibleMonthsChange={(date) => {
            if (date[0]) {
              const start = moment(date[0].dateString).subtract(1, "M");
              const end = moment(date[0].dateString).add(1, "M");

              setStartDate(start);
              setEndDate(end);
            }
          }}
        />
      </>
    );
  };

  const startDateValue = () => {

    if (moment().isoWeekday() === 7) {
      return moment().locale(localize('LOCALE_LANGUAGE'));
    } else {
      return moment().locale(localize('LOCALE_LANGUAGE')).subtract(1, "week").endOf("week");
    }
  };

  var daysView: any[] = _.range(7).map((day) => {
    const date = startDateValue().day(day);

    return (
      <StyledText
        key={day}
        variant={"body4"}
        fontSize={11}
        fontWeight={"600"}
        color={_.includes([6, 7], date.isoWeekday()) ? "hint" : "primary"} //TODO : Fix color
        style={
          textStyles({ disabled: _.includes([6, 7], date.isoWeekday()) })
            .dayText
        }
      >
        {date.locale(localize('LOCALE_LANGUAGE')).format("dddd").toUpperCase()}
      </StyledText>
    );
  });

  return (
    <>
      {renderCalendarWithSelectableDate()}
    </>
  );
};

export default connect(mapStateToProps, dispatchProps)(BigCalendar);

type DayTextProps = {
  selected: boolean;
};

type HeaderProps = {
  displayWeekView: boolean;
};

const DaysContainer = styled.View<ViewProps>`
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2px;
  align-items: stretch;
  width: 100%;
  padding: 0;
  height: 70px;
  align-items: center;
`;

const BackArrowContainer = styled.View`
  padding-right: 20px;
`;

const LinkContainer = styled.TouchableOpacity`
  font-family: 'Barlow';
  position: absolute;
  right: 0;
  padding: 5px 50px 0 4px;
`;

const ForwardArrowContainer = styled.View``;

const styles = (props) => StyleSheet.create({
  calendar: {
    paddingBottom: 4,
    height: props.height,
  },
});

const CalendarStyles = StyleSheet.create({
  disabled: {
    opacity: 0.4,
  }
});

const textStyles = (props) =>
  StyleSheet.create({
    todayText: {
      textAlign: "center",
      paddingVertical: 2,
      paddingHorizontal: props.selected ? 8 : 0,
      borderRadius: 4,
      height: 16,
      width: props.selected ? "auto" : 0,
      color: colors.white,
    },
    dateText: {
      marginLeft: 8,
      letterSpacing: 3.2,
    },
    dayText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: 1,
      paddingTop: 2,
      textAlign: "center",
      width: 123,
      height: 50,
      letterSpacing: '0.2em',
      fontSize: 12,
      fontWeight: 500,
    },
  });
