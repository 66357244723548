import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import theme from 'styled-theming';
import { colors } from '@features/preferences/style/themes';
import Checked from '../img/checked.svg';
import { ThemeUtil } from '@space/common';

type CheckBoxProps = {
  ticked: boolean;
  onPress?: () => void;
};

type ThemeProps = {
  styleName: string;
};

const renderTick = (color: string) => (
  <Checked fill={color} width={24} height={24} />
);

const background = theme('mode', {
  light: colors.grey1,
  dark: colors.black,
});

const tickColor = theme('mode', {
  light: colors.grey5,
  dark: colors.white,
});

const CheckBox: React.FC<CheckBoxProps> = ({ ticked, onPress }) => {
  const styleName = useTheme().mode;
  const color = ThemeUtil.getThemeValue(tickColor)(styleName);

  return (
    <StyledContainer styleName={styleName}>
      <StyledCheckBox onPress={onPress} ticked={ticked}>
        {ticked ? renderTick(color) : null}
      </StyledCheckBox>
    </StyledContainer>
  );
};

const StyledCheckBox = styled.TouchableOpacity<CheckBoxProps>`
  width: 100%;
  height: 100%;
`;

const StyledContainer = styled.View<ThemeProps>`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    ThemeUtil.getThemeValue(background)(props.styleName)};
  border-radius: 4px;
`;

export default CheckBox;
