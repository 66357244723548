import {RootEpic} from 'StoreTypes';
import {from, of} from 'rxjs';
import {filter, switchMap, map, catchError} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {defer} from 'rxjs';
import moment from 'moment';
import config from '../../config';
import {
  loadTodayBookingAsync,
  loadMyBookingAsync,
  loadBookingAsync,
  createBookingAsync,
  deleteBookingAsync,
  updateBookingAsync
} from './actions';
import {Booking} from 'BookingFeature';

export const loadTodayBookingEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(
      isActionOf([
        loadTodayBookingAsync.request,
        createBookingAsync.success,
        updateBookingAsync.success,
        deleteBookingAsync.success,
      ]),
    ),
    switchMap((action) =>
      defer(() =>
        from(
          api.common.fetchBooking({
            startDate: moment().format(config.defaultDateFormat),
            endDate: moment().format(config.defaultDateFormat),
          }),
        ),
      ).pipe(
        map((data) => data as Booking[]),
        map(loadTodayBookingAsync.success),
        catchError((error) => of(loadTodayBookingAsync.failure(error))),
      ),
    ),
  );

export const loadMyBookingEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(
      isActionOf([loadMyBookingAsync.request, createBookingAsync.success, updateBookingAsync.success]),
    ),
    switchMap((action) =>
      defer(() =>
        from(api.common.fetchMyBookings(state$.value.login.user.id)),
      ).pipe(
        map((data) => data as Booking[]),
        map(loadMyBookingAsync.success),
        catchError((error) => of(loadMyBookingAsync.failure(error))),
      ),
    ),
  );

export const loadBookingEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf([loadBookingAsync.request])),
    switchMap((action) =>
      defer(() => from(api.common.fetchBooking(action.payload))).pipe(
        map((data) => data as Booking[]),
        map(loadBookingAsync.success),
        catchError((error) => of(loadBookingAsync.failure(error))),
      ),
    ),
  );

export const deleteBookingEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf(deleteBookingAsync.request)),
    switchMap((action) =>
      defer(() => from(api.common.deleteBooking(state$.value.booking.selectedBooking))).pipe(
        map(deleteBookingAsync.success),
        catchError((error) => of(deleteBookingAsync.failure(error))),
      ),
    ),
  );

export const createBookingEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf(createBookingAsync.request)),
    switchMap((action) =>
      defer(() => from(api.common.createBooking(action.payload))).pipe(
        map(createBookingAsync.success),
        catchError((error) => of(createBookingAsync.failure(error))),
      ),
    ),
  );

  export const updateBookingEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateBookingAsync.request)),
    switchMap((action) => {
      return from(
        api.common.updateBooking(action.payload.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateBookingAsync.success(updateResponse);
        }),
        catchError((err) => of(updateBookingAsync.failure(err))),
      );
    }),
  );
