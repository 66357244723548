import React from 'react';
import { StyleSheet, FlatList, TouchableOpacity } from 'react-native';
import { User } from 'UserFeature';
import Avatar from './Avatar';
import { StyledText, ThemeUtil } from '@space/common';
import {
  colors,
  getThemeValue,
  palette,
} from '@features/preferences/style/themes';
import Checked from '../img/checked.svg';
import styled, { useTheme } from 'styled-components/native';
import theme from 'styled-theming';

type AvatarListProps = {
  list: User[];
  selectedUserId?: string;
  onItemSelected?: (user?: User) => void;
};

const renderTick = (color: string) => (
  <StyledChecked>
    <Checked fill={color} width={24} height={24} />
  </StyledChecked>
);

const tickColor = theme('mode', {
  light: colors.grey6,
  dark: colors.white,
});

const AvatarList: React.FC<AvatarListProps> = ({
  list,
  selectedUserId,
  onItemSelected,
}) => {
  const styleName = useTheme().mode;
  const color = ThemeUtil.getThemeValue(tickColor)(styleName);
  return (
    <Container>
      <FlatList
        data={list}
        contentContainerStyle={Styles.contentContainer}
        style={Styles.list}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => (onItemSelected ? onItemSelected(item) : null)}>
            <ItemContainer>
              <Avatar onPress={() => (onItemSelected ? onItemSelected(item) : null)} name={item.firstName} lastName={item.lastName} src={item.avatar} />
              <StyledSpace />
              <StyledText variant={'body4'} color={'primary'}>
                {item.firstName + ' ' + item.lastName}
              </StyledText>
              {selectedUserId && item.id === selectedUserId ? renderTick(color) : null}
            </ItemContainer>
          </TouchableOpacity>
        )}
      />
    </Container>
  );
};

export default AvatarList;

const Container = styled.View`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: ${palette.background};
`;

const ItemContainer = styled.View`
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  padding: 8px 0px;
`;

const StyledSpace = styled.View`
  width: 16px;
`;

const StyledChecked = styled.View`
  margin: 0px 25px;
`;

const Styles = StyleSheet.create({
  image: {
    width: 24,
    height: 24,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 24,
  },
  list: {
    marginTop: 10,
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    marginTop: 10,
  },
  titleText: {
    marginTop: 24,
    marginLeft: 32,
  },
});
