import React from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { Divider, StyledText, ThemeUtil } from '@space/common';
import { colors, palette } from '@features/preferences/style/themes';
import { useTheme } from 'styled-components/native';
import { DateObject } from 'react-native-calendars';
import { Switch } from "react-native";
import theme from 'styled-theming';
import { localize } from '../../localization';
import AddBooking from '../../img/add_booking.svg';
import SvgIcon from '@components/SvgIcon';
import VerticalDivider from '../VerticalDivider';
import SpacingView from '../SpacingView';
import { LocaleConfig } from 'react-native-calendars';

type PeriodMarking = {
  selected?: boolean;
  disabled?: boolean;
  bookings?: number;
}

const trackColorOn = theme('mode', {
  light: '#ACD2B6',
  dark: '#8DC89D',
});

const trackColorOff = theme('mode', {
  light: '#C5C5C5',
  dark: '#111111',
});

const iconBackgroundColor = theme('mode', {
  light: '#333333',
  dark: '#F2F2F2',
});

const color = theme('mode', {
  light: colors.grey5,
  dark: colors.grey4,
});

type DayProps = {
  state: string;
  marking: PeriodMarking;
  date: DateObject;
  occupation: number | null;
  onPress: (date: DateObject) => any;
  onNewBookingPress: (date: DateObject) => any;
};

const BigDayView: React.FC<DayProps> = ({
  state,
  marking,
  date,
  onPress,
  occupation,
  onNewBookingPress,
}) => {

  const onDayPress = () => onPress(date);

  const styleName = useTheme().mode;
  const highlighted = state === 'today';
  const occupationValue = localize('BOOKING_OCCUPATION_MAX', { count: marking.bookings ?? 0 });

  const colorTrackColorOn = ThemeUtil.getThemeValue(trackColorOn)(styleName);
  const colorTrackColorOff = ThemeUtil.getThemeValue(trackColorOff)(styleName);

  const toggleSwitch = () => {
    onPress(date);
  }

  const newBookingPress = () => {
    onNewBookingPress(date);
  }

  const month = LocaleConfig.locales[localize('LOCALE_LANGUAGE')].monthNamesShort[date.month-1].toLowerCase();

  return (
    <Container>
      <ItemContainer selected={marking.selected ?? false} highlighted={highlighted}>
        <ItemDate>
          <ItemTouchableOpacity
            selected={marking.selected ?? false}
            highlighted={highlighted}
            disabled={true}
            onPress={onDayPress}>
            <StyledText
              variant={'caption'}
              fontSize={18}
              fontColor={
                highlighted
                  ? colors.white
                  : marking.selected
                    ? styleName === 'dark'
                      ? colors.black
                      : colors.white
                    : undefined
              }
              fontWeight={occupation === null && styleName === 'dark' ? '200' : 'normal'}
              color={occupation === null ? 'hint' : 'primary'}
              style={styles({ occupation: occupation, selected: marking.selected }).textItem}>
              {date.day}
            </StyledText>
            
            <IndicatorView occupation={occupation} selected={marking.selected ?? false} />
          </ItemTouchableOpacity>
          <StyledText>{`${month}`}</StyledText>
        </ItemDate>
        <OccupationText>
          <StyledText variant={'caption'}>{occupationValue}</StyledText>
        </OccupationText>
        <SpacingView height={'5px'} />
        <Divider />
        <RowContent>
          <SwitchContainer>
            <Switch
              trackColor={{ false: colorTrackColorOff, true: colorTrackColorOn }}
              thumbColor={colors.white}
              ios_backgroundColor="#3e3e3e"
              onValueChange={toggleSwitch}
              value={marking.selected}/>
          </SwitchContainer>
          <VerticalDivider />
          {marking.selected ? <StyledTouchableOpacity onPress={newBookingPress}>
            <SvgIcon svg={AddBooking} width={24} height={24} fillColor={ThemeUtil.getThemeValue(palette.border)(styleName)} />
          </StyledTouchableOpacity> : <StyledTouchableOpacity><SpacingView width={'24px'} height={'24px'} /></StyledTouchableOpacity>}
        </RowContent>
      </ItemContainer>
    </Container>
  );
};

export default React.memo(BigDayView);

type DayViewProps = {
  selected: boolean;
  highlighted: boolean;
};

const Container = styled.View`
  align-items: center;
  justify-content: center;
  width: 100%
  max-width: 123px;
  height: 123px;
  border-radius: 8px;
  border: solid 1px;
  margin-bottom: 12px;
`;

const ItemContainer = styled.View<DayViewProps>`
  width: 100%;
  align-items: center;
  justify-content: center;  
  border-radius: 16px;
`;

const ItemDate = styled.View`
  width: 100%;
  padding: 9px 9px 0;
  flex-direction: row;
  align-items: center;
`;

const ItemTouchableOpacity = styled.TouchableOpacity<DayViewProps>`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 5px;
  background-color: ${(props) =>
    props.highlighted ? palette.secondary : props.selected ? palette.info : ''};
`;

const RowContent = styled.View`
  width: 100%;
  flex-direction: row;
  height: 53px;
  align-items: center;
`;

const SwitchContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const OccupationText = styled.View`  
   width: 100%;
   padding: 2px 16px 0;
`;

const StyledTouchableOpacity = styled.TouchableOpacity`
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

type IndicatorViewProps = {
  selected: boolean;
  occupancy: number | null;
};

const IndicatorView = styled.TouchableOpacity<IndicatorViewProps>`
  align-self: flex-end;
  top: 0;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.selected || props.occupancy === null
      ? ''
      : props.occupancy >= 100
        ? palette.error
        : props.occupation > 75
          ? palette.warning
          : ''};
`;

const styles = (props) =>
  StyleSheet.create({
    textItem: {
      textAlign: 'center',
    },
  });
