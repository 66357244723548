import React, { useRef } from 'react';
import { View, ScrollView, Dimensions, NativeScrollEvent } from 'react-native';
import WeekView from './WeekView';
import moment from 'moment';
import config from '../../config';
import { connect } from 'react-redux';
import { setSelectedDate } from '@features/booking/actions';
import _ from 'lodash';
import styled from 'styled-components/native';
import { RootState } from 'StoreTypes';
import CalendarHeader from "./CalendarHeader";
import { localize } from '../../localization';

type CalendarProps = {
  startDate: string;
  weeks: number;
  occupationForDate: (date: string) => number | null;
  onDateSelect: (date: string) => void;
  daysView: any[];
};

const dispatchProps = {
  selectDate: setSelectedDate,
};

const mapStateToProps = (state: RootState) => ({
  selectedDate: state.booking.selectedDate,
});

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  CalendarProps;

const HorizontalCalendar: React.FC<Props> = ({
  onDateSelect,
  startDate,
  weeks,
  selectDate,
  occupationForDate,
  selectedDate,
  daysView
}) => {
  const [width, setWidth] = React.useState(Dimensions.get('window').width);
  const [scrollCount, setScrollCount] = React.useState(0);
  const scrollViewRef = useRef<ScrollView>(null);
  const init = (value: number) => {
    if (value === 0) {
      return;
    }
    const calculatedWidth = value / weeks;
    setWidth(calculatedWidth);
    if (scrollViewRef.current) {
      const selectedWeek =
        _.range(weeks).find((week) =>
          moment(selectedDate).isBetween(
            startDateValue().add(week, 'weeks'),
            startDateValue().add(week + 1, 'weeks'),
          ),
        ) ?? 0;
      scrollViewRef.current.scrollTo({
        x: selectedWeek * calculatedWidth,
        y: 0,
        animated: false,
      });
    }
  };

  function startDateValue() {
    return moment(startDate).locale(localize('LOCALE_LANGUAGE'));
  }

  function onScroll(event: NativeScrollEvent) {
    const pageWidth = event.contentSize.width / weeks;
    //Select the first open day when the view is scrolled.
    for (let i = 0; i < weeks; i++) {
      if (
        event.contentOffset.x >= i * pageWidth &&
        event.contentOffset.x < (i + 0.5) * pageWidth
      ) {
        setScrollCount(i);
        if (
          moment(selectedDate).isBetween(
            startDateValue().add(i, 'weeks'),
            startDateValue().add(i + 1, 'weeks'),
          )
        ) {
          return;
        }
        for (let day = 0; day < 7; day++) {
          const newDate = startDateValue().add(7 * i + day, 'days');
          if (
            occupationForDate(newDate.format(config.defaultDateFormat)) != null
          ) {
            selectDate(newDate.format(config.defaultDateFormat));
            break;
          }
        }
      }
    }
  }

  if (scrollViewRef.current) {            
    scrollViewRef.current.scrollTo({
      x: scrollCount * width,
      y: 0,
      animated: false,
    });
  }

  const scrollOnRightClick = () => {
    if(scrollCount < (weeks-1)){
      setScrollCount(scrollCount + 1)
    }
  }

  const scrollOnLeftClick = () => {
    if(scrollCount > 0){
      setScrollCount(scrollCount - 1)
    } 
  }

  var weeksView: any[] = _.range(weeks).map((week, i) => {
    const weekStartDate = startDateValue().add(week, 'weeks');

    const occupations = _.range(7).reduce((result, day) => {
      const date = moment(weekStartDate)
        .add(day, 'day')
        .format(config.defaultDateFormat);
      const occupation = occupationForDate(date);
      if (occupation != null) {
        result[date] = occupation;
      }
      return result;
    }, {});

    return (
      <View key={i} style={{ width: width }}>
        <WeekView
          startDate={weekStartDate.toDate()}
          occupations={occupations}
          onDateSelect={onDateSelect}
        />
      </View>
    );
  });

  return (
    <>
      <CalendarHeader 
          displayWeekView={true}
          scrollOnRightClick={scrollOnRightClick}
          scrollOnLeftClick={scrollOnLeftClick}
          selectedDate={selectedDate}
          scrollCount={scrollCount}
          weeks={weeks}
        />
      <DaysContainer>{daysView}</DaysContainer>
      <Container>
        <ScrollView
          ref={scrollViewRef}
          showsHorizontalScrollIndicator={false}
          horizontal
          pagingEnabled
          onContentSizeChange={(w, h) => init(w)}
          onScroll={({ nativeEvent }) => onScroll(nativeEvent)}
          scrollEventThrottle={20}
          contentContainerStyle={{ width: `${100 * weeks}%` }}>
          {weeksView}
        </ScrollView>
      </Container>
    </>
  );
};
export default connect(mapStateToProps, dispatchProps)(HorizontalCalendar);
const Container = styled.View`
  width: 100%;
  max-width: 375px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
`;

const DaysContainer = styled.View`
  flex-direction: row;
  width: 100%;
  max-width: 375px;
  justify-content: space-around;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 2px;
  align-items: stretch;
`;