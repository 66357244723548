import { combineEpics } from 'redux-observable';

import * as login from '../features/login/epics';
import * as home from '../features/home/epics';
import * as booking from '../features/booking/epics';
import * as space from '../features/space/epics';
import * as user from '../features/user/epics';
import * as organization from '../features/organization/epics';

export default combineEpics(
  ...Object.values(login),
  ...Object.values(home),
  ...Object.values(booking),
  ...Object.values(space),
  ...Object.values(user),
  ...Object.values(organization)
);
