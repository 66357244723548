import React from "react";
import { NativeSyntheticEvent, TargetedEvent, StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { default as ThemeUtil } from '../ThemeProvider/theme.utils';
import { palette } from '@features/preferences/style/themes';
import SpacingView from '../SpacingView';
import { StyledText } from '@space/common';

type TextInputProps = {
    title: string;
    placeholder?: string;
    onChangeText?: (text: string) => void;
    onBlur?: (e: NativeSyntheticEvent<TargetedEvent>) => void
    error?: string;
    touched?: boolean
    value?: string;
    multiline?: boolean;
    maxLength?: number;
    disabled?: boolean;
    keyboardType?: 'default' | 'email-address' | 'numeric' | 'phone-pad' | 'number-pad' | 'decimal-pad';
    width?: number;
};

type InputProps = {
    placeholder?: string;
    onChangeText?: (text: string) => void;
    onBlur?: (e: NativeSyntheticEvent<TargetedEvent>) => void;
    error?: string;
    touched?: boolean
    value?: string;
    multiline?: boolean;
    maxLength?: number;
    disabled?: boolean;
    keyboardType?: 'default' | 'email-address' | 'numeric' | 'phone-pad' | 'number-pad' | 'decimal-pad';
};

type ContainerProps = {
    width?: number;
}

const StyledTextInput: React.FC<TextInputProps> = ({
    title,
    placeholder,
    onChangeText,
    onBlur,
    error,
    touched,
    value,
    multiline,
    maxLength,
    keyboardType,
    disabled,
    width
}) => {
    return (
        <RootContainer width={width} style={disabled && styles.disabled}>
            <StyledText variant={'caption'} color={'title'}>{title}</StyledText>
            <SpacingView height={'8px'} />
            <SingleTextInputContainer
                disabled={disabled}
                placeholder={placeholder}
                onChangeText={onChangeText}
                onBlur={onBlur}
                value={value}
                multiline={multiline}
                maxLength={maxLength}
                keyboardType={keyboardType}
                error={error}
                touched={touched}
            />
            <ErrorContainer>
                {error && touched ? <StyledText variant={'caption'} color={'error'}>{'* ' + error}</StyledText> : <StyledText />}
            </ErrorContainer>
        </RootContainer>
    );
}

export default StyledTextInput;

const RootContainer = styled.View<ContainerProps>`
  width:${(props) => (props.width ? props.width + 'px' : '100%')};
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  margin: 0px 0px 4px 0px;
`;

const ErrorContainer = styled.View`
  width:100%;
  flex: 1;
`;

const SingleTextInputContainer = styled.TextInput<InputProps>`
  height: 40px;
  padding: 4px 16px;
  background-color: ${palette.textInput};
  border-radius: 4px;
  border: 1px solid ${(props) => (props.error && props.touched ? 'red' : 'transparent')};
  margin: 0px 0px 4px 0px;
  color: ${palette.info};
`;

const styles = StyleSheet.create({
    disabled: {
      opacity: '0.4'
    } 
  });