import React from 'react';
import {StyleSheet} from 'react-native';
import styled from 'styled-components/native';
import { StyledText } from '@space/common';
import {colors, palette} from '@features/preferences/style/themes';
import {useTheme} from 'styled-components/native';
import { DateObject } from 'react-native-calendars';

type DayProps = {
  state: string;
  marking: any;
  date: DateObject;
  occupation: number | null;
  onPress: (date: DateObject) => any;
};

const DayView: React.FC<DayProps> = ({
  state,
  marking,
  date,
  onPress,
  occupation,
}) => {
  const onDayPress = () => onPress(date);

  const styleName = useTheme().mode;
  const { selected } = marking;
  const highlighted = state === 'today';

  return (
    <ItemContainer selected={selected} highlighted={highlighted}>
      <ItemTouchableOpacity
        selected={selected}
        highlighted={highlighted}
        disabled={occupation === null && state !== 'today'}
        onPress={onDayPress}>
        <StyledText
          variant={'caption'}
          fontSize={18}
          fontColor={
            highlighted
              ? colors.white
              : selected
              ? styleName === 'dark'
                ? colors.black
                : colors.white
              : undefined
          }
          fontWeight={occupation === null && styleName === 'dark' ? '200' : 'normal'}
          color={occupation === null ? 'hint' : 'primary'}
          style={styles({occupation: occupation, selected: selected}).textItem}>
          {date.day}
        </StyledText>
        <IndicatorView occupation={occupation} selected={selected} />
      </ItemTouchableOpacity>
    </ItemContainer>
  );
};

export default React.memo(DayView);

type DayViewProps = {
  selected: boolean;
  highlighted: boolean;
};

const ItemContainer = styled.View<DayViewProps>`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-bottom: 12px;
`;

const ItemTouchableOpacity = styled.TouchableOpacity<DayViewProps>`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${(props) =>
    props.highlighted ? palette.secondary : props.selected ? palette.info : ''};
`;

type IndicatorViewProps = {
  selected: boolean;
  occupancy: number | null;
};

const IndicatorView = styled.TouchableOpacity<IndicatorViewProps>`
  align-self: flex-end;
  top: 0;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.occupancy === null
      ? ''
      : props.occupancy >= 100
      ? palette.error
      : props.occupancy > 75
      ? palette.warning
      : ''};
`;

const styles = (props) =>
  StyleSheet.create({
    textItem: {
      textAlign: 'center',
    },
  });
