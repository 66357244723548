import { RootEpic } from 'StoreTypes';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  fetchOrganizationAsync,
  fetchOrganizationsAsync,
  updateOrganizationAsync,
} from './actions';
import { defer } from 'rxjs';
import { Organization } from 'OrganizationFeature';

export const fetchOrganizationEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchOrganizationAsync.request)),
    switchMap((action) =>
      defer(() =>
        from(
          api.common.fetchOrganization(
            state$.value.login.user.organization.id
          )
        )
      ).pipe(
        map((data) => data as Organization),
        map(fetchOrganizationAsync.success),
        catchError((error) => of(fetchOrganizationAsync.failure(error)))
      )
    )
  );

export const fetchOrganizationsEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchOrganizationsAsync.request)),
    switchMap((action) =>
      defer(() => from(api.admin.fetchOrganizations())).pipe(
        map((data) => data as Organization[]),
        map(fetchOrganizationsAsync.success),
        catchError((error) => of(fetchOrganizationsAsync.failure(error)))
      )
    )
  );

export const updateOrganizationEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateOrganizationAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.updateOrganization(
          state$.value.login.user.organization.id,
          action.payload
        )
      ).pipe(
        map((updateResponse) => {
          return updateOrganizationAsync.success(updateResponse);
        }),
        catchError((err) => of(updateOrganizationAsync.failure(err)))
      );
    })
  );
