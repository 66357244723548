import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import preferences from '../features/preferences/reducer';
import login from '../features/login/reducer';
import home from '../features/home/reducer';
import booking from '../features/booking/reducer';
import space from '../features/space/reducer';
import user from '../features/user/reducer';
import organization from '../features/organization/reducer';

const rootReducer = (history: History<any>) =>
  combineReducers({
    router: connectRouter(history),
    preferences,
    login,
    home,
    booking,
    space,
    user,
    organization,
  });

export default rootReducer;
