import React from 'react';
import { View, Image } from 'react-native';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { StyledText, ThemeUtil } from '@space/common';
import SpaceAvailabilityItem from './SpaceAvailabilityItem';
import Pod from '../img/pod.svg';
import Seat from '../img/seat.svg';
import Room from '../img/room.svg';
import StatusFull from '../img/status_full.svg';
import styled, { useTheme } from 'styled-components/native';
import _ from 'lodash';
import theme from 'styled-theming';
import { colors, palette } from '@features/preferences/style/themes';
import { Formik, Field, Form } from 'formik';

export type SpaceStatsVariant = 'iconOnly' | 'textIcon';

type SpaceStatsProps = {
  size: string;
  spaceName: string;
  icon: string;
  availability: number;
  percent: number;
  variant?: SpaceStatsVariant;
};

const renderIcon = (src: string, styleName: string, size: number) => {
  const theme = useTheme();
  const props = {
    fill: ThemeUtil.getInvertedThemeValue(theme.palette.common)(styleName),
    width: size,
    height: size,
  };

  const iconName = _.head(src.split('/').pop()?.split('.'));

  switch (iconName) {
    case 'pod':
      return <Pod {...props} />;
    case 'seat':
      return <Seat {...props} />;
    case 'room':
      return <Room {...props} />;
    default:
      return (
        <Image
          source={{
            uri: src ?? 'img/logo.svg',
          }}
          accessibilityLabel={'TODO'}
        />
      );
  }
};

const renderNoAvailability = () => (
  <StyledNoAvailability>
    <StatusFull width={32} height={20} />
  </StyledNoAvailability>
);

const renderAvailability = (availability: number) => (
  <StyledText variant={'h1'} fontSize={58} fontWeight={'500'} color={'primary'}>
    {availability}
  </StyledText>
);

const pathColorFromPercent = (percent: number, mode: any) => {
  if (percent == 100) {
    return ThemeUtil.getThemeValue(palette.error)(mode);
  } else if (percent >= 75) {
    return ThemeUtil.getThemeValue(palette.warning)(mode)
  } else {
    return ThemeUtil.getThemeValue(palette.primary)(mode);
  }
};

const background = theme('mode', {
  light: colors.grey1,
  dark: colors.black,
});

const renderBigStats = (
  spaceName: string,
  icon: string,
  availability: number,
  percent: number,
  theme: DefaultTheme,
  variant = 'textIcon'
) => {
  const mode = theme.mode;
  return (
    <CircularProgressbarWithChildren
      value={percent}
      background
      styles={buildStyles({
        strokeLinecap: 'round',
        backgroundColor: ThemeUtil.getThemeValue(background)(mode),
        pathColor: pathColorFromPercent(percent, mode),
        trailColor: ThemeUtil.getThemeValue(background)(mode),
      })}>
      <StyledContent>

      </StyledContent>
    </CircularProgressbarWithChildren>
  );
}
const renderSmallContent = (variant: SpaceStatsVariant, spaceName: string, icon: string, styleName: string) => {
  if (variant === 'iconOnly') {
    return (
      <>
        {renderIcon(icon, styleName, 40)}
      </>
    );
  } else {
    return (
      <>
        {renderIcon(icon, styleName, 24)}
        <View style={{ height: 4 }} />
        <StyledText variant={'caption'} color={'primary'} letterSpacing={'0.1em'}>
          {spaceName}
        </StyledText>
      </>
    );
  }
};

const renderSmallStats = (
  spaceName: string,
  icon: string,
  availability: number,
  percent: number,
  theme: DefaultTheme,
  variant: SpaceStatsVariant,
) => {
  const mode = theme.mode;

  return (
    <CircularProgressbarWithChildren
      value={percent}
      background
      styles={buildStyles({
        strokeLinecap: 'round',
        backgroundColor: ThemeUtil.getThemeValue(background)(mode),
        pathColor: pathColorFromPercent(percent, mode),
        trailColor: ThemeUtil.getThemeValue(background)(mode),
      })}>
      <StyledContent>
        {renderSmallContent(variant, spaceName, icon, mode)}
        <Badge>
          <SpaceAvailabilityItem availability={availability} />
        </Badge>
      </StyledContent>
    </CircularProgressbarWithChildren>
  );
}

const statsSize = (size: string) => {
  switch (size) {
    case 'small':
      return 80;
    case 'big':
    default:
      return 200;
  }
};

/**
 * A small profile card
 */
const SpaceStats: React.FC<SpaceStatsProps> = ({
  size = 'big',
  spaceName,
  icon,
  availability,
  percent,
  variant = 'textIcon',
}) => {
  const theme = useTheme();

  const viewSize = statsSize(size);
  const stats =
    size === 'small'
      ? renderSmallStats(spaceName, icon, availability, percent, theme, variant)
      : renderBigStats(spaceName, icon, availability, percent, theme);

  return (
    <View
      style={[
        {
          width: viewSize,
          height: viewSize,
        },
      ]}>
      {stats}
    </View>
  );
};

const StyledContent = styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
`;

const StyledNoAvailability = styled.View`
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: ${palette.destructive};
  margin: 4px;
`;

const Badge = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
`;

export default SpaceStats;
