import { RRuleSet, rrulestr } from 'rrule';
import config from '../config';
import _head from 'lodash/head';
import { timefromRruleData } from '@space/common';  

export enum WeekDay {
  MO = 'MO',
  TU = 'TU',
  WE = 'WE',
  TH = 'TH',
  FR = 'FR',
  SA = 'SA',
  SU = 'SU',
};
export const weekDays = [WeekDay.MO, WeekDay.TU, WeekDay.WE, WeekDay.TH, WeekDay.FR, WeekDay.SA, WeekDay.SU];

const dateFormatter = (dateFormat: string, timezone: string) => {
  return (date: Date): string => {
    return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
  }
}

const formatDate = (timezone: string) => dateFormatter(config.exceptionDateFormat, timezone);

export const scheduleFromRruleSet = (value: string) => {
  const rruleSet = rrulestr(value, {forceset: true}) as RRuleSet;

  const rrule = _head(rruleSet.rrules());
  if(!rrule) {
    return { byDay: [], byHour: [], exDates: [], rDates: [] };
  }

  const byHour = rrule.options.byhour;
  const byMinute = rrule.options.byminute;
  const times = timefromRruleData(byHour, byMinute);
  const formatter = formatDate(rrule.options.tzid ?? 'local')
  const exdates = rruleSet.exdates().map(formatter);
  
  return { byDays: rrule.options.byweekday, times: times, exDates: exdates }
}

export const timesfromRrule = (value: string) => {
  const rruleSet = rrulestr(value, {forceset: true}) as RRuleSet;
  const rrule = _head(rruleSet.rrules());
  if(!rrule) return [0,0];

  const hours = rrule.options.byhour;
  const minutes = rrule.options.byminute;
  return [hours, minutes];
}

export const exDatesFromRruleSet = (value: string): string[] => {
  const rruleSet = rrulestr(value);
  if (rruleSet instanceof RRuleSet) {
    const formatter = formatDate(_head(rruleSet.rrules())?.options.tzid ?? 'local')
    return rruleSet.exdates().map(formatter);
  }
  return [];
}

export const rDatesFromRruleSet = (value: string): string[] => {
  const rruleSet = rrulestr(value, {forceset: true});
  if (rruleSet instanceof RRuleSet) {
    const formatter = formatDate(_head(rruleSet.rrules())?.options.tzid ?? 'local')
    return rruleSet.rdates().map(formatter);
  }
  return [];
}

export const byDayFromRrule = (value: string): number[] => {
  const rruleSet = rrulestr(value, { forceset: true}) as RRuleSet;
  const rrule = _head(rruleSet.rrules());
  
  return rrule?.options.byweekday ?? [];
}

export const byHourFromRrule = (value: string): number[] => {
  const rruleSet = rrulestr(value, {forceset: true});
  if (rruleSet instanceof RRuleSet) {
    return _head(rruleSet.rrules())?.options.byhour ?? [];
  }
  return [];
}
