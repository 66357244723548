import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { StyledText } from '@space/common';
import { localize } from '../../localization';
import { ScrollView } from 'react-native-gesture-handler';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import SpaceStatsForTypeView from './SpaceStatsForTypeView';
import { useNavigation } from '@react-navigation/native';
import { BookingsViewName } from '../../routes/BookingsView';
import { StackNavigationProp } from '@react-navigation/stack';

const mapStateToProps = (state: RootState) => ({
    spaceList: state.space.space,
    availableSpacesCache: state.space.availableSpacesCache,
    spaceGroupList: state.space.spaceGroups,
    spaceTypes: state.space.spaceTypes,
});

const dispatchProps = {};

type SpaceStatsForTypeProps = {
    date: string;
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    SpaceStatsForTypeProps;

const SpaceStatsForTypeList: React.FC<Props> = ({
    date,
    spaceTypes,
    spaceList,
    availableSpacesCache,
    spaceGroupList,
}) => {
    const navigation = useNavigation<StackNavigationProp<any>>();

    const data: any[] = spaceTypes.map((item, index) => (
        <SpaceStatsForTypeView
            key={index}
            date={date}
            spaces={spaceList}
            availableSpacesCache={availableSpacesCache}
            spaceGroups={spaceGroupList}
            type={item}
            onPress={(spaceType: SpaceType) => {
                navigation.push(BookingsViewName, { spaceType: spaceType });
            }}
        />
    ));

    return (
        <RootContainer>
            <HeaderContainer>
                <StyledText variant={'h3'} color={'primary'}>
                    {localize('HOME_BOOKED_SPACES').toUpperCase()}
                </StyledText>
            </HeaderContainer>
            <Container>
                <ScrollView
                    showsHorizontalScrollIndicator={true}
                    horizontal >
                    {data}
                </ScrollView>
            </Container>
        </RootContainer>
    );
};

const RootContainer = styled.View`
  width: 100%;
  align-items: flex-start;
  padding: 16px;
`;

const HeaderContainer = styled.View`
  padding-left: 5px;
  padding-top: 5px;
`;

const Container = styled.View`
  flex-direction: row;
  margin-top: 22px;
`;

export default connect(mapStateToProps, dispatchProps)(SpaceStatsForTypeList);