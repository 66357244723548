import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  Organization,
  UpdateOrganizationQuery,
  Language,
} from 'OrganizationFeature';
import { OrganizationEditType } from './components/OrganizationEditorModal';

export const setOrganization = createAction(
  'SET_ORGANIZATION',
  (message: string) => message
)();

export const setOrganizationEditType = createAction(
  'SET_ORGANIZATION_EDIT_TYPE',
  (value: OrganizationEditType | null) => value
)();

export const displayNoActiveLanguageError = createAction(
  'DISPLAY_NO_ACTIVE_LANGUAGE_ERROR',
  (value: string) => value
)();

export const openOrganizationEditorModal = createAction(
  'OPEN_ORGANIZATION_EDITOR_MODAL',
  () => true
)();

export const closeOrganizationEditorModal = createAction(
  'CLOSE_ORGANIZATION_EDITOR_MODAL',
  () => false
)();

export const fetchOrganizationAsync = createAsyncAction(
  'LOAD_ORGANIZATION_REQUEST',
  'LOAD_ORGANIZATION_SUCCESS',
  'LOAD_ORGANIZATION_FAILURE'
)<void, Organization, string>();

export const fetchOrganizationsAsync = createAsyncAction(
  'LOAD_ALL_ORGANIZATIONS_REQUEST',
  'LOAD_ALL_ORGANIZATIONS_SUCCESS',
  'LOAD_ALL_ORGANIZATIONS_FAILURE'
)<void, Organization[], string>();

export const updateOrganizationAsync = createAsyncAction(
  'SET_ORGANIZATION_REQUEST',
  'SET_ORGANIZATION_SUCCESS',
  'SET_ORGANIZATION_FAILURE'
)<UpdateOrganizationQuery, Organization, string>();

export const showConfirmOrganizationBookingsDelete = createAction(
  'SHOW_CONFIRM_BOOKIG_DELETION',
  (value: boolean) => value
)();

export const clearError = createAction('CLEAR_ERROR', () => {})();
