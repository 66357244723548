import { StyleSheet } from 'react-native';
import styled, { css } from 'styled-components/native';
import theme from 'styled-theming';
import { colors } from '../features/preferences/style/themes';

type Props = {
  variant?: 'fullWidth' | 'middle';
};

const color = theme('mode', {
  light: colors.grey5,
  dark: colors.grey4,
});

const margin = theme.variants('mode', 'variant', {
  middle: {
    light: css`
      margin-left: 24px;
      margin-right: 24px;
      opacity: 0.25;
    `,
    dark: css`
      margin-left: 24px;
      margin-right: 24px;
      opacity: 0.25;
    `,
  },
  fullWidth: {
    light: css``,
    dark: css``,
  },
});

// border-top isn't supported
const VerticalDivider = styled.View<Props>`
  margin: 0;
  flex-shrink: 0;
  border-width: 0;
  align-self: stretch;
  background: ${color};
  width: ${`${StyleSheet.hairlineWidth}px`};
  ${margin}
`;

export default VerticalDivider;
