import { createAction, createAsyncAction } from 'typesafe-actions';
import { User, UserRole, UserGroup, CreateUserQuery, UpdateUserQuery, UpdateUserNoImageQuery, CreateUserRoleQuery, UpdateUserRoleQuery, CreateUserGroupQuery, UpdateUserGroupQuery } from 'UserFeature';

export const loadUsersAsync = createAsyncAction(
  'LOAD_USERS_REQUEST',
  'LOAD_USERS_SUCCESS',
  'LOAD_USERS_FAILURE',
)<void, User[], string>();

export const setUserSearchTextForMapping = createAction(
  'SET_USER_SEARCH_TEXT',
  (value: string) => value,
)();

export const setSpaceIdSelectedForMapping = createAction(
  'SET_SPACE_ID_SELECTED_FOR_MAPPING',
  (value: string) => value,
)();

export const openUserEditor = createAction(
  'OPEN_USER_EDITOR',
  () => true,
)();

export const closeUserEditor = createAction(
  'CLOSE_USER_EDITOR',
  () => false,
)();

export const openUserRolesEditor = createAction(
  'OPEN_USER_ROLE_EDITOR',
  () => true,
)();

export const closeUserRolesEditor = createAction(
  'CLOSE_USER_ROLE_EDITOR',
  () => false,
)();

export const openUserGroupsEditor = createAction(
  'OPEN_USER_GROUP_EDITOR',
  () => true,
)();

export const closeUserGroupsEditor = createAction(
  'CLOSE_USER_GROUP_EDITOR',
  () => false,
)();

export const showConfirmUserDelete = createAction(
  'SHOW_CONFIRM_USER_DELETE_MODAL',
  (value: boolean) => value,
)();

export const showConfirmUserDisabled = createAction(
  'SHOW_CONFIRM_USER_ROLE_DELETE_MODAL',
  (value: boolean) => value,
)();

export const showConfirmUserRoleDelete = createAction(
  'SHOW_CONFIRM_USER_GROUP_DELETE_MODAL',
  (value: boolean) => value,
)();

export const showConfirmUserGroupDelete = createAction(
  'SHOW_CONFIRM_USER_DISABLED_MODAL',
  (value: boolean) => value,
)();

export const loadUserRolesAsync = createAsyncAction(
  'LOAD_USER_ROLES_REQUEST',
  'LOAD_USER_ROLES_SUCCESS',
  'LOAD_USER_ROLES_FAILURE',
)<void, UserRole[], string>();

export const loadUserGroupsAsync = createAsyncAction(
  'LOAD_USER_GROUPS_REQUEST',
  'LOAD_USER_GROUPS_SUCCESS',
  'LOAD_USER_GROUPS_FAILURE',
)<void, UserGroup[], string>();

export const createUserAsync = createAsyncAction(
  'CREATE_USER_REQUEST',
  'CREATE_USER_SUCCESS',
  'CREATE_USER_FAILURE',
)<CreateUserQuery, User, string>();

export const updateUserAsync = createAsyncAction(
  'UPDATE_USER_REQUEST',
  'UPDATE_USER_SUCCESS',
  'UPDATE_USER_FAILURE',
)<UpdateUserQuery, User, string>();

export const updateUserNoImageAsync = createAsyncAction(
  'UPDATE_USER_NOIMAGE_REQUEST',
  'UPDATE_USER_NOIMAGE_SUCCESS',
  'UPDATE_USER_NO_IMAGE_FAILURE',
)<UpdateUserNoImageQuery, User, string>();

export const deleteUserAsync = createAsyncAction(
  'DELETE_USER_REQUEST',
  'DELETE_USER_SUCCESS',
  'DELETE_USER_FAILURE',
)<User, User, string>();

export const selectUser = createAction(
  'SELECT_USER',
  (value: User) => value,
)();

export const selectUserRole = createAction(
  'SELECT_USER_ROLE',
  (value: UserRole) => value,
)();

export const createUserRoleAsync = createAsyncAction(
  'CREATE_USER_ROLE_REQUEST',
  'CREATE_USER_ROLE_SUCCESS',
  'CREATE_USER_ROLE_FAILURE',
)<CreateUserRoleQuery, UserRole, string>();

export const deleteUserRoleAsync = createAsyncAction(
  'DELETE_USER_ROLE_REQUEST',
  'DELETE_USER_ROLE_SUCCESS',
  'DELETE_USER_ROLE_FAILURE',
)<UserRole, UserRole, string>();

export const updateUserRoleAsync = createAsyncAction(
  'UPDATE_USER_ROLE_REQUEST',
  'UPDATE_USER_ROLE_SUCCESS',
  'UPDATE_USER_ROLE_FAILURE',
)<UpdateUserRoleQuery, UserRole, string>();

export const selectUserGroup = createAction(
  'SELECT_USER_GROUP',
  (value: UserGroup) => value,
)();

export const createUserGroupAsync = createAsyncAction(
  'CREATE_USER_GROUP_REQUEST',
  'CREATE_USER_GROUP_SUCCESS',
  'CREATE_USER_GROUP_FAILURE',
)<CreateUserGroupQuery, UserGroup, string>();

export const deleteUserGroupAsync = createAsyncAction(
  'DELETE_USER_GROUP_REQUEST',
  'DELETE_USER_GROUP_SUCCESS',
  'DELETE_USER_GROUP_FAILURE',
)<User, User, string>();

export const updateUserGroupAsync = createAsyncAction(
  'UPDATE_USER_GROUP_REQUEST',
  'UPDATE_USER_GROUP_SUCCESS',
  'UPDATE_USER_GROUP_FAILURE',
)<UpdateUserGroupQuery, User, string>();

export const clearError = createAction('CLEAR_ERROR', () => { })();

