import {RootEpic} from 'StoreTypes';
import {from, of} from 'rxjs';
import {filter, switchMap, map, catchError} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {loadHomeMessageAsync, updateHomeMessageAsync, createHomeMessageAsync} from './actions';

export const homeMessageEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf([loadHomeMessageAsync.request, updateHomeMessageAsync.success, createHomeMessageAsync.success])),
    switchMap((action) => {
      return from(
        api.common.fetchMessage(state$.value.login.user.organization.id, 'HOME')
      ).pipe(
        map((messageResponse) => {
          return loadHomeMessageAsync.success(messageResponse);
        }),
        catchError((err) => of(loadHomeMessageAsync.failure(err))),
      );
    }),
  );

export const createHomeMessageEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf(createHomeMessageAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.createMessage(action.payload)
      ).pipe(
        map((response) => {
          return createHomeMessageAsync.success(response);
        }),
        catchError((err) => of(createHomeMessageAsync.failure(err))),
      );
    }),
  );

export const updateHomeMessageEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf(updateHomeMessageAsync.request)),
    switchMap((action) => {
      return from(
        api.admin.updateMessage(state$.value.home.homeMessage?.id ?? '', action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateHomeMessageAsync.success(updateResponse);
        }),
        catchError((err) => of(updateHomeMessageAsync.failure(err))),
      );
    }),
  );