import {RootEpic} from 'StoreTypes';
import {from, of, defer} from 'rxjs';
import {filter, switchMap, map, catchError} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {authenticateAsync, logoutAsync, refreshTokenAsync, loadUserAsync} from './actions';
import {updateAuthToken} from '../../services/admin-api-client';
import { User } from 'UserFeature';
import Api from '../../services/common-api-client';

export const authenticationEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf(authenticateAsync.request)),
    switchMap((action) => {
      return from(
        api.common.login(action.payload.token, action.payload.provider),
      ).pipe(
        map((authResponse) => {
          updateAuthToken(authResponse.accessToken);
          Api.updateAuthToken(authResponse.accessToken);
          return authenticateAsync.success(authResponse);
        }),
        catchError((err) => of(authenticateAsync.failure(err))),
      );
    }),
  );

export const loadUserEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf([loadUserAsync.request])),
    switchMap((_action) =>
      defer(() => from(api.common.fetchUser(state$.value.login.user.id))).pipe(
        map((data) => data as User),
        map(loadUserAsync.success),
        catchError((error) => of(loadUserAsync.failure(error.message))),
      ),
    ),
  );

export const logoutEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf(logoutAsync.request)),
    map((_action) => {
      return logoutAsync.success();
    }),
  );

export const refreshAuthTokenEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf(refreshTokenAsync.request)),
    switchMap((action) => {
      return from(
        api.auth.refreshAuthToken(
          state$.value.login.authtoken.refreshToken ?? '',
        ),
      ).pipe(
        map((authToken) => {
          updateAuthToken(authToken.token);
          return refreshTokenAsync.success(authToken);
        }),
        catchError((message) => of(refreshTokenAsync.failure(message))),
      );
    }),
  );
