import React, { useEffect, useMemo, useState } from 'react';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import { loadUserAsync, logoutAsync } from '@features/login/actions';
import config from '../config'
import styled, { useTheme } from 'styled-components/native';
import { loadHomeMessageAsync, clearError } from "../features/home/actions";
import moment from 'moment';
import MessageView from '@features/message/components/MessageView';
import { localize } from '../localization';
import { Divider, Button, StyledText, ModalView, Dialog, ThemeUtil } from '@space/common';
import Menu from '@features/menu/components/Menu'
import { loadBookingAsync } from '@features/booking/actions';
import { Booking } from 'BookingFeature';
import _ from 'lodash';
import AvatarBookingCarousel from '@components/AvatarBookingCarousel';
import { User } from 'UserFeature';
import { fetchAllSpacesAsync, fetchSpaceGroupsAsync, fetchSpacesAsync, fetchSpaceTypesAsync } from '@features/space/actions';
import { fetchOrganizationAsync } from '@features/organization/actions';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import theme from 'styled-theming';
import {
  colors,
  palette,
} from '@features/preferences/style/themes';
import SpaceStatsDayFiguresView from '@components/spaceStats/SpaceStatsFiguresView';
import SpaceStatsForTypeList from '@components/spaceStats/SpaceStatsForTypeList';
import VerticalDivider from '@components/VerticalDivider';
import SpaceStatsDayList from '@components/spaceStats/SpaceStatsDayList';
import { BookingsViewName } from './BookingsView';
import { SpacesViewName } from './SpacesView';
import SpacingView from '@components/SpacingView';
import SpaceHelper from '../utils/SpaceHelper';
import { Space } from 'SpaceFeature';

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  homeMessage: state.home.homeMessage,
  homeMessageText: state.home.homeMessageValue,
  modalError: state.home.setHomeMessageError,
  homeMessageError: state.home.loadHomeMessageError,
  bookings: state.booking.bookings,
  selectedDate: state.booking.selectedDate,
  spaces: state.space.space,
  availableSpacesCache: state.space.availableSpacesCache,
  allSpaces: state.space.allSpaces,
  spaceTypes: state.space.spaceTypes,
});

const dispatchProps = {
  logout: logoutAsync.request,
  loadHomeMessage: loadHomeMessageAsync.request,
  clearError: clearError,
  fetchBookings: loadBookingAsync.request,
  fetchSpaces: fetchSpacesAsync.request,
  fetchSpaceGroups: fetchSpaceGroupsAsync.request,
  fetchAllSpaces: fetchAllSpacesAsync.request,
  fecthSpaceTypes: fetchSpaceTypesAsync.request,
  fetchOrganization: fetchOrganizationAsync.request,
  fetchUser: loadUserAsync.request
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const HomeView: React.FC<Props> = ({
  user,
  loadHomeMessage,
  homeMessage,
  homeMessageText,
  modalError,
  homeMessageError,
  clearError,
  bookings,
  selectedDate,
  fetchBookings,
  fetchSpaces,
  fetchSpaceGroups,
  fetchAllSpaces,
  fecthSpaceTypes,
  spaceTypes,
  spaces,
  availableSpacesCache,
  allSpaces,
  fetchOrganization,
  fetchUser
}) => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const defaultDateFormat = 'YYYY-MM-DD';
  const today = moment().startOf('day').format(config.defaultDateFormat);
  const nextDay = moment().startOf('day').add(1, 'day').format(config.defaultDateFormat);
  const dayAfterTomorrow = moment().startOf('day').add(2, 'day').format(config.defaultDateFormat);
  const [displayEditMessage, openEditMessage] = useState(false);

  useEffect(() => {
    loadHomeMessage();
    const startDate = moment().utc().format(config.defaultDateFormat);
    const endDate = moment().utc().add(3, 'w').format(config.defaultDateFormat);
    fetchOrganization();
    fecthSpaceTypes();
    fetchBookings({ startDate, endDate });
    fetchSpaces();
    fetchSpaceGroups();
    fetchUser();

    const startOfDay = moment().utc().startOf('day').format(config.defaultDateFormat);
    const endOfDate = moment().utc().endOf('day').format(config.defaultDateFormat);
    fetchSpaces({ startOfDay, endOfDate });
    fetchAllSpaces();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newMessageButtonPressed = () => {
    openEditMessage(true);
  };

  const styleName = useTheme().mode;
  const background = theme('mode', {
    light: colors.grey1,
    dark: colors.black,
  });

  const users: User[] = useMemo(
    () =>
      _.uniqBy(
        bookings.reduce((result: User[], item: Booking) => {
          if (
            today ===
            moment(item.schedule.startDate).format(config.defaultDateFormat)
          ) {
            item.users?.map((user) => {
              user.todayBooking = item
              return user
            })
            return result.concat(item.users);
          }
          return result;
        }, []),
        (user) => user.email,
      ),
    [bookings],
  );

  const bookedSpaces: string[] = useMemo(
    () =>
      _.uniqBy(
        bookings.reduce((result: string[], item: Booking) => {
          if (
            today ===
            moment(item.schedule.startDate).format(config.defaultDateFormat)
          ) {
            return result.concat(item.spaces.map((map) => {
              return map.id
            }));
          }
          return result;
        }, []),
        (item) => item,
      ),
    [bookings],
  );

  const percentOccupation = spaces.length > 0 ? bookedSpaces.length / spaces.length * 100 : 0;

  const activeSpaces = useMemo(() => spaces.filter((space: Space) => {
    return availableSpacesCache.get(selectedDate).openSpaces.get(space.id) ?? false;
  }).length, [availableSpacesCache, spaces]);

  return (
    <Container>
      <Menu />
      <ContentContainer>
        <TopContainer>
          <TopLeftContainer>
            <StyledText variant={'h1'}>{localize('HELLO_USER', { name: user.firstName })}</StyledText>
            <TopHeaderContainer>
              {homeMessageError ? <StyledText variant={'body1'}>{homeMessageError}</StyledText> : <><StyledText style={{ marginBottom: 4 }} variant={'body4'} color={'hint'}>{homeMessage !== null ? localize('HOME_MESSAGE_TITLE') : localize('HOME_EMPTY_MESSAGE_TITLE')}</StyledText>
                <StyledText variant={'body1'} adjustsFontSizeToFit>{homeMessageText}</StyledText></>
              }
            </TopHeaderContainer>
            <TopContentHeaderContainer>
              {homeMessage !== null ? <StyledText color={'hint'}>{localize('HOME_MESSAGE_INFO', { name: homeMessage.user?.firstName + ' ' + homeMessage.user?.lastName, date: moment(homeMessage.updatedAt).format(config.homeMessageFormat) })}</StyledText> : null}
            </TopContentHeaderContainer>
          </TopLeftContainer>
          <ButtonContainer>
            <Button variant={'monochrome'} inverted={true} onPress={newMessageButtonPressed} title={localize('NEW_MESSAGE')} />
          </ButtonContainer>
          <ModalView isVisible={displayEditMessage}>
            <MessageView messageKey={'HOME'} openEditMessage={openEditMessage}/>
          </ModalView>
          {modalError && (
            <Dialog
              isOpen={modalError !== null}
              title={localize('ERROR_DEFAULT')}
              onClose={() => clearError()}
              onbuttonAction={() => {
                clearError();
              }}
            />
          )}
        </TopContainer>
        <Divider />
        <TodaySpaceStatsContainer>
          <StyledText variant={'h2'} color={'primary'}>{localize('HOME_TODAY_AT_OFFICE')}</StyledText>
          <SpacingView height={'16px'} />
          <RowContent>
            <TodayLeftContainer>
              <SpacingView height={'16px'} />
              <StyledText variant={'h3'} color={'primary'}>{localize('HOME_CAPACITY')}</StyledText>
              <StatsContainer>
                <ProgressbarContainer onPress={() => { navigation.push(BookingsViewName); }}>
                  <CircularProgressbarWithChildren
                    value={percentOccupation}
                    background
                    styles={buildStyles({
                      strokeLinecap: 'round',
                      backgroundColor: ThemeUtil.getThemeValue(background)(styleName),
                      pathColor: ThemeUtil.getThemeValue(palette.primary)(styleName),
                      trailColor: ThemeUtil.getThemeValue(background)(styleName),
                    })}>
                    <StyledContent>
                      <StyledText fontSize={60} variant={'h1'} color={'primary'} letterSpacing={'0.1em'}>
                        {Math.round(percentOccupation) + '%'}
                      </StyledText>
                    </StyledContent>
                  </CircularProgressbarWithChildren>
                </ProgressbarContainer>
                <TodayRightContainer>
                  <TodayRightContainerStats>
                    <SpaceStatsDayFiguresView title={localize('HOME_BOOKED_SPACES')}
                      currentValue={bookedSpaces.length}
                      totalValue={spaces.length}
                      link={localize('MANAGE_BOOKINGS')}
                      onPress={() => {
                        navigation.push(BookingsViewName);
                      }} />
                  </TodayRightContainerStats>
                  <TodayRightContainerStats>
                    <SpaceStatsDayFiguresView title={localize('HOME_ACTIVE_SPACES')}
                      currentValue={activeSpaces}
                      totalValue={allSpaces.length}
                      link={localize('MANAGE_SPACES')}
                      onPress={() => {
                        navigation.push(SpacesViewName);
                      }} />
                  </TodayRightContainerStats>
                </TodayRightContainer>
              </StatsContainer>
            </TodayLeftContainer>
            <VerticalDivider variant={'middle'} />
            <TodayRightContainer>
              <SpaceStatsForTypeListContainer>
                <SpaceStatsForTypeList date={today} spaceTypes={spaceTypes} />
              </SpaceStatsForTypeListContainer>
            </TodayRightContainer>
          </RowContent>
        </TodaySpaceStatsContainer>
        <AvatarBookingCarousel
          users={users}
          onPress={(user) => {
            navigation.push(BookingsViewName, { bookingId: user.todayBooking?.id });
          }}
        />
        <SpacingView height={'28px'} />
        <Divider />
        <RowContent>
          <StyledSpaceStatsDayList>
            <SpaceStatsDayList
              date={nextDay}
              spaceTypes={spaceTypes}
              onModelPress={(model) => { navigation.push(BookingsViewName, { model: model }); }}
              onPress={() => { navigation.push(BookingsViewName); }} />
          </StyledSpaceStatsDayList>
          <VerticalDivider />
          <StyledSpaceStatsDayList>
            <SpaceStatsDayList
              date={dayAfterTomorrow}
              spaceTypes={spaceTypes}
              onModelPress={(model) => { navigation.push(BookingsViewName, { model: model }); }}
              onPress={() => { navigation.push(BookingsViewName); }} />
          </StyledSpaceStatsDayList>
        </RowContent>
      </ContentContainer>
    </Container >
  );
};

export const HomeViewName = 'HomeView';

const Container = styled.SafeAreaView`
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  flex-shrink: 1;
  background-color: ${palette.background};
`;

const ContentContainer = styled.ScrollView`
  height: 100vh;
  background-color: ${palette.background};
`;

const TopContainer = styled.View`
  flex-direction: row;
  align-items: flex-end;
  min-height: 140px;
  margin: 40px 48px 34px 48px;
`;

const TopLeftContainer = styled.View`
  margin: 0px 24px 0px 0px;
  flex-grow: 1;
  flex-shrink: 1;
`;

const TopHeaderContainer = styled.View`
  margin: 25px 0px 4px 0px;
`;

const TopContentHeaderContainer = styled.View`
  margin: 0px 0px 24px 0px;
  max-width: 600px;
`;

const ButtonContainer = styled.View`
  margin: 8px;
  align-self: center;
  min-width: 200px;
`;

const StyledContent = styled.View`

`;

const TodaySpaceStatsContainer = styled.View`
  margin: 48px 48px 0px 48px;
`;

const RowContent = styled.View`
  flex-direction: row;
`;

const TodayLeftContainer = styled.View`
  width: 50%;
  min-width: 500px;
`;

const StatsContainer = styled.View`
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  flex-direction: row;
`;

const ProgressbarContainer = styled.TouchableOpacity`
  width: 200px;
  height: 200px;
`;

const TodayRightContainer = styled.View`
  height: 240px;
  width: 50%;
`;

const SpaceStatsForTypeListContainer = styled.View`
  align-items: flex-start;
  height: 200px;
`;

const TodayRightContainerStats = styled.View`
  height: 50%;
`;

const OtherDaysSpaceStatsContainer = styled.View`
  height: 100vh;
  background-color: ${palette.background};
`;

const TemporaryFooter = styled.View`
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

const StyledSpaceStatsDayList = styled.View`
  width: 50%;
`;

export default connect(mapStateToProps, dispatchProps)(HomeView);
