import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Space, SpaceGroup, SpaceTag, SpaceType, SpaceCache } from 'SpaceFeature';
import { SpaceModel } from '@features/space';
import {
  fetchAllSpacesAsync,
  fetchSpaceGroupsAsync,
  fetchSpaceTypesAsync,
  fetchSpacesAsync,
  fetchSpaceTagsAsync,
  showSpaceTagEditor,
  showSpaceTypeEditor,
  createSpaceTagAsync,
  updateSpaceTagAsync,
  deleteSpaceTagAsync,
  clearSpaceError,
  createSpaceTypeAsync,
  updateSpaceTypeAsync,
  deleteSpaceTypeAsync,
  selectSpaceTag,
  selectSpaceType,
  showConfirmSpaceTagDelete,
  showConfirmSpaceDelete,
  showConfirmSpaceDisabled,
  createSpaceAsync,
  updateSpaceAsync,
  deleteSpaceAsync,
  selectSpace,
  createSpaceGroupAsync,
  updateSpaceGroupAsync,
  deleteSpaceGroupAsync,
  selectSpaceGroup,
  showConfirmSpaceGroupDelete,
  showConfirmSpaceGroupDisabled,
  showConfirmSpaceTypeDelete,
  showWarningPodActivation,
  showEditSpace,
  setEditSpaceType,
} from './actions';
import { SpaceEditorType } from './components/SpaceEditorView';
import { weeksForOfficeInAdvanceBookingLimit, openDates } from "@space/common";
import moment from 'moment';
import _ from "lodash";
import SpaceHelper from '../../utils/SpaceHelper';

const reducer = combineReducers({
  isLoadingAllSpaces: createReducer(false as boolean)
    .handleAction([fetchAllSpacesAsync.request], (state, action) => true)
    .handleAction(
      [fetchAllSpacesAsync.success, fetchAllSpacesAsync.failure],
      (state, action) => false,
    ),

  allSpaces: createReducer([] as Space[])
    .handleAction(fetchAllSpacesAsync.success, (state, action) => {
      return action.payload;
    })
    .handleAction(createSpaceAsync.success, (state, action) => {
      return [...state, action.payload];
    })
    .handleAction(updateSpaceAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    })
    .handleAction(deleteSpaceAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  allSpacesError: createReducer('')
    .handleAction(
      [fetchAllSpacesAsync.failure],
      (state, action) => action.payload,
    )
    .handleAction([fetchAllSpacesAsync.request], (state, action) => ''),

  isLoading: createReducer(false as boolean)
    .handleAction([fetchSpacesAsync.request], (state, action) => true)
    .handleAction(
      [fetchSpacesAsync.success, fetchSpacesAsync.failure],
      (state, action) => false,
    ),

  space: createReducer([] as Space[])
    .handleAction(fetchSpacesAsync.success, (state, action) => {
      return action.payload.sort((a, b) => (a.name > b.name) ? 1 : -1);
    })
    .handleAction(createSpaceAsync.success, (state, action) => {
      return [...state, action.payload];
    })
    .handleAction(updateSpaceAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    })
    .handleAction(deleteSpaceAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  
  availableSpacesCache: createReducer(new Map() as Map<string, SpaceCache>).handleAction(
    fetchSpacesAsync.success,
    (state, action) => {

      var cache:Map<string, SpaceCache> = new Map();

      const firstSpace = _.first(action.payload) as Space;
      if(firstSpace != undefined) {
        const office = firstSpace.office;
        const officeBookingWeeks = weeksForOfficeInAdvanceBookingLimit(office.inAdvanceBookingLimit);
        const officeOpenDates = openDates(office.rrule, officeBookingWeeks);

        officeOpenDates.forEach((value: boolean, dateKey: string) => {

          var spaceIsBookable:Map<string, boolean> = new Map();
          var occupancies:Map<string, Occupancy | null> = new Map();

          if(!value) {
            action.payload.map((space: Space)=>{
              spaceIsBookable.set(space.id, false);

              occupancies.set('all', null);
              occupancies.set('seat', null);
              occupancies.set('room', null);
              occupancies.set('pod', null);
            });
          } else {
            const mDate = moment(dateKey);
            action.payload.map((space: Space)=>{
              spaceIsBookable.set(space.id, SpaceHelper.isOpenSpace(space, mDate));
            });

            const allOccupancy = SpaceHelper.occupationForDate(action.payload, spaceIsBookable, dateKey);
            occupancies.set('all', allOccupancy);
            const seatOccupancy = SpaceHelper.occupationForDate(action.payload, spaceIsBookable, dateKey, SpaceModel.SEAT);
            occupancies.set('seat', seatOccupancy);
            const roomOccupancy = SpaceHelper.occupationForDate(action.payload, spaceIsBookable, dateKey, SpaceModel.ROOM);
            occupancies.set('room', roomOccupancy);
            const podOccupancy = SpaceHelper.occupationForDate(action.payload, spaceIsBookable, dateKey, SpaceModel.POD);
            occupancies.set('pod', podOccupancy);
          }

          cache.set(dateKey, {openSpaces: spaceIsBookable, occupancies: occupancies});
        });
      }
      return cache;
    },
  ),

  spaceDeleted: createReducer<Space | null>(null).handleAction(
    deleteSpaceAsync.success,
    (state, action) => action.payload,
  ),

  setSpaceError: createReducer<string | null>(null)
    .handleAction(
      [createSpaceAsync.failure, updateSpaceAsync.failure],
      (state, action) => action.payload,
    )
    .handleAction(clearSpaceError, (state, action) => null),

  isLoadingSpaceGroups: createReducer<boolean>(false)
    .handleAction([fetchSpaceGroupsAsync.request], (state, action) => true)
    .handleAction(
      [fetchSpaceGroupsAsync.success, fetchSpaceGroupsAsync.failure],
      (state, action) => false,
    ),

  spaceGroupsError: createReducer('')
    .handleAction(
      [fetchSpaceGroupsAsync.failure],
      (state, action) => action.payload,
    )
    .handleAction([fetchSpaceGroupsAsync.request], (state, action) => ''),

  spaceGroups: createReducer([] as SpaceGroup[]).handleAction(
    fetchSpaceGroupsAsync.success,
    (state, action) => {
      return action.payload;
    },
  ).handleAction(createSpaceGroupAsync.success, (state, action) => {
    return [...state, action.payload];
  })
    .handleAction(updateSpaceGroupAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    })
    .handleAction(deleteSpaceGroupAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  setSpaceGroupError: createReducer<string | null>(null)
    .handleAction(
      [
        createSpaceGroupAsync.failure,
        updateSpaceGroupAsync.failure
      ],
      (state, action) => action.payload,
    )
    .handleAction(clearSpaceError, (state, action) => null),

  spaceGroupDeleted: createReducer<SpaceGroup | null>(null).handleAction(
    deleteSpaceGroupAsync.success,
    (state, action) => action.payload,
  ),

  spaceGroupUpdated: createReducer<SpaceGroup | null>(null).handleAction(
    updateSpaceGroupAsync.success,
    (state, action) => action.payload,
  ),

  isLoadingSpaceTypes: createReducer<boolean>(false)
    .handleAction([fetchSpaceTypesAsync.request], (state, action) => true)
    .handleAction(
      [fetchSpaceTypesAsync.success, fetchSpaceTypesAsync.failure],
      (state, action) => false,
    ),

  spaceTypesError: createReducer('')
    .handleAction(
      [fetchSpaceTypesAsync.failure],
      (state, action) => action.payload,
    )
    .handleAction([fetchSpaceTypesAsync.request], (state, action) => ''),

  spaceTypes: createReducer([] as SpaceType[]).handleAction(
    fetchSpaceTypesAsync.success,
    (state, action) => {
      return action.payload;
    },
  ).handleAction(createSpaceTypeAsync.success, (state, action) => {
    return [...state, action.payload];
  })
    .handleAction(updateSpaceTypeAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    })
    .handleAction(deleteSpaceTypeAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  error: createReducer('')
    .handleAction(fetchSpacesAsync.failure, (state, action) => action.payload)
    .handleAction(fetchSpacesAsync.request, (state, action) => ''),

  isLoadingSpaceTags: createReducer<boolean>(false)
    .handleAction([fetchSpaceTagsAsync.request], (state, action) => true)
    .handleAction(
      [fetchSpaceTagsAsync.success, fetchSpaceTagsAsync.failure],
      (state, action) => false,
    ),

  spaceTagsError: createReducer('')
    .handleAction(
      [fetchSpaceTagsAsync.failure],
      (state, action) => action.payload,
    )
    .handleAction([fetchSpaceTagsAsync.request], (state, action) => ''),

  spaceTags: createReducer([] as SpaceTag[]).handleAction(
    fetchSpaceTagsAsync.success,
    (state, action) => {
      return action.payload;
    },
  ).handleAction(createSpaceTagAsync.success, (state, action) => {
    return [...state, action.payload];
  })
    .handleAction(updateSpaceTagAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];
    })
    .handleAction(deleteSpaceTagAsync.success, (state, action) => {
      return state.filter((item) => item.id !== action.payload.id);
    }),

  setSpaceTagError: createReducer<string | null>(null)
    .handleAction(
      [
        createSpaceTagAsync.failure,
        updateSpaceTagAsync.failure
      ],
      (state, action) => action.payload,
    )
    .handleAction(clearSpaceError, (state, action) => null),

  selectedSpaceTag: createReducer<SpaceTag | null>(null).handleAction(
    selectSpaceTag,
    (state, action) => action.payload,
  ).handleAction(createSpaceTagAsync.success, (state, action) => {
    return null;
  }).handleAction(updateSpaceTagAsync.success, (state, action) => {
    return null;
  }),

  selectedSpaceType: createReducer<SpaceType | null>(null).handleAction(
    selectSpaceType,
    (state, action) => action.payload,
  ).handleAction(createSpaceTypeAsync.success, (state, action) => {
    return null;
  }).handleAction(updateSpaceTypeAsync.success, (state, action) => {
    return null;
  }),

  userGroupDeleted: createReducer<SpaceTag | null>(null).handleAction(
    deleteSpaceTagAsync.success,
    (state, action) => action.payload,
  ),

  isSpaceTagEditorViewDisplayed: createReducer<boolean>(false).handleAction(
    [showSpaceTagEditor],
    (state, action) => action.payload,
  ),

  isConfirmSpaceTagDeleteViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmSpaceTagDelete],
    (state, action) => action.payload,
  ),

  selectedSpace: createReducer<Space | null>(null)
    .handleAction(
      selectSpace,
      (state, action) => action.payload,
    ).handleAction(createSpaceAsync.success, (state, action) => {
      return null;
    }),

  selectedSpaceGroup: createReducer<SpaceGroup | null>(null)
    .handleAction(
      selectSpaceGroup,
      (state, action) => action.payload,
    ).handleAction(createSpaceGroupAsync.success, (state, action) => {
      return null;
    }),

  isConfirmSpaceDeleteViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmSpaceDelete],
    (state, action) => action.payload,
  ).handleAction(
    [deleteSpaceAsync.success, deleteSpaceAsync.failure],
    (_state, _action) => false,
  ),

  isConfirmSpaceDisabledViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmSpaceDisabled],
    (state, action) => action.payload,
  ).handleAction(
    [updateSpaceAsync.success, updateSpaceAsync.failure],
    (_state, _action) => false,
  ),

  isConfirmSpaceGroupDeleteViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmSpaceGroupDelete],
    (state, action) => action.payload,
  ).handleAction(
    [deleteSpaceGroupAsync.success, deleteSpaceGroupAsync.failure],
    (_state, _action) => false,
  ),

  isConfirmSpaceGroupDisabledViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmSpaceGroupDisabled],
    (state, action) => action.payload,
  ).handleAction(
    [updateSpaceGroupAsync.success, updateSpaceGroupAsync.failure],
    (_state, _action) => false,
  ),

  isSpaceEditorViewDisplayed: createReducer<boolean>(false).handleAction(
    [showEditSpace],
    (state, action) => action.payload,
  ).handleAction(createSpaceAsync.success, (state, action) => {
    return false;
  }).handleAction(createSpaceGroupAsync.success, (state, action) => {
    return false;
  }),

  isSpaceTypeEditorViewDisplayed: createReducer<boolean>(false).handleAction(
    [showSpaceTypeEditor],
    (state, action) => action.payload,
  ),

  selectedEditSpaceType: createReducer<SpaceEditorType | null>(null).handleAction(
    setEditSpaceType,
    (state, action) => action.payload,
  ),

  isConfirmSpaceTypeDeleteViewDisplayed: createReducer<boolean>(false).handleAction(
    [showConfirmSpaceTypeDelete],
    (state, action) => action.payload,
  ),

  isWarningPodActivationViewDisplayed: createReducer<boolean>(false).handleAction(
    [showWarningPodActivation],
    (state, action) => action.payload,
  ),
});

export default reducer;
