import React, { useState } from "react";
import { connect } from "react-redux";
import styled, { useTheme } from 'styled-components/native';
import { RootState } from 'StoreTypes';
import { colors, palette } from '../../preferences/style/themes';
import { localize } from '../../../localization';
import { updateHomeMessageAsync, createHomeMessageAsync } from '../../../features/home/actions'
import { StyledText, Button, ThemeUtil } from '@space/common';
import { Formik } from 'formik';
import { LocalizedMessage } from 'MessageFeature';
import ExpandableSingleChoice from "@components/form/ExpandableSingleChoice";
import _ from 'lodash';
import { Language } from 'OrganizationFeature';
import Warning from '../../../img/warning.svg';
import SvgIcon from '@components/SvgIcon';
import isoLanguageCodes from '../../../utils/isoLanguageCodes';

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  homeMessage: state.home.homeMessage,
  homeMessageText: state.home.homeMessageValue,
  languages: state.organization.languages,
  organization: state.organization.organization
});

const dispatchProps = {
  updateHomeMessage: updateHomeMessageAsync.request,
  createHomeMessage: createHomeMessageAsync.request
};

type MessageProps = {
  messageKey: string;
  openEditMessage: (active: boolean) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  MessageProps;

const MessageView: React.FC<Props> = ({
  createHomeMessage,
  homeMessage,
  messageKey,
  languages,
  updateHomeMessage,
  user,
  openEditMessage
}) => {
  
  const styleName = useTheme().mode;
  const [language, setLanguage] = useState<string>(languages.find((language) => language.name.substring(0,2) === localize('LOCALE_LANGUAGE')).name);

  const cancelButtonPressed = () => {
    openEditMessage(false);
  };

  const updateMessage = (messages: LocalizedMessage[]) => {
    const newMessage = { organizationId: user.organization.id, userId: user.id, key: messageKey, content: messages }
    if (homeMessage === null) {
      createHomeMessage(newMessage);
    } else {
      updateHomeMessage(newMessage);
    }
    openEditMessage(false);
  }

  const initialValues: { messages: LocalizedMessage[] } = {
    messages: homeMessage?.content ?? languages.map(m => ({ language: m.name, message: '' }))
  };

  return (
    <Container>
      <HeaderContainer>
        <StyledText variant={'body2'}>{localize('EDIT_MESSAGE_TITLE')}</StyledText>
      </HeaderContainer>

      <Formik
        initialValues={initialValues}
        onSubmit={values => updateMessage(values.messages)}
      >
        {({ handleChange, handleBlur, handleSubmit, values, dirty }) => {
          const currentLanguageIndex = values.messages.findIndex((item: LocalizedMessage) => item.language == language);

          return (
            <>
              <ExpandableSingleChoice
                items={languages.map((lang: Language) => ({ label: isoLanguageCodes[lang.name], id: lang.id.toString() }))}
                selectedValue={languages[currentLanguageIndex].id.toString()}
                placeholder={''}
                title={localize('LANGUAGE')}
                onItemSelected={(item) => {
                  setLanguage(languages.find((lang: Language) => lang.id.toString() == item).name);
                }}
              />

              <WarningText>
                <WarningIconContainer>
                  <SvgIcon
                    svg={Warning}
                    width={16}
                    height={16}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)}
                  />
                </WarningIconContainer>
                <StyledText
                  variant={'caption'}
                  color={'hint'}
                >
                  {localize("PUBLISH_MESSAGE_WARNING")}
                </StyledText>
              </WarningText>

              <TextContainer>
                <InputTextContainer
                  key={`messages.${currentLanguageIndex}.message`}
                  value={values.messages[currentLanguageIndex].message}
                  multiline={true}
                  maxLength={280}
                  onChangeText={handleChange(`messages.${currentLanguageIndex}.message`)}
                />
                <StyledText variant={'caption'} color={'hint'}>{localize('MESSAGE_LIMIT')}</StyledText>
              </TextContainer>

              <ButtonContainer>
                <Button fontColor={colors.black} onPress={() => handleSubmit()} title={localize('PUBLISH')} disabled={!dirty} />
              </ButtonContainer>
            </>
          );
        }}
      </Formik>

      <ButtonContainer>
        <Button 
          onPress={cancelButtonPressed} 
          title={localize('CANCEL')} 
          variant={'light'} 
          disableElevation={true} 
          border={ThemeUtil.getThemeValue(palette.borderLight)(styleName)} 
          fontColor={colors.black} />
      </ButtonContainer>
    </Container>
  );
};

export default connect(mapStateToProps, dispatchProps)(MessageView);

const Container = styled.View`
  width: 100vw;
  max-width: 402px;
  padding: 32px 32px 32px 32px;
  background-color: ${palette.background};
`;

const HeaderContainer = styled.View`
  margin: 0px 0px 18px 0px;
`;

const TextContainer = styled.View`
  margin: 0px 0px 14px 0px;
`;
const InputTextContainer = styled.TextInput`
  min-height: 149px;
  padding: 16px 16px 16px 16px;
  background-color: ${palette.textInput};
  border-radius: 4px;
  margin: 0px 0px 4px 0px;
  color: ${palette.info};
`;

const ButtonContainer = styled.TouchableOpacity`
  margin: 8px 0px 0px 0px;
  width: 277px;
  align-self: center
`;

const WarningText = styled.View`
  flex-direction: row;
  padding: 5px;
`;

const WarningIconContainer = styled.View`
  margin-right: 5px;
`;
