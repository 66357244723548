import React, { useMemo, useEffect, useCallback } from "react";
import {
    FlatList,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import { connect } from "react-redux";
import styled from 'styled-components/native';
import { RootState } from 'StoreTypes';
import { palette, colors, shadow } from '../../preferences/style/themes';
import { Button } from '@space/common';
import { localize } from '../../../localization';
import { StyledText, Dialog } from '@space/common';
import { useState } from "react";
import _ from 'lodash';
import SvgIcon from '@components/SvgIcon';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { selectUserRole } from "../actions";
import StyledTextInput from "@components/form/StyledTextInput";
import SimpleDeleteRow from "@components/list/SimpleDeleteRow";
import Close from '../../../img/close.svg';
import { UserRoleType } from "..";
import { createUserRoleAsync, updateUserRoleAsync, deleteUserRoleAsync, showConfirmUserRoleDelete, clearError } from "../actions";
import VerticalDivider from '@components/VerticalDivider';
import { UserRole } from './UserFeature';

type UserRoleProps = {
    openUserRolesEditorModal: (active: boolean) => void;
};

const mapStateToProps = (state: RootState) => ({
    roles: state.user.userRoles,
    selectedUserRole: state.user.selectedUserRole,
    isConfirmUserRoleDeleteViewDisplayed: state.user.isConfirmUserRoleDeleteViewDisplayed,
    modalError: state.user.setUserRoleError,
    activeLanguages: state.organization.languages
});

const dispatchProps = {
    createUserRole: createUserRoleAsync.request,
    updateUserRole: updateUserRoleAsync.request,
    deleteUserRole: deleteUserRoleAsync.request,
    showConfirmUserRoleDeleteModal: showConfirmUserRoleDelete,
    selectUserRole: selectUserRole,
    clearError: clearError
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    UserRoleProps;

const UserRoleEditorView: React.FC<Props> = ({
    roles,
    selectedUserRole,
    activeLanguages,
    createUserRole,
    updateUserRole,
    deleteUserRole,
    selectUserRole,
    showConfirmUserRoleDeleteModal,
    isConfirmUserRoleDeleteViewDisplayed,
    modalError,
    clearError,
    openUserRolesEditorModal
}) => {

    const newRole = { id: '', type: UserRoleType.USER, name: [], maxReservationNumber: '' };
    const [currentUserRole, setUserRole] = useState(newRole);
    const savePressed = () => {

        if (selectedUserRole) {
            const updatedUserRole = {
                id: currentUserRole.id,
                name: currentUserRole.name,
                type: currentUserRole.type,
                maxReservationNumber: parseInt(currentUserRole.maxReservationNumber),
            };
            updateUserRole(updatedUserRole);
        } else {
            const createdUserRole = {
                name: currentUserRole.name,
                type: UserRoleType.USER,
                maxReservationNumber: parseInt(currentUserRole.maxReservationNumber),
            };
            createUserRole(createdUserRole);
        }

        resetField();
    };

    const onCancelEditPress = () => {
        selectUserRole(null);
        resetField();
    };

    const resetField = () => {
        currentUserRole.id = '';
        currentUserRole.name = [{}];
        currentUserRole.type = UserRoleType.USER;
        currentUserRole.maxReservationNumber = '';
        setUserRole(currentUserRole);
    }

    const renderCancelEdit = () => {
        return (
            <StyledButton onPress={onCancelEditPress} variant={'cancel'} title={localize('CANCEL')} fontColor={colors.black} />
        );
    };

    const UserRoleSchema = Yup.object().shape({
        name: Yup.array().of(
            Yup.object().shape({
                languageId: Yup.number().required(localize('REQUIRED')),
                name: Yup.string().required(localize('REQUIRED')),
                title: Yup.string().required(localize('REQUIRED'))
            })
        ).required(localize('INVALID_NAME')),
        maxReservationNumber: Yup.number().required(localize('REQUIRED'))
    });

    const renderUserGroupForm = () => {
        const defaultName = activeLanguages.map((language: Language) => ({
            languageId: language.id,
            name: currentUserRole.name?.find(i=>i.languageId === language.id)?.name ?? '',
            title: localize(language.name)
        }));
        return (
        <Formik
            enableReinitialize={true}
            validationSchema={UserRoleSchema}
            initialValues={{
                name: defaultName,
                maxReservationNumber: currentUserRole.maxReservationNumber,
                type: currentUserRole.type
            }}
            onSubmit={(values, { resetForm, setFieldValue, setFieldTouched }) => {
                currentUserRole.name = values.name;
                currentUserRole.maxReservationNumber = values.maxReservationNumber;
                currentUserRole.type = values.type;
                setUserRole(currentUserRole);
                savePressed();
                setFieldValue('values', {
                    name: values.name.map((newName) => ({ name: newName.name, languageId: newName.languageId })),
                });
                setFieldTouched('values', false);
                resetForm({ values: { name: defaultName, maxReservationNumber: '', type: UserRoleType.USER } });
            }}
        >
            {({ handleChange, handleSubmit, setFieldValue, values, errors, touched }) => {
                const submitDisabled = useMemo(() => {
                    return !values.name.every(value => value.name !== '') || values.maxReservationNumber === '';
                }, [values]);

                return (
                    <FormContainer>
                        <FieldContainer>
                            <FieldArray name="name">
                            {() => (
                                <>
                                {values.name.map((name, index) => {
                                    const title = localize(`${name.title}`);
                                    return (
                                    <div key={name.languageId}>
                                        <StyledTextInput
                                        value={name.name}
                                        placeholder={localize('PLACEHOLDER_NAME', {title: title})}
                                        onChangeText={handleChange(`name.${index}.name`)}
                                        title={title}
                                        multiline={false}
                                        maxLength={185}
                                        />
                                    </div>
                                    );
                                })}
                                </>
                            )}
                            </FieldArray>
                        </FieldContainer>

                        <StyledTextInput
                            title={localize('MAX_RESERVATION')}
                            placeholder={'###'}
                            multiline={false}
                            maxLength={275}
                            width={275}
                            onChangeText={handleChange('maxReservationNumber')}
                            error={errors.maxReservationNumber ? localize('ERROR_NUMBER') : null}
                            touched={touched.maxReservationNumber}
                            value={values.maxReservationNumber} />

                        <ButtonContainer>
                            <Button onPress={handleSubmit} title={selectedUserRole ? localize('UPDATE') : localize('CREATE')} disabled={!_.isEmpty(errors) || submitDisabled} fontColor={colors.black} />
                            {selectedUserRole ? renderCancelEdit() : null}
                        </ButtonContainer>
                    </FormContainer>
                );
            }}
        </Formik>
    )};

    const cancelButtonPressed = () => {
        selectUserRole(null);
        openUserRolesEditorModal(false);
    };

    const onRowPress = (item: RowItem) => {
        const role = roles.filter((role: UserRole) => role.id === item.id)[0];
        selectUserRole(role);
        currentUserRole.id = role.id;
        currentUserRole.name = role.name;
        currentUserRole.type = role.type;
        currentUserRole.maxReservationNumber = role.maxReservationNumber ?? 0;
        setUserRole(currentUserRole);
    };

    const onDeletePress = (item: RowItem) => {
        const role = roles.filter((role: UserRole) => role.id === item.id)[0]
        selectUserRole(role);
        showConfirmUserRoleDeleteModal(true);
    };

    const renderUserItem = ({ item }) => {
        return (
            <SimpleDeleteRow item={{ id: item.id, label: item.name }} disabled={item.isSystem} onRowPress={onRowPress} onDeletePress={onDeletePress} />
        );
    };

// Exiting modal with escape key
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
        document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, []);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
        cancelButtonPressed();
        }
    },[]);

    return (
        <Container>
            <ContentContainer>
                <HeaderContainer>
                    <Title>
                        <StyledText variant={'h2'}>{localize('MANAGE_USER_ROLE')}</StyledText>
                    </Title>
                    <CloseContainer onPress={cancelButtonPressed}>
                        <SvgIcon svg={Close} inverted={true} />
                    </CloseContainer>
                </HeaderContainer>
                <ScrollView>
                    <MainContainer>
                        <ColumnContainer>
                            <ColumnTitle>
                                <StyledText variant={'h3'} color={'hint'}>
                                    {selectedUserRole ? localize('MODIFY_ROLE') : localize('CREATE_ROLE')}
                                </StyledText>
                            </ColumnTitle>
                            {renderUserGroupForm()}
                        </ColumnContainer>
                        <VerticalDivider variant={'middle'} />
                        <ColumnRightContainer>
                            <ColumnRightTitle>
                                <StyledText variant={'h3'} color={'hint'}>
                                    {localize('EXISTING_ROLE')}
                                </StyledText>
                            </ColumnRightTitle>
                            <FlatList
                                data={roles.sort((roleA, roleB) => (roleA.name < roleB.name ? -1 : 1))}
                                renderItem={renderUserItem}
                                keyExtractor={(item: UserRole) => item.id}
                            />
                        </ColumnRightContainer>
                    </MainContainer>
                </ScrollView>
            </ContentContainer>
            <Dialog
                isOpen={isConfirmUserRoleDeleteViewDisplayed}
                title={localize('DELETE_USER_ROLE')}
                onClose={() => {
                    showConfirmUserRoleDeleteModal(false);
                }}
                actionTitle={localize('DELETE')}
                cancelTitle={localize('CANCEL')}
                onbuttonAction={() => {
                    deleteUserRole(selectedUserRole);
                    selectUserRole(null);
                    resetField();
                    showConfirmUserRoleDeleteModal(false);
                }} />

            {modalError && (
                <Dialog
                    isOpen={modalError !== null}
                    title={localize('ERROR_DEFAULT')}
                    onClose={() => clearError()}
                    onbuttonAction={() => {
                        clearError();
                    }}
                />
            )}
        </Container >
    );
};

export default connect(mapStateToProps, dispatchProps)(UserRoleEditorView);

const Container = styled.View`
  width: 100vw;
  max-width: 800px;
  background-color: ${palette.background};
  max-height: 85vh;
  flex-direction: row;
`;

const ButtonContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 34px 0 0 8px;
  align-self: flex-start;
`;

const CloseContainer = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  padding: 32px 24px 24px 24px;
  background: ${palette.background};
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom-width: 1px;
  border-bottom: solid black;
`;

const Title = styled.View`
  padding-top: 24px;
  flex-grow: 1;
`;

const FormContainer = styled.View`
`;

const ColumnContainer = styled.View`
  margin: 0px 0px 14px 0px;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ColumnTitle = styled.View`
  padding-left: 16px;
`;

const ColumnRightContainer = styled.View`
  width: 300px;   
  margin: 0px 20px 14px 0px;
`;

const ColumnRightTitle = styled.View`
  padding: 0px 8px 8px 8px;
`;

const MainContainer = styled.View`
  padding: 0px 8px;
  flex-direction: row;
  justify-content: flex-end;
  margin: 22px 0px;
  display: flex;
  flex: auto;
`;

const FieldContainer = styled.View`
  flex-direction: column;
`;

const CheckedOnContainer = styled.View`
  background-color: ${palette.border};
  border-radius: 6px;
  width: 12px;
  height: 12px;
`;

const LinkContainer = styled.View`
  text-decoration-line: underline;
`;

const ContentContainer = styled.View`
  background-color: ${palette.background};
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin-left: 24px;
  border: 1px solid black;
`;
