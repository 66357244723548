import {createAction, createAsyncAction} from 'typesafe-actions';
import {Message, CreateMessageQuery} from 'MessageFeature';

export const setHomeMessage = createAction(
  'SET_HOME_MESSAGE',
  (message: string) => message,
)();

export const openMessageEditor = createAction(
  'OPEN_MESSAGE_EDITOR',
  () => true,
)();

export const closeMessageEditor = createAction(
  'CLOSE_MESSAGE_EDITOR',
  () => false,
)();

export const loadHomeMessageAsync = createAsyncAction(
  'LOAD_HOME_MESSAGE_REQUEST',
  'LOAD_HOME_MESSAGE_SUCCESS',
  'LOAD_HOME_MESSAGE_FAILURE',
)<void, Message, string>();

export const updateHomeMessageAsync = createAsyncAction(
  'SET_HOME_MESSAGE_REQUEST',
  'SET_HOME_MESSAGE_SUCCESS',
  'SET_HOME_MESSAGE_FAILURE',
)<CreateMessageQuery, Message, string>();

export const createHomeMessageAsync = createAsyncAction(
  'NEW_HOME_MESSAGE_REQUEST',
  'NEW_HOME_MESSAGE_SUCCESS',
  'NEW_HOME_MESSAGE_FAILURE',
)<CreateMessageQuery, Message, string>();

export const clearError = createAction('CLEAR_ERROR', () => {})();



