import React, { useEffect, useCallback, useMemo } from 'react';
import { FlatList, TouchableOpacity, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import styled from 'styled-components/native';
import { RootState } from 'StoreTypes';
import { palette, colors } from '../../preferences/style/themes';
import { Button } from '@space/common';
import { localize } from '../../../localization';
import { StyledText, Divider, Dialog } from '@space/common';
import { useState } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import SvgIcon from '@components/SvgIcon';
import { FieldArray, Formik } from 'formik';
import StyledTextInput from '@components/form/StyledTextInput';
import SimpleDeleteRow from '@components/list/SimpleDeleteRow';
import Close from '../../../img/close.svg';
import {
  createUserGroupAsync,
  updateUserGroupAsync,
  deleteUserGroupAsync,
  showConfirmUserGroupDelete,
  selectUserGroup,
  clearError,
} from '../actions';
import VerticalDivider from '@components/VerticalDivider';
import { UserGroup } from 'UserFeature';
import { Language } from 'OrganizationFeature';

type UserGroupProps = {
  openUserGroupsEditorModal: (active: boolean) => void;
};

const mapStateToProps = (state: RootState) => ({
  groups: state.user.userGroups,
  selectedUserGroup: state.user.selectedUserGroup,
  isConfirmUserGroupDeleteViewDisplayed:
    state.user.isConfirmUserGroupDeleteViewDisplayed,
  modalError: state.user.setUserGroupError,
  activeLanguages: state.organization.languages
});

const dispatchProps = {
  createUserGroup: createUserGroupAsync.request,
  updateUserGroup: updateUserGroupAsync.request,
  deleteUserGroup: deleteUserGroupAsync.request,
  showConfirmUserGroupDeleteModal: showConfirmUserGroupDelete,
  selectUserGroup: selectUserGroup,
  clearError: clearError,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  UserGroupProps;

const UserGroupEditorView: React.FC<Props> = ({
  groups,
  selectedUserGroup,
  isConfirmUserGroupDeleteViewDisplayed,
  modalError,
  activeLanguages,
  createUserGroup,
  updateUserGroup,
  deleteUserGroup,
  showConfirmUserGroupDeleteModal,
  selectUserGroup,
  clearError,
  openUserGroupsEditorModal
}) => {

  const newGroup = { id: '', name: [] };
  const [currentUserGroup, setUserGroup] = useState(selectedUserGroup ?? newGroup);

  const savePressed = () => {
    if (selectedUserGroup) {
      const updatedGroup = {
        id: currentUserGroup.id,
        name: currentUserGroup.name,
      };
      updateUserGroup(updatedGroup);
    } else {
      const createdUserGroup = {
        name: currentUserGroup.name,
      };
      createUserGroup(createdUserGroup);
    }
    resetField();
  };

  const onCancelEditPress = () => {
    selectUserGroup(null);
    resetField();
  };

  const renderCancelEdit = () => {
    return (
      <StyledButton onPress={onCancelEditPress} variant={'cancel'} title={localize('CANCEL')} fontColor={colors.black} />
    );
  };

  const resetField = () => {
    currentUserGroup.id = '';
    currentUserGroup.name = [{}];
    setUserGroup(currentUserGroup);
  };

  const renderUserGroupForm = () => {
    const defaultName = activeLanguages.map((language: Language) => ({
        languageId: language.id,
        name: currentUserGroup.name?.find(i=>i.languageId === language.id)?.name ?? '',
        title: localize(language.name)
    }));

    const UserGroupSchema = Yup.object().shape({
      name: Yup.array().of(
          Yup.object().shape({
              languageId: Yup.number().required(localize('REQUIRED')),
              name: Yup.string().required(localize('REQUIRED')),
              title: Yup.string().required(localize('REQUIRED'))
          })
      ).required(localize('INVALID_NAME')),
  });

    return (
      <Formik
        enableReinitialize={true}
        validationSchema={UserGroupSchema}
        initialValues={{
          name: defaultName,
        }}
        onSubmit={(values, { setFieldValue, setFieldTouched, resetForm }) => {
          currentUserGroup.name = values.name;
          setUserGroup(currentUserGroup);
          savePressed();
          setFieldValue('values', {
            name: values.name.map((newName) => ({ name: newName.name, languageId: newName.languageId })),
          });
          setFieldTouched('values', false);
          resetForm({ values: { name: defaultName } });
        }}
      >
        {({ handleChange, handleSubmit, values, errors }) => {
          const submitDisabled = useMemo(() => {
            return !values.name.every(value => value.name !== '');
        }, [values]);        

          return (
            <FormContainer>
              <FieldContainer>
                <FieldArray name="name">
                  {() => (
                    <div>
                      {values.name.map((name, index) => {
                        const title = localize(`${name.title}`);
                        return (
                          <div key={name.languageId}>
                            <StyledTextInput
                              value={name.name}
                              placeholder={localize('PLACEHOLDER_NAME', {title: title})}
                              onChangeText={handleChange(`name.${index}.name`)}
                              title={title}
                              multiline={false}
                              maxLength={185}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </FieldArray>
              </FieldContainer>

              <ButtonContainer>
                <Button
                  title={
                    selectedUserGroup ? localize('UPDATE') : localize('CREATE')
                  }
                  onPress={handleSubmit}
                  disabled={!_.isEmpty(errors) || submitDisabled}
                  fontColor={colors.black}
                />
                {selectedUserGroup ? renderCancelEdit() : null}
              </ButtonContainer>
            </FormContainer>
          );
        }}
      </Formik>
    );
  };

  const cancelButtonPressed = () => {
    selectUserGroup(null);
    openUserGroupsEditorModal(false)
  };

  const onRowPress = (item: RowItem) => {
    const group = groups.filter((group: UserGroup) => group.id === item.id)[0];
    selectUserGroup(group);
    currentUserGroup.id = group.id;
    currentUserGroup.name = group.name;
    setUserGroup(currentUserGroup);
  };

  const onDeletePress = (item: RowItem) => {
    const group = groups.filter((group: UserGroup) => group.id === item.id)[0];
    selectUserGroup(group);
    showConfirmUserGroupDeleteModal(true);
  };

  const renderUserGroupItem = ({ item }) => {
    return (
      <SimpleDeleteRow
        item={{ id: item.id, label: item.name}}
        onRowPress={onRowPress}
        onDeletePress={onDeletePress}
      />
    );
  };
  
// Exiting modal with escape key

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, []);
  
  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 27) {
      cancelButtonPressed();
    }
  },[]);
 
  return (
    <Container>
      <ContentContainer>
        <HeaderContainer>
          <Title>
            <StyledText variant={'h2'}>
              {localize('MANAGE_USER_GROUP')}
            </StyledText>
          </Title>
          <CloseContainer onPress={cancelButtonPressed}>
            <SvgIcon svg={Close} inverted={true} />
          </CloseContainer>
        </HeaderContainer>
        <ScrollView>
          <MainContainer>
            <ColumnContainer>
              <ColumnTitle>
                <StyledText variant={'h3'} color={'hint'}>
                  {selectedUserGroup
                    ? localize('MODIFY_GROUP')
                    : localize('CREATE_GROUP')}
                </StyledText>
              </ColumnTitle>
              {renderUserGroupForm()}
            </ColumnContainer>
            <VerticalDivider variant={'middle'} />
            <ColumnRightContainer>
              <ColumnRightTitle>
                <StyledText variant={'h3'} color={'hint'}>
                  {localize('EXISTING_GROUP')}
                </StyledText>
              </ColumnRightTitle>
              <FlatList
                data={groups.sort((groupA, groupB) =>
                  groupA.name < groupB.name ? -1 : 1
                )}
                renderItem={renderUserGroupItem}
                keyExtractor={(item: UserGroup) => item.id}
              />
            </ColumnRightContainer>
          </MainContainer>
        </ScrollView>
      </ContentContainer>
      <Dialog
        isOpen={isConfirmUserGroupDeleteViewDisplayed}
        title={localize('DELETE_USER_GROUP')}
        onClose={() => {
          showConfirmUserGroupDeleteModal(false);
        }}
        actionTitle={localize('DELETE')}
        cancelTitle={localize('CANCEL')}
        onbuttonAction={() => {
          deleteUserGroup(selectedUserGroup);
          selectUserGroup(null);
          resetField();
          showConfirmUserGroupDeleteModal(false);
        }}
      />
      {modalError && (
        <Dialog
          isOpen={modalError !== null}
          title={localize('ERROR_DEFAULT')}
          onClose={() => clearError()}
          onbuttonAction={() => {
            clearError();
          }}
        />
      )}
    </Container>
  );
};
export default connect(mapStateToProps, dispatchProps)(UserGroupEditorView);

const Container = styled.View`
  width: 100vw;
  max-width: 800px;
  background-color: ${palette.background};
  max-height: 90vh;
  flex-direction: row;
`;

const ButtonContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 34px 0 0 8PX;
  align-self: flex-start;
`;

const CloseContainer = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  padding: 32px 24px 24px 24px;
  background: ${palette.background};
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom-width: 1px;
  border-bottom: solid black;
`;

const Title = styled.View`
  padding-top: 24px;
  flex-grow: 1;
`;

const FormContainer = styled.View`
  padding: 8px;
`;

const FieldContainer = styled.View`
  flex-direction: column;
`;

const ColumnContainer = styled.View`
  margin: 0px 0px 14px 0px;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ColumnTitle = styled.View`
  padding-left: 16px;
`;

const ColumnRightContainer = styled.View`
  width: 300px;
  margin: 0px 20px 14px 0px;
`;

const ColumnRightTitle = styled.View`
  padding: 0px 8px;
`;

const MainContainer = styled.View`
  padding: 0px 8px;
  flex-direction: row;
  justify-content: flex-end;
  margin: 22px 0px;
  display: flex;
  flex: auto;
`;

const LinkContainer = styled.View`
  text-decoration-line: underline;
`;

const ContentContainer = styled.View`
  background-color: ${palette.background};
  flex: 1;
`;

const StyledButton = styled(Button)`
    margin-left: 24px;
    border: 1px solid black;
`;
