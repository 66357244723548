import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { StyledText } from '@space/common';
import { TouchableOpacity } from 'react-native';

//TODO : AJA add onTouch
type Props = {
  title: string;
  currentValue: number;
  totalValue: number;
  link: string;
  onPress?: () => void;
};

const SpaceStatsDayFiguresView: React.FC<Props> = ({
  title,
  currentValue,
  totalValue,
  link,
  onPress,
}) => {

  return (
    <RootContainer>
      <StyledText variant={'h5'}>{title}</StyledText>
      <InlineContainer>
        <StyledText variant={'body2'} fontSize={22}>{currentValue}</StyledText><StyledText variant={'body1'} fontSize={22} color={'secondary'}> / {totalValue}</StyledText>
      </InlineContainer>
      <TouchableOpacity onPress={onPress}>
        <LinkContainer>
          <StyledText variant={'body4'} underline={true}>{link}</StyledText>
        </LinkContainer>
      </TouchableOpacity>
    </RootContainer>
  );
};

const RootContainer = styled.View`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-left: 25px;
`;

const InlineContainer = styled.View`
  padding-top: 1px;
  flexDirection: row;
  flexWrap: wrap;
`;

const LinkContainer = styled.View`
  padding-top: 5px;
  text-decoration-line: underline;
`;

export default SpaceStatsDayFiguresView;
