import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
  FlatList,
  TextInput,
  StyleSheet,
  Dimensions
} from 'react-native';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';
import Menu from '@features/menu/components/Menu'
import { palette, colors, shadow } from '@features/preferences/style/themes';
import { localize, localizedName } from '../localization';
import SvgIcon from '@components/SvgIcon';
import SpacingView from '@components/SpacingView';
import { Divider, Button, StyledText, ModalView, Dialog, ThemeUtil, CircularIcon } from '@space/common';
import { useNavigation, useRoute } from '@react-navigation/native';
import { loadUserRolesAsync, loadUsersAsync, loadUserGroupsAsync, openUserEditor,
  selectUser, deleteUserAsync, clearError, closeUserEditor } from '../features/user/actions';
import { fetchOrganizationsAsync, fetchOrganizationAsync } from '../features/organization/actions';
import { loadUserAsync } from '@features/login/actions';
import { User, UserRole, UserGroup } from 'UserFeature';
import UserDetailsRow from '@components/user/UserDetailsRow';
import UserEditorView from '../features/user/components/UserEditorView';
import UserRolesEditorView from '../features/user/components/UserRolesEditorView';
import UserGroupsEditorView from '../features/user/components/UserGroupsEditorView';
import NewUser from '../img/new_user.svg';
import Sort from '../img/sort.svg';
import ExpandableFilterListChoice from "@components/form/ExpandableFilterListChoice";
import ExpandableFilterSingleChoice from "@components/form/ExpandableFilterSingleChoice";
import _ from 'lodash';

enum SortUser {
  NAME = 'name',
  GROUP = 'group',
  ROLE = 'role',
}

enum ActiveOptionUser {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type UserFilter = {
  groups: string[];
  roles: string[];
  name: string;
  active: ActiveOptionUser;
};

export type UserSort = {
  name: 1;
  group: 1;
  role: 1;
  current: SortUser
};

const mapStateToProps = (state: RootState) => ({
  users: state.user.users,
  userRoles: state.user.userRoles,
  userGroups: state.user.userGroups,
  organizations: state.organization.organizations,
  displayEditUser: state.user.editUserIsDisplayed,
  userError: state.user.setUserError,
  modalError: state.user.setUserGroupError,
  activeLanguages: state.organization.languages
});

const dispatchProps = {
  fecthUsers: loadUsersAsync.request,
  fetchUser: loadUserAsync.request,
  fetchUserRoles: loadUserRolesAsync.request,
  fetchUserGroups: loadUserGroupsAsync.request,
  fetchOrganization: fetchOrganizationAsync.request,
  fetchOrganizations: fetchOrganizationsAsync.request,
  selectEditUser: selectUser,
  openUserEditorModal: openUserEditor,
  closeModal: closeUserEditor,
  deleteUser: deleteUserAsync.request,
  clearError: clearError,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Users: React.FC<Props> = ({
  fecthUsers,
  fetchUser,
  users,
  displayEditUser,
  fetchUserRoles,
  userRoles,
  userGroups,
  fetchUserGroups,
  fetchOrganization,
  fetchOrganizations,
  organizations,
  selectEditUser,
  openUserEditorModal,
  closeModal,
  deleteUser,
  modalError,
  userError,
  clearError,
  activeLanguages
}) => {

  const route = useRoute();
  var searchText: TextInput | null;

  const prefilterRoles = !_.isEmpty(route.params?.roles) && Array.isArray(route.params?.roles) ? route.params?.roles : [];
  const emptyFilters = { name: '', active: ActiveOptionUser.ALL, groups: [], roles: prefilterRoles };
  const defaultSort = { name: 1, group: 1, role: 1, order: SortUser.NAME };
  const [usersSort, setUsersSort] = useState(defaultSort);
  const [usersFilters, setUsersFilters] = useState<UserFilter>(emptyFilters);
  const [change, setChange] = useState(0);
  const [animation, setAnimation] = useState(false);
  const pathName = window.location.href;
  const [isConfirmUserDeleteViewDisplayed, showConfirmUserDeleteModal] = useState(false);
  const [displayEditUserRoles, openUserRolesEditorModal]= useState(false);
  const [displayEditUserGroups, openUserGroupsEditorModal]= useState(false);

  useEffect(() => {
    fetchUser();
    fecthUsers();
    fetchUserRoles();
    fetchUserGroups();
    fetchOrganization();
    fetchOrganizations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    closeModal();
    selectEditUser(null);
    setAnimation(false);
  },[pathName])

  useEffect(() => {
    fecthUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayEditUserRoles, displayEditUserGroups]);

  const filterName = (user: User) => {
    return (
      (_.isEmpty(usersFilters.name) ||
        usersFilters.name.length < 2 ||
        user.firstName
          .toLowerCase()
          .includes(usersFilters.name.toLowerCase()) ||
        user.lastName
          .toLowerCase()
          .includes(usersFilters.name.toLowerCase()) ||
        (user.firstName + user.lastName)
          .toLowerCase().replace(' ', '')
          .includes(usersFilters.name.toLowerCase().replace(' ', ""))
      )
    );
  }

  const filterGroups = (user: User) => {
    const groupIdsList = user.groupsList.map((userGroup: UserGroup) => {
      return userGroup.id;
    });

    return (
      (usersFilters.groups.length === 0) ||
      (!_.isEmpty(user.groupsList) && usersFilters.groups.filter((groupId: string) => groupIdsList.includes(groupId)).length > 0)
    );
  }

  const filterRoles = (user: User) => {
    const roleIdsList = user.rolesList.map((userRole: UserRole) => {
      return userRole.id;
    });

    return (
      (usersFilters.roles.length === 0) ||
      (!_.isEmpty(user.rolesList) && usersFilters.roles.filter((roleId: string) => roleIdsList.includes(roleId)).length > 0)
    );
  }

  const filterActive = (user: User) => {
    return (
      usersFilters.active === ActiveOptionUser.ALL
      || (_.isEmpty(user.disabledAt) && usersFilters.active === ActiveOptionUser.ACTIVE)
      || (!_.isEmpty(user.disabledAt) && usersFilters.active === ActiveOptionUser.INACTIVE)
    );
  }

  const sortUser = (userA: User, userB: User) => {
    return sortUserCondition(userA, userB, usersSort.order)
  };

  const getFirstGroupdName = (user: User) => {
    const groups = !_.isEmpty(user.groupIdsList) ? userGroups.filter((group) => user.groupIdsList.includes(group.id)) : [];
    return !_.isEmpty(groups) ? groups[0].name : null;
  };

  const getFirstRoledName = (user: User) => {
    const roles = !_.isEmpty(user.roleIdsList) ? userRoles.filter((role) => user.roleIdsList.includes(role.id)) : [];
    return !_.isEmpty(roles) ? roles[0].name : null;
  };

  const sortUserCondition = (userA: User, userB: User, sortParam: SortUser) => {
    if (_.isEmpty(userA.disabledAt) && !_.isEmpty(userB.disabledAt)) {
      return -1;
    } else if (!_.isEmpty(userA.disabledAt) && _.isEmpty(userB.disabledAt)) {
      return 1;
    }
    switch (sortParam) {
      case SortUser.NAME:
        return (userA.firstName < userB.firstName ? -(usersSort.name) : usersSort.name);
        break;
      case SortUser.GROUP:
        var groupA = getFirstGroupdName(userA);
        var groupB = getFirstGroupdName(userB);
        groupA = groupA === null ? '0' : groupA;
        groupB = groupB === null ? '0' : groupB;

        return (groupA < groupB ? -(usersSort.group) : usersSort.group);
        break;

      case SortUser.ROLE:
        var roleA = getFirstRoledName(userA);
        var roleB = getFirstRoledName(userB);
        roleA = roleA === null ? '0' : roleA;
        roleB = roleB === null ? '0' : roleB;

        return (roleA < roleB ? -(usersSort.role) : usersSort.role);
        break;
    }
  };

  const sortedUsers = useMemo(() => {
    return users.filter((user) => (filterName(user) && filterGroups(user) && filterRoles(user) && filterActive(user)))
      .sort((userA: User, userB: User) => sortUser(userA, userB))
  }, [users, usersSort, usersFilters, change]);

  const newUserButtonPressed = () => {
    openUserEditorModal();
    setTimeout(() => setAnimation(true), 100);
  };

  const cancelButtonPressed = () => {
    selectEditUser(null);
    setAnimation(false);
    setTimeout(() => closeModal(), 100);
  };

  const manageUserRolesButtonPressed = () => {
    openUserRolesEditorModal(true);
  };

  const manageUserGroupsButtonPressed = () => {
    openUserGroupsEditorModal(true);
  };

  const editUserButtonPressed = (user: User) => {
    selectEditUser(user);
    openUserEditorModal();
    setTimeout(() => setAnimation(true), 100);
  };

  const deleteUserButtonPressed = (user: User) => {
    selectEditUser(user);
    showConfirmUserDeleteModal(true);
  };

  const onPressSortName = () => {
    setUsersSort({ name: -(usersSort.name), group: usersSort.group, role: usersSort.role, order: SortUser.NAME });
  };

  const onPressSortGroups = () => {
    setUsersSort({ name: usersSort.name, group: -(usersSort.group), role: usersSort.role, order: SortUser.GROUP });
  };

  const onPressSortRoles = () => {
    setUsersSort({ name: usersSort.name, group: usersSort.group, role: -(usersSort.role), order: SortUser.ROLE });
  };

  const onFilterGroup = (selectedItems: string[]) => {
    setUsersFilters({
      name: usersFilters.name, groups: selectedItems, roles: usersFilters.roles, active: usersFilters.active
    });
  };

  const onFilterRole = (selectedItems: string[]) => {
    usersFilters.roles = selectedItems;

    setUsersFilters({
      name: usersFilters.name, groups: usersFilters.groups, roles: selectedItems, active: usersFilters.active
    });
  };

  const onFilterActive = (selectedId: string) => {
    const value: ActiveOptionUser = selectedId as ActiveOptionUser;
    setUsersFilters({
      name: usersFilters.name, groups: usersFilters.groups, roles: usersFilters.roles, active: value
    });
  };

  const resetFilter = () => {
    setUsersFilters(emptyFilters);
    searchText.clear();
  };

  const activeItems = [
    { id: ActiveOptionUser.ALL, label: localize('USER_ACTIVE_ALL') },
    { id: ActiveOptionUser.ACTIVE, label: localize('USER_ACTIVE') },
    { id: ActiveOptionUser.INACTIVE, label: localize('USER_INACTIVE') }
  ];

  const renderUserItem = ({ item }) => {
    return (
      <UserDetailsRow user={item} onEditPress={editUserButtonPressed} onDeletePress={deleteUserButtonPressed} />
    );
  };
  const styleName = useTheme().mode;

  return (
    <Container>
      <Menu />
      <ContentContainer>

        <Dialog
          isOpen={isConfirmUserDeleteViewDisplayed}
          title={localize('DELETE_USER_ALERT_TITLE')}
          onClose={() => {
            selectEditUser(null);
            showConfirmUserDeleteModal(false);
          }}
          actionTitle={localize('DELETE')}
          cancelTitle={localize('CANCEL')}
          onbuttonAction={() => {
            showConfirmUserDeleteModal(false);
            deleteUser(selectUser);
            selectEditUser(null);
          }}
        />

        <TopContainer>
          <StyledText variant={'h1'}>{localize('UsersView')}</StyledText>
          <SpacingView height={'35px'} />
          <RowContent>
            <LeftSpaceContainer>
              <ButtonContainer onPress={newUserButtonPressed} disabled={displayEditUser} style={displayEditUser && styles.disabled}>
                <LeftButtonContent>
                  <StyledText>{localize('NEW_USER')}</StyledText>
                </LeftButtonContent>
                <CircularIcon
                  svg={NewUser}
                  tintColor={colors.black}
                  accessibilityLabel={localize('NEW_USER')}
                  variant={'fill'}
                  size={'large'}
                />
              </ButtonContainer>
            </LeftSpaceContainer>
            <RightContainer>
              <ButtonRoleContainer>
                <Button variant={'monochrome'} inverted={true} disabled={displayEditUser} onPress={manageUserRolesButtonPressed} title={localize('MANAGE_USER_ROLE')} />
              </ButtonRoleContainer>
              <ButtonRoleContainer>
                <Button variant={'monochrome'} inverted={true} disabled={displayEditUser} onPress={manageUserGroupsButtonPressed} title={localize('MANAGE_USER_GROUP')} />
              </ButtonRoleContainer>
            </RightContainer>
          </RowContent>
        </TopContainer>
        {!displayEditUser ? <MainContainer>
          <ActionContainer>
            <FilterContainer>
              <StyledText variant={'h3'}>{localize('FILTER_BY')}</StyledText>

              <FilterActionContainer>
                <ExpandableFilterListChoice
                  width={160}
                  items={userGroups.map((group) => ({ id: group.id, label: localizedName(group.name, activeLanguages) }))}
                  title={localize('FILTER_BY_GROUP')}
                  selectedValues={usersFilters.groups}
                  onSelectedItemsChange={onFilterGroup} />
                <SpacingView width={'8px'} height={'5px'} />
                <ExpandableFilterListChoice
                  width={160} items={userRoles.map((role) => ({ id: role.id, label: localizedName(role.name, activeLanguages) }))}
                  title={localize('FILTER_BY_ROLE')}
                  selectedValues={usersFilters.roles}
                  onSelectedItemsChange={onFilterRole} />
                <SpacingView width={'8px'} height={'5px'} />
                <ExpandableFilterSingleChoice
                  width={160} items={activeItems}
                  title={localize('FILTER_BY_ACTIVE')}
                  onItemSelected={onFilterActive}
                  selectedValue={usersFilters.active} />
                <SpacingView width={'8px'} height={'5px'} />
                <ResetButton onPress={resetFilter}>
                  <StyledText variant={'body4'}>{localize('RESET_FILTER')}</StyledText>
                </ResetButton>
              </FilterActionContainer>
            </FilterContainer>
            <SearchContainer>
              <StyledText variant={'h3'}>{localize('SEARCH_USER')}</StyledText>
              <SpacingView height={'12px'} />
              <SingleInputTextContainer
                placeholder={localize('FIRSTNAME_LASTNAME')}
                ref={input => { searchText = input }}
                multiline={false}
                maxLength={280}
                onChangeText={
                  (text) => {
                    setUsersFilters({
                      name: text, groups: usersFilters.groups, roles: usersFilters.roles, active: usersFilters.active
                    });
                  }
                } />
            </SearchContainer>
          </ActionContainer>
          <HorizontalSeparator />
          <HeaderContainer>
            <CellContainer>
              <StyledText variant={'body1'}>{localize('USER_NAMES')}</StyledText>
              <CellSvgContainer onPress={onPressSortName}>
                <SvgIcon svg={Sort} width={18} height={18} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
              </CellSvgContainer>
            </CellContainer>
            <CellContainer>
              <StyledText variant={'body1'}>{localize('USER_GROUPS')}</StyledText>
              <CellSvgContainer onPress={onPressSortGroups}>
                <SvgIcon svg={Sort} width={18} height={18} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
              </CellSvgContainer>
            </CellContainer>
            <CellContainer>
              <StyledText variant={'body1'}>{localize('USER_ROLES')}</StyledText>
              <CellSvgContainer onPress={onPressSortRoles}>
                <SvgIcon svg={Sort} width={18} height={18} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
              </CellSvgContainer>
            </CellContainer>
            <BookingCellContainer><StyledText variant={'body1'}>{localize('USER_BOOKINGS')}</StyledText></BookingCellContainer>
            <ActiveCellContainer><StyledText variant={'body1'}>{localize('USER_ACTIVE')}</StyledText></ActiveCellContainer>
          </HeaderContainer>
          <HorizontalSeparator />
          <SpacingView height={'2px'} />
          <FlatList
            data={sortedUsers}
            scrollEnabled={false}
            showsVerticalScrollIndicator={false}
            renderItem={renderUserItem}
            keyExtractor={(item: User) => item.email}
          />
        </MainContainer> : <UserEditorViewContainer style={[!animation ? styles.editorOpen : styles.editorClosed]}>
          <UserEditorView onEditorClosed={cancelButtonPressed} />
        </UserEditorViewContainer> }
      </ContentContainer>
      <ModalView isVisible={displayEditUserRoles}>
        <UserRolesEditorView openUserRolesEditorModal={openUserRolesEditorModal}/>
      </ModalView>
      <ModalView isVisible={displayEditUserGroups}>
        <UserGroupsEditorView openUserGroupsEditorModal={openUserGroupsEditorModal}/>
      </ModalView>
      
      {userError && (
        <Dialog
        isOpen={userError !== null}
        title={localize('APP_ERROR_TITLE')}
        body={userError}
        onClose={() => clearError()}
        onbuttonAction={() => { clearError() }}
        cancelTitle={localize('CANCEL')}
      />)}

      { modalError && (
        <Dialog
          isOpen={modalError !== null}
          title={localize('ERROR_DEFAULT')}
          onClose={() => clearError()}
          onbuttonAction={() => {
            clearError();
          }}
        />
      )}
    </Container>
  );
};
export const UsersViewName = 'UsersView';
export default connect(mapStateToProps, dispatchProps)(Users);

const Container = styled.View`
  flex-direction: row;
  background-color: ${palette.background};
  width: 100vw;
  height: 100%;
`;

const ContentContainer = styled.View`
  height: 100vh;
  background-color: ${palette.background};
  flex: 1;
`;

const MainContainer = styled.ScrollView`
  padding: 48px;
`;

const TopContainer = styled.View`
  align-items: flex-start;
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${palette.background};
  padding: 40px 48px;
  margin: 0;
  border-bottom-color: ${palette.border};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

const RowContent = styled.SafeAreaView`
  flex-direction: row;
  width: 100%;
`;

const ActionContainer = styled.View`
  padding-bottom: 21px;
  flex-direction: row;
  z-index: 10;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  align-self: flex-start;
  width: 100%;
`;

const RowContainer = styled.View`
  flex-direction: row;
  width: 100%;
`;

const SearchContainer = styled.View`
  flex-direction: column;
  width: 30%;
`;

const FilterContainer = styled.View`
  width: 70%;
  z-index: 10;
`;

const FilterActionContainer = styled.View`
  margin: 12px 0px;
  flex-direction: row;
`;

const SingleInputTextContainer = styled.TextInput`
  height: 40px;
  padding: 16px 16px 16px 16px;
  background-color: ${palette.textInput};
  border-radius: 4px;
  margin: 0px 0px 4px 0px;
  color: ${palette.info};
`;

const ButtonContainer = styled.TouchableOpacity`
  width: 327px;
  height: 64px;
  flex-direction: row;
  align-items: center;
  margin: 16px 0px 0px 0px;
  padding: 0px 16px 0px 32px;
  border-color: ${palette.border};
  border-width: 1px;
  box-shadow: ${shadow};
  border-radius: 8px;
  justify-content: center;
`;

const LeftButtonContent = styled.View`
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
`;

const LeftSpaceContainer = styled.View`
  flex-grow: 1;
`;

const RightContainer = styled.View`
  flex-direction: row;
  align-items: right;
  justify-content: center;
`;

const ButtonRoleContainer = styled.View`
  margin: 20px 0px 20px 20px;
`;

const CellContainer = styled.View`
  flex-direction: row;
  justify-items: center;
  width: 23%;
  padding: 2px 12px;
`;

const CellSvgContainer = styled.TouchableOpacity`
  padding: 2px;;
  align-self: center;
`;

const BookingCellContainer = styled.View`
  flex-direction: row;
  width: 14%;
  padding: 2px 12px;
  min-width: 120px;
`;

const ActiveCellContainer = styled.View`
  justify-content: center;
  flex-direction: row;
  width: 6%;
  min-width: 80px;
  padding: 2px 12px;
`;

const ActionCellContainer = styled.View`
  justify-content: center;
  flex-direction: row;
  width: 10%;
  min-width: 100px;
  padding: 2px 12px;
`;

const ResetButton = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 24px;
  height: 40px;
  text-decoration: underline;
`;

const HorizontalSeparator = styled.View`
  background-color: ${palette.border};
  opacity: 0.25;
  height: 1px;
  width: 100%;
  flex-shrink: 0;
`;

const UserEditorViewContainer = styled.View`
  transition: 0.4s;
`;

const styles = StyleSheet.create({
  disabled: {
    opacity: '0.4'
  },
  editorOpen: {
    transform: `translateX(${Dimensions.get('window').width}px)`,
  },
  editorClosed: {
    transform: 'initial',
  }
});
