import React, { useEffect, useMemo, useState, useRef } from 'react';
import { FlatList, StyleSheet, Dimensions, ScrollView, View, TextInput } from 'react-native';
import SvgIcon from '@components/SvgIcon';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';
import Menu from '@features/menu/components/Menu';
import { palette, colors, shadow } from '@features/preferences/style/themes';
import { localize, localizedName } from '../localization';
import {
  Button,
  StyledText,
  ModalView,
  Dialog,
  ThemeUtil,
  CircularIcon,
} from '@space/common';
import {
  fetchAllSpacesAsync,
  fetchSpaceGroupsAsync,
  fetchSpaceTypesAsync,
  updateSpaceAsync,
  updateSpaceGroupAsync,
  fetchSpaceTagsAsync,
  selectSpace,
  selectSpaceGroup,
  deleteSpaceAsync,
  deleteSpaceGroupAsync,
  clearSpaceError,
  showWarningPodActivation,
  setEditSpaceType,
  showEditSpace,
} from '../features/space/actions';
import { loadUserAsync } from '@features/login/actions';
import { loadUserGroupsAsync } from '../features/user/actions'
import SpacingView from '@components/SpacingView';
import Sort from '../img/sort.svg';
import Plus from '../img/plus.svg';
import SpaceTagEditorView from '../features/space/components/SpaceTagEditorView';
import ExpandableFilterListChoice from '@components/form/ExpandableFilterListChoice';
import ExpandableFilterSingleChoice from '@components/form/ExpandableFilterSingleChoice';
import SpaceDetailsRow from '../features/space/components/SpaceDetailsRow';
import { MixedSpace, Space, SpaceGroup, SpaceTag, SpaceGroupList } from 'SpaceFeature';
import { Schedule } from 'LoginFeature';
import _ from 'lodash';
import SpaceTypeEditorView from '../features/space/components/SpaceTypeEditorView';
import SpaceEditorView from '../features/space/components/SpaceEditorView';
import { SpaceEditorType } from '../features/space/components/SpaceEditorView';
import SpaceHelper from '../utils/SpaceHelper';
import moment from 'moment';
import config from '../config';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';

enum SortSpace {
  TYPE = 'type',
  NAME = 'name',
  CAPACITY = 'capacity',
}

enum ActiveOptionSpace {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type SpaceFilter = {
  groups: number[];
  types: number[];
  details: number[];
  active: ActiveOptionSpace;
  name: string;
};

export type SpaceSort = {
  type: 1;
  name: 1;
  capacity: 1;
};

const mapStateToProps = (state: RootState) => ({
  spaces: state.space.allSpaces,
  spaceGroups: state.space.spaceGroups,
  spaceTags: state.space.spaceTags,
  spaceTypes: state.space.spaceTypes,
  selectedSpace: state.space.selectedSpace,
  selectedSpaceGroup: state.space.selectedSpaceGroup,
  isWarningPodActivationViewDisplayed:
    state.space.isWarningPodActivationViewDisplayed,
  spaceError: state.space.setSpaceError,
  spaceGroupError: state.space.setSpaceGroupError,
  isSpaceEditViewDisplayed: state.space.isSpaceEditorViewDisplayed,
  selectedEditSpaceType: state.space.selectedEditSpaceType,
  activeLanguages: state.organization.languages,
  userGroups: state.user.userGroups,
});

const dispatchProps = {
  fetchUser: loadUserAsync.request,
  fetchUserGroups: loadUserGroupsAsync.request,
  fetchAllSpaces: fetchAllSpacesAsync.request,
  fetchSpaceGroups: fetchSpaceGroupsAsync.request,
  fetchSpaceTags: fetchSpaceTagsAsync.request,
  fetchSpaceTypes: fetchSpaceTypesAsync.request,
  updateSpace: updateSpaceAsync.request,
  updateSpaceGroup: updateSpaceGroupAsync.request,
  selectEditSpace: selectSpace,
  selectEditSpaceGroup: selectSpaceGroup,
  deleteSpace: deleteSpaceAsync.request,
  deleteSpaceGroup: deleteSpaceGroupAsync.request,
  clearError: clearSpaceError,
  showWarningPodActivation: showWarningPodActivation,
  setEditSpaceType: setEditSpaceType,
  showSpaceModal: showEditSpace,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Spaces: React.FC<Props> = ({
  activeLanguages,
  spaces,
  spaceGroups,
  spaceTags,
  spaceTypes,
  selectedSpace,
  selectedSpaceGroup,
  fetchAllSpaces,
  fetchSpaceGroups,
  fetchSpaceTags,
  fetchSpaceTypes,
  updateSpace,
  updateSpaceGroup,
  selectEditSpace,
  selectEditSpaceGroup,
  spaceError,
  spaceGroupError,
  deleteSpace,
  deleteSpaceGroup,
  clearError,
  showSpaceModal,
  isSpaceEditViewDisplayed,
  isWarningPodActivationViewDisplayed,
  showWarningPodActivation,
  setEditSpaceType,
  selectedEditSpaceType,
  userGroups,
  fetchUserGroups,
  fetchUser,
}) => {
  const styleName = useTheme().mode;

  useEffect(() => {
    fetchAllSpaces();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpaceGroup]);

  useEffect(() => {
    fetchSpaceGroups();
    fetchSpaceTags();
    fetchSpaceTypes();
    fetchUserGroups();
    fetchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyFilters = {
    name: '',
    groups: [],
    types: [],
    details: [],
    active: ActiveOptionSpace.ALL,
  };
  const defaultSort = { type: 1, name: 1, capacity: 1, order: SortSpace.NAME };
  const [spacesSort, setSpacesSort] = useState(defaultSort);
  const [spacesFilters, setSpacesFilters] = useState<SpaceFilter>(emptyFilters);
  const [animation, setAnimation] = useState(false);
  const today = moment().startOf('day').format(config.defaultDateFormat);
  const [isConfirmSpaceDeleteViewDisplayed, showConfirmSpaceDeleteModal] = useState(false);
  const [isConfirmSpaceDisabledViewDisplayed, showConfirmSpaceDisabledModal] = useState(false);
  const [isConfirmSpaceGroupDeleteViewDisplayed, showConfirmSpaceGroupDeleteModal] = useState(false);
  const [isConfirmSpaceGroupDisabledViewDisplayed, showConfirmSpaceGroupDisabledModal] = useState(false);
  const [displayEditSpaceTag, showSpaceTagEditorModal] = useState(false);
  const [displayEditSpaceType, showSpaceTypeEditorModal] = useState(false);

  const [currentEditSubSpaces, setEditSubSpaces] = useState([]);
  const scrollViewRef = useRef<ScrollView>(null);
  const navigation = useNavigation();
  const pathName = window.location.href;

  useEffect(() => {
    selectEditSpace(null);
    selectEditSpaceGroup(null);
    showSpaceModal(false);
  },[pathName])

  const sortSpaceCondition = (
    spaceA: MixedSpace,
    spaceB: MixedSpace,
    sortParam: SortSpace
  ) => {
    if (
      SpaceHelper.isActiveSpace(spaceA.openDate) >
      SpaceHelper.isActiveSpace(spaceB.openDate)
    ) {
      return -1;
    } else if (
      SpaceHelper.isActiveSpace(spaceA.openDate) <
      SpaceHelper.isActiveSpace(spaceB.openDate)
    ) {
      return 1;
    }
    switch (sortParam) {
      case SortSpace.TYPE:
        const typeNameA = localizedName(spaceA.type.name, activeLanguages);
        const typeNameB = localizedName(spaceB.type.name, activeLanguages);
        return typeNameA > typeNameB
          ? -spacesSort.type
          : typeNameA < typeNameB
          ? spacesSort.type
          : 0;
      case SortSpace.NAME:
        const spaceNameA = localizedName(spaceA.name, activeLanguages).toLowerCase() ?? '';
        const spaceNameB = localizedName(spaceB.name, activeLanguages).toLowerCase() ?? '';
          return spaceNameA.localeCompare(spaceNameB, undefined, {numeric: true, ignorePunctuation: true}) === 1
          ? spacesSort.name
          : spaceNameA.localeCompare(spaceNameB, undefined, {numeric: true, ignorePunctuation: true}) === -1
          ? -spacesSort.name
          : 0;
      case SortSpace.CAPACITY:
        return spaceA.capacity > spaceB.capacity
          ? spacesSort.capacity
          : spaceA.capacity < spaceB.capacity
          ? -spacesSort.capacity
          : 0;
    }
  };

  const sortSpace = (spaceA: MixedSpace, spaceB: MixedSpace) => {
    return sortSpaceCondition(spaceA, spaceB, spacesSort.order);
  };

  const filterName = (space: MixedSpace) => {
    return (
      (_.isEmpty(spacesFilters.name) ||
      spacesFilters.name.length < 0 ||
        localizedName(space.name, activeLanguages).toLowerCase().replace(' ', '')
          .includes(spacesFilters.name.toLowerCase().replace(' ', ""))
      )
    );
  }

  const filterActive = (space: MixedSpace) => {
    return (
      spacesFilters.active === ActiveOptionSpace.ALL ||
      (SpaceHelper.isActiveSpace(space.openDate) &&
        spacesFilters.active === ActiveOptionSpace.ACTIVE) ||
      (!SpaceHelper.isActiveSpace(space.openDate) &&
        spacesFilters.active === ActiveOptionSpace.INACTIVE)
    );
  };

  const filterType = (space: Space) => {
    return (
      spacesFilters.types.length === 0 ||
      (!_.isEmpty(space.type) && spacesFilters.types.includes(space.type.id))
    );
  };

  const filterDetails = (space: Space) => {
    const detailsIdsList = space.tagsList.map((tagList: SpaceTag) => {
      return tagList.id;
    });
    return (
      spacesFilters.details.length === 0 ||
      (!_.isEmpty(space.tagsList) &&
        spacesFilters.details.filter((detailId: number) =>
        detailsIdsList.includes(detailId)
        ).length > 0)
    );
  };

  const filterGroups = (space: Space) => {
    const groupsIdsList = space.groupsList.map((groupList: SpaceGroupList) => {
      return groupList.id;
    });
    return (
      spacesFilters.groups.length === 0 ||
      (!_.isEmpty(space.groupsList) &&
        spacesFilters.groups.filter((detailId: number) =>
        groupsIdsList.includes(detailId)
        ).length > 0)
    );
  };
  const allSpaces = useMemo(() => {
    const mapSpaces = spaces.map((space: Space) => {
      return {
        id: space.id,
        name: space.name,
        tagsList: space.tagsList,
        type: space.type,
        capacity: space.capacity,
        openDate: space.openDate,
        openSchedule: space.openSchedule,
        isGroup: false,
        groupsList: space.groupsList,
        group: space.group
      };
    });

    const mapSpaceGroups = spaceGroups.map((group: SpaceGroup) => {
      return {
        id: group.id,
        name: group.name,
        tagsList: group.tagsList,
        type: group.type,
        capacity: group.capacity,
        openDate: group.openDate,
        openSchedule: group.openSchedule,
        isGroup: true,
        groupsList: group.groupsList,
        groupSpaces: group.spaces,
      };
    });
    const all = mapSpaces.concat(mapSpaceGroups);

    return all
      .filter(
        (space) =>
          filterType(space) && filterDetails(space) && filterActive(space) && filterGroups(space) && filterName(space)
      )
      .sort((spaceA: MixedSpace, spaceB: MixedSpace) =>
        sortSpace(spaceA, spaceB)
      );
  }, [spaces, spaceGroups, spaceTags, spaceTypes, spacesFilters, spacesSort, userGroups]);

  const allTypes = useMemo(() => {
    const types = spaceTypes.map((type) => ({
      id: type.id,
      label: localizedName(type.name, activeLanguages),
    }));
    return types;
  }, [spaceTypes]);

  const activeItems = [
    { id: ActiveOptionSpace.ALL, label: localize('SPACE_ACTIVE_ALL') },
    { id: ActiveOptionSpace.ACTIVE, label: localize('SPACE_ACTIVE') },
    { id: ActiveOptionSpace.INACTIVE, label: localize('SPACE_INACTIVE') },
  ];

  const onPressSortType = () => {
    setSpacesSort({
      type: -spacesSort.type,
      name: spacesSort.name,
      capacity: spacesSort.capacity,
      order: SortSpace.TYPE,
    });
  };

  const onPressSortName = () => {
    setSpacesSort({
      type: spacesSort.type,
      name: -(spacesSort.name),
      capacity: spacesSort.capacity,
      order: SortSpace.NAME,
    });
  };

  const onPressSortCapacity = () => {
    setSpacesSort({
      type: spacesSort.type,
      name: spacesSort.name,
      capacity: -spacesSort.capacity,
      order: SortSpace.CAPACITY,
    });
  };

  const onFilterType = (selectedItems: number[]) => {
    setSpacesFilters({
      name: spacesFilters.name,
      groups: spacesFilters.groups,
      types: selectedItems,
      details: spacesFilters.details,
      active: spacesFilters.active,
    });
  };

  const onFilterDetails = (selectedItems: number[]) => {
    setSpacesFilters({
      name: spacesFilters.name,
      groups: spacesFilters.groups,
      types: spacesFilters.types,
      details: selectedItems,
      active: spacesFilters.active,
    });
  };

  const onFilterActive = (selectedId: string) => {
    const value: ActiveOptionSpace = selectedId as ActiveOptionSpace;
    setSpacesFilters({
      name: spacesFilters.name,
      groups: spacesFilters.groups,
      types: spacesFilters.types,
      details: spacesFilters.details,
      active: value,
    });
  };

  const onFilterGroup = (selectedItems: number[]) => {
    setSpacesFilters({
      name: spacesFilters.name, groups: selectedItems, types: spacesFilters.types, details: spacesFilters.details, active: spacesFilters.active
    });
  };

  const resetFilter = () => {
    setSpacesFilters(emptyFilters);
  };

  const scrollToTop = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: false });
    }
  };

  const newSpaceButtonPressed = () => {
    scrollToTop();
    setEditSpaceType(SpaceEditorType.NEW);
    showSpaceModal(true);
    window.scrollTo(0, 0);
    setTimeout(() => setAnimation(true), 100);
  };

  const manageSpaceTypeButtonPressed = () => {
    showSpaceTypeEditorModal(true);
  };

  const manageSpaceDetailsButtonPressed = () => {
    showSpaceTagEditorModal(true);
  };

  const deleteSpaceButtonPressed = (space: MixedSpace) => {
    if (space.isGroup) {
      selectEditSpaceGroup(space);
      showConfirmSpaceGroupDeleteModal(true);
    } else {
      selectEditSpace(space);
      showConfirmSpaceDeleteModal(true);
    }
  };

  const editSpaceButtonPressed = (space: MixedSpace) => {
    scrollToTop();
    if (space.isGroup) {
      selectEditSpaceGroup(space);
    } else {
      selectEditSpace(space);
    }
    setEditSpaceType(SpaceEditorType.EDIT);
    showSpaceModal(true);
    setTimeout(() => setAnimation(true), 100);
  };

  const disabledSpaceButtonPressed = (space: MixedSpace) => {
    if (space.isGroup) {
      selectEditSpaceGroup(space);
      showConfirmSpaceGroupDisabledModal(true);
    } else {
      selectEditSpace(space);
      showConfirmSpaceDisabledModal(true);
    }
  };

  const disabledSpace = (space: Space) => {
    const updatedSpace = {
      ...space,
      openDate: null
    };
    updateSpace(updatedSpace);
  };

  const disabledSpaceGroup = (space: SpaceGroup) => {
    if (space.capacity === 0) {
      return;
    }
    const updatedSpaceGroup = {
      ...space,
      openDate: null,
      typeId: space.type.id,
    };
    updateSpaceGroup(updatedSpaceGroup);
  };

  const renderSpaceItem = ({ item, index }) => {
    return (
      <SpaceDetailsRow
        space={item}
        index={index}
        onEditPress={editSpaceButtonPressed}
        onDeletePress={deleteSpaceButtonPressed}
        onDisabledPress={disabledSpaceButtonPressed}
      />
    );
  };

  const saveEditSpaceChanges = () => {
    if (
      selectedSpaceGroup &&
      selectedEditSpaceType === SpaceEditorType.CAPACITY
    ) {
      // space to remove:
      const toRemove = !_.isEmpty(currentEditSubSpaces)
        ? allSpaces?.filter((space) => !currentEditSubSpaces.includes(space.id))
        : [];

      const spaceToUpdate = !_.isEmpty(currentEditSubSpaces)
        ? allSpaces.filter((space) => currentEditSubSpaces.includes(space.id))
        : [];

      toRemove.forEach((space) => {
        const updatedSpace = {
          id: space.id,
          type: space.type,
          name: space.name,
          tagsList: space.tagsList,
          capacity: space.capacity,
          openSchedule: space.openSchedule,
          openDate: space.openDate,
          disabledAt: space.disabledAt,
          groupId: null,
          groupsList: space.groupsList
        };
        updateSpace(updatedSpace);
      });

      spaceToUpdate.forEach((space) => {
        const updatedSpace = {
          id: space.id,
          type: space.type,
          name: space.name,
          tagsList: space.tagsList,
          capacity: space.capacity,
          openSchedule: space.openSchedule,
          openDate: space.openDate,
          disabledAt: space.disabledAt,
          groupId: selectedSpaceGroup.id,
          groupsList: space.groupsList
        };
        updateSpace(updatedSpace);
      });

      return;
    }

    if (selectedSpace) {
      const updatedSpace = {
        id: selectedSpace.id,
        type: selectedSpace.type,
        name: selectedSpace.name,
        tagsList: selectedSpace.tagsList,
        capacity: selectedSpace.capacity,
        openSchedule: selectedSpace.openSchedule,
        openDate: selectedSpace.openDate,
        disabledAt: selectedSpace.disabledAt,
        groupsList: selectedSpace.groupsList
      };
      updateSpace(updatedSpace);
      selectSpace(null);
    } else if (selectedSpaceGroup) {
      const updatedSpaceGroup = {
        id: selectedSpaceGroup.id,
        type: selectedSpaceGroup.type,
        typeId: selectedSpaceGroup.type.id,
        openSchedule: selectedSpaceGroup.openSchedule,
        name: selectedSpaceGroup.name,
        tagsList: selectedSpaceGroup.tagsList,
        groupsList: selectedSpaceGroup.groupsList
      };
      updateSpaceGroup(updatedSpaceGroup);
      selectSpaceGroup(null);
    }
  };

  const closeSpaceEditor = () => {
    scrollToTop();
    setAnimation(false);
    setTimeout(() => showSpaceModal(false), 100);
  };

  const onSaveEditedSpace = () => {
    scrollToTop();
    saveEditSpaceChanges();
    showSpaceModal(false);
  };

  const onTypeChanged = (selectedType: string) => {
    if (selectedSpace) {
      const editSpace = selectedSpace;
      editSpace.typeId = selectedType;
      selectEditSpace(editSpace);
    } else if (selectedSpaceGroup) {
      const editGroup = selectedSpaceGroup;
      editGroup.typeId = selectedType;
      selectEditSpaceGroup(editGroup);
    }
  };

  const onTagsChanged = (selectedTags: string[]) => {
    if (selectedSpace) {
      const editSpace = selectedSpace;
      editSpace.tagIdsList = selectedTags;
      selectEditSpace(editSpace);
    } else if (selectedSpaceGroup) {
      const editGroup = selectedSpaceGroup;
      editGroup.tagIdsList = selectedTags;
      selectEditSpaceGroup(editGroup);
    }
  };

  const onGroupsChanged = (selectedGroups: string[]) => {
    if (selectedSpace) {
      const editSpace = selectedSpace;
      editSpace.groupIdsList = selectedGroups;
      selectEditSpace(editSpace);
    } else if (selectedSpaceGroup) {
      const editGroup = selectedSpaceGroup;
      editGroup.groupIdsList = selectedGroups;
      selectEditSpaceGroup(editGroup);
    }
  }

  const onSpacesChanged = (selectedSpaces: string[]) => {
    if (selectedSpaceGroup) {
      setEditSubSpaces(selectedSpaces);
    }
  };

  const onScheduleChanged = (openSchedule: Schedule) => {
    if (selectedSpace) {
      const editSpace = selectedSpace;
      editSpace.openSchedule = openSchedule;
      selectEditSpace(editSpace);
    }
  };

  const activeSpaces = useMemo(() => allSpaces.filter((space: Space) => {
    return SpaceHelper.isOpenSpace(space, moment());
  }).length, [allSpaces]);
  
  return (
    <Container>
      <Menu />
      <ContentContainer ref={scrollViewRef}>
        <Dialog
          isOpen={isConfirmSpaceDeleteViewDisplayed}
          title={localize('DELETE_SPACE_ALERT_TITLE')}
          onClose={() => {
            selectEditSpace(null);
            showConfirmSpaceDeleteModal(false);
          }}
          actionTitle={localize('DELETE')}
          cancelTitle={localize('CANCEL')}
          onbuttonAction={() => {
            showConfirmSpaceDeleteModal(false);
            deleteSpace(selectedSpace);
            selectEditSpace(null);
          }}
        />
        <Dialog
          isOpen={isConfirmSpaceDisabledViewDisplayed}
          title={localize('DESACTIVATE_SPACE_ALERT_TITLE')}
          onClose={() => {
            showConfirmSpaceDisabledModal(false);
          }}
          actionTitle={localize('DESACTIVATE')}
          cancelTitle={localize('CANCEL')}
          onbuttonAction={() => {
            disabledSpace(selectedSpace);
            showConfirmSpaceDisabledModal(false)
          }}
        />
        <Dialog
          isOpen={isConfirmSpaceGroupDeleteViewDisplayed}
          title={localize('DELETE_SPACE_GROUP_ALERT_TITLE')}
          onClose={() => {
            selectEditSpaceGroup(null);
            showConfirmSpaceGroupDeleteModal(false);
          }}
          actionTitle={localize('DELETE')}
          cancelTitle={localize('CANCEL')}
          onbuttonAction={() => {
            showConfirmSpaceGroupDeleteModal(false);
            deleteSpaceGroup(selectedSpace);
            selectEditSpaceGroup(null);
          }}
        />
        <Dialog
          isOpen={isConfirmSpaceGroupDisabledViewDisplayed}
          title={localize('DESACTIVATE_SPACE_GROUP_ALERT_TITLE')}
          onClose={() => {
            showConfirmSpaceGroupDisabledModal(false);
          }}
          actionTitle={localize('DESACTIVATE')}
          cancelTitle={localize('CANCEL')}
          onbuttonAction={() => {
            disabledSpaceGroup(selectedSpaceGroup);
            showConfirmSpaceGroupDisabledModal(false);
          }}
        />
        <TopContainer>
          <StyledText variant={'h1'}>{localize('SpacesView')}</StyledText>
          <SpacingView height={'35px'} />
          <RowContent>
            <LeftSpaceContainer>
              <ButtonContainer
                disabled={isSpaceEditViewDisplayed}
                style={isSpaceEditViewDisplayed && styles.disabled}
                onPress={newSpaceButtonPressed}
              >
                <LeftButtonContent>
                  <StyledText>{localize('NEW_SPACE')}</StyledText>
                </LeftButtonContent>
                <CircularIcon
                  svg={Plus}
                  tintColor={colors.black}
                  accessibilityLabel={localize('NEW_SPACE')}
                  variant={'fill'}
                  size={'large'}
                />
              </ButtonContainer>
            </LeftSpaceContainer>
            <RightContainer>
              <ButtonRoleContainer>
                <Button
                  variant={'monochrome'}
                  disabled={isSpaceEditViewDisplayed}
                  inverted={true}
                  onPress={manageSpaceTypeButtonPressed}
                  title={localize('MANAGE_SPACE_TYPE')}
                />
              </ButtonRoleContainer>
              <ButtonRoleContainer>
                <Button
                  variant={'monochrome'}
                  disabled={isSpaceEditViewDisplayed}
                  inverted={true}
                  onPress={manageSpaceDetailsButtonPressed}
                  title={localize('MANAGE_SPACE_TAG')}
                />
              </ButtonRoleContainer>
            </RightContainer>
          </RowContent>
        </TopContainer>
        {!isSpaceEditViewDisplayed ? (
          <MainContainer>
            <ActionContainer>
              <FilterContainer>
                <StyledText variant={'h3'}>{localize('FILTER_BY')}</StyledText>

                <FilterActionContainer>
                  <ExpandableFilterListChoice
                    width={110}
                    items={allTypes.map((type) => ({
                      id: type.id,
                      label: type.label,
                    }))}
                    title={localize('FILTER_BY_TYPE')}
                    selectedValues={spacesFilters.types}
                    onSelectedItemsChange={onFilterType}
                  />
                  <SpacingView width={'8px'} height={'5px'} />
                  <ExpandableFilterListChoice
                    width={160}
                    items={spaceTags.map((tag) => ({
                      id: tag.id,
                      label: localizedName(tag.name, activeLanguages),
                    }))}
                    title={localize('FILTER_BY_DETAILS')}
                    selectedValues={spacesFilters.details}
                    onSelectedItemsChange={onFilterDetails}
                  />
                  <SpacingView width={'8px'} height={'5px'} />
                  <ExpandableFilterSingleChoice
                    width={180}
                    items={activeItems}
                    title={localize('FILTER_BY_ACTIVE')}
                    onItemSelected={onFilterActive}
                    selectedValue={spacesFilters.active}
                  />
                  <SpacingView width={'8px'} height={'5px'} />
                  <ExpandableFilterListChoice
                    width={160}
                    items={userGroups.map((group) => ({ id: group.id, label: localizedName(group.name, activeLanguages) }))}
                    title={localize('FILTER_BY_GROUP')}
                    selectedValues={spacesFilters.groups}
                    onSelectedItemsChange={onFilterGroup} 
                  />
                  <SpacingView width={'8px'} height={'5px'} />
                  <ResetButton onPress={resetFilter}>
                    <StyledText variant={'body4'}>
                      {localize('RESET_FILTER')}
                    </StyledText>
                  </ResetButton>
                </FilterActionContainer>
              </FilterContainer>
              <RightStatsContainer>

                <SearchContainer>
                  <StyledText variant={'h3'}>{localize('SEARCH_SPACE')}</StyledText>
                  <SpacingView height={'12px'} />
                  <SingleInputTextContainer
                    placeholder={localize('NAME_OF_SPACES')}
                    multiline={false}
                    maxLength={280}
                    onBlur={
                      (event) => {
                        setSpacesFilters({
                          name: event.nativeEvent.text,
                          groups: spacesFilters.groups,
                          types: spacesFilters.types,
                          details: spacesFilters.details,
                          active: spacesFilters.active,
                        });
                      }
                    } />
                </SearchContainer>

                <View>
                  <StyledText variant={'h5'}>
                    {localize('ALL_ACTIVE_SPACES')}
                  </StyledText>
                  <RowStatsContent>
                    <StyledText
                      variant={'body1'}
                      letterSpacing={'1.2px'}
                      fontSize={22}
                      fontWeight={'600'}
                    >
                      {activeSpaces}
                    </StyledText>
                    <StyledText
                      variant={'body1'}
                      letterSpacing={'1.2px'}
                      fontSize={22}
                    >
                      {' / ' + allSpaces.length}
                    </StyledText>
                  </RowStatsContent>
                  <View>
                  {Platform.OS === 'web'?
                    <LinkContainer onPress={() => window.open(config.url.base + '/plan', '_blank')}>
                        <StyledText 
                          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                          fontWeight={'400'} 
                          fontSize={14} 
                          fontColor={palette.text['primary']} 
                          letterSpacing={'1.2px'} 
                          underline={true} >
                          {localize('OFFICE_MAP')} 
                          <LinkIcon 
                            source={{uri: "https://icon-library.com/images/open-in-new-tab-icon/open-in-new-tab-icon-13.jpg"}}
                        />
                        </StyledText>
                    </LinkContainer>
                    :
                    <LinkContainer onPress={() => navigation.navigate('OfficeMapView')}>
                        <StyledText 
                          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                          fontWeight={'400'} 
                          fontSize={14} 
                          fontColor={palette.text['primary']} 
                          letterSpacing={'1.2px'} 
                          underline={true} >
                          {localize('OFFICE_MAP')} 
                        </StyledText>
                    </LinkContainer>}
                  </View>
                </View>
              </RightStatsContainer>
            </ActionContainer>
            <HorizontalSeparator />
            <HeaderContainer>
              <CellContainer>
                <StyledText variant={'body1'} color={'hint'}>
                  {localize('SPACE_TYPE')}
                </StyledText>
                <CellSvgContainer onPress={onPressSortType}>
                  <SvgIcon
                    svg={Sort}
                    width={18}
                    height={18}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}
                  />
                </CellSvgContainer>
              </CellContainer>
              <CellContainer>
                <StyledText variant={'body1'} color={'hint'}>
                  {localize('SPACE_NAME')}
                </StyledText>
                <CellSvgContainer onPress={onPressSortName}>
                  <SvgIcon
                    svg={Sort}
                    width={18}
                    height={18}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}
                  />
                </CellSvgContainer>
              </CellContainer>
              <CellContainer>
                <StyledText variant={'body1'} color={'hint'}>
                  {localize('SPACE_CAPACITY')}
                </StyledText>
                <CellSvgContainer onPress={onPressSortCapacity}>
                  <SvgIcon
                    svg={Sort}
                    width={18}
                    height={18}
                    fillColor={ThemeUtil.getThemeValue(palette.tertiary)(
                      styleName
                    )}
                  />
                </CellSvgContainer>
              </CellContainer>
              <CellContainer>
                <StyledText variant={'body1'} color={'hint'}>
                  {localize('SPACE_DETAILS')}
                </StyledText>
              </CellContainer>
              <CellContainer>
                <StyledText variant={'body1'} color={'hint'}>
                  {localize('SPACE_PERIOD')}
                </StyledText>
              </CellContainer>
              <ActionCellContainer>
                <StyledText variant={'body1'} color={'hint'}>
                  {localize('SPACE_ACTIVE')}
                </StyledText>
              </ActionCellContainer>
            </HeaderContainer>
            <HorizontalSeparator />
            {allSpaces && 
            <FlatList
              data={allSpaces}
              renderItem={renderSpaceItem}
              keyExtractor={(item, index) => index.toString()}
            />
            }
          </MainContainer>
        ) : (
          <SpaceEditorViewContainer
            style={[!animation ? styles.editorOpen : styles.editorClosed]}
          >
            <SpaceEditorView
              onEditorClosed={closeSpaceEditor}
              onSaveEditedSpace={onSaveEditedSpace}
              onTagsChanged={onTagsChanged}
              onGroupsChanged={onGroupsChanged}
              onTypeChanged={onTypeChanged}
              onSpacesChanged={onSpacesChanged}
              onScheduleChanged={onScheduleChanged}
            />
          </SpaceEditorViewContainer>
        )}
      </ContentContainer>
      <ModalView isVisible={displayEditSpaceTag}>
        <SpaceTagEditorView showSpaceTagEditorModal={showSpaceTagEditorModal}/>
      </ModalView>
      <Dialog
        isOpen={!_.isEmpty(spaceError) || !_.isEmpty(spaceGroupError)}
        title={localize('ERROR_DEFAULT')}
        onClose={() => clearError()}
        onbuttonAction={() => clearError()}
      />
      <Dialog
        isOpen={isWarningPodActivationViewDisplayed}
        title={localize('INFO')}
        body={localize('POD_EMPTY_CAPACITY')}
        onClose={() => showWarningPodActivation(false)}
        onbuttonAction={() => showWarningPodActivation(false)}
      />
      <ModalView isVisible={displayEditSpaceType}>
        <SpaceTypeEditorView showSpaceTypeEditorModal={showSpaceTypeEditorModal}/>
      </ModalView>
    </Container>
  );
};
export const SpacesViewName = 'SpacesView';
export default connect(mapStateToProps, dispatchProps)(Spaces);

const Container = styled.View`
  flex-direction: row;
  background-color: ${palette.background};
  width: 100vw;
  height: 100%;
`;

const ContentContainer = styled.View`
  height: 100vh;
  background-color: ${palette.background};
  flex: 1;
`;

const TopContainer = styled.View`
  align-items: flex-start;
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${palette.background};
  padding: 40px 48px;
  margin: 0;
  border-bottom-color: ${palette.border};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

const RowContent = styled.SafeAreaView`
  flex-direction: row;
  width: 100%;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  align-self: flex-start;
  width: 100%;
`;

const Row = styled.View`
  flex-direction: row;
`;

const ButtonContainer = styled.TouchableOpacity`
  width: 327px;
  height: 64px;
  flex-direction: row;
  align-items: center;
  margin: 16px 0px 0px 0px;
  padding: 0px 16px 0px 32px;
  border-color: ${palette.border};
  border-width: 1px;
  box-shadow: ${shadow};
  border-radius: 8px;
  justify-content: center;
`;

const LeftButtonContent = styled.View`
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
`;

const LeftSpaceContainer = styled.View`
  flex-grow: 1;
`;

const RightContainer = styled.View`
  flex-direction: row;
  align-items: right;
  justify-content: center;
`;

const ButtonRoleContainer = styled.View`
  margin: 20px 0px 20px 20px;
`;

const CellContainer = styled.View`
  flex-direction: row;
  justify-items: center;
  width: 17%;
  padding: 2px 12px;
`;

const CellSvgContainer = styled.TouchableOpacity`
  padding: 2px;
  align-self: center;
`;

const BookingCellContainer = styled.View`
  flex-direction: row;
  width: 154px;
  padding: 2px 12px;
`;

const ActionCellContainer = styled.View`
  flex-direction: row;
  width: 136px;
  padding: 2px 12px;
`;

const FilterContainer = styled.View`
  z-index: 10;
`;

const FilterActionContainer = styled.View`
  margin: 12px 0px;
  flex-direction: row;
`;

const MainContainer = styled.ScrollView`
  padding: 48px;
`;

const ActionContainer = styled.View`
  padding-bottom: 21px;
  flex-direction: row;
  width: 100%;
  z-index: 10;
`;

const HorizontalSeparator = styled.View`
  background-color: ${palette.border};
  opacity: 0.25;
  height: 1px;
  width: 100%;
  flex-shrink: 0;
`;

const ResetButton = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 24px;
  height: 40px;
  text-decoration: underline;
`;

const RightStatsContainer = styled.View`
  margin-bottom: 12px;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;
`;

const SearchContainer = styled.View`
  flex-direction: column;
  min-width: 300px;
  padding: 0 40px 0 0;
`;

const SingleInputTextContainer = styled.TextInput`
  height: 40px;
  padding: 16px 16px 16px 16px;
  background-color: ${palette.textInput};
  border-radius: 4px;
  margin: 0px 0px 4px 0px;
  color: ${palette.info};
`;

const RowStatsContent = styled.View`
  margin-top: 1px;
  flex-direction: row;
  align-items: flex-end;
`;

const StyledScrollView = styled.ScrollView`
  height: 400px;
`;

const StyledView = styled.View`
  background-color: red;
  height: 40px;
  width: 200px;
`;

const SpaceEditorViewContainer = styled.ScrollView`
  transition: 0.4s;
`;

const LinkContainer = styled.TouchableOpacity`
  font-family: 'Barlow';
`;

const LinkIcon = styled.Image`
  width: 14px;
  height: 14px;
  resize: contain;
  margin-left: 5px;
  color: ${colors.grey5};
  font-weight: 400;
`;

const styles = StyleSheet.create({
  disabled: {
    opacity: '0.4',
  },
  editorOpen: {
    transform: `translateX(${Dimensions.get('window').width}px)`,
  },
  editorClosed: {
    transform: 'initial',
  },
});
