import _, { parseInt } from 'lodash';
import Bin from '../../../img/bin.svg';
import Edit from '../../../img/edit.svg';
import React, { useMemo, useState } from 'react';
import SpaceHelper from '../../../utils/SpaceHelper';
import SpacingView from '@components/SpacingView';
import styled, { css, useTheme } from 'styled-components/native';
import SvgIcon from '@components/SvgIcon';
import theme from 'styled-theming';
import { MixedSpace, SpaceGroup } from 'SpaceFeature';
import { StyledText, Dialog, ThemeUtil } from '@space/common';
import { StyleSheet,  Switch } from "react-native";
import { colors } from '../../../features/preferences/style/themes';
import { connect } from 'react-redux';
import { localize, localizedName } from '../../../localization';
import { palette } from '@features/preferences/style/themes';
import { RootState } from 'StoreTypes';
import {
    selectSpace,
    selectSpaceGroup,
    setEditSpaceType,
    showConfirmSpaceDisabled,
    showConfirmSpaceGroupDisabled,
    showEditSpace,
    showWarningPodActivation,
    updateSpaceAsync,
    updateSpaceGroupAsync
} from '../../../features/space/actions';
import moment from 'moment';
import config from '../../../config'

type SpaceRowProps = {
    index: number;
    space: MixedSpace;
    onDisabledPress: (space: MixedSpace) => void;
    onDeletePress: (space: MixedSpace) => void;
    onEditPress: (space: MixedSpace) => void;
};

type ViewProps = {
    index: number;
};


const mapStateToProps = (state: RootState) => ({
    spaceTags: state.space.spaceTags,
    selectedSpace: state.space.selectedSpace,
    selectedSpaceGroup: state.space.selectedSpaceGroup,
    isConfirmSpaceDisabledViewDisplayed: state.user.isConfirmSpaceDisabledViewDisplayed,
    spaceTypes: state.space.spaceTypes,
    activeLanguages: state.organization.languages,
    spaceGroups: state.space.spaceGroups,
});

const dispatchProps = {
    updateSpace: updateSpaceAsync.request,
    updateSpaceGroup: updateSpaceGroupAsync.request,
    selectEditSpace: selectSpace,
    selectEditSpaceGroup: selectSpaceGroup,
    showConfirmSpaceDisabledModal: showConfirmSpaceDisabled,
    showConfirmSpaceGroupDisabledModal: showConfirmSpaceGroupDisabled,
    showWarningPodActivation: showWarningPodActivation,
    setEditSpaceType: setEditSpaceType,
    showSpaceModal: showEditSpace,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    SpaceRowProps;

const trackColorOn = theme('mode', {
    light: '#ACD2B6',
    dark: '#8DC89D',
});

const trackColorOff = theme('mode', {
    light: '#C5C5C5',
    dark: '#111111',
});

const iconBackgroundColor = theme('mode', {
    light: '#333333',
    dark: '#F2F2F2',
});

const SpaceDetailsRow: React.FC<Props> = ({
    activeLanguages,
    index,
    space,
    spaceTags,
    spaceTypes,
    selectedSpace,
    selectedSpaceGroup,
    onDisabledPress,
    onDeletePress,
    onEditPress,
    updateSpace,
    updateSpaceGroup,
    selectEditSpace,
    selectEditSpaceGroup,
    showConfirmSpaceDisabledModal,
    isConfirmSpaceDisabledViewDisplayed,
    showWarningPodActivation,
    spaceGroups
}) => {

    const [currentSpace, setSpace] = useState(space);
    const capacity = localize('SPACE_DETAIL_CAPACITY', { count: space.capacity });

    const details = !_.isEmpty(space.tagsList) ? space.tagsList.map((tag) => localizedName(tag.name, activeLanguages)).join(', ') : '';

    const styleName = useTheme().mode;
    const colorTrackColorOn = ThemeUtil.getThemeValue(trackColorOn)(styleName);
    const colorTrackColorOff = ThemeUtil.getThemeValue(trackColorOff)(styleName);

    //Inline editable text
    const [currentName, setName] = useState(localizedName(currentSpace.name, activeLanguages));
    const [currentCapacity, setCapacity] = useState(currentSpace.capacity);
    const today = moment().startOf('day').format(config.defaultDateFormat);

    const allTypes = useMemo(() => {
        const types = spaceTypes.map((type) => ({ id: type.id, label: type.name }));
        types.push({ id: '0', label: localize('SPACE_UNDEFINED') });
        return types
    }, [spaceTypes]);


      
    const onButtonDeletePress = () => {
        onDeletePress(space);
    }

    
    const onButtonEditPress = () => {
        onEditPress(space);
    }

    const toggleSwitch = () => {
        space.isGroup ? selectEditSpaceGroup(space) : selectEditSpace(space);

        if (SpaceHelper.isActiveSpace(space.openDate) && !isConfirmSpaceDisabledViewDisplayed) {
            onDisabledPress(space)
            return
        }
        updateActivateStatus(space);
    }

    const updateActivateStatus = (space: MixedSpace) => {
        if (space.isGroup) {
            if (space.capacity === 0) {
                showWarningPodActivation(true);
                return;
            }
            const updatedSpaceGroup = {
                id: space.id,
                name: space.name,
                tagsList: space.tagsList,
                typeId: space.type.id,
                openDate: today
            };
            updateSpaceGroup(updatedSpaceGroup);
        } else {
            const updatedSpace = {
                id: space.id,
                name: space.name,
                tagsList: space.tagsList,
                type: space.type,
                capacity: space.capacity,
                openSchedule: space.openSchedule,
                groupId: space.groupId,
                groupsList: space.groupsList,
                openDate: today
            };
            updateSpace(updatedSpace);
        }
    }

    const saveChanges = (space: MixedSpace) => {

        if (selectedSpace) {

            const capacity = parseInt(currentCapacity);

            const updatedSpace = {
                id: space.id,
                type: space.type,
                name: _.isEmpty(currentName) ? space.name : currentName,
                tagsList: space.tagsList,
                capacity: _.isEmpty(capacity) ? space.capacity : capacity,
                openSchedule: space.openSchedule,
                openDate: SpaceHelper.isActiveSpace(space.openDate),
                disabledAt: space.disabledAt,
                groupsList: space.groupsList,
            };
            updateSpace(updatedSpace);
            selectSpace(null);
        } else if (selectedSpaceGroup) {
            const updatedSpaceGroup = {
                id: space.id,
                type: space.type,
                name: _.isEmpty(currentName) ? space.name : currentName,
                tagsList: space.tagsList,
                groupsList: space.groupsList,
            };
            updateSpaceGroup(updatedSpaceGroup);
            selectSpaceGroup(null);
        }
    }

    const renderCapacity = () => {
        return (
            <StyledText variant={'body4'} color={spaceOpenDate <= today ? 'primary' : 'inactive'}>
                {capacity}
            </StyledText>
        );
    }

    const renderScheduleDescription = () => {
            return (
                <StyledText variant={'body4'} color={spaceOpenDate <= today ? 'primary' : 'inactive'}>
                    {SpaceHelper.scheduleToLiteralDescription(space)}
                </StyledText>
            );
    }

    const findGroupName = (item) => {
        const groupName = spaceGroups.find((group: SpaceGroup) => 
            group.id === item.group.id
        )
        return localizedName(groupName.name, activeLanguages)
    }

    const spaceOpenDate = moment(space.openDate).format(config.defaultDateFormat);
    return (
        <>
            <Dialog
                isOpen={isConfirmSpaceDisabledViewDisplayed}
                title={localize('DESACTIVATE_SPACE_ALERT_TITLE')}
                onClose={() => {
                    showConfirmSpaceDisabledModal(false);
                }}
                actionTitle={localize('DESACTIVATE')}
                cancelTitle={localize('CANCEL')}
                onbuttonAction={() => {
                    updateActivateStatus(selectedSpace);
                }} />
            <RootContainer index={index}>
                <CellContainer>
                    <StyledText variant={'body4'} color={spaceOpenDate <= today ? 'primary' : 'inactive'}>
                        {localizedName(space.type?.name, activeLanguages) ?? localize('SPACE_UNDEFINED')}
                    </StyledText>
                </CellContainer>
                <VerticalDivider />
                <CellContainer>
                    <StyledText variant={'body4'} color={spaceOpenDate <= today ? 'primary' : 'inactive'}>
                        {space.group ? 
                            localizedName(space.name, activeLanguages) + ' - ' + findGroupName(space) : localizedName(space.name, activeLanguages)}
                    </StyledText>
                    <SpacingView height={'8px'} />
                </CellContainer>
                <VerticalDivider />
                <CellContainer>
                    {renderCapacity()}
                    <SpacingView height={'8px'} />
                </CellContainer>
                <VerticalDivider />
                <CellContainer>
                    <StyledText variant={'body4'} color={spaceOpenDate <= today ? 'primary' : 'inactive'}>
                        {details}
                    </StyledText>
                    <SpacingView height={'8px'} />
                </CellContainer>
                <VerticalDivider />
                <CellContainer>
                    {renderScheduleDescription()}
                    <SpacingView height={'8px'} />
                </CellContainer>
                <VerticalDivider />
                <ActionCellContainer>
                    <Switch
                        trackColor={{ false: colorTrackColorOff, true: colorTrackColorOn }}
                        thumbColor={colors.white}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={toggleSwitch}
                        value={spaceOpenDate <= today}
                    />
                </ActionCellContainer>
                <VerticalDivider />
                <ActionCellContainer>
                    <StyledTouchableOpacity onPress={onButtonEditPress}>
                        <SvgIcon svg={Edit} width={24} height={24} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
                    </StyledTouchableOpacity>

                    <StyledTouchableOpacity onPress={onButtonDeletePress}>
                        <SvgIcon svg={Bin} width={24} height={24} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
                    </StyledTouchableOpacity>
                </ActionCellContainer>
            </RootContainer>
            <HorizontalSeparator />
        </>
    );
};

const elevation = theme('mode', {
    light: css`
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  `,
    dark: css`
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.6);
  `,
});

const color = theme('mode', {
    light: colors.grey5,
    dark: colors.grey4,
  });

const RootContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
`;

const CellContainer = styled.View`
    flex-direction: row;
    width: 17%;
    padding: 20px;
`;

const ActionCellContainer = styled.View`
    flex-direction: row;
    width: 68px;
    padding: 12px;
`;

const StyledTouchableOpacity = styled.TouchableOpacity`
    padding: 8px;
`;

const HorizontalSeparator = styled.View`
    background-color: ${palette.border};
    opacity: 0.25;
    height: 1px;
    width: 100 %;
    flex-shrink: 0;
`;

const VerticalDivider = styled.View<ViewProps>`
  margin: 18px 0;
  flex-shrink: 0;
  opacity: 0.25;
  border-width: 0;
  align-self: stretch;
  background: ${color};
  width: ${`${StyleSheet.hairlineWidth}px`};
`;

export default connect(mapStateToProps, dispatchProps)(SpaceDetailsRow);
