import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import moment from 'moment';
import config from '../../config';
import styled from 'styled-components/native';
import { colors, palette } from '@features/preferences/style/themes';
import { StyledText } from '@space/common';
import { localize } from '../../localization';
import ForwardArrow from '../img/forward_arrow.svg';
import BackArrow from '../img/back_arrow.svg';

type CalendarProps = {
  displayWeekView?: boolean;
  scrollOnRightClick?:() => void;
  scrollOnLeftClick?:() => void;
  selectedDate: string;
  scrollCount?: number;
  weeks: number;
};

const CalendarHeader: React.FC<CalendarProps> = ({ 
  selectedDate,
  displayWeekView,
  scrollOnRightClick,
  scrollOnLeftClick,
  scrollCount,
  weeks
}) => {

  return (
    <HeaderContainer displayWeekView={displayWeekView ?? false}>
      <TextContainer>
        <StyledText
          variant={'h3'}
          fontColor={colors.grey4}
          style={textStyles({}).dateText}>
          {moment(selectedDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_DAY_FORMAT')).toUpperCase()}
        </StyledText>
        <TodayTextContainer
          selected={selectedDate === moment().format(config.defaultDateFormat)}>
          <StyledText
            variant={'caption'}
            fontWeight={'500'}
            style={
              textStyles({
                selected:
                  selectedDate === moment().format(config.defaultDateFormat),
              }).todayText
            }>
            {localize('TODAY')}
          </StyledText>
        </TodayTextContainer>
      </TextContainer>
      {displayWeekView && 
        <ArrowSvgContainer>
          <ArrowContent 
            style={[textStyles({}).leftArrowIcon, textStyles({}).disabled]}
            onPress={() => { scrollOnLeftClick()}}
            disabled={scrollCount === 0}
          >
            <BackArrow /> 
          </ArrowContent>
          <ArrowContent 
            onPress={()=> { scrollOnRightClick()}}
            disabled={scrollCount === (weeks-1)}
          >
            <ForwardArrow />
          </ArrowContent>
        </ArrowSvgContainer>
      }
    </HeaderContainer>
  );
};

export default CalendarHeader;

type DayTextProps = {
  selected: boolean;
};

type HeaderProps = {
  displayWeekView: boolean;
};

type ArrowsProps = {
  disabled: boolean;
}

const HeaderContainer = styled.View<HeaderProps>`
  width: 325px;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 24px;
  margin-left: 24px;
  align-items: center;
  padding-top: ${(props) => (props.displayWeekView ? '24px' : '26px')};
  padding-bottom: ${(props) => (props.displayWeekView ? '11px' : '20px')};
`;

const TextContainer = styled.View`
  flex-direction: row;
`;

const TodayTextContainer = styled.View<DayTextProps>`
  align-items: center;
  align-content: center;
  border-radius: 4px;
  width: ${(props) => (props.selected ? 'auto' : 0)};
  height: 20px;
  opacity: ${(props) => (props.selected ? 100 : 0)};
  background-color: ${palette.secondary};
  padding: ${(props) => (props.selected ? '0 8px' : '0 0')};
`;

const ArrowSvgContainer = styled.View`
  flex-direction: row;
`;

const ArrowContent = styled.TouchableOpacity<ArrowsProps>`
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const textStyles = (props) =>
  StyleSheet.create({
    todayText: {
      textAlign: 'center',
      paddingVertical: 2,
      borderRadius: 4,
      height: 16,
      width: props.selected ? 'auto' : 0,
      color: colors.white,
    },
    dateText: {
      marginRight: 8,
      letterSpacing: 3.2,
    },
    dayText: {
      paddingBottom: 1,
      paddingTop: 2,
      textAlign: 'center',
      width: 32,
      height: 18,
    },
    leftArrowIcon: {
      marginLeft: 18,
      marginRight: 32,
    }
  });
