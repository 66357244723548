import React from 'react';
import moment from 'moment';
import DayView from './DayView';
import { connect } from 'react-redux';
import { RootState } from 'StoreTypes';
import config from '../../config';
import { setSelectedDate } from '@features/booking/actions';
import _ from 'lodash';
import styled from 'styled-components/native';
import { momentToCalendarDate } from "@space/common";

type WeekProps = {
  startDate: Date;
  occupations: {};
  onDateSelect: (date: string) => void;
};

const mapStateToProps = (state: RootState) => ({
  selectedDate: state.booking.selectedDate,
});

const dispatchProps = {
  selectDate: setSelectedDate,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  WeekProps;

const WeekView: React.FC<Props> = ({
  onDateSelect,
  startDate,
  selectedDate,
  selectDate,
  occupations,
}) => {
  const daysView: any[] = _.range(7).map((day) => {
    const date = moment(startDate).day(day);
    const occupation = occupations[date.format(config.defaultDateFormat)] ?? null;

    return (
      <ItemContainer key={day}>
        <DayView
          marking={{
            selected: date.format(config.defaultDateFormat) === selectedDate,
          }}
          state={date.format(config.defaultDateFormat) === moment().format(config.defaultDateFormat) ? 'today' : ''}
          occupation={occupation}
          date={momentToCalendarDate(date)}
          onPress={() => {
            selectDate(date.format(config.defaultDateFormat));
            onDateSelect(date.format(config.defaultDateFormat))
          }}
        />
      </ItemContainer>
    );
  });
  return <Container>{daysView}</Container>;
};
export default connect(mapStateToProps, dispatchProps)(WeekView);

const Container = styled.View`
  flex: 1;
  flex-basis: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  max-width: 375px;
  padding-bottom: 1px;
`;
const ItemContainer = styled.View`
  justify-content: center;
`;
