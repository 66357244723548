import React, { useEffect, useCallback, useMemo } from "react";
import {
    FlatList,
    TouchableOpacity,
    View,
    ScrollView
} from 'react-native';
import { connect } from "react-redux";
import styled, { useTheme } from 'styled-components/native';
import { RootState } from 'StoreTypes';
import { colors, palette, shadow } from '../../preferences/style/themes';
import { Button } from '@space/common';
import { localize } from '../../../localization';
import { StyledText, Dialog, ThemeUtil, CircularIcon } from '@space/common';
import { useState } from "react";
import _ from 'lodash';
import SvgIcon from '@components/SvgIcon';
import { Formik, FieldArray } from 'formik';
import { StyleSheet } from 'react-native';
import * as Yup from 'yup';
import StyledTextInput from "@components/form/StyledTextInput";
import SimpleDeleteRow, { RowItem } from "@components/list/SimpleDeleteRow";
import Close from '../../../img/close.svg';
import VerticalDivider from '@components/VerticalDivider';
import {
    createSpaceTypeAsync,
    updateSpaceTypeAsync,
    deleteSpaceTypeAsync,
    showSpaceTypeEditor,
    selectSpaceType,
    showConfirmSpaceTypeDelete
} from "../actions";
import {
    fetchOrganizationAsync,
} from '@features/organization/actions';
import Seat from '../../../img/seat.svg';
import Pod from '../../../img/pod.svg';
import Room from '../../../img/room.svg';
import { SpaceModel, SpaceModelIcon } from '@features/space';

const mapStateToProps = (state: RootState) => ({
    organization: state.organization.organization,
    spaceTypes: state.space.spaceTypes,
    selectedSpaceType: state.space.selectedSpaceType,
    isConfirmSpaceTypeDeleteViewDisplayed: state.space.isConfirmSpaceTypeDeleteViewDisplayed,
    activeLanguages: state.organization.languages,
});

const dispatchProps = {
    fetchOrganization: fetchOrganizationAsync.request,
    createSpaceType: createSpaceTypeAsync.request,
    updateSpaceType: updateSpaceTypeAsync.request,
    deleteSpaceType: deleteSpaceTypeAsync.request,
    showSpaceTypeEditor: showSpaceTypeEditor,
    selectSpaceType: selectSpaceType,
    showConfirmSpaceTypeDeleteModal: showConfirmSpaceTypeDelete,
};

type SpaceTypeProps = {
    showSpaceTypeEditorModal: (active: boolean) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & SpaceTypeProps;

const SpaceTypeEditorView: React.FC<Props> = ({
    organization,
    spaceTypes,
    selectedSpaceType,
    isConfirmSpaceTypeDeleteViewDisplayed,
    activeLanguages,
    createSpaceType,
    updateSpaceType,
    deleteSpaceType,
    fetchOrganization,
    selectSpaceType,
    showConfirmSpaceTypeDeleteModal,
    showSpaceTypeEditorModal,
}) => {

    useEffect(() => {
        fetchOrganization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const styleName = useTheme().mode;
    const newType = { id: '', name: [], model: Object.values(SpaceModel)[0], icon: Object.values(SpaceModelIcon)[0] };
    const [currentType, setSpaceType] = useState(newType);
    const savePressed = () => {

        if (selectedSpaceType) {
            const updatedSpaceType = {
                id: currentType.id,
                name: currentType.name,
                model: currentType.model,
                icon: currentType.icon,
            };
            updateSpaceType(updatedSpaceType);
        } else {
            const createdSpaceType = {
                name: currentType.name,
                model: currentType.model,
                icon: currentType.icon,
            };
            createSpaceType(createdSpaceType);
        }

        resetField();
    };

    const SpaceTypeSchema = Yup.object().shape({
        name: Yup.array().of(
            Yup.object().shape({
                languageId: Yup.number().required(localize('REQUIRED')),
                name: Yup.string().required(localize('REQUIRED')),
                title: Yup.string().required(localize('REQUIRED'))
            })
        ).required(localize('INVALID_NAME')),
    });

    const onCancelEditPress = () => {
        selectSpaceType(null);
        resetField();
    };

    const resetField = () => {
        currentType.id = newType.id;
        currentType.name = [{}];
        currentType.model = newType.model;
        currentType.icon = newType.icon;
        setSpaceType(currentType);
    }

    const renderCancelEdit = () => {
        return (
            <StyledButton onPress={onCancelEditPress} variant={'cancel'} title={localize('CANCEL')} fontColor={colors.black} />
        );
    };

    const renderTick = () => (
        <CheckedOnContainer />
    );

    const svgType = (model: string) => {
        switch (model) {
            case SpaceModelIcon.SEAT:
                return Seat;
            case SpaceModelIcon.POD:
                return Pod;
            case SpaceModelIcon.ROOM:
                return Room;
        }
    };

    const cancelButtonPressed = () => {
        selectSpaceType(null);
        showSpaceTypeEditorModal(false);
    };

    const onRowPress = (item: RowItem) => {
        const type = spaceTypes.filter((type: SpaceType) => type.id === item.id)[0];
        selectSpaceType(type);
        currentType.id = type.id;
        currentType.name = type.name;
        currentType.model = type.model;
        currentType.icon = type.icon;
        setSpaceType(currentType);
    };

    const onDeletePress = (item: RowItem) => {
        const type = spaceTypes.filter((type: SpaceType) => type.id === item.id)[0]
        selectSpaceType(type);
        showConfirmSpaceTypeDeleteModal(true);
    };

    const renderSpaceType = ({ item }) => {
        return (
            <SimpleDeleteRow item={{ id: item.id, label: item.name }} onRowPress={onRowPress} onDeletePress={onDeletePress} />
        );
    };


    const renderSpaceTypeForm = () => {
        const defaultName = activeLanguages.map((language: Language) => ({
            languageId: language.id,
            name: currentType.name?.find(i=>i.languageId === language.id)?.name ?? '',
            title: localize(language.name)
        }));
        return (<Formik
            enableReinitialize={true}
            validationSchema={SpaceTypeSchema}
            initialValues={{
                name: defaultName,
                model: currentType.model,
                icon: currentType.icon,
            }}
            onSubmit={(values, { setFieldValue, setFieldTouched, resetForm }) => {
                currentType.name = values.name;
                currentType.model = values.model;
                currentType.icon = values.icon;
                setSpaceType(currentType);
                savePressed();
                setFieldValue('values', {
                    name: values.name.map((newName) => ({ name: newName.name, languageId: newName.languageId })),
                    model: currentType.model,
                    icon: currentType.icon,
                  });
                setFieldTouched('values', false);
                resetForm({ values: { name: defaultName } });
            }}
        >
            {({ handleChange, handleSubmit, setFieldValue, values, errors }) => {
                const submitDisabled = useMemo(() => {
                    return !values.name.every(value => value.name !== '');
                }, [values]);                

                const onModelCheckChange = (model: string) => {
                    currentType.model = model;
                    setFieldValue("model", model, false);
                }

                const onIconCheckChange = (icon: string) => {
                    currentType.icon = icon;
                    setFieldValue("icon", icon, false);
                }

                return (
                    <FormContainer>
                        <FieldContainer style={styles.field}>
                            <FieldArray>
                                    {() => (
                                        <>
                                            {values.name.map((name, index) => {
                                                const title = localize(`${name.title}`);
                                                return (
                                                    <View key={name.languageId}>
                                                        <StyledTextInput
                                                        value={name.name}
                                                        placeholder={localize('PLACEHOLDER_NAME', {title: title})}
                                                        onChangeText={handleChange(`name.${index}.name`)}
                                                        title={title}
                                                        multiline={false}
                                                        maxLength={185}
                                                        />
                                                    </View>
                                                );
                                            })}
                                        </>
                                    )}
                                </FieldArray>
                        </FieldContainer>
                        <CheckboxContainer>
                            <TextContainer>
                                <StyledText variant={'caption'} color={'hint'}>
                                    {localize('CHOOSE_MODEL')}
                                </StyledText>
                            </TextContainer>
                            {!selectedSpaceType ? Object.keys(SpaceModel).filter(key => key != SpaceModel.UNDEFINED).map((key) => {
                                return <Checkbox key={key} onPress={() => onModelCheckChange(key)}>
                                    <TextContainer>
                                        <StyledText variant={'body3'}>{localize('SPACE_TYPE_DESCRIPTION_' + key)}</StyledText>
                                    </TextContainer>
                                    <CheckedContainer>
                                        {key === currentType.model ? renderTick() : null}
                                    </CheckedContainer>
                                </Checkbox>;
                            }) : <SelectedSpaceModel>
                                <StyledText variant={'body3'}>{localize('SPACE_TYPE_NAME_' + selectedSpaceType.model)}</StyledText>
                                <StyledText variant={'caption'}>{localize('SPACE_TYPE_DESCRIPTION_' + selectedSpaceType.model)}</StyledText>
                            </SelectedSpaceModel>}
                        </CheckboxContainer>
                        <FieldContainer style={styles.field}>
                            <IconsContainer>
                                <TextContainer>
                                    <StyledText variant={'caption'} color={'hint'}>
                                        {localize('CHOOSE_ICON')}
                                    </StyledText>
                                </TextContainer>
                                <CircularIconContainer>
                                    {Object.values(SpaceModelIcon).map((key) => {
                                        return <TouchableOpacity key={key} onPress={() => onIconCheckChange(key)}>
                                            <CircularIcon
                                                svg={svgType(key)}
                                                variant={key == currentType.icon ? 'stroke' : null}
                                                inverted={true}
                                                accessibilityLabel={localize('NEW_SPACE')}
                                                size={'large'}
                                            />
                                        </TouchableOpacity>
                                    })}
                                </CircularIconContainer>
                            </IconsContainer>
                        </FieldContainer>
                        <ButtonContainer>
                            <Button onPress={handleSubmit} title={selectedSpaceType ? localize('UPDATE') : localize('CREATE')} disabled={!_.isEmpty(errors) || submitDisabled} fontColor={colors.black} />
                            {selectedSpaceType ? renderCancelEdit() : null}
                        </ButtonContainer>
                    </FormContainer>
                );
            }}
        </Formik>
        )
    };
// Exiting modal with escape key
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
        document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, []);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
        cancelButtonPressed();
        }
    },[]);

    return (
        <Container>
            <ContentContainer>
                <HeaderContainer>
                    <Title>
                        <StyledText variant={'h2'}>{localize('MANAGE_SPACE_TYPE')}</StyledText>
                    </Title>
                    <CloseContainer onPress={cancelButtonPressed}>
                        <SvgIcon svg={Close} fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)} />
                    </CloseContainer>
                </HeaderContainer>
                <ScrollView>
                    <MainContainer>
                        <ColumnContainer>
                            <ColumnTitle>
                                <StyledText variant={'h3'} color={'hint'}>
                                    {selectedSpaceType ? localize('MODIFY_SPACE_TYPE') : localize('CREATE_SPACE_TYPE')}
                                </StyledText>
                            </ColumnTitle>
                            {renderSpaceTypeForm()}
                        </ColumnContainer>
                        <VerticalDivider variant={'middle'} />
                        <ColumnRightContainer>
                            <ColumnRightTitle>
                                <StyledText variant={'h3'} color={'hint'}>
                                    {localize('EXISTING_SPACE_TYPE')}
                                </StyledText>
                            </ColumnRightTitle>
                            <FlatList
                                style={{ overflow: "visible" }}
                                data={spaceTypes.sort((typeA, typeB) => (typeA.name < typeB.name ? -1 : 1))}
                                renderItem={renderSpaceType}
                                keyExtractor={(item: SpaceType) => item.id}
                            />
                        </ColumnRightContainer>
                    </MainContainer>
                </ScrollView>
            </ContentContainer>
            <Dialog
                isOpen={isConfirmSpaceTypeDeleteViewDisplayed}
                title={localize('DELETE_SPACE_TYPE')}
                onClose={() => {
                    showConfirmSpaceTypeDeleteModal(false);
                }}
                actionTitle={localize('DELETE')}
                cancelTitle={localize('CANCEL')}
                onbuttonAction={() => {
                    deleteSpaceType(selectedSpaceType);
                    selectSpaceType(null);
                    showConfirmSpaceTypeDeleteModal(false);
                }} />
        </Container >
    );
};

export default connect(mapStateToProps, dispatchProps)(SpaceTypeEditorView);

const Container = styled.View`
  width: 100vw;
  max-width: 800px;
  background-color: ${palette.background};
  max-height: 90vh;
  flex-direction: row;
`;

const ButtonContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 34px 0 0;
  align-self: flex-start;
`;

const CloseContainer = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  padding: 32px 24px 24px 24px;
  background: ${palette.background};
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom-width: 1px;
  border-bottom: solid black;
`;

const Title = styled.View`
  padding-top: 24px;
  flex-grow: 1;
`;

const NameContainer = styled.View`
    display: flex;
    flex-direction: column;
`

const FieldContainer = styled.View`
  width: 50%;
  flex-direction: row;
  padding: 16px 16px 0 0;
`;

const FormContainer = styled.View`
`;

const ColumnContainer = styled.View`
  margin: 0px 0px 14px 0px;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ColumnTitle = styled.View`
`;

const ColumnRightContainer = styled.View`
  width: 250px;
  margin: 0px 20px 14px 0px;
`;

const ColumnRightTitle = styled.View`
    padding: 8px;
`;

const MainContainer = styled.View`
  padding: 0px 24px;
  flex-direction: row;
  justify-content: flex-end;
  margin: 22px 0px;
`;

const CheckboxContainer = styled.View`
  padding: 10px 0;
`;

const Checkbox = styled.TouchableOpacity`
  width: 408px;
  height: 56px;
  flex-direction: row;
  align-items: center;
  margin: 16px 24px 0 0;
  padding: 16px;
  border-color: ${palette.border};
  border-width: 1px;
  box-shadow: ${shadow};
  border-radius: 4px;
  justify-content: space-between;
`;

const CheckedContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${palette.actionItem};
  border-radius: 12px;
  width: 24px;
  height: 24px;
`;

const CheckedOnContainer = styled.View`
  background-color: ${palette.border};
  border-radius: 6px;
  width: 12px;
  height: 12px;
`;

const SelectedSpaceModel = styled.View`
  margin: 10px 0 0;
`;

const TextContainer = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
`;

const IconsContainer = styled.View`
  flex-direction: column;
  padding: 4px 8px;
`;

const CircularIconContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

const LinkContainer = styled.View`
  text-decoration-line: underline;
`;

const ContentContainer = styled.View`
  background-color: ${palette.background};
  flex: 1;
`;

const StyledButton = styled(Button)`
    margin-left: 24px;
    border: 1px solid black;
`;

const styles = StyleSheet.create({
    field: {
        marginLeft: '-8px',
        flexDirection: 'column',
    }
});
