import React, { useEffect, useState, useMemo, useRef } from "react";
import {
    FlatList,
} from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { StyledText, ThemeUtil } from '@space/common';
import Checked from '../img/checked.svg';
import Down from '../img/Down.svg';
import Up from '../img/Up.svg';
import SvgIcon from '@components/SvgIcon';
import { xorBy } from "lodash";
import _ from 'lodash';
import {
    colors,
    palette,
} from '@features/preferences/style/themes';
import theme from 'styled-theming';
import SpacingView from '../SpacingView';

export type ChoiceItem = {
    id: string;
    label: string;
};

type ViewProps = {
    width: number;
};

type ChoiceProps = {
    items: ChoiceItem[];
    selectedValues: string[];
    onSelectedItemsChange: (selectedIds: string[]) => void;
    onTouchOutside: () => void;
    width: number;
};

const tickColor = theme('mode', {
    light: colors.grey6,
    dark: colors.white,
});

const ListChoice: React.FC<ChoiceProps> = ({
    items,
    onSelectedItemsChange,
    onTouchOutside,
    selectedValues,
    width,
}) => {
    const mainContainer = useRef();
    const styleName = useTheme().mode;

    const [expanded, setExpanded] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>(selectedValues);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = (event) => {
        if (mainContainer.current.contains(event.target)) {
            return;
        }
        onTouchOutside();
    };

    const onExpandPress = () => {
        setExpanded(!expanded);
    };

    const onCheckChange = (item: ChoiceItem) => {
        const updated = xorBy(selectedItems, [item.id]);
        setSelectedItems(updated);
        onSelectedItemsChange(updated);
    }

    const renderTick = (item: ChoiceItem) => (
        <StyledChecked>
            <SvgIcon svg={Checked} width={24} height={24} fillColor={ThemeUtil.getThemeValue(palette.border)(styleName)} />
        </StyledChecked>
    );

    return (
        <StyledTouchableWithoutFeedback onPress={() => onExpandPress} ref={mainContainer}>
            <Container width={width}>
                <FlatList
                    data={items}
                    renderItem={({ item }) => (
                        <ItemContainer>
                            <TextContainer>
                                <StyledText variant={'body3'} color={'primary'}>
                                    {item.label}
                                </StyledText>
                            </TextContainer>
                            <CheckedContainer onPress={() => onCheckChange(item)}>
                                {selectedItems && selectedItems.includes(item.id) ? renderTick(item) : null}
                            </CheckedContainer>
                        </ItemContainer>
                    )}
                />
            </Container >
        </StyledTouchableWithoutFeedback>
    );
}

export default ListChoice;

const StyledTouchableWithoutFeedback = styled.TouchableWithoutFeedback`
    position: absolute;
    top: 0px;
    bottom: 0px
    left: 0pc;
    right: 0px;
  resize-mode: 'cover';
  background-color: red;
`;

const Container = styled.View<ViewProps>`
  width: ${(props) => `${props.width}px` ?? '150px'};
  height: 250px;
  padding-top: 12px;
`;

const ItemContainer = styled.View`
  justify-content: center;
  align-items: center;
  margin: 9px;
  flex-direction: row;
`;

const StyledChecked = styled.View`
  width: 24px;
  height: 24px;
`;

const DropDownContainer = styled.View<ViewProps>`
  background-color: ${palette.background};
  border: 1px solid ${palette.color};
  border-radius: 4px;
  width: ${(props) => `${props.width + 100}px` ?? '150px'};
  height: 150px;
`;

const TextContainer = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
`;

const CheckedContainer = styled.TouchableOpacity`
  background-color: ${palette.actionItem};
  border-radius: 4px;
  width: 24px;
  height: 24px;
`;

