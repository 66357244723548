import _ from 'lodash';
import Bin from '../../img/bin.svg';
import Ellipse from '../../img/ellipse.svg';
import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import SvgIcon from '@components/SvgIcon';
import { connect } from 'react-redux';
import { palette } from '@features/preferences/style/themes';
import { RootState } from 'StoreTypes';
import { StyledText, ThemeUtil } from '@space/common';
import { localizedName } from '../../localization';

export type RowItem = {
  id: string;
  label: labelItem[];
};

type labelItem = {
  name: string;
  languageId: string;
};

const mapStateToProps = (state: RootState) => ({
  selectedSpaceType: state.space.selectedSpaceType,
  activeLanguages: state.organization.languages
});

type Props = {
  item: RowItem;
  disabled: Boolean;
  onRowPress: (item: RowItem) => void;
  onDeletePress: (item: RowItem) => void;
} & ReturnType<typeof mapStateToProps>;

const SimpleDeleteRow: React.FC<Props> = ({
  activeLanguages,
  item,
  disabled,
  selectedSpaceType,
  onRowPress,
  onDeletePress,
}) => {
  const styleName = useTheme().mode;
  const onPress = () => {
    onRowPress(item);
  };

  const onButtonDeletePress = () => {
    onDeletePress(item);
  };

  return (
    <StyledTouchableOpacity disabled={disabled} onPress={onPress}>
      {selectedSpaceType?.id == item.id && (
        <EllipseContainer>
          <SvgIcon svg={Ellipse} width={8} height={8} />
        </EllipseContainer>
      )}
      <LabelContainer>
        <StyledText fontColor={disabled && palette.disable} variant={'body3'}>{localizedName(item.label, activeLanguages)}</StyledText>
      </LabelContainer>
      {!disabled && <CloseTouchableOpacity onPress={onButtonDeletePress}>
        <SvgIcon
          svg={Bin}
          width={24}
          height={24}
          fillColor={ThemeUtil.getThemeValue(palette.tertiary)(styleName)}
        />
      </CloseTouchableOpacity>}
    </StyledTouchableOpacity>
  );
};

const StyledTouchableOpacity = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
`;

const LabelContainer = styled.View`
  flex-grow: 1;
  padding: 8px 0;
  justify-content: center;
`;

const CloseTouchableOpacity = styled.TouchableOpacity`
  width: 24px;
  padding: 8px;
  justify-content: center;
`;

const EllipseContainer = styled.View`
  position: absolute;
  left: -8px;
`;

export default connect(mapStateToProps)(SimpleDeleteRow);
