import {
  loginRequest,
  logoutRequest,
  msalConfig,
  tokenRequest,
} from './AuthConfig';
import {
  AuthenticationResult,
  PublicClientApplication,
} from '@azure/msal-browser';
import config from '../config';

export class AuthManager {
  private static instance: AuthManager;
  readonly client: PublicClientApplication;

  public static getInstance(): AuthManager {
    if (!AuthManager.instance) {
      AuthManager.instance = new AuthManager(config.url.redirect, config.authClientId);
    }
    return AuthManager.instance;
  }

  private constructor(redirectUrl: string, clientId: string) {
    this.client = new PublicClientApplication(msalConfig(redirectUrl, clientId));
  }

  signIn = async () => {
    this.client.loginRedirect(loginRequest);
  };

  signOut = async () => {
    const accountInfo = this.client.getAccountByUsername(this.getUsername());
    this.client.logout(logoutRequest(accountInfo));
  };

  getToken = async () => {
    const accountInfo = this.client.getAccountByUsername(this.getUsername());
    if (accountInfo) {
      const request = tokenRequest(accountInfo);

      return await this.client
        .acquireTokenSilent(request)
        .then((result: AuthenticationResult) => {
          return result.accessToken;
        })
        .catch(async () => {
          await this.client
            .acquireTokenPopup(request)
            .then((result: AuthenticationResult) => {
              return result.accessToken;
            })
            .catch((error) => {
              return null;
            });
        });
    } else {
      return null;
    }
  };

  getTokenSilently = async () => {
    const accountInfo = this.client.getAccountByUsername(this.getUsername());
    if (accountInfo) {
      const request = tokenRequest(accountInfo);

      return await this.client
        .acquireTokenSilent(request)
        .then((result: AuthenticationResult) => {
          return result.accessToken;
        });
    } else {
      return null;
    }
  }

  getUsername = (): string => {
    const currentAccounts = this.client.getAllAccounts();
    return currentAccounts?.[0]?.username ?? '';
  };
}
