import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FlatList, View, StyleSheet } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { StyledText, ThemeUtil } from '@space/common';
import _ from 'lodash';
import { colors, palette, shadow } from '@features/preferences/style/themes';
import WeekDayView from '../Calendar/WeekDayView';
import { localize } from '../../localization';
import { Schedule } from 'LoginFeature';
import PeriodCalendar from '../Calendar/PeriodCalendar';
import { WeekDay, weekDays } from '../../utils/dateutils';
import moment from "moment";
import { Space } from 'SpaceFeature';
import SpaceHelper from "../../utils/SpaceHelper";
import { connect } from 'react-redux';

const mapStateToProps = (state: RootState) => ({
  organization: state.organization.organization,
});

export enum SpaceEditorType {
  ALWAYS = 'always',
  SOME_WEEK_DAY = 'week',
  BETWEEN_DATES = 'dates',
}

export type SingleChoiceItem = {
  id: string;
  label: string;
};

type ViewProps = {
  width: number;
};

type ChoiceProps = {
  editorType: string;
  selectedValue: Schedule;
  onScheduleSelected: (schedule: Schedule) => void;
  width: number;
  space: Space;
};

const ListScheduleChoice: React.FC<ChoiceProps> = ({
  editorType,
  selectedValue,
  onScheduleSelected,
  width,
  space,
  organization
}) => {
  const mainContainer = useRef();
  const styleName = useTheme().mode;

  const items: SingleChoiceItem = [
    { id: SpaceEditorType.ALWAYS, label: localize('SCHEDULE_ALWAYS') },
    {
      id: SpaceEditorType.SOME_WEEK_DAY,
      label: localize('SCHEDULE_SOME_DAYS'),
    },
    {
      id: SpaceEditorType.BETWEEN_DATES,
      label: localize('SCHEDULE_BETWEEN_DATES'),
    },
  ];
  const [selectedId, setSelectedId] = useState(editorType);
  const [currentDays, setDays] = useState<number[]>([]);
  const [currentSchedule, setSchedule] = useState(selectedValue);
  const [currentStartDate, setCurrentStartDate] = useState(moment(space?.openSchedule?.startDate).utc());
  const [currentEndDate, setCurrentEndDate] = useState(moment(space?.openSchedule?.endDate).utc());

  const onCheckChange = (item: SingleChoiceItem) => {
    setSelectedId(item.id);
    if (item.id === SpaceEditorType.ALWAYS) {
      setDays([])
      setCurrentStartDate('');
      setCurrentEndDate('');
      setSchedule(null);
      onScheduleSelected({startDate: null, endDate: null, rrule: null});
    } else if (item.id === SpaceEditorType.SOME_WEEK_DAY) {
      setCurrentStartDate('');
      setCurrentEndDate('');
      setSchedule(null);
      onScheduleSelected(null);
    } else {
      setDays([])
      setSchedule(null)
      onScheduleSelected(null);
    }
  };

  const renderSubview = () => {
    return (
      <>
        <WeekDayViewContainer
          pointerEvents={
            selectedId !== SpaceEditorType.SOME_WEEK_DAY ? 'none' : 'auto'
          }
          style={
            selectedId !== SpaceEditorType.SOME_WEEK_DAY && styles.disabled
          }
        >
          <WeekDayView
            selectedDays={currentDays}
            onSelectedDays={onSelectedDays}
            space={space}
          />
        </WeekDayViewContainer>
        <PeriodCalendarContainer
          pointerEvents={
            selectedId !== SpaceEditorType.BETWEEN_DATES ? 'none' : 'auto'
          }
          style={
            selectedId !== SpaceEditorType.BETWEEN_DATES && styles.disabled
          }
        >
          <PeriodCalendar
            onStartDateSelect={onStartDateSelect}
            onEndDateSelect={onEndDateSelect}
            space={space}
            currentStartDate={currentStartDate}
            currentEndDate={currentEndDate}
            setCurrentStartDate={setCurrentStartDate}
            setCurrentEndDate={setCurrentEndDate}
          />
        </PeriodCalendarContainer>
      </>
    );
  };

  const renderTick = () => <CheckedOnContainer />;

  const onSelectedDays = (days: number[]) => {
    setDays(days);

    const byday =
      days?.length > 0
        ? 'BYDAY=' + days.map((dayNumber) => weekDays[dayNumber]).join(',')
        : '';
    const rrule = 'RRULE:FREQ=WEEKLY;' + byday;
    const schedule = byday ? { startDate: null, endDate: null, rrule: rrule } : null;
    setSchedule(schedule);
    onScheduleSelected(schedule);
  };

  const onStartDateSelect = (date: string) => {
    const selectedDate = moment.tz(date, SpaceHelper.getRruleCurrentOffice(organization).options.tzid).format().toString();

    const schedule = {
      startDate: selectedDate,
      endDate: moment.tz(currentSchedule?.endDate, SpaceHelper.getRruleCurrentOffice(organization).options.tzid).format().toString() ?? selectedDate,
      rrule: null,
    };
    setSchedule(schedule);
    onScheduleSelected(schedule);
  };

  const onEndDateSelect = (date: string) => {
    const selectedDate = moment.tz(date, SpaceHelper.getRruleCurrentOffice(organization).options.tzid).format().toString();
    
    const schedule = {
      startDate: moment.tz(currentSchedule?.startDate, SpaceHelper.getRruleCurrentOffice(organization).options.tzid).format().toString() ?? selectedDate,
      endDate: selectedDate,
      rrule: null,
    };
    setSchedule(schedule);
    onScheduleSelected(schedule);
  };

  const selectedTick = () => {
    if(_.isEmpty(selectedId)){
      if(_.isEmpty(space?.openSchedule?.rrule) && _.isEmpty(space?.openSchedule?.startDate) && space?.openSchedule){
        setSelectedId('always');
      } else if (space?.openSchedule?.rrule?.includes('WEEKLY')){
        setSelectedId('week');
      } else if (!_.isEmpty(space?.openSchedule?.endDate)){
        setSelectedId('dates');
      } else {
        setSelectedId(null);
      }
    }
  }

  const renderItem = ({item}) => {
    selectedTick()
    return (
      <>
        <Checkbox onPress={() => onCheckChange(item)}>
          <TextContainer>
            <StyledText variant={'body3'} color={'primary'}>
              {item.label}
            </StyledText>
          </TextContainer>
          <RoundedCheckedContainer>
            {selectedId === item.id ? renderTick() : null}
          </RoundedCheckedContainer>
        </Checkbox>
      </>
    );
  };

  return (
    <Container width={width} ref={mainContainer}>
      <ItemsContainer>
        <FlatList
          data={items}
          renderItem={renderItem}
          keyExtractor={(item) => item.id.toString()}
        />
      </ItemsContainer>
      <CalendarContainer>{renderSubview()}</CalendarContainer>
    </Container>
  );
};

export default connect(mapStateToProps)(ListScheduleChoice);

const Container = styled.View<ViewProps>`
  width: ${(props) => `${props.width}px` ?? '200px'};
  z-index: 10;
  height: auto;
  display: flex;
  flex-direction: row;
`;

const ItemsContainer = styled.View`
  width: auto;
  flex-shrink: inherit;
`;

const CalendarContainer = styled.View``;

const WeekDayViewContainer = styled.View`
  padding: 0 24px;
  height: 64px;
  margin: 96px 0 22px;
`;

const PeriodCalendarContainer = styled.View``;

const TextContainer = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
`;

const CheckedContainer = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  background-color: ${palette.actionItem};
  border-radius: 12px;
  width: 24px;
  height: 24px;
`;

const Checkbox = styled.TouchableOpacity`
  width: 340px;
  height: 64px;
  flex-direction: row;
  align-items: center;
  margin: 16px 24px 0 0;
  padding: 16px;
  border-color: ${palette.border};
  border-width: 1px;
  box-shadow: ${shadow};
  border-radius: 4px;
  justify-content: space-between;
`;

const CheckedOnContainer = styled.TouchableOpacity`
  background-color: ${palette.border};
  border-radius: 6px;
  width: 12px;
  height: 12px;
`;

const RoundedCheckedContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${palette.actionItem};
  border-radius: 12px;
  width: 24px;
  height: 24px;
`;

const styles = StyleSheet.create({
  disabled: {
    opacity: '0.4',
  },
});
