import React, { useState, useMemo } from "react";
import { ScrollView, Dimensions, StyleSheet } from "react-native";
import {
  Calendar as CalendarReact,
  CalendarList,
  DateObject,
  LocaleConfig
} from "react-native-calendars";
import moment from "moment";

import { fetchSpacesAsync } from "../../features/space/actions";
import { setSelectedDate } from "../../features/booking/actions";

import { connect } from "react-redux";
import { RootState } from "StoreTypes";
import config from "../../config";
import DayView from "./DayView";
import HorizontalCalendar from "./HorizontalCalendar";
import SpaceHelper from "../../utils/SpaceHelper";
import styled from "styled-components/native";
import { colors } from "../../features/preferences/style/themes";
import { StyledText } from "@space/common";
import _ from "lodash";
import CalendarHeader from "./CalendarHeader";
import { localize } from '../../localization';
import { weeksForOfficeInAdvanceBookingLimit } from "@space/common";
import { Occupancy } from "SpaceFeature";

const mapStateToProps = (state: RootState) => ({
  isLoading: state.space.isLoading,
  error: state.space.error,
  spacesList: state.space.space,
  availableSpacesCache: state.space.availableSpacesCache,
  selectedDate: state.booking.selectedDate,
  organization: state.organization.organization,
  office: state.organization.office,
  officeOpenCache: state.organization.officeOpenCache
});

const dispatchProps = {
  fetchSpaces: fetchSpacesAsync.request,
  selectDate: setSelectedDate,
};

type CalendarProps = {
  displayWeekView: boolean;
  onDateSelect: (date: string) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  CalendarProps;

const CalendarsScreen: React.FC<Props> = ({
  spacesList,
  availableSpacesCache,
  selectedDate,
  fetchSpaces,
  office,
  officeOpenCache,
  displayWeekView,
  onDateSelect,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentDate, setCurrentDate] = useState(selectedDate);
  const isStartOfNextMonthOpen = moment().add(1, 'M').startOf('month');

  const startDateValue = () => {
    if (moment().isoWeekday() === 7) {
      return moment().locale(localize('LOCALE_LANGUAGE'));
    } else {
      return moment().locale(localize('LOCALE_LANGUAGE')).subtract(1, "week").endOf("week");
    }
  };

  const officeBookingWeeks = useMemo(() => {
    return office.inAdvanceBookingLimit ? weeksForOfficeInAdvanceBookingLimit(office.inAdvanceBookingLimit) : config.weeksToDisplay;
  }, [office]);

  const previousScroll = useMemo(() => {
    const current = moment(currentDate);
    return moment().utc().month() != current.month() ? 1 : 0;
  }, [currentDate]);

  const futureScroll = useMemo(() => {
    const current = moment(currentDate);
    const endInAdvance = moment().add(officeBookingWeeks, 'week').utc();
    return endInAdvance.month() != current.month() ? 1 : 0;
  }, [currentDate]);

  const occupancies: Map<string, Occupancy> = useMemo(() => {
    const result = new Map<string, any>();

    _.range(officeBookingWeeks).forEach((week) => {
      const weekStartDate = startDateValue().add(week, 'weeks');

      _.range(7).forEach((day) => {
        const date = moment(weekStartDate)
          .add(day, 'day')
          .format(config.defaultDateFormat);

        const occupancy = SpaceHelper.cacheForOccupancy(date, availableSpacesCache);

        if (occupancy != null && officeOpenCache.get(date)) {
          result.set(date, occupancy);
        }
      });
    });
  
    return result;
  }, [officeOpenCache, spacesList]);

  const occupancyForDate = (date: string) => {
    const isofficeOpen = officeOpenCache.size > 0 ? officeOpenCache.get(date) : false;
    if (!isofficeOpen) {
      return null;
    }
    return occupancies.get(date);
  };

  const didSelectDate = (day: DateObject) => {
    const date = day.dateString;
    onDateSelect(date);
    setCurrentDate(date);
  };

  const horizontalCalendarDidSelectDate = (date: string) => {
    onDateSelect(date);
    setCurrentDate(date);
  }

  const renderCalendarWithSelectableDate = () => {
    LocaleConfig.defaultLocale = localize('LOCALE_LANGUAGE')
    return (
      <CalendarList
        monthFormat={'MMMM'}
        calendarWidth={_.min([Dimensions.get("window").width, 375])}
        calendarHeight={300}
        pastScrollRange={previousScroll}
        current={currentDate}
        futureScrollRange={futureScroll}
        style={styles.calendar}
        onDayPress={didSelectDate}
        markedDates={{
          [currentDate]: { selected: true, marked: true },
        }}
        dayComponent={(props) => (
          <DayView
            {...props}
            occupation={
              SpaceHelper.isOfficeOpened(props.date.dateString, officeOpenCache, spacesList, availableSpacesCache)?.occupancy ?? null
            }
          />
        )}
        hideDayNames={true}
        theme={{
          calendarBackground: "",
          "stylesheet.day.basic": {
            base: {
              width: 32,
              height: 32,
            },
          },
          monthTextColor: colors.grey3,
          "stylesheet.calendar.main": {
            week: {
              marginTop: 0,
              marginBottom: 0,
              flexDirection: "row",
              justifyContent: "space-around",
            },
          },
          "stylesheet.calendar-list.main": {
            calendar: {
              paddingLeft: 24,
              paddingRight: 24,
              height: 'auto'
            },
          },
          "stylesheet.calendar.header": {
            header: {
              alignItems: "flex-start",
              paddingLeft: 11,
              height: 30,
            },
            monthText: {
              fontSize: 13,
              fontFamily: 'Barlow_400Regular',
              color: colors.grey3,
              margin: 10,
            },
          },
        }}
        onMonthChange={(date) => {
          if (moment(date).isAfter(moment().startOf("day"))) {
            //TODO change state of range with setRange()

            fetchSpaces({
              startDate: moment(date.dateString)
                .subtract(1, "months")
                .format(config.defaultDateFormat),
              endDate: moment(date.dateString)
                .add(1, "months")
                .format(config.defaultDateFormat),
            });
          }
        }}
      />
    );
  };

  var daysView: any[] = _.range(7).map((day) => {
    const date = startDateValue().day(day);
    return (
      <StyledText
        key={day}
        variant={"body4"}
        fontSize={11}
        fontWeight={"600"}
        color={_.includes([6, 7], date.isoWeekday()) ? "hint" : "primary"} //TODO : Fix color
        style={
          textStyles({ disabled: _.includes([6, 7], date.isoWeekday()) })
            .dayText
        }
      >
        {date.locale(localize('LOCALE_LANGUAGE')).format("dd").charAt(0).toUpperCase()}
      </StyledText>
    );
  });

  return (
    <>
      {!displayWeekView && <CalendarHeader displayWeekView={displayWeekView} selectedDate={currentDate}/>}           
      {!displayWeekView && <DaysContainer>{daysView}</DaysContainer>}
      {displayWeekView ? (
        <HorizontalCalendar
          startDate={moment().startOf('week').format(config.defaultDateFormat)}
          weeks={officeBookingWeeks}
          occupationForDate={(date) =>
            occupancyForDate(date)?.occupancy ?? null
          }
          daysView={daysView}
          onDateSelect={horizontalCalendarDidSelectDate}              
        />
      ) : (
        <ScrollView showsVerticalScrollIndicator={false}>
          {renderCalendarWithSelectableDate()}
        </ScrollView>
      )}
    </>
  );
};

export default connect(mapStateToProps, dispatchProps)(CalendarsScreen);

type DayTextProps = {
  selected: boolean;
};

type HeaderProps = {
  displayWeekView: boolean;
};

const DaysContainer = styled.View`
  flex-direction: row;
  width: 100%;
  max-width: 375px;
  justify-content: space-around;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 2px;
  align-items: stretch;
`;

const styles = StyleSheet.create({
  calendar: {
    maxWidth: 375,
    height: 305,
    paddingBottom: 4,
  },
});

const textStyles = (props) =>
  StyleSheet.create({
    todayText: {
      textAlign: "center",
      paddingVertical: 2,
      paddingHorizontal: props.selected ? 8 : 0,
      borderRadius: 4,
      height: 16,
      width: props.selected ? "auto" : 0,
      color: colors.white,
    },
    dateText: {
      marginLeft: 8,
      letterSpacing: 3.2,
    },
    dayText: {
      paddingBottom: 1,
      paddingTop: 2,
      textAlign: "center",
      width: 32,
      height: 18,
    },
  });
