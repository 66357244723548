import React from 'react';
import { useTheme } from 'styled-components/native';

import { palette } from '@features/preferences/style/themes';
import { SvgProps } from 'react-native-svg';
import { ThemeUtil } from '@space/common';

type SvgIconProps = {
  svg: React.FC<SvgProps>;
  fillColor?: string;
  width?: number;
  height?: number;
  inverted?: boolean;
};

const SvgIcon: React.FC<SvgIconProps> = ({
  svg,
  fillColor,
  width,
  height,
  inverted,
}) => {
  const styleName = useTheme().mode;
  const props = {
    fill: fillColor
      ? fillColor
      : inverted
      ? ThemeUtil.getInvertedThemeValue(palette.common)(styleName)
      : ThemeUtil.getThemeValue(palette.common)(styleName),
    width: width ?? 24,
    height: height ?? 24,
  };

  return svg(props);
};

export default SvgIcon;
