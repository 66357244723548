import React, { useContext } from 'react';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import { useNavigation, useRoute, Link } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import SvgIcon, { SvgType } from '@components/SvgIcon';
import Avatar from '@components/Avatar';
import { Divider, StyledText } from '@space/common';
import { HomeViewName } from '../../../routes/HomeView';
import { CalendarViewName } from '../../../routes/CalendarView';
import { BookingsViewName } from '../../../routes/BookingsView';
import { SpacesViewName } from '../../../routes/SpacesView';
import { SettingsViewName } from '../../../routes/SettingsView';
import { UsersViewName } from '../../../routes/UsersView';
import { colors, palette } from '../../preferences/style/themes';
import { localize } from '../../../localization';
import { logoutAsync } from '../../../features/login/actions';
import { useCallback } from 'react';
import ThemeSwitch from '@features/preferences/components/ThemeSwitch';
import Logo from '../../../img/flexable_logo.svg';
import Expand from '../../../img/expand.svg';
import Expanded from '../../../img/expanded.svg';
import Check from '../../../img/checked.svg';
import config from '../../../config';
import SpacingView from '@components/SpacingView';
import { StackNavigationProp } from '@react-navigation/stack';
import { AuthManager } from '../../../auth/AuthManager';

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
});

const dispatchProps = {
  logout: logoutAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Menu: React.FC<Props> = ({ user, logout }) => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const route = useRoute();
  const [expanded, setExpanded] = React.useState(false);
  const [userType, setUserType] = React.useState(localize('ADMIN'));

  const onMenuItemPressed = (menuNavigation: string) => {
    navigation.push(menuNavigation);
  };

  const onAdminMenuItemPressed = (item: string) => {
    setUserType(item);
    setExpanded(!expanded);
  };

  const expandButtonPressed = () => {
    setExpanded(!expanded);
  };

  const onLogoPress = () => {
    navigation.push(HomeViewName);
  };

  const logoutButtonPressed = () => {
    AuthManager.getInstance()
      .signOut()
      .then(() => logout());
  };

  const keyExtractor = (item) => item;

  const renderExternalLink = (title, url) => {
    return (
      <LinkToClientApp href={url} accessibilityRole="link" target={'_self'}>
        <StyledText variant={'body3'}>{title}</StyledText>
      </LinkToClientApp>
    );
  };

  const renderSimpleItem = (item) => {
    return <StyledText variant={'body3'}>{item}</StyledText>;
  };

  const renderAdminItem = useCallback(
    ({ item }) => {
      return (
        <AdminItemContainer
          key={item}
        >
          {item === localize('PERSONAL')
            ? renderExternalLink(item, config.url.client)
            : renderSimpleItem(item)}
          {userType === item ? <SvgIcon svg={Check} inverted={true} /> : null}
        </AdminItemContainer>
      );
    },
    [userType, expanded]
  );

  const adminMenu = () => {
    return (
      <AdminMenuContainer>
        <BottomListContainer>
          <LogOutButton onPress={logoutButtonPressed}>
            <StyledText variant={'body2'}>{localize('LOGOUT')}</StyledText>
          </LogOutButton>
          <Divider />
          <AdminListContainer
            data={[localize('PERSONAL'), localize('ADMIN')]}
            keyExtractor={keyExtractor}
            renderItem={renderAdminItem}
            showsVerticalScrollIndicator={false}
          />
        </BottomListContainer>
      </AdminMenuContainer>
    );
  };

  const menuItems = [
    HomeViewName,
    CalendarViewName,
    BookingsViewName,
    SpacesViewName,
    UsersViewName,
    SettingsViewName,
  ];

  const renderMenuItem = useCallback(
    ({ item }) => {
      return (
        <ItemContainer key={item} onPress={() => onMenuItemPressed(item)}>
          {route.name === item ? (
            <SelectedDot />
          ) : (
            <SpacingView width={'16px'} />
          )}
          <StyledText
            variant={'h3'}
            lineHeight={'14px'}
            color={route.name === item ? 'quaternary' : undefined}
          >
            {localize(item)}
          </StyledText>
        </ItemContainer>
      );
    },
    [route.name]
  );

  return (
    <MenuContainer>
      <SwitchContainer>
        <TouchableOpacity onPress={onLogoPress}>
          <SvgIcon svg={Logo} inverted={true} width={185} height={190} />
        </TouchableOpacity>
      </SwitchContainer>
      {/* <ThemeSwitch /> */}
      <ListContainer
        data={(user.isAdmin || process.env.NODE_ENV !== "development") ? menuItems : menuItems.filter(item => item != SettingsViewName)}
        keyExtractor={keyExtractor}
        renderItem={renderMenuItem}
      />
      {expanded ? adminMenu() : null}
      {!expanded ? (
        <Divider />
      ) : null}
      <BottomContainer onPress={expandButtonPressed}>
        <LeftContent>
          <Avatar
            name={user.firstName}
            lastName={user.lastName}
            src={user.avatar}
            onPress={expandButtonPressed}
          />
          <StyledText variant={'body3'} style={{ marginLeft: 13 }}>
            {userType}
          </StyledText>
        </LeftContent>
        <ExpandButton>
          <SvgIcon
            svg={expanded ? Expanded : Expand}
            inverted={true}
            width={14}
            height={8}
          />
        </ExpandButton>
      </BottomContainer>
    </MenuContainer>
  );
};

const MenuContainer = styled.View`
  width: 276px;
  height: 100vh;
  padding: 45px 0px 0px 0px;
  border-right-color: ${palette.border};
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: ${palette.border};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-color: ${palette.background};
`;

const SwitchContainer = styled.View`
  padding: 0px 0px 0px 36px;
`;

const HeaderContainer = styled.View`
  padding: 0px 16px 0px 36px;
  height: 90px;
  margin: 0px 0px 46px 0px;
`;

const ListContainer = styled.FlatList`
  padding: 0px 16px 0px 36px;
`;

const SelectedDot = styled.View`
  margin: 0px 8px 0px 0px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  align-self: center;
  background-color: ${colors.quaternary};
`;

const ItemContainer = styled.TouchableOpacity`
  margin: 0px 0px 34px 0px;
  flex-direction: row;
`;

const LogOutButton = styled.TouchableOpacity`
  padding: 24px 0px 19px 0px;
`;

const BottomListContainer = styled.View`
  padding: 0px 24px 0px 24px;
`;

const AdminMenuContainer = styled.View`
  width: 100%;
  flex-grow: 1;
  border-top-color: ${palette.border};
  background-color: ${palette.background};
  border-top-style: solid;
  border-top-width: 1px;
  position: absolute;
  bottom: 65px;
`;

const AdminItemContainer = styled.View`
  height: 22px;
  margin: 24px 0px 0px 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AdminListContainer = styled.FlatList`
  margin: 0px 0px 10px 0px;
`;

const BottomContainer = styled.TouchableOpacity`
  height: 65px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 24px 0px 24px;
`;

const ExpandButton = styled.View`
  align-items: center;
  justify-content: center;
`;

const LeftContent = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LinkToClientApp = styled(Link)`
  width: 100%;
`;

export default connect(mapStateToProps, dispatchProps)(Menu);
